import Model from './model';
import OC from '../';

const legacyPaletteColors = [
  { id: "0", option: "#fafafa", backgroundColor: "", textColor: "" },
  { id: "1", option: "#fffbe6", backgroundColor: "#FEFFE6", textColor: "#876800" },
  { id: "2", option: "#f4ffb8", backgroundColor: "#FCFFE6", textColor: "#237804" },
  { id: "3", option: "#d9f7be", backgroundColor: "#F6FFED", textColor: "#006D75" },
  { id: "4", option: "#b5f5ec", backgroundColor: "#E6FFFB", textColor: "#006D75" },
  { id: "5", option: "#bae7ff", backgroundColor: "#E6F7FF", textColor: "#0050B3" },
  { id: "6", option: "#efdbff", backgroundColor: "#F9F0FF", textColor: "#391085" },
  { id: "7", option: "#ffd6e7", backgroundColor: "#FFF0F6", textColor: "#A8071A" },
  { id: "8", option: "#ffd8bf", backgroundColor: "#FFF2E8", textColor: "#AD4E00" },
];

export default class Post extends Model {
  id = null;
  ownerInfo = { ref: null, name: null, location: null, ownerId: null };
  message = null;
  // liked = null; // Deprecated
  themeId = null;
  gify = null;
  createdAt = null;
  asyncData = { avatarURL: null };
  getKey() {
    return this.id;
  }
  parse(id, data) {
    this.id = id;
    this.ownerInfo = data.ownerInfo;
    if (data.ownerInfo && data.ownerInfo.ref) {
      this.ownerInfo.ownerId = data.ownerInfo.ref.id;
    }
    this.message = data.message;
    // this.liked = data.liked; // Deprecated
    this.themeId = data.themeId ? data.themeId : this._convertFromPalette(data.palette);
    this.gify = data.gify || null;
    this.createdAt = data.createdAt;
    return this;
  }
  getDoc() {
    return {
      ownerInfo: {
        ref: this.ownerInfo.ref,
        name: this.ownerInfo.name || null,
        location: this.ownerInfo.location || null,
      },
      message: this.message || null,
      // liked: this.liked || [], // Deprecated
      themeId: this.themeId || "0",
      gify: this.gify || null,
      createdAt: this.createdAt,
    }
  }
  fetchAvatar(callback) {
    if (this.ownerInfo.ref && this.ownerInfo.ref.get) {
      this.ownerInfo.ref.get().then(res => {
        if (res && res.data) {
          const user = res.data();
          if (user && user.avatar) {
            OC.getInstance().getImageURL(user.avatar).then(url => {
              if (url) {
                this.asyncData.avatarURL = url;
                callback && callback(url);
                return;
              }
            });
          }
        }
      });
    }
    callback && callback(null);
  }
  getAvatar() {
    if (this.asyncData && this.asyncData.avatarURL) {
      return this.asyncData.avatarURL;
    }
    return null;
  }
  _convertFromPalette(oldPalette) {
    if (oldPalette) {
      try {
        const item = legacyPaletteColors.find(
          (element) => element.option.toLowerCase() === oldPalette.toLowerCase()
        );
        return item ? item.id : null;
      } catch {
        return null;
      }
    } else {
      return null;
    }
  }
}