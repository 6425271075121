import { Plan } from "./models/stripe_models";
import {
  PLAN_TYPE_SUBSCRIPTION,
  PLAN_TYPE_PAYMENT,
  PLAN_TYPE_FREE_COMPANY,
  DEFAULT_USER_LEVEL_PLAN,
} from "../shared/constants";

export class StripeManager {
  payments = null;
  subs = null;
  userDefaultPlan = null;
  freeCompanyPlan = false;

  constructor(user, company, stripeActiveSubscriptions, succeededPayments) {
    this.subs = stripeActiveSubscriptions || [];
    this.payments = succeededPayments || [];
    this.userDefaultPlan = user.defaultPlan
      ? user.defaultPlan
      : DEFAULT_USER_LEVEL_PLAN;
    this.freeCompanyPlan =
      company.defaultPlan && company.defaultPlan === PLAN_TYPE_FREE_COMPANY
        ? true
        : false;
  }

  async getActivePlan() {
    if (this.freeCompanyPlan) {
      const activePlan = new Plan();
      activePlan.type = PLAN_TYPE_FREE_COMPANY;
      activePlan.name = "Free (company-wide)";
      return activePlan;
    }

    const remainingCardsFromUser = this._getRemainingFromUserDefaultPlan();
    const aggSub = await this._getAggregatedSubInfo();
    const aggPayments = await this._getAggregatedPaymentInfo();
    const remainingFromAll =
      remainingCardsFromUser +
      aggSub.remainingCards +
      aggPayments.remainingCards;
    const primarySub = await this._findPrimaryRedeemableSubscription();

    if (aggSub.remainingCards && primarySub) {
      return await this._getSubscriptionPlanAsync(
        primarySub,
        remainingFromAll,
        aggSub.totalCards + aggPayments.totalCards + remainingCardsFromUser,
        aggSub.autoRenewingSubscription
      );
    } else if (aggPayments.remainingCards && aggPayments.primaryPayment) {
      var planName = "";
      if (primarySub) {
        planName = await primarySub.getProductName();
      } else {
        planName = this.userDefaultPlan.name;
      }
      return await this._getPaymentPlanAsync(
        remainingFromAll,
        aggPayments.totalCards + remainingCardsFromUser,
        aggPayments.primaryPayment.id || null,
        planName,
        aggSub.autoRenewingSubscription,
      );
    } else if (remainingCardsFromUser) {
      return await this._getUserPlan(aggSub.autoRenewingSubscription);
    } else {
      if (primarySub) {
        return await this._getSubscriptionPlanAsync(
          primarySub,
          0,
          aggSub.totalCards + aggPayments.totalCards + remainingCardsFromUser,
          aggSub.autoRenewingSubscription
        );
      } else {
        return await this._getUserPlan(aggSub.autoRenewingSubscription);
      }
    }
  }
  hasMultipleRenewalDanger() {
    if (!this.subs || !this.subs.length) {
      return false;
    }
    var count = 0;
    for (const sub of this.subs) {
      if (sub.willRenewAfterExpiry()) {
        count++;
      }
    }
    return count > 1;
  }

  /**
   * ALL RIVATE FUNCTIONS BELOW:
   */

  _getRemainingFromUserDefaultPlan() {
    return this.userDefaultPlan.remainingCards;
  }
  async _getAggregatedSubInfo() {
    var totalCards = 0;
    var availableCards = 0;
    var autoRenewingSub = null;
    if (this.subs && this.subs.length) {
      for (const sub of this.subs) {
        if (sub.willRenewAfterExpiry()) {
          autoRenewingSub = sub;
        }
        if (sub.isRecurring()) {
          const tol = await sub.getTotalCardsAsync();
          const avl = await sub.getAvailableCardsAsync();
          availableCards += avl;
          if (avl) {
            totalCards += tol;
          }
        }
      }
    }
    return {
      totalCards: totalCards,
      remainingCards: availableCards,
      autoRenewingSubscription: autoRenewingSub,
    };
  }
  async _getAggregatedPaymentInfo() {
    var totalCards = 0;
    var availableCards = 0;
    var primaryPayment = null;
    if (this.payments && this.payments.length) {
      for (const pay of this.payments) {
        if (!pay.isConsumed()) {
          if (primaryPayment === null) {
            primaryPayment = pay;
          }
          availableCards += 1;
          totalCards += 1;
        }
      }
    }
    return {
      totalCards: totalCards,
      remainingCards: availableCards,
      primaryPayment: primaryPayment,
    };
  }

  async _getUserPlan(autoRenewingSubscription) {
    const renewingCardsCount = await autoRenewingSubscription?.getTotalCardsAsync();
    const plan = new Plan();
    plan.type = this.userDefaultPlan.type;
    plan.name = this.userDefaultPlan.name;
    plan.expiry = autoRenewingSubscription?.getExpiryDate() || null;
    plan.remainingCards = this.userDefaultPlan.remainingCards;
    plan.totalCards = this.userDefaultPlan.totalCards;
    plan.renewingSubId = autoRenewingSubscription?.id || null;
    plan.renewingCardsCount = renewingCardsCount || 0;
    return plan;
  }
  async _getSubscriptionPlanAsync(
    primarySub,
    remainingCardsAll,
    totalCardsAll,
    autoRenewingSubscription
  ) {
    const productName = await primarySub.getProductName();
    const renewingCardsCount = await autoRenewingSubscription?.getTotalCardsAsync();
    const plan = new Plan();
    plan.type = PLAN_TYPE_SUBSCRIPTION;
    plan.name = productName;
    plan.expiry = autoRenewingSubscription?.getExpiryDate() || null;
    plan.remainingCards = remainingCardsAll;
    plan.totalCards = totalCardsAll || 0;
    plan.redeemableDocId = primarySub.id;
    plan.renewingSubId = autoRenewingSubscription?.id || null;
    plan.renewingCardsCount = renewingCardsCount || 0;
    return plan;
  }
  async _getPaymentPlanAsync(
    remainingCardsAll,
    totalCardsAll,
    paymentId,
    planName,
    autoRenewingSubscription,
  ) {
    const renewingCardsCount = await autoRenewingSubscription?.getTotalCardsAsync();
    const plan = new Plan();
    plan.type = PLAN_TYPE_PAYMENT;
    plan.name = planName;
    plan.expiry = autoRenewingSubscription?.getExpiryDate() || null;
    plan.remainingCards = remainingCardsAll;
    plan.totalCards = totalCardsAll || 0;
    plan.redeemableDocId = paymentId;
    plan.renewingSubId = autoRenewingSubscription?.id || null;
    plan.renewingCardsCount = renewingCardsCount || 0;
    return plan;
  }

  async _findPrimaryRedeemableSubscription() {
    var current = null;
    if (this.subs && this.subs.length) {
      for (const sub of this.subs) {
        if (await sub.getAvailableCardsAsync()){
          if (current === null) {
            current = sub;
          } else {
            current = this._selectOne(current, sub);
          }
        }
      }
    }
    return current;
  }
  _selectOne(subA, subB) {
    if (!subA.hasExpiry() && !subB.hasExpiry()) {
      return subA.getCreatedValueOf() < subB.getCreatedValueOf() ? subA : subB;
    }
    if (subA.hasExpiry() && subB.hasExpiry()) {
      return subA.getExpiryValueOf() < subB.getExpiryValueOf() ? subA : subB;
    }
    return subA.hasExpiry() ? subA : subB;
  }
}
