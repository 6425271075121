import React from "react";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import { Button, Form, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./styles/Modal.less";
import awModalSettings from "./img/aw-modal-company.png";
import ImageUploader from "./custom/ImageUploader";
import OC from "../oc";
import { OC_PROD_TITLE, OC_CARD_SINGULAR, OC_CARD_PLURAL } from "../shared/constants";

class ModalCompanySettings extends React.Component {
  constructor(props) {
    super(props);
    const { company } = this.props.metadata;
    this.state = {
      logoFile: null,
      name: company && company.name,
      brandColor: company && company.brandColor,
      defaultPreview: null,
      componentBusy: false,
    };
    this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onLooksGoodClick = this.onLooksGoodClick.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
  }
  loadCurrentLogo() {
    const { company } = this.props.metadata;
    const currentLogo = company && company.logo;
    if (currentLogo) {
      OC.getInstance()
        .getImageURL(currentLogo)
        .then((url) => {
          if (!url) {
            return;
          }
          this.setState({ defaultPreview: url });
        });
    }
  }
  removeLogo() {
    const { company } = this.props.metadata;
    const currentLogo = company && company.logo;
    if (currentLogo) {
      setTimeout(() => this.setState({ componentBusy: true }));
      OC.getInstance()
        .deleteImage(currentLogo)
        .then((err) => {
          setTimeout(() => this.setState({ componentBusy: false }));
          if (err) {
            message.error(
              "Could not remove company logo. Please check network connection."
            );
            return;
          }
          this.props.onRemoveLogo && this.props.onRemoveLogo();
          this.setState({ logoFile: null, defaultPreview: null });
          message.info("Company logo removed.");
        });
    }
  }
  onFileChanged(file) {
    if (!file) {
      this.removeLogo();
      return;
    }
    this.setState({ logoFile: file });
  }
  onCompanyNameChange(e) {
    this.setState({ name: e.target.value });
  }
  onColorChange(color) {
    this.setState({ brandColor: color.color });
  }
  onLooksGoodClick() {
    if (this.props.busyInitCard || this.state.componentBusy) {
      return;
    }
    if (!this.state.name) {
      message.info("Please provide organization name.");
      return;
    }
    this.props.onSubmit(this.state);
  }
  componentDidMount() {
    this.loadCurrentLogo();
  }
  render() {
    return (
      <div className="ModalCompanySettings">
        <div className="title">
          <div className="artwork">
            <img src={awModalSettings} alt={OC_PROD_TITLE} />
          </div>
          <h2>Make your {OC_CARD_PLURAL} look great!</h2>
          <p>
            The following organization settings are used on all {OC_CARD_PLURAL}
            .
          </p>
        </div>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <Form.Item>
            <h4>Your Organization’s Name</h4>
            <Input
              onChange={this.onCompanyNameChange}
              placeholder="Dunder Mifflin"
              prefix={<SearchOutlined />}
              value={this.state.name}
            />
          </Form.Item>
          <Form.Item>
            <h4>Your Organization’s Logo (optional)</h4>
            <ImageUploader
              allowGif={true}
              defaultPreview={this.state.defaultPreview}
              onFileChanged={this.onFileChanged}
            >
              <p className="ant-upload-text">
                Drag &amp; Drop Company Logo or <Button>Browse</Button>
              </p>
            </ImageUploader>
          </Form.Item>
          <div className="brand-color">
            <div>
              <h4>Brand Color (optional)</h4>
              <p>Used for all {OC_CARD_SINGULAR} titles and cover art.</p>
            </div>

            <div className="company-color">
              <i
                className="far fa-paint-brush-alt"
                style={{ pointerEvents: "none" }}
              ></i>
              <ColorPicker
                className="color-picker"
                onChange={this.onColorChange}
                defaultColor="#049fd9"
                color={this.state.brandColor}
              />
            </div>
          </div>
          <Form.Item>
            <Button
              loading={this.props.busyInitCard || this.state.componentBusy}
              disabled={this.props.busyInitCard || this.state.componentBusy}
              onClick={this.onLooksGoodClick}
              type="primary"
              size="large"
              block
              htmlType="submit"
            >
              Done
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ModalCompanySettings;
