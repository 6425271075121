import React from "react";
import { Button } from "antd";
import "./styles/Modal.less";
import EmojiHandler from "./EmojiHandler";
import { categoryData } from "../shared/constants";
import { groupCardsExist } from "../shared/util";

class ModalCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasGroupCards: groupCardsExist(),
    };
  }
  onGroupCategoryClick(catKey) {
    const { onGroupCardSelected, busyInitCard } = this.props;
    if (busyInitCard) {
      return;
    }
    onGroupCardSelected && onGroupCardSelected(catKey);
  }
  onCategoryClick(catKey) {
    const { onSubmit } = this.props;
    onSubmit && onSubmit(catKey);
  }
  render() {
    const { hasGroupCards } = this.state;
    return (
      <div className="ModalCategory">
        <h2>Ocassion</h2>
        <span>
          {hasGroupCards ? (
            <h3 style={{ marginTop: 24 }}>For Someone</h3>
          ) : null}

          {this.props.metadata.categories.map((item, i) => {
            if (item.isGroupType || item.isHidden) {
              return null;
            }
            return (
              <Button
                className={`${
                  item.key === this.props.selectedCategory
                    ? "selected-category"
                    : ""
                }`}
                onClick={this.onCategoryClick.bind(this, item.key)}
                key={i}
              >
                <EmojiHandler>
                  <span role="img" aria-labelledby="OC">
                    {categoryData[item.key].icon}
                  </span>
                </EmojiHandler>
                {item.name}
              </Button>
            );
          })}
        </span>

        {hasGroupCards && !this.props.hideGroupCategories ? (
          <span>
            <h3 style={{ marginTop: 12 }}>For Everyone at the Company</h3>

            {this.props.metadata.categories.map((item, i) => {
              if (!item.isGroupType || item.isHidden) {
                return null;
              }
              return (
                <Button
                  className={`${
                    item.key === this.props.selectedCategory
                      ? "selected-category"
                      : ""
                  }`}
                  onClick={this.onGroupCategoryClick.bind(this, item.key)}
                  key={i}
                >
                  <EmojiHandler>
                    <span role="img" aria-labelledby="OC">
                      {categoryData[item.key].icon}
                    </span>
                  </EmojiHandler>
                  {item.name}
                </Button>
              );
            })}
          </span>
        ) : null}
      </div>
    );
  }
}

export default ModalCategory;
