import React from "react";
import "../styles/HeaderOne.less";
import { Layout, Button, Space, Tooltip } from "antd";
import AuthHeader from "../AuthHeader";
import logo from "../img/logo-greeted.svg";
import { categoryData, OC_PROD_TITLE } from "../../shared/constants";
import * as nav from "../../routes/nav";
import EmojiHandler from "../EmojiHandler";
import UserAvatar from "../custom/UserAvatar";
import moment from "moment-timezone";
import { getCurrentTimezoneName } from "../../shared/timezones";
import { copyLink } from "../../shared/util";

const { Header } = Layout;
const zoneName = getCurrentTimezoneName();

class HeaderPostingView extends React.Component {
  constructor(props) {
    super(props);
    this.onCopyLinkClicked = this.onCopyLinkClicked.bind(this);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.showInvitesModal = this.showInvitesModal.bind(this);
  }
  showInvitesModal() {
    const { toggleInviteModal } = this.props;
    toggleInviteModal && toggleInviteModal(true);
  }
  // Hiding mobile menu for PostingContainer
  // getMenu(cardData, category, selectedCategory) {
  //   const isGroup = cardData.isGroupType ? cardData.isGroupType() : false;
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <EmojiHandler>
  //           <span role="img" aria-label="emoji" className="emoji">
  //             {category && categoryData[category].icon}
  //           </span>
  //         </EmojiHandler>
  //         <span className="selected-category">
  //           {selectedCategory && selectedCategory.name}
  //         </span>
  //         <span className="name" style={isGroup ? { display: "none" } : null}>
  //           <UserAvatar
  //             key={`menu_header_avatar_${
  //               cardData && cardData.recipient ? cardData.recipient.email : null
  //             }`}
  //             size="small"
  //             fetchPath={cardData.getAvatarFetchPath()}
  //             name={cardData.getRecipientName()}
  //           />{" "}
  //           <span className="ellipsis">{cardData.getRecipientName()}</span>
  //         </span>
  //       </Menu.Item>
  //       <Menu.Item key="2">
  //         <span className="status">
  //           <i
  //             className={
  //               cardData.postsDisabled ? "fal fa-lock" : "fal fa-lock-open"
  //             }
  //           ></i>{" "}
  //           {cardData.postsDisabled ? "Published" : "Open for Posts"}
  //         </span>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }
  // getMobileMenu(cardData, category, selectedCategory) {
  //   return (
  //     <Dropdown
  //       overlay={this.getMenu(cardData, category, selectedCategory)}
  //       className="mobile-header"
  //       overlayClassName="mobile-header-overlay"
  //       trigger={["click"]}
  //     >
  //       <Button
  //         type="link"
  //         className="ant-dropdown-link"
  //         onClick={(e) => e.preventDefault()}
  //       >
  //         <i className="fal fa-bars"></i>
  //         <i className="fal fa-times"></i>
  //       </Button>
  //     </Dropdown>
  //   );
  // }
  onLogoClick() {
    nav.gotoMain();
  }
  onCopyLinkClicked() {
    copyLink("HeaderPostingView:onCopyLinkClicked");
  }
  getTag(cardData) {
    if (cardData.delivery && cardData.delivery.utc) {
      return (
        <Tooltip placement="top" title={zoneName}>
          <span className={`status ${"status-scheduled"}`}>
            <i className="fal fa-paper-plane"></i>
            {moment
              .utc(cardData.delivery.utc)
              .local()
              .format("MMM DD [at] hh:mm a")}
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className={`status ${"status-open"}`}>
          <i className={"fal fa-lock-open"}></i> {"Open for posts"}{" "}
        </span>
      );
    }
  }
  render() {
    const { cardData, categoriesList, isLoggedIn } = this.props;
    const { category } = cardData;
    const selectedCategory =
      categoriesList && categoriesList !== undefined
        ? categoriesList.find((element) => element.key === cardData.category)
        : null;
    const isGroup = cardData.isGroupType ? cardData.isGroupType() : false;
    return (
      <div className="HeaderDraft posting-view">
        <Layout>
          <Header theme="light">
            <div className="logo-parent">
              <div className="logo">
                <Button type="link" onClick={this.onLogoClick}>
                  <img src={logo} alt={OC_PROD_TITLE} />
                </Button>
              </div>
              <div className="cardfor">
                <Space size={0}>
                  <EmojiHandler>
                    <span role="img" aria-label="emoji" className="emoji">
                      {category && categoryData[category].icon}
                    </span>
                  </EmojiHandler>
                  <span className="selected-category">
                    {selectedCategory && selectedCategory.name}
                  </span>
                  <span
                    className="name"
                    style={isGroup ? { display: "none" } : null}
                  >
                    <UserAvatar
                      key={`header_post_avatar_${
                        cardData && cardData.recipient
                          ? cardData.recipient.email
                          : null
                      }`}
                      size="small"
                      fetchPath={cardData.getAvatarFetchPath()}
                      name={cardData.getRecipientName()}
                    />
                    <span className="ellipsis">
                      {cardData.getRecipientName()}
                    </span>
                  </span>
                  {/* Hiding mobile menu for PostingContainer */}
                  {/* {this.getMobileMenu(cardData, category, selectedCategory)} */}
                </Space>
              </div>
            </div>

            <div className="actions">
              <Space size={12}>
                {!cardData.postsDisabled && isLoggedIn ? (
                  <Tooltip
                    trigger="hover"
                    zIndex={1}
                    title="Invite others to make posts."
                  >
                    <Button
                      type="link"
                      className="copy-link"
                      onClick={this.showInvitesModal}
                    >
                      <i className="fal fa-share"></i>&nbsp;Invite Others
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    trigger="hover"
                    zIndex={1}
                    title="Invite others to make posts."
                  >
                    <Button
                      type="link"
                      className="copy-link"
                      onClick={this.onCopyLinkClicked}
                    >
                      <i className="fal fa-link"></i> Copy Link
                    </Button>
                  </Tooltip>
                )}
                {this.getTag(cardData)}
                <AuthHeader />
              </Space>
            </div>
          </Header>
        </Layout>
      </div>
    );
  }
}

export default HeaderPostingView;
