import React from "react";
import "./styles/VerifyEmail.less";
import { Button, Space, message } from "antd";
import pending from "./img/artwork-pendingemail.png";
import gmail from "./img/icon-gmail.svg";
import outlook from "./img/icon-outlook.svg";
import yahoo from "./img/icon-yahoo.svg";
import OC from "../oc";
import { OC_TYPE_FORGOT, OC_PROD_TITLE } from "../shared/constants";

class CreatePassLinkSent extends React.Component {
  constructor(props) {
    super(props);
    this.onResend = this.onResend.bind(this);
    this.state = {
      busy: false,
    };
  }
  async onResend() {
    const { user } = this.props;
    if (!user) {
      return false;
    }
    this.setState({ busy: true });
    const err = await OC.getInstance().sendMagicLink(
      user.email,
      OC_TYPE_FORGOT
    );
    if (err) {
      message.error("Could not send email with password creation link.");
    }
    this.setState({ busy: false });
  }
  render() {
    return (
      <div className="VerifyEmail Pending">
        <div className="title">
          <div className="brand-logo"></div>
          <div className="artwork">
            <img src={pending} alt={OC_PROD_TITLE} />
          </div>
          <h2>We've emailed you a password creation link</h2>
          <p style={{ textAlign: "center" }}>
            Simply click on the link to set your new password.
          </p>
        </div>

        <div className="services">
          <Space size="large">
            <Button size="large" href="http://gmail.com" target="blank">
              <img src={gmail} alt={OC_PROD_TITLE} /> Open Gmail
            </Button>
            <Button size="large" href="http://outlook.com" target="blank">
              <img src={outlook} alt={OC_PROD_TITLE} /> Open Outlook
            </Button>
            <Button size="large" href="http://login.yahoo.com" target="blank">
              <img src={yahoo} alt={OC_PROD_TITLE} /> Open Yahoo
            </Button>
          </Space>
        </div>
        <p className="text-center resend-email">
          Didn’t receive email?&nbsp;
          <Button
            type="link"
            loading={this.state.busy}
            disabled={this.state.busy}
            onClick={this.onResend}
          >
            Resend Email
          </Button>
        </p>
      </div>
    );
  }
}

export default CreatePassLinkSent;
