import * as types from '../constants';
import OC from '../oc';
import moment from 'moment';

export function toggleInviteModal(visible) {
  return (dispatch) => {
    return dispatch({
      type: types.ON_TOGGLE_INVITE_MODAL,
      visible: visible,
    });
  };
}

export function fetchCard(id) {
  return dispatch => {
    OC.getInstance().findCard(id)
      .then((res) => {
        return dispatch(findCardDispatch(res || {}));
      });
  }
}

export function fetchCompanyCard(id, companyId) {
  return dispatch => {
    OC.getInstance().findCompanyCard(id, companyId || null)
      .then((res) => {
        return dispatch(findCardDispatch(res || {}));
      });
  }
}

export function saveCard(card, onCardCreate) {
  return dispatch => {
    OC.getInstance().saveCard(card)
      .then((err) => {
        if (err) {
          onCardCreate && onCardCreate(null);
          return dispatch(saveCardFailedDispatch(err));
        } else {
          onCardCreate && onCardCreate(card);
          return dispatch(saveCardSuccessDispatch(card));
        }
      });
  }
}

export function fetchPosts(cardId, usersList, aysncCallback) {
  return dispatch => {
    OC.getInstance().fetchPosts(cardId)
      .then((res) => {
        aysncCallback && setTimeout(aysncCallback);
        return dispatch(fetchPostsDispatch((res && res.sort(function (a, b) {
          return moment(a.createdAt).diff(moment(b.createdAt))
        })) || [], usersList))
      });
  }
}

export function fetchCompanyPosts(cardId, companyId, usersList) {
  return dispatch => {
    OC.getInstance().fetchCompanyPosts(cardId, companyId || null)
      .then((res) => {
        return dispatch(fetchPostsDispatch((res && res.sort(function (a, b) {
          return moment(a.createdAt).diff(moment(b.createdAt))
        })) || [], usersList))
      });
  }
}

export function savePost(post, cardId, statusCallback) {
  return dispatch => {
    OC.getInstance().savePost(post, cardId)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          return dispatch(savePostFailedDispatch(err));
        } else {
          statusCallback && statusCallback(null);
          return dispatch(savePostSuccessDispatch(post));
        }
      });
  }
}

export function deleteCardAndPosts(cardId, statusCallback) {
  return dispatch => {
    OC.getInstance().deleteCardAndPosts(cardId)
      .then(() => {
        statusCallback && statusCallback(null);
        return dispatch(deleteCardAndPostsDispatch(cardId));
      });
  }
}

export function likePost(post, cardId, statusCallback) {
  return dispatch => {
    OC.getInstance().likePost(post, cardId)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          // Intentionally using savePostSuccessDispatch.
          // statusCallback should take care of error messaging.
          return dispatch(savePostSuccessDispatch(post));
        } else {
          statusCallback && statusCallback(null);
          return dispatch(savePostSuccessDispatch(post));
        }
      });
  }
}


export function clearDraft() {
  return dispatch => {
    return dispatch(clearDraftDispatch())
  }
}

export function fetchCards(callback, cacheHook) {
  return dispatch => {
    OC.getInstance().fetchCards((res) => { cacheHook && cacheHook(res) })
      .then((res) => {
        callback && setTimeout(() => callback(res || []));
        return dispatch(fetchCardsDispatch((res && res.sort(function (a, b) {
          return moment(b.createdAt).diff(moment(a.createdAt))
        })) || []));
      });
  }
}

export function loadCardsListFrom(res) {
  return dispatch => {
    return dispatch(fetchCardsDispatch((res && res.sort(function (a, b) {
      return moment(b.createdAt).diff(moment(a.createdAt))
    })) || []));
  }
}

function fetchCardsDispatch(res) {
  return {
    type: types.ON_CARDS_FETCHED,
    cards: res,
  }
}

function saveCardFailedDispatch(err) {
  //mz-todo: check err object to post proper error message
  return {
    type: types.ON_CARD_SAVE_FAILED,
    msg: "Failed to save card.",
  }
}
function saveCardSuccessDispatch(res) {
  return {
    type: types.ON_CARD_SAVED,
    card: res,
  }
}
function findCardDispatch(res) {
  return {
    type: types.ON_CARD_FETCHED,
    card: res,
  }
}
function fetchPostsDispatch(res, usersList) {
  //mz-todo: Temp. logic to update user name and location but it only works in /draft & /view components.
  //         Remove this logic after using ref types in post ownerInfo.
  if (usersList && usersList !== undefined) {
    res.forEach(post => {
      const { ownerInfo } = post;
      if (ownerInfo && ownerInfo.ownerId) {
        const user = usersList.find((user) => user.email === ownerInfo.ownerId);
        if (user && user !== undefined) {
          ownerInfo.name = user.name ? user.name : "";
          ownerInfo.location = user.location && user.location.name ? user.location.name : "";
        }
      }
    });
  }
  return {
    type: types.ON_POSTS_FETCHED,
    posts: res,
  }
}
function savePostFailedDispatch(err) {
  //mz-todo: check err object to post proper error message
  //         Handle this case in reducer.
  return {
    type: types.ON_POST_SAVE_FAILED,
    msg: "Failed to save post.",
  }
}
function savePostSuccessDispatch(res) {
  return {
    type: types.ON_POST_SAVED,
    post: res,
  }
}
function clearDraftDispatch() {
  return {
    type: types.CLEAR_DRAFT,
  }
}
function deleteCardAndPostsDispatch(cardId) {
  return {
    type: types.ON_CARD_AND_POSTS_DELETED,
    cardId: cardId,
  }
}