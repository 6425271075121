import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import OC from "../../oc";

// PROPS DETAILS:
// downloadURL:     Used directly in <img> src. The `fetchPath` and `showUserOutline` props will be ignored.
// fetchPath:       If `downloadURL` is null, it will be used to async. fetch downloadable URL and
//                  the fetched URL will be cached in local state, `cachedURL`
// showUserOutline: If `ture` and `downloadURL` is null, `UserOutlined` icon will be shown, instead.
// name:            If `showUserOutline` is `false`, first char. will be used in avatar.
// imageStyles:     Applied on `Avatar` element with image or letter.
// outlineStyles:   Applied on `Avatar` element with `UserOutlined` icon.

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedURL: null,
    };
  }
  componentDidUpdate(prevProps, _) {
    //second param was: prevState
    if (!this.props.downloadURL) {
      if (!this.props.versionTag || this.props.versionTag === undefined) {
        return;
      }
      if (prevProps.versionTag !== this.props.versionTag) {
        this.fetchImageURL(this.props.fetchPath);
        return;
      }
    }
  }
  fetchImageURL(fetchPath) {
    OC.getInstance()
      .getImageURL(fetchPath)
      .then((res) => {
        if (!res) {
          return;
        }
        if (this._isMounted) {
          this.setState({ cachedURL: res });
          this.props.onFetchUrl && this.props.onFetchUrl(res);
        }
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    const { downloadURL, fetchPath } = this.props;
    if (!downloadURL && fetchPath) {
      this.fetchImageURL(fetchPath);
    }
  }
  getLetters(name) {
    if (!name || name === undefined) {
      return null;
    }
    try {
      const parts = name.split(" ");
      if (parts.length === 1) {
        return parts[0][0];
      }
      if (parts.length === 2) {
        return parts[0][0] + "" + parts[1][0];
      }
      if (parts[0].indexOf(".") !== -1) {
        parts.shift();
      }
      return parts[0][0] + "" + parts[parts.length - 1][0];
    } catch {
      return name[0];
    }
  }
  render() {
    const { downloadURL } = this.props;
    const { cachedURL } = this.state;
    if (downloadURL || cachedURL) {
      return (
        <Avatar
          style={this.props.imageStyles}
          size={this.props.size || "small"}
          src={downloadURL || cachedURL}
        />
      );
    } else {
      const { name } = this.props;
      const letter = this.getLetters(name);
      if (this.props.showUserOutline || !letter) {
        return (
          <Avatar
            style={this.props.outlineStyles}
            size={this.props.size || "small"}
            icon={<UserOutlined />}
          />
        );
      } else {
        return (
          <Avatar
            style={this.props.imageStyles}
            size={this.props.size || "small"}
          >
            {letter.toUpperCase()}
          </Avatar>
        );
      }
    }
  }
}

export default UserAvatar;
