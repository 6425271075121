import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/card";
import * as metadataActions from "../actions/metadata";
import Error404 from "../components/Error404";
import { LoadingOutlined } from "@ant-design/icons";
import CardDraft from "./CardDraft";
import PostingContainer from "./PostingContainer";
import CardView from "./CardView";
import * as nav from "../routes/nav";

const VIEW_DRAFT = "VIEW_DRAFT";
const VIEW_POSTING = "VIEW_POSTING";
const VIEW_RECIPIENT = "VIEW_RECIPIENT";
const VIEW_NOT_PUBLISHED = "VIEW_NOT_PUBLISHED";

class CardMainContainer extends React.Component {
  componentWillUnmount() {
    this.props.actions.clearDraft();
  }
  componentDidUpdate(prevProps) {
    if (prevProps?.location?.pathname !== this.props.location?.pathname) {
      this.loadCard();
    }
  }
  loadCard() {
    window.scrollTo(0, 0);
    const cardDomainId = this.props.routeParams ? this.props.routeParams.id : null;
    if (!cardDomainId) {
      nav.goto(nav.URL_HOMEPAGE);
      return;
    }
    const ids = cardDomainId.split('_');
    if (!ids || ids.length !== 2 || !ids[0] || !ids[1]) {
      nav.goto(nav.URL_HOMEPAGE);
      return;
    }
    const cardID = ids[0];
    const domain = atob(ids[1]);
    if (!this.props.metadata.usersList?.length) {
      this.props.metadataActions.fetchUsersByDomain(domain, (res)=> {
        this.props.actions.fetchCompanyCard(cardID, domain);
        this.props.actions.fetchCompanyPosts(cardID, domain, res);  
      });
    } else {
      this.props.actions.fetchCompanyCard(cardID, domain);
      this.props.actions.fetchCompanyPosts(cardID, domain, this.props.metadata.usersList);
    }  
    if (!this.props.metadata.company) {
      this.props.metadataActions.fetchCompanyByDomain(domain);
    }
  }
  componentDidMount() {
    this.loadCard();
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  renderDraftNotFound() {
    return <Error404 />;
  }
  isLoading() {
    if (!this.props.metadata.usersList?.length) {
      return true;
    }
    return this.props.card.draftCard === null || this.props.metadata.company === null;
  }
  cardNotFound() {
    const { draftCard } = this.props.card;
    if (draftCard && !draftCard.id) {
      return true;
    }
    return false;
  }
  getCurrentViewType(user, draftCard) {
    try {
      if (!user) {
        return draftCard.postsDisabled ? VIEW_RECIPIENT : VIEW_POSTING;
      } else if (draftCard.owner.id === user.email) {
        return VIEW_DRAFT;
      } else if (draftCard.getRecipientEmail() === user.email) {
        if (draftCard.postsDisabled) {
          return VIEW_RECIPIENT;
        } else {
          return VIEW_NOT_PUBLISHED;
        }
      } else if (draftCard.postsDisabled) {
        return VIEW_RECIPIENT;
      }
    } catch {
      return VIEW_POSTING;
    }
    return VIEW_POSTING;
  }
  render() {
    const { draftCard } = this.props.card;
    const { user, company } = this.props.metadata;
    if (this.isLoading()) {
      return this.renderLoader();
    }
    if (this.cardNotFound(user)) {
      return this.renderDraftNotFound();
    }
    const viewType = this.getCurrentViewType(user, draftCard);
    switch (viewType) {
      case VIEW_DRAFT:
        return <CardDraft
          {...this.props}
          routeParams={{ ...this.props.routeParams, id: draftCard.id }} />;

      case VIEW_POSTING:
        return <PostingContainer
          {...this.props}
          routeParams={{ ...this.props.routeParams, id: draftCard.id, domain: company.domain }} />;

      case VIEW_RECIPIENT:
        return <CardView {...this.props}
          routeParams={{ ...this.props.routeParams, id: draftCard.id }} />;

      case VIEW_NOT_PUBLISHED:
        return this.renderDraftNotFound();

      default:
        return <div />;
    }
  }
}

function mapStateToProps(state) {
  const { card, metadata } = state;
  return { card, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardMainContainer);
