import React from "react";
import { getCurrentTimezoneName } from "../shared/timezones";
import {
  Button,
  Form,
  Select,
  DatePicker,
  Checkbox,
  Input,
  message,
  Tooltip,
} from "antd";
import {
  categoryData,
  CAT_BIRTHDAY,
  CAT_ANNIVERSARY,
  CAT_OTHER,
  PLAN_TYPE_FREE_COMPANY,
  OC_CARD_PLURAL,
  OC_CARD_SINGULAR,
} from "../shared/constants";
import "./styles/Modal.less";
import EmojiHandler from "./EmojiHandler";
import UserSelect from "./custom/UserSelect";
import EmailSelect from "./custom/EmailSelect";
import OC from "../oc";
import moment from "moment-timezone";
import Fade from "react-reveal/Fade";
import Logger, { PRIORITY_CRITICAL } from "../shared/logger";
import { LoadingOutlined } from "@ant-design/icons";
import {
  startSingleCardSubscription,
  availableCardsCount,
  isRunningLow,
  getBuyButtonText,
} from "../shared/card_utils";
import { isNonNegativeInteger } from "../shared/util";

const { Option } = Select;
const DEFAULT_HOUR_MINUTES_INDEX = 18;
const hoursArray = [
  { label: "12:00 AM", hour: 0, minutes: 0 },
  { label: "12:30 AM", hour: 0, minutes: 30 },
  { label: "1:00 AM", hour: 1, minutes: 0 },
  { label: "1:30 AM", hour: 1, minutes: 30 },
  { label: "2:00 AM", hour: 2, minutes: 0 },
  { label: "2:30 AM", hour: 2, minutes: 30 },
  { label: "3:00 AM", hour: 3, minutes: 0 },
  { label: "3:30 AM", hour: 3, minutes: 30 },
  { label: "4:00 AM", hour: 4, minutes: 0 },
  { label: "4:30 AM", hour: 4, minutes: 30 },
  { label: "5:00 AM", hour: 5, minutes: 0 },
  { label: "5:30 AM", hour: 5, minutes: 30 },
  { label: "6:00 AM", hour: 6, minutes: 0 },
  { label: "6:30 AM", hour: 6, minutes: 30 },
  { label: "7:00 AM", hour: 7, minutes: 0 },
  { label: "7:30 AM", hour: 7, minutes: 30 },
  { label: "8:00 AM", hour: 8, minutes: 0 },
  { label: "8:30 AM", hour: 8, minutes: 30 },
  { label: "9:00 AM", hour: 9, minutes: 0 },
  { label: "9:30 AM", hour: 9, minutes: 30 },
  { label: "10:00 AM", hour: 10, minutes: 0 },
  { label: "10:30 AM", hour: 10, minutes: 30 },
  { label: "11:00 AM", hour: 11, minutes: 0 },
  { label: "11:30 AM", hour: 11, minutes: 30 },
  { label: "12:00 PM", hour: 12, minutes: 0 },
  { label: "12:30 PM", hour: 12, minutes: 30 },
  { label: "1:00 PM", hour: 13, minutes: 0 },
  { label: "1:30 PM", hour: 13, minutes: 30 },
  { label: "2:00 PM", hour: 14, minutes: 0 },
  { label: "2:30 PM", hour: 14, minutes: 30 },
  { label: "3:00 PM", hour: 15, minutes: 0 },
  { label: "3:30 PM", hour: 15, minutes: 30 },
  { label: "4:00 PM", hour: 16, minutes: 0 },
  { label: "4:30 PM", hour: 16, minutes: 30 },
  { label: "5:00 PM", hour: 17, minutes: 0 },
  { label: "5:30 PM", hour: 17, minutes: 30 },
  { label: "6:00 PM", hour: 18, minutes: 0 },
  { label: "6:30 PM", hour: 18, minutes: 30 },
  { label: "7:00 PM", hour: 19, minutes: 0 },
  { label: "7:30 PM", hour: 19, minutes: 30 },
  { label: "8:00 PM", hour: 20, minutes: 0 },
  { label: "8:30 PM", hour: 20, minutes: 30 },
  { label: "9:00 PM", hour: 21, minutes: 0 },
  { label: "9:30 PM", hour: 21, minutes: 30 },
  { label: "10:00 PM", hour: 22, minutes: 0 },
  { label: "10:30 PM", hour: 22, minutes: 30 },
  { label: "11:00 PM", hour: 23, minutes: 0 },
  { label: "11:30 PM", hour: 23, minutes: 30 },
];

class ModalCardSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValidated: true,
      scheduledDeliver: true,
      recipient: null,
      deliveryDate: null,
      hourMinutesIndex: DEFAULT_HOUR_MINUTES_INDEX,
      newUser: null,
      busyAddingUser: false,
      saveBirthday: true,
      saveAnniversary: true,
      emailError: null,
      dateTimeError: null,
      singleCardProduct: null,
      planRequestDispatched: false,
      buyButtonBusy: false,
    };
    this.onUserSelect = this.onUserSelect.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onTimeSelect = this.onTimeSelect.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    // this.onAddNewLocation = this.onAddNewLocation.bind(this);
    // this.onLocationSelect = this.onLocationSelect.bind(this);
    this.onAddNewUser = this.onAddNewUser.bind(this);
    this.onScheduledDeliverToggle = this.onScheduledDeliverToggle.bind(this);
    this.emailValidated = this.emailValidated.bind(this);
    this.onSaveBirthday = this.onSaveBirthday.bind(this);
    this.resetEmailError = this.resetEmailError.bind(this);
    this.onSaveAnniversary = this.onSaveAnniversary.bind(this);
    this.onWithCompanyNameToggle = this.onWithCompanyNameToggle.bind(this);
    this.onClickBuy = this.onClickBuy.bind(this);
    this.onClickUpgrade = this.onClickUpgrade.bind(this);
  }
  onWithCompanyNameToggle(e) {
    const { onWithCompanyNameToggle } = this.props;
    onWithCompanyNameToggle(e.target.checked);
  }
  refillDraftUserOnPaymentCallback(draftUser) {
    this.onAddNewUser(draftUser);
  }
  async getEncodedData() {
    const { selectedCategory } = this.props;
    const { recipient, deliveryDate, hourMinutesIndex } = this.state;
    try {
      const deliveryUTC = this.getUTCDateTime(deliveryDate, hourMinutesIndex);
      const data = {
        selectedCategory: selectedCategory || "",
        recipientEmail: recipient?.email || "",
        deliveryUTC: deliveryUTC?.utc().format() || "",
      };
      if (recipient?.isDraft) {
        data.draftUsername = recipient.name;
      }
      const jsonString = JSON.stringify(data);
      const encodedData = Buffer.from(jsonString).toString("base64");
      return encodedData;
    } catch (err) {
      this.setState({ buyButtonBusy: false });
      await Logger.logStripe(
        PRIORITY_CRITICAL,
        err && err.message ? err.message : "function failed",
        "ModalCardSettings:getEncodedData",
        {
          selectedCategory: selectedCategory || null,
          recipient: recipient && recipient.email ? recipient.email : null,
          deliveryDate: deliveryDate ? deliveryDate : null,
          hourMinutesIndex: isNonNegativeInteger(hourMinutesIndex) ? hourMinutesIndex : null,
        }
      );
      return null;
    }
  }
  // onClickBuy ... Also exists in WooCandidateSettings.js
  async onClickBuy() {
    this.setState({ buyButtonBusy: true });
    const { singleCardProduct } = this.state;
    if (!singleCardProduct) {
      this.onClickUpgrade();
      this.setState({ buyButtonBusy: false });
      return;
    }
    const encodedData = await this.getEncodedData();
    startSingleCardSubscription(singleCardProduct, encodedData);
  }
  async onClickUpgrade() {
    const { paymentActions, closeModal } = this.props;
    const encodedData = await this.getEncodedData();
    paymentActions && paymentActions.togglePaymentModal(true, encodedData);
    closeModal && closeModal();
  }
  onSaveBirthday(e) {
    this.setState({ saveBirthday: e.target.checked });
  }
  onSaveAnniversary(e) {
    this.setState({ saveAnniversary: e.target.checked });
  }
  // loadSingleCardProduct... Also exists in WooCandidateSettings.js
  loadSingleCardProduct(products) {
    if (!products) {
      this.state.singleCardProduct && this.setState({singleCardProduct: null});
      return;
    }
    const prd = products.find(item => item.getTotalCards() === 1);
    if (prd) {
      this.setState({singleCardProduct: prd});
    } else {
      this.state.singleCardProduct && this.setState({singleCardProduct: null});
    }
  }
  // loadSubscriptionData... Also exists in WooCandidateSettings.js
  loadSubscriptionData() {
    const { products, activePlan, activeSubscriptions } = this.props.payment;
    const { paymentActions } = this.props;
    if (!products || !products.length) {
      paymentActions.fetchProducts((products) => {
        this._isMounted && this.loadSingleCardProduct(products);
      });
    } else {
      this._isMounted && this.loadSingleCardProduct(products);
    }
    if (!activePlan || !activeSubscriptions || !activeSubscriptions.length) {
      paymentActions.loadActiveSubscription((res) => {
        paymentActions.loadActivePlan(res);
        this._isMounted && this.setState({ planRequestDispatched: true });
      });
    } else {
      this._isMounted && this.setState({ planRequestDispatched: true });
    }
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadSubscriptionData();
    const { cardSettingsData } = this.props;

    if (!cardSettingsData || !cardSettingsData.recipient) {
      return;
    }
    const { recipient, utc } = cardSettingsData;
    const index = this.getTimeIndexForCurrentZone(utc);
    this.setState({
      scheduledDeliver: utc ? true : false,
      recipient: recipient,
      deliveryDate: this.getDateForCurrentZone(utc),
      hourMinutesIndex:
        isNonNegativeInteger(index) ? index : DEFAULT_HOUR_MINUTES_INDEX,
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getDateForCurrentZone(utc) {
    const dt = moment.utc(utc).local();
    return dt.isValid() ? dt : null;
  }
  getTimeIndexForCurrentZone(utc) {
    const dt = this.getDateForCurrentZone(utc);
    if (!dt) {
      return null;
    }
    const hour = dt.hour();
    const minutes = dt.minute();
    const index = hoursArray.findIndex(
      (element) => element.hour === hour && element.minutes === minutes
    );
    return index > -1 ? index : null;
  }
  disabledDate(current) {
    return (
      current &&
      (current < moment().subtract(1, "days") ||
        current > moment().add(29, "days"))
    );
  }
  emailValidated(value) {
    this.setState({ emailValidated: value });
  }
  onScheduledDeliverToggle(e) {
    this.setState({
      scheduledDeliver: e.target.checked,
      dateTimeError: !e.target.checked ? null : this.state.dateTimeError,
    });
    //mz-info: Removing because it's messing up edit state.
    // if (!e.target.checked) {
    //   this.setState({
    //     deliveryDate: "",
    //     hourMinutesIndex: DEFAULT_HOUR_MINUTES_INDEX,
    //   });
    // }
  }
  addUserAndSubmit() {
    const { recipient } = this.state;
    if (
      !recipient ||
      !recipient.isDraft ||
      !recipient.email ||
      !recipient.name
    ) {
      return;
    }
    this.setState({ busyAddingUser: true });
    OC.getInstance()
      .addUser(recipient)
      .then((err) => {
        if (err) {
          message.error(
            "Could not add new user. Please check network connection."
          );
          return;
        }
        OC.getInstance()
          .findCompanyUser(recipient.email)
          .then((newUser) => {
            if (newUser) {
              this.props.includeNewUser && this.props.includeNewUser(newUser);
              setTimeout(() => this.setState({ recipient: newUser }));
              setTimeout(() => {
                this.setState({ busyAddingUser: false });
                this.submit();
              });
            } else {
              message.error(
                "There was a problem adding new user. Please try again."
              );
              return;
            }
          });
      });
  }
  updateUserAndSubmit() {
    const { recipient } = this.state;
    if (!recipient || !recipient.email || !recipient.name) {
      return;
    }
    this.setState({ busyAddingUser: true });
    OC.getInstance()
      .updateProfile(recipient)
      .then((err) => {
        if (err) {
          //mz-todo: Handle user update error.
          return;
        }
        this.setState({ busyAddingUser: false });
        this.submit();
      });
  }
  onAddNewUser(user) {
    this.setState({ newUser: user, recipient: user });
  }
  onUserSelect(user) {
    try {
      const { selectedCategory } = this.props;
      if (selectedCategory === CAT_BIRTHDAY) {
        if (!user) {
          this.setState({ recipient: null, deliveryDate: null });
        }
        var dd_DOB = null;
        if (user && user.hasDOB && user.hasDOB()) {
          const { dob } = user;
          dd_DOB = moment();
          dd_DOB.month(dob.month);
          dd_DOB.date(dob.day);
          if (dd_DOB.isBefore(moment())) {
            dd_DOB.year(dd_DOB.year() + 1);
          }
          if (!dd_DOB.isValid()) {
            dd_DOB = null;
          }
        }
        if (this.disabledDate(dd_DOB)) {
          this.setState({ 
            recipient: user, 
            deliveryDate: null,
            scheduledDeliver: false,
            dateTimeError: null,
          });
        } else {
          this.setState({
            recipient: user,
            deliveryDate: dd_DOB,
            scheduledDeliver: true,
            dateTimeError: null,
          });
        }
      } else if (selectedCategory === CAT_ANNIVERSARY) {
        if (!user) {
          this.setState({ recipient: null, deliveryDate: null });
        }
        var dd_Ann = null;
        if (user && user.hasAnniversary && user.hasAnniversary()) {
          const { anniversary } = user;
          dd_Ann = moment();
          dd_Ann.month(anniversary.month);
          dd_Ann.date(anniversary.day);
          if (dd_Ann.isBefore(moment())) {
            dd_Ann.year(dd_Ann.year() + 1);
          }
          if (!dd_Ann.isValid()) {
            dd_Ann = null;
          }
        }
        if (this.disabledDate(dd_Ann)) {
          this.setState({
            recipient: user,
            deliveryDate: null,
            scheduledDeliver: false,
            dateTimeError: null,
          });
        } else {
          this.setState({
            recipient: user,
            deliveryDate: dd_Ann,
            scheduledDeliver: true,
            dateTimeError: null,
          });
        }
      } else {
        this.setState({ recipient: user });
      }
      user && user.isDraft
        ? this.setState({ newUser: user })
        : this.setState({ newUser: null });
    } catch (err) {
      Logger.logAnalysis(
        PRIORITY_CRITICAL,
        err && err.message ? err.message : "function failed",
        "ModalCardSettings:onUserSelect",
        { user: user ? user : null }
      );
    }
  }
  onDateSelect(item) {
    // mz-info: Disabling hour limits because it doesn't work for all timezones.
    // if (moment(item).format("MMM Do YY") === moment().format("MMM Do YY")) {
    //   if (this.state.deliveryTime < moment().hour()) {
    //     this.setState({ deliveryTime: moment().hour() + 1 });
    //   }
    // }
    this.setState({ dateTimeError: null });
    this.setState({ deliveryDate: item });
  }
  onTimeSelect(itemIndex) {
    this.setState({ hourMinutesIndex: itemIndex, dateTimeError: null });
  }
  deliveryValidated() {
    const { deliveryDate, hourMinutesIndex, scheduledDeliver } = this.state;
    if (!scheduledDeliver) {
      return true;
    }
    if (
      deliveryDate &&
      deliveryDate.isValid &&
      deliveryDate.isValid() &&
      isNonNegativeInteger(hourMinutesIndex)
    ) {
      const dt = this.getUTCDateTime(deliveryDate, hourMinutesIndex);
      if (!dt) {
        return false;
      }
      var duration = moment.duration(dt.diff(moment.utc()));
      var minutes = duration.asMinutes();
      if (minutes < 30) {
        this.setState({
          dateTimeError:
            "Scheduled time should be at least 30 minutes from now.",
        });
        return false;
      }
      return true;
    } else {
      this.setState({ dateTimeError: "Please select delivery date & time." });
      return false;
    }
  }
  //mz-info: Last minute fail-safe
  reValidateEmail(email) {
    const domain = this.props.metadata?.company?.domain;
    if (!domain || !email || email.indexOf("@") === -1) {
      this.setState({ emailError: "Please provide a valid email." });
      console.log("oc_fs_reval1", email, domain); //mz-info: For fs log.
      return false;
    }
    if (email.split("@").pop() === domain) {
      return true;
    }
    this.setState({ emailError: "Please provide a valid email." });
    console.log("oc_fs_reval2", email, domain); //mz-info: For fs log.
    return false;
  }
  onClickNext() {
    if (this.props.busyInitCard) {
      return;
    }
    const { recipient, emailValidated } = this.state;
    if (recipient !== null && recipient !== undefined && emailValidated) {
      if (!this.reValidateEmail(recipient.email)) {
        return;
      }
      if (this.deliveryValidated()) {
        if (recipient.isDraft) {
          this.addUserAndSubmit();
          return;
        }
        if (!recipient.isDraft && recipient.locationAdded) {
          this.updateUserAndSubmit();
          return;
        }
        this.submit();
      }
    }
  }
  getUTCDateTime(deliveryDate, hourMinutesIndex) {
    const { scheduledDeliver } = this.state;
    if (!scheduledDeliver || !deliveryDate || !isNonNegativeInteger(hourMinutesIndex)) {
      return null;
    }
    const dt = moment(deliveryDate);
    const hourMinute = hoursArray[hourMinutesIndex];
    dt.hour(hourMinute.hour);
    dt.minute(hourMinute.minutes);
    dt.second(0);
    return dt.isValid() ? dt.utc() : null;
  }
  async submit() {
    const { recipient, deliveryDate, hourMinutesIndex } = this.state;
    try {
      if (this.shouldSaveBirthdayData() && deliveryDate) {
        recipient.dob = {
          day: deliveryDate.date(),
          month: deliveryDate.month(),
        };
        const err = await OC.getInstance().addUser(recipient, true);
        if (err) {
          message.info("Birthday could not be saved due to network issue.");
        }
      }
      if (this.shouldSaveAnniversaryData() && deliveryDate) {
        recipient.anniversary = {
          day: deliveryDate.date(),
          month: deliveryDate.month(),
        };
        const err = await OC.getInstance().addUser(recipient, true);
        if (err) {
          message.info("Anniversary could not be saved due to network issue.");
        }
      }
      const deliveryUTC = this.getUTCDateTime(deliveryDate, hourMinutesIndex);
      this.props.onSubmit({
        recipient: recipient,
        deliveryUTC: deliveryUTC,
      });
      return null;
    } catch (err) {
      await Logger.logAnalysis(
        PRIORITY_CRITICAL,
        err && err.message ? err.message : "function failed",
        "ModalCardSettings:submit",
        {
          recipient: recipient ? recipient : null,
          deliveryDate: deliveryDate ? deliveryDate : null,
          hourMinutesIndex: isNonNegativeInteger(hourMinutesIndex) ? hourMinutesIndex : null,
        }
      );
      return null;
    }
  }
  shouldShowEmailSelect() {
    const { recipient, newUser } = this.state;
    const { recipientsList } = this.props;
    if (!recipient || !recipientsList) {
      return true;
    }
    const { name } = recipient;
    if (!name) {
      return true;
    }
    var count = 0;
    for (const element of recipientsList) {
      name === element.name && count++;
      if (count === 2) {
        return true;
      }
    }
    if (newUser && newUser.name === recipient.name) {
      return true;
    }
    return false;
  }
  //mz-info: Removing location
  // shouldShowLocationSelect() {
  //   const { recipient } = this.state;
  //   if (recipient && recipient.isDraft) {
  //     return true;
  //   }
  //   if (recipient && recipient.locationAdded) {
  //     return true;
  //   }
  //   return !(recipient && recipient.locationRef);
  // }
  renderHours() {
    // mz-info: Disabling hour limits because it doesn't work for all timezones.
    // const today =
    //   moment(this.state.deliveryDate).format("MMM Do YY") ===
    //   moment().format("MMM Do YY");
    return hoursArray.map((item, i) => {
      // mz-info: Disabling hour limits because it doesn't work for all timezones.
      // if (!today || i > moment().hour()) {
      return (
        <Option key={`hourItem_${i}`} value={i}>
          {item.label}
        </Option>
      );
      // }
    });
  }
  showDateTimeSelect() {
    const { hourMinutesIndex, deliveryDate } = this.state;
    const zoneName = getCurrentTimezoneName();
    const validDateOrNull =
      deliveryDate && deliveryDate.isValid && deliveryDate.isValid()
        ? deliveryDate
        : null;
    return (
      <Fade>
        <Input.Group compact>
          <DatePicker
            size="large"
            onChange={this.onDateSelect}
            disabledDate={this.disabledDate}
            value={validDateOrNull}
            dropdownClassName="picker-card-settings"
          />
          <Tooltip placement="top" mouseLeaveDelay={0.1} title={zoneName}>
            <Select
              size="large"
              showSearch={false}
              value={hourMinutesIndex}
              onSelect={this.onTimeSelect}
              className="time-select"
            >
              {this.renderHours()}
            </Select>
          </Tooltip>
        </Input.Group>
        <div className="full-timezone">{zoneName}</div>
      </Fade>
    );
  }
  showBirthdayCheckbox() {
    const { recipient, scheduledDeliver } = this.state;
    const { selectedCategory } = this.props;
    const { user } = this.props.metadata;
    if (!recipient || !user) {
      return false;
    }
    const { dob } = recipient;
    return (
      scheduledDeliver &&
      selectedCategory === CAT_BIRTHDAY &&
      user.isAdmin &&
      (!dob || !dob.day || !(isNonNegativeInteger(dob.month)))
    );
  }
  showAnniversaryCheckbox() {
    const { recipient, scheduledDeliver } = this.state;
    const { selectedCategory } = this.props;
    const { user } = this.props.metadata;
    if (!recipient || !user) {
      return false;
    }
    const { anniversary } = recipient;
    return (
      scheduledDeliver &&
      selectedCategory === CAT_ANNIVERSARY &&
      user.isAdmin &&
      (!anniversary || !anniversary.day || (anniversary.month === null || anniversary.month === undefined))
    );
  }
  shouldSaveBirthdayData() {
    return this.showBirthdayCheckbox() && this.state.saveBirthday;
  }
  shouldSaveAnniversaryData() {
    return this.showAnniversaryCheckbox() && this.state.saveAnniversary;
  }
  resetEmailError() {
    this.setState({ emailError: null });
  }
  renderLoader() {
    return (
      <h3 className="loader" style={{marginTop: 0}}>
        <LoadingOutlined />
      </h3>
    );
  }
  // renderCTAs ... Also exists in WooCandidateSettings.js
  renderCTAs() {
    if (this.props.isEditMode) {
      return (
        <Form.Item>
          <Button
            loading={this.props.busyInitCard}
            disabled={this.state.busyAddingUser}
            type="primary"
            size="large"
            block
            htmlType="submit"
            onClick={this.onClickNext}
          >
            Update
          </Button>
        </Form.Item>        
      );
    }
    const { products, activePlan, activeSubscriptions } = this.props.payment;
    const { planRequestDispatched, singleCardProduct, buyButtonBusy } = this.state;
    const avCardsCount = availableCardsCount(activePlan);
    if (!products || !products.length || !activePlan || !activeSubscriptions || !planRequestDispatched) {
      return this.renderLoader();
    }
    return (
      <>
        <Form.Item>
          {activePlan.type === PLAN_TYPE_FREE_COMPANY || avCardsCount ? (
            <Button
              loading={this.props.busyInitCard}
              disabled={this.state.busyAddingUser}
              type="primary"
              size="large"
              block
              htmlType="submit"
              onClick={this.onClickNext}
            >
              Next
            </Button>
          ) : (
            <Button
              type="primary"
              loading={buyButtonBusy}
              disabled={buyButtonBusy}              
              size="large"
              block
              onClick={this.onClickBuy}
            >
              {getBuyButtonText(singleCardProduct)}
            </Button>
          )}
        </Form.Item>
        {isRunningLow(activePlan) ? (
          <Button
            type="link"
            size="large"
            block
            onClick={this.onClickUpgrade}
          >
            Upgrade for discounts ({avCardsCount ? avCardsCount : "No"}{" "}
            {avCardsCount === 1 ? OC_CARD_SINGULAR.toLowerCase() : OC_CARD_PLURAL.toLowerCase()} remaining)
          </Button>
        ) : null}
      </>
    )
  }
  render() {
    const { recipientsList, selectedCategory, isWithCompanyName } = this.props;
    const { categories, company, user } = this.props.metadata;
    const category = categories.find(
      (element) => element.key === selectedCategory
    );
    const {
      recipient,
      newUser,
      scheduledDeliver,
      saveBirthday,
      emailError,
      dateTimeError,
      saveAnniversary,

    } = this.state;
    //mz-info: Removing location
    // const showLocationSelect = this.shouldShowLocationSelect();
    const showEmailSelect = this.shouldShowEmailSelect();
    return (
      <div className="ModalCardSettings">
        <h2>
          <EmojiHandler>
            <span role="img" aria-labelledby="OC">
              {categoryData[selectedCategory].icon}
            </span>
          </EmojiHandler>
          {category && category.name}{" "}
          <Button onClick={this.props.onEditCategoryClick} type="link">
            <i className="fal fa-pen"></i>
          </Button>
        </h2>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          {/* mz-todo: Implement for "Other" category */}
          {selectedCategory === CAT_OTHER &&
            <Checkbox
              checked={isWithCompanyName}
              onChange={this.onWithCompanyNameToggle}
            >Recipient is with {company?.name || "the organization"}</Checkbox>}
          <Form.Item
            rules={[{ required: true, message: "Please select recipient" }]}
          >
            <h4>Recipient’s Full Name</h4>
            <UserSelect
              usersList={recipientsList}
              recipient={recipient}
              newUser={newUser}
              onUserSelect={this.onUserSelect}
              onAddNewUser={this.onAddNewUser}
            />
          </Form.Item>

          {showEmailSelect && (
            <Form.Item
              rules={[
                { required: true, message: "Please enter recipient's email" },
              ]}
            >
              <h4>Recipient’s Work Email</h4>
              <EmailSelect
                user={user}
                emailValidated={this.emailValidated}
                company={company}
                usersList={recipientsList}
                recipient={recipient}
                newUser={newUser}
                onUserSelect={this.onUserSelect}
                onAddNewUser={this.onAddNewUser}
                resetEmailError={this.resetEmailError}
                isPaymentCallback={this.props.isPaymentCallback}
              />
              {emailError && <span className="error-state">{emailError}</span>}
            </Form.Item>
          )}

          {/* mz-info: Removing location */}
          {/* {showLocationSelect && (
            <Form.Item
              rules={[{ required: true, message: "Please enter location" }]}
            >
              <h4>Recipient’s Location</h4>
              <LocationSelect
                onLocationSelect={this.onLocationSelect}
                onAddNewLocation={this.onAddNewLocation}
                locations={this.props.metadata.locations}
              />
            </Form.Item>
          )} */}

          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select scheduled date and time",
              },
            ]}
            className="schedule-delivery"
          >
            <div className="schedule">
              <Checkbox
                checked={scheduledDeliver}
                onChange={this.onScheduledDeliverToggle}
              >
                Scheduled Delivery
              </Checkbox>
              {scheduledDeliver && this.showDateTimeSelect()}
            </div>
            {dateTimeError && (
              <span className="error-state">{dateTimeError}</span>
            )}
          </Form.Item>

          {this.showBirthdayCheckbox() ? (
            <Checkbox checked={saveBirthday} onChange={this.onSaveBirthday}>
              {`Save delivery date as birthday`}
            </Checkbox>
          ) : null}

          {this.showAnniversaryCheckbox() ? (
            <Checkbox
              checked={saveAnniversary}
              onChange={this.onSaveAnniversary}
            >
              {`Save delivery date as work anniversary`}
            </Checkbox>
          ) : null}

          {this.renderCTAs()}
        </Form>
      </div>
    );
  }
}

export default ModalCardSettings;
