import 'firebase/storage';

const STORAGE_PATH = "jubili/";

export default class Storage {
  storageRef = null;
  constructor(firebase) {
    if (!firebase) {
      throw new Error("null parameter");
    }
    this.storageRef = firebase.storage().ref();
  }
  getPath(companyId, filename) {
    return STORAGE_PATH + companyId.replace(/[#[\]*?]/g,'') + "/" + filename;
  }
  async upload(byteArray, companyId, filename) {
    try {
      const fileRef = this.storageRef.child(this.getPath(companyId, filename));
      await fileRef.put(byteArray);
      return fileRef.fullPath;
    } catch(err) {
      return null;
    }
  }
  async update(byteArray, url) {
    try {
      const fileRef = this.storageRef.child(url);
      await fileRef.put(byteArray);
      return fileRef.fullPath;
    } catch(err) {
      return null;
    }
  }
  async getDownloadURL(path) {
    try {
      return await this.storageRef.child(path).getDownloadURL();
    } catch(err) {
      return null;
    }
  }
  async deleteImage(path) {
    try {
      await this.storageRef.child(path).delete();
      return null;
    } catch(err) {
      return err;
    }
  }
}
