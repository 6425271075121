import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/auth";
import * as metadataActions from "../actions/metadata";
import * as nav from "../routes/nav";
import "./styles/HeaderOne.less";
import {
  Menu,
  Button,
  Space,
  Dropdown,
  Modal,
  Typography,
  Tooltip,
  message,
} from "antd";
import ModalUpdateProfile from "./ModalUpdateProfile";
import * as errc from "../oc/error_codes";
import UserAvatar from "./custom/UserAvatar";
import CreatePassLinkSent from "./CreatePassLinkSent";
import * as types from "../constants";

const { Text } = Typography;

class AuthHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalMounted: false,
      showEmailSentModal: false,
    };
    this.hideProfileModal = this.hideProfileModal.bind(this);
    this.showProfileModal = this.showProfileModal.bind(this);
    this.onProfileSave = this.onProfileSave.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.modalAfterClose = this.modalAfterClose.bind(this);
    this.showEmailSentModal = this.showEmailSentModal.bind(this);
    this.hideEmailSentModal = this.hideEmailSentModal.bind(this);
    this.onSettingsClick = this.onSettingsClick.bind(this);
  }
  showEmailSentModal() {
    this.setState({ showEmailSentModal: true });
  }
  hideEmailSentModal() {
    this.setState({ showEmailSentModal: false });
  }
  onSettingsClick() {
    nav.goto(nav.URL_SETTINGS);
  }
  saveLocation(newLocation) {
    this.props.metadataActions.saveLocation(newLocation);
  }
  onProfileSave(user, password, hasChange, callback) {
    if (!hasChange) {
      this.hideProfileModal();
      callback && callback(null);
      return;
    }
    this.props.actions.updateProfile(user, password || null, (err) => {
      if (err) {
        callback && callback(err);
        if (err.code === errc.AUTH_REQUIRES_LOGIN) {
          //mz-todo: Automatically initiate auth flow for user and remote message prompt.
          message.error(
            "Sensitive operations such as updating password requires recent login."
          );
          return;
        }
        message.error(
          "Could not update user profile. Please check network connection."
        );
        return;
      }
      if (hasChange) {
        message.success("Profile updated!");
      }
      this.hideProfileModal();
      callback && callback(null);
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      prevState.modalMounted === false &&
      this.state.modalMounted === false
    ) {
      if (
        prevProps &&
        prevProps.metadata.userProfileModalVisible === false &&
        this.props.metadata.userProfileModalVisible === true
      ) {
        this.setState({ modalMounted: true });
      }
    }
  }
  showProfileModal() {
    this.props.metadataActions.toggleProfileModal(true);
  }
  hideProfileModal() {
    this.props.metadataActions.toggleProfileModal(false);
  }
  modalAfterClose() {
    this.setState({ modalMounted: false });
  }
  getMenu() {
    return (
      <Menu>
        <Menu.Item onClick={this.showProfileModal}>
          <i className="fal fa-user"></i> Update Profile
        </Menu.Item>
        <Menu.Item onClick={this.onSettingsClick}>
          <i className="fal fa-user"></i> Settings
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={() =>
            this.props.actions.logout && this.props.actions.logout()
          }
          className="logout"
        >
          <Text type="danger">
            <i className="fal fa-sign-out"></i> Log Out
          </Text>
        </Menu.Item>
      </Menu>
    );
  }
  getAvatar() {
    const { user } = this.props.metadata;
    return (
      <UserAvatar
        key={`auth_header_avatar_${user && user.email ? user.email : ""}`}
        size="small"
        downloadURL={user.avatarDownloadURL}
        fetchPath={user.avatar}
        name={user.name}
        versionTag={user._lastUpdated || null}
      />
    );
  }
  render() {
    const { currentUser } = this.props.auth;
    const { user, actionArgs } = this.props.metadata;
    return (
      <div className="AuthHeader">
        {currentUser && user ? (
          <Space>
            <Dropdown overlay={this.getMenu()} trigger={["click"]}>
              <Tooltip
                overlayClassName="login-username"
                title={user && user.name}
                placement="bottom"
                mouseLeaveDelay={0}
              >
                <span
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {this.getAvatar()}
                </span>
              </Tooltip>
            </Dropdown>
          </Space>
        ) : (
          <Space>
            <Button
              className="sign-in"
              type="link"
              onClick={() => {
                nav.goto(nav.URL_AUTH_FLOW_SIGNIN);
              }}
            >
              Sign In
            </Button>
            {/* mz-todo: Enable when needed. Temp. hiding Sign up functionality. */}
            {/* <Button
              className="sign-up"
              type="primary"
              onClick={() => {
                nav.goto(nav.URL_AUTH_FLOW_JOIN);
              }}
            >
              Sign Up
            </Button> */}
          </Space>
        )}

        {this.state.modalMounted ? (
          <Modal
            width={520}
            afterClose={this.modalAfterClose}
            maskClosable={true}
            visible={this.props.metadata.userProfileModalVisible}
            footer={null}
            onCancel={this.hideProfileModal}
            centered
            closeIcon={<i className="fal fa-times"></i>}
          >
            <ModalUpdateProfile
              focusLocationSelect={
                actionArgs &&
                actionArgs.length &&
                actionArgs.indexOf(types.ACTION_FOCUS_LOCATION_SELECT) !== -1
                  ? true
                  : false
              }
              clearActionArgs={this.props.metadataActions.clearActionArgs}
              isPasswordUpdateFlow={false} //warn mz-todo: Remove all dependencies from ModalUpdateProfile, later.
              onAddNewLocation={this.saveLocation}
              locationList={this.props.metadata.locations}
              user={user.clone()}
              onSave={this.onProfileSave}
              showEmailSentModal={this.showEmailSentModal}
            />
          </Modal>
        ) : null}
        <Modal
          visible={this.state.showEmailSentModal}
          onCancel={this.hideEmailSentModal}
          width={580}
          footer={null}
          closeIcon={<i className="fal fa-times"></i>}
        >
          <CreatePassLinkSent user={user} />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth, metadata } = state;
  return { auth, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeader);
