import React from "react";
import "./styles/ZeroState.less";
import { Form, Input, Button, message } from "antd";
import {
  STATE_JUBILI_EMAIL,
  STATE_SIGNIN_EMAIL,
  OC_TYPE_FORGOT,
  OC_PROD_TITLE,
} from "../shared/constants";
import ForgotPasswordSent from "./ForgotPasswordSent";
import signin from "./img/artwork-signin.png";
import logo from "./img/logo-greeted.svg";

class ZeroStatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      showForgotLinkSentState: false,
      busyResend: false,
    };
    this.onPasswordNextClicked = this.onPasswordNextClicked.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onForgotClick = this.onForgotClick.bind(this);
  }
  onForgotClick() {
    const { email, sendForgotLink } = this.props;
    if (!email || !sendForgotLink) {
      return;
    }
    this.setState({ busyResend: true });
    sendForgotLink(email, OC_TYPE_FORGOT, (err) => {
      if (err) {
        this.setState({ busyResend: false });
        message.error("Invalid email address");
        return;
      }
      this.setState({ showForgotLinkSentState: true, busyResend: false });
    });
  }
  onPasswordChanged(e) {
    this.setState({ password: e.target.value });
  }
  onPasswordNextClicked() {
    const { onNext } = this.props;
    onNext && onNext(this.state.password);
  }
  renderLogo() {
    const { companyLogo } = this.props;
    if (companyLogo) {
      return (
        <div className="brand-logo">
          <img src={companyLogo} alt={OC_PROD_TITLE} />
        </div>
      );
    } else {
      return (
        <div className="brand-logo">
          <span className="product">
            <img src={logo} alt={OC_PROD_TITLE} />
          </span>
        </div>
      );
    }
  }
  render() {
    var title = "";
    switch (this.props.authFlow) {
      case STATE_JUBILI_EMAIL:
        title = "Log In";
        break;
      case STATE_SIGNIN_EMAIL:
        title = "Sign In";
        break;
      default:
        break;
    }
    if (this.state.showForgotLinkSentState) {
      return (
        <ForgotPasswordSent
          resend={this.onForgotClick}
          companyLogo={this.props.companyLogo}
          busy={this.state.busyResend}
        />
      );
    }
    return (
      <div>
        <div className="ZeroState">
          <div className="title">
            {this.renderLogo()}
            <div className="artwork">
              <img src={signin} alt={OC_PROD_TITLE} />
            </div>
            <h2>{title}</h2>
            {/* mz-todo: We don't need this prompt anymore. Remove commented, after confirmation.*/}
            {/* <p className="subtext">
              Looks like you already have an account. Let’s log in.{" "}
            </p> */}

            {/* mz-todo: Removing editable property for now. Confirm later. */}
            <p
              className="editable"
              // contentEditable={true}
              // suppressContentEditableWarning={true}
            >
              {this.props.email}
            </p>
          </div>

          <Form name="basic">
            <Form.Item
              rules={[{ required: true, message: "Please enter password" }]}
            >
              <h4>Enter Your Password</h4>
              <Input
                autoFocus
                onChange={this.onPasswordChanged}
                type="password"
                placeholder="Enter your password to proceed"
                prefix={<i className="fal fa-key"></i>}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                loading={this.props.busy}
                size="large"
                block
                htmlType="submit"
                onClick={this.onPasswordNextClicked}
              >
                Continue
              </Button>

              <Button
                type="link"
                size="large"
                block
                onClick={this.onForgotClick}
                className="sign-in-link secondary"
              >
                Forgot password?
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default ZeroStatePassword;
