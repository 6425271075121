import React from "react";
import { Input, Select } from "antd";
import "./styles/Modal.less";
import { isNonNegativeInteger } from "../shared/util";

const { Option } = Select;
const maxDaysMap = {
  0: 31,
  1: 29,
  2: 31,
  3: 30,
  4: 31,
  5: 30,
  6: 31,
  7: 31,
  8: 30,
  9: 31,
  10: 30,
  11: 31,
};

class DayMonthPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      day: null,
      month: null,
      error: null,
      hasInteracted: false,
    };
    this.onDayChange = this.onDayChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
  }
  onDayChange(val) {
    this.setState({ day: val, error: null });
    const data = {
      month: this.state.month,
      day: val,
      isValid: this.isValidDate(val, this.state.month),
    };
    this.props.onValueChange(data);
  }
  onMonthChange(val) {
    this.setState({ month: val, day: null, error: null });
    const data = {
      month: val,
      day: null,
      isValid: this.isValidDate(null, val),
    };
    this.props.onValueChange(data);
  }
  isValidDate(day, month) {
    if ((day >= 1) ^ (month !== null && month !== undefined)) {
      this.setState({ error: "Please select a day" });
      return false;
    }
    const { isNullable } = this.props;
    if (!isNullable) {
      if (!day && (month === null || month === undefined)) {
        this.state.hasInteracted &&
          this.setState({ error: "Please select a date" });
        return false;
      } else {
        return true;
      }
    }
    return true;
  }
  validate() {
    this.setState({ hasInteracted: true });
    setTimeout(() => {
      this.isValidDate(this.state.day, this.state.month);
    });
  }
  componentDidMount() {
    const { day, month, onValueChange } = this.props;
    this.setState({
      day: day,
      month: month,
    });
    const data = {
      month: month !== null && month !== undefined ? month : null,
      day: day || null,
      isValid: this.isValidDate(day, month),
    };
    onValueChange(data);
  }
  render() {
    const { month, error } = this.state;
    const days = [];
    for (var i = 1; i <= maxDaysMap[month]; i++) {
      days.push(
        <Option key={`dob_day_${i}`} value={i}>
          {i}
        </Option>
      );
    }
    return (
      <Input.Group compact className="select-birthday">
        <Select
          showSearch
          placeholder="Month"
          optionFilterProp="children"
          value={this.state.month !== null ? this.state.month : undefined}
          onSelect={this.onMonthChange}
          notFoundContent={null}
          filterOption={(input, option) => isNonNegativeInteger(option.children.toLowerCase().indexOf(input.toLowerCase()))
          }
          style={{ width: "50%" }}
          onDropdownVisibleChange={(open) => {
            !this.state.hasInteracted &&
              !open &&
              this.setState({ hasInteracted: true });
          }}
        >
          <Option value={0}>January</Option>
          <Option value={1}>February</Option>
          <Option value={2}>March</Option>
          <Option value={3}>April</Option>
          <Option value={4}>May</Option>
          <Option value={5}>June</Option>
          <Option value={6}>July</Option>
          <Option value={7}>August</Option>
          <Option value={8}>September</Option>
          <Option value={9}>October</Option>
          <Option value={10}>November</Option>
          <Option value={11}>December</Option>
        </Select>

        <Select
          showSearch
          placeholder="Day"
          style={{ width: "50%" }}
          onSelect={this.onDayChange}
          value={this.state.day || undefined}
          notFoundContent={null}
          onDropdownVisibleChange={(open) => {
            !this.state.hasInteracted &&
              !open &&
              this.setState({ hasInteracted: true });
          }}
        >
          {days.map((item) => item)}
        </Select>
        {error && <span className="error-state">{error}</span>}
      </Input.Group>
      // </Form>
      // </div>
    );
  }
}

export default DayMonthPicker;
