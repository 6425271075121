export const OC_PROD_TITLE = "Greeted";
export const OC_CARD_SINGULAR = "Card";
export const OC_CARD_PLURAL = "Cards";

export const PLAN_TYPE_TRIAL = "trial"; // No expiry, 2 free cards for individual user.
export const PLAN_TYPE_SUBSCRIPTION = "subscription"; // Stripe subscription for individual user.
export const PLAN_TYPE_PAYMENT = "payment"; // Stripe paymentIntent for individual user.
export const PLAN_TYPE_FREE_COMPANY = "free"; // No expiry, unlimited cards for the whole company.
export const DEFAULT_USER_LEVEL_PLAN = {
  type: PLAN_TYPE_TRIAL,
  name: "Free",
  remainingCards: 2,
  totalCards: 2,
};

export const categoryData = {
  birthday: {
    icon: "🎂",
    placeHolder: "Wish %FIRST_NAME% a happy birthday",
    defaultCardTitle: "Happy Birthday, %FIRST_NAME%!",
    defaultCardSubtitle:
      "Wishing you a wonderful day, from everyone at %COMPANY_NAME%.",
  },
  work_anniversary: {
    icon: "🥂",
    placeHolder: "Wish %FIRST_NAME% on their work anniversary",
    defaultCardTitle: "Happy Work Anniversary %FIRST_NAME%!",
    defaultCardSubtitle:
      "Heartiest congratulations on setting yet another milestone, from everyone at %COMPANY_NAME%.",
  },
  great_work: {
    icon: "🏆",
    placeHolder: "Recognize %FIRST_NAME% for their great work",
    defaultCardTitle: "Great Work %FIRST_NAME%!",
    defaultCardSubtitle:
      "Thank you for doing an awesome job, from everyone at %COMPANY_NAME%.",
  },
  promotion: {
    icon: "🎖",
    placeHolder: "Congratulate %FIRST_NAME% on their promotion",
    defaultCardTitle: "Congrats on the promotion %FIRST_NAME%!",
    defaultCardSubtitle:
      "Wishing you great success in your new position, from everyone at %COMPANY_NAME%.",
  },
  goodbye: {
    icon: "👋",
    placeHolder: "Goodbye %FIRST_NAME%",
    defaultCardTitle: "Goodbye %FIRST_NAME%!",
    defaultCardSubtitle:
      "Goodbye and Best Of Luck from everyone at %COMPANY_NAME%.",
  },
  welcome_new_hire: {
    icon: "🖖",
    placeHolder: "Welcome %FIRST_NAME% to the company",
    defaultCardTitle: "Welcome to the team, %FIRST_NAME%!",
    defaultCardSubtitle:
      "We are so excited about having you as part of %COMPANY_NAME%. Let's have a wonderful time working together!",
  },
  woo_candidate: {
    icon: "😍",
    placeHolder: "Tell %FIRST_NAME% you'd love to work with them",
    defaultCardTitle:
      "%FIRST_NAME%! we'd love to have you as part of our team!",
    defaultCardSubtitle:
      "On behalf of everyone at %COMPANY_NAME%, we're excited to extend you an offer to join our company.",
  },
  wedding: {
    icon: "🎊",
    placeHolder: "Congratulate %FIRST_NAME% on their wedding",
    defaultCardTitle: "Congratulations %FIRST_NAME%!",
    defaultCardSubtitle:
      "Wishing you a lifetime of love and happiness, from everyone at %COMPANY_NAME%.",
  },
  baby: {
    icon: "👶",
    placeHolder: "Congratulate %FIRST_NAME% on their baby",
    defaultCardTitle: "Heartiest Congratulations %FIRST_NAME% & Family",
    defaultCardSubtitle:
      "On behalf of everyone at %COMPANY_NAME%, welcome to the world, little one!",
  },
  thank_you: {
    icon: "💌",
    placeHolder: "Thank %FIRST_NAME%",
    defaultCardTitle: "Thank You %FIRST_NAME%!",
    defaultCardSubtitle: "A big THANK YOU from everyone at %COMPANY_NAME%.",
  },
  other: {
    icon: "🗓",
    placeHolder: "Write something for %FIRST_NAME%",
    defaultCardTitle: "",
    defaultCardSubtitle: "",
  },
  thanksgiving: {
    icon: "🦃",
    placeHolder: "Write something for Everyone",
    defaultCardTitle: "Happy Thanksgiving Everyone!",
    defaultCardSubtitle:
      "Wishing you a wonderful Thanksgiving, from everyone at %COMPANY_NAME%.",
  },
  an_occasion: {
    icon: "🙌",
    placeHolder: "Write something for Everyone",
    defaultCardTitle: "",
    defaultCardSubtitle: "",
  },
  happy_holidays: {
    icon: "🎄",
    placeHolder: "Write something for Everyone",
    defaultCardTitle: "Happy Holidays Everyone!",
    defaultCardSubtitle: "",
  },
  happy_new_year: {
    icon: "🎊",
    placeHolder: "Write something for Everyone",
    defaultCardTitle: "Happy New Year Everyone!",
    defaultCardSubtitle: "",
  },
};

//mz-todo: This is probabaly not required since we are handling placeholder via css.
//         verify and remove the following two constants, later.
// export const CARD_TITLE_PLACEHOLDER = "Create a title here";
// export const CARD_MESSAGE_PLACEHOLDER = "Write a message here";

export const postEmojis = [
  { id: "1", emoji: "😄" },
  { id: "2", emoji: "😊" },
  { id: "3", emoji: "😜" },
  { id: "4", emoji: "🙌" },
  { id: "5", emoji: "👏" },
  { id: "6", emoji: "👍" },
  { id: "7", emoji: "🎉" },
  { id: "8", emoji: "🎊" },
  { id: "9", emoji: "🎈" },
  { id: "10", emoji: "🎂" },
  { id: "11", emoji: "🍰" },
  { id: "12", emoji: "🍫" },
  { id: "13", emoji: "🥃" },
];

// Modal state identifiers
export const MODAL_CATEGORY_COMPONENT = "MODAL_CATEGORY_COMPONENT";
export const MODAL_CARD_SETTINGS_COMPONENT = "MODAL_CARD_SETTINGS_COMPONENT";
export const MODAL_COMPANY_SETTINGS_COMPONENT =
  "MODAL_COMPANY_SETTINGS_COMPONENT";
export const MODAL_ROUTE_CARD = "MODAL_ROUTE_CARD";

// Auth flow states
export const STATE_JUBILI_EMAIL = "STATE_JUBILI_EMAIL";
export const STATE_SIGNIN_PASSWORD = "STATE_SIGNIN_PASSWORD";
export const STATE_JUBILI_PASSWORD = "STATE_JUBILI_PASSWORD";
export const STATE_JOIN_EMAIL = "STATE_JOIN_EMAIL";
export const STATE_SIGNIN_EMAIL = "STATE_SIGNIN_EMAIL";
export const STATE_USER_SETTINGS = "STATE_USER_SETTINGS";
export const STATE_VERIFICATION_LINK = "STATE_VERIFICATION_LINK";
export const STATE_SIGNIN_LINK = "STATE_SIGNIN_LINK";
export const STATE_JUBILI_PHOTO = "STATE_JUBILI_PHOTO";

// AUTH_ACTION OC_TYPE
export const OC_TYPE_CAT = "cat";
export const OC_TYPE_JUBILI_VERIFIED = "jub_ver"; //mz-todo: Find out if that's still relevant
export const OC_TYPE_JOIN_VERIFIED = "join_ver";
export const OC_TYPE_MAIN = "main";
export const OC_TYPE_POST = "post";
export const OC_TYPE_JOIN_POST = "join_post";
export const OC_TYPE_FORGOT = "create_pass";

export const themes = [
  // solid
  { id: "0", className: "palette-0" },
  { id: "1", className: "palette-1" },
  { id: "2", className: "palette-2" },
  { id: "3", className: "palette-3" },
  { id: "4", className: "palette-4" },
  { id: "5", className: "palette-5" },
  { id: "6", className: "palette-6" },
  { id: "7", className: "palette-7" },
  { id: "8", className: "palette-8" },

  // birthday
  { id: "9", className: "palette-9" },
  { id: "10", className: "palette-10" },
  { id: "11", className: "palette-11" },
  { id: "12", className: "palette-12" },
  { id: "13", className: "palette-13" },
  { id: "14", className: "palette-14" },
  { id: "15", className: "palette-15" },
  { id: "16", className: "palette-16" },
  { id: "17", className: "palette-17" },
  { id: "18", className: "palette-18" },
  { id: "19", className: "palette-19" },
  { id: "20", className: "palette-20" },
  { id: "21", className: "palette-21" },

  //party
  { id: "22", className: "palette-22" },
  { id: "23", className: "palette-23" },
  { id: "24", className: "palette-24" },
  { id: "25", className: "palette-25" },
  { id: "26", className: "palette-26" },
  { id: "27", className: "palette-27" },
  { id: "28", className: "palette-28" },
  { id: "29", className: "palette-29" },
  { id: "30", className: "palette-30" },
  { id: "31", className: "palette-31" },
  { id: "32", className: "palette-32" },
  { id: "33", className: "palette-33" },
  { id: "34", className: "palette-34" },

  //floral

  { id: "35", className: "palette-35" },
  { id: "36", className: "palette-36" },
  { id: "37", className: "palette-37" },
  { id: "38", className: "palette-38" },
  { id: "39", className: "palette-39" },
  { id: "40", className: "palette-40" },
  { id: "41", className: "palette-41" },
  { id: "42", className: "palette-42" },
  { id: "43", className: "palette-43" },
  { id: "44", className: "palette-44" },
  { id: "45", className: "palette-45" },
  { id: "46", className: "palette-46" },
  { id: "47", className: "palette-47" },
  { id: "48", className: "palette-48" },
  { id: "49", className: "palette-49" },
  { id: "50", className: "palette-50" },
  { id: "51", className: "palette-51" },
  { id: "52", className: "palette-52" },
  { id: "53", className: "palette-53" },
];

//mz-todo: Make sure we are not using id anywhere and then remove it.
//mz-todo: Refactor and move categoryData attributes in this object instead.
export const categories = [
  {
    id: "ZzQCsVuNA6dBk1jS7gG0",
    key: "birthday",
    seq: 0,
    icon: "",
    name: "Birthday",
  },
  {
    id: "LwL7ZRgZKyQ2tcT3oqPh",
    key: "work_anniversary",
    seq: 1,
    icon: "",
    name: "Work Anniversary",
  },
  {
    id: "QHE7JWrK3o2LK1BgvRSv",
    key: "great_work",
    seq: 2,
    icon: "",
    name: "Great Work",
  },
  {
    id: "ZgOJjJ1VxDIrWWmRdiy6",
    key: "promotion",
    seq: 3,
    icon: "",
    name: "Promotion",
  },
  {
    id: "YdC6uQNJzpUqIIZEJFEH",
    key: "goodbye",
    seq: 4,
    icon: "",
    name: "Goodbye",
  },
  {
    id: "QmVlmMTTlqpWtVFwo00S",
    key: "welcome_new_hire",
    seq: 5,
    icon: "",
    name: "Welcome New Hire",
  },
  {
    id: "mnCFgtEofVUmngqeNy0u",
    key: "woo_candidate",
    seq: 6,
    icon: "",
    name: "Woo Candidate",
  },
  {
    id: "1t49iXfwUt9vCOnhfkKB",
    key: "wedding",
    seq: 7,
    icon: "",
    name: "Wedding",
  },
  { id: "JKvOso0tU2cUkOm0hcXm", key: "baby", seq: 8, icon: "", name: "Baby" },
  {
    id: "qIIZEJFEHYdC6uQNJzpU",
    key: "thank_you",
    seq: 9,
    icon: "",
    name: "Thank you",
    isHidden: true,
  },
  {
    id: "MxnhqMdcBoLEwugc2qbz",
    key: "other",
    seq: 10,
    icon: "",
    name: "Other",
  },
  {
    id: "group-cat-1",
    key: "thanksgiving",
    seq: 11,
    icon: "",
    name: "Thanksgiving",
    isGroupType: true,
    isHidden: true,
  },
  {
    id: "group-cat-2",
    key: "an_occasion",
    seq: 12,
    icon: "",
    name: "An Ocassion",
    isGroupType: true,
    isHidden: true,
  },
  {
    id: "group-cat-3",
    key: "happy_holidays",
    seq: 13,
    icon: "",
    name: "Happy Holidays",
    isGroupType: true,
    isHidden: true,
  },
  {
    id: "group-cat-4",
    key: "happy_new_year",
    seq: 14,
    icon: "",
    name: "Happy New Year",
    isGroupType: true,
    isHidden: true,
  },
];

// Special Categories:
export const CAT_WOO_CANDIDATE = "woo_candidate";
export const CAT_THANKSGIVING = "thanksgiving";
export const CAT_AN_OCCASION = "an_occasion";
export const CAT_HAPPY_HOLIDAYS = "happy_holidays";
export const CAT_HAPPY_NEW_YEAR = "happy_new_year";
export const CAT_BIRTHDAY = "birthday";
export const CAT_ANNIVERSARY = "work_anniversary";
export const CAT_OTHER = "other";

export function isGroupedCategory(category) {
  return (
    category === CAT_THANKSGIVING ||
    category === CAT_AN_OCCASION ||
    category === CAT_HAPPY_HOLIDAYS ||
    category === CAT_HAPPY_NEW_YEAR
  );
}

// Workspace Integration:
export const WORKSPACE_GWS = "GWS";
export const WS_RES_NO_USERS = "NO_USERS_FOUND";
export const WS_RES_USER_COUNT_EXCEEDS = "USER_COUNT_EXCEEDS";
export const MAX_GWS_USERS_ALLOWED = 50;
export const WS_RES_NON_ADMIN_USER = "NON_ADMIN_USER"; // thrown for Greeted non admin user
export const WS_RES_GWS_FORBIDDEN = "GWS_FORBIDDEN"; // thrown for GWS non admin user