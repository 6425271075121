import React from "react";
import "./styles/HeaderOne.less";
import { Layout, Button, Space, Modal, Menu, Dropdown, Tooltip } from "antd";
import AuthHeader from "./AuthHeader";
import logo from "./img/logo-greeted.svg";
import { categoryData, OC_PROD_TITLE } from "../shared/constants";
import * as nav from "../routes/nav";
import PrepareDelivery from "./PrepareDelivery";
import SentDelivery from "./SentDelivery";
import CardInitButton from "../components/CardInitButton";
import EmojiHandler from "./EmojiHandler";
import UserAvatar from "./custom/UserAvatar";
import Logger, { PRIORITY_CRITICAL } from "../shared/logger";
import { getCurrentTimezoneName } from "../shared/timezones";
import moment from "moment-timezone";
import { copyLink } from "../shared/util";

const { Header } = Layout;
const zoneName = getCurrentTimezoneName();

class HeaderDraft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryModalVisible: false,
      modalContent: null,
    };
    this.onPreviewClick = this.onPreviewClick.bind(this);
    this.onCloseDeliverClick = this.onCloseDeliverClick.bind(this);
    this.onConfirmAndDeliver = this.onConfirmAndDeliver.bind(this);
    this.onConfirmAndCopyLink = this.onConfirmAndCopyLink.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.afterModalClosed = this.afterModalClosed.bind(this);
    this.onCopyLinkClicked = this.onCopyLinkClicked.bind(this);
    this.onLogoClick = this.onLogoClick.bind(this);
  }
  getMenu(cardData, category, selectedCategory) {
    const isGroup = cardData.isGroupType ? cardData.isGroupType() : false;
    return (
      <Menu>
        <Menu.Item key="0">
          <EmojiHandler>
            <span role="img" aria-label="emoji" className="emoji">
              {category && categoryData[category].icon}
            </span>
          </EmojiHandler>
          <span className="selected-category">
            {selectedCategory && selectedCategory.name}
          </span>
          <span className="name" style={isGroup ? { display: "none" } : null}>
            <UserAvatar
              key={`menu_header_avatar_${
                cardData && cardData.recipient ? cardData.recipient.email : null
              }`}
              size="small"
              fetchPath={cardData.getAvatarFetchPath()}
              name={cardData.getRecipientName()}
            />{" "}
            <span className="ellipsis">{cardData.getRecipientName()}</span>
          </span>
        </Menu.Item>
        <Menu.Item
          key="1"
          style={isGroup || cardData.postsDisabled ? { display: "none" } : null}
        >
          <CardInitButton
            editMode={true}
            buttonProps={{ type: "link", size: "normal" }}
          >
            <i className="fal fa-cog"></i>Settings
          </CardInitButton>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            disabled={!cardData}
            onClick={this.onPreviewClick}
            type="link"
          >
            <i className="fal fa-eye"></i> Preview
          </Button>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item key="3">{this.getMobileTag(cardData)}</Menu.Item>

        {!cardData.postsDisabled && (
          <Menu.Item key="4">
            <Button type="primary" ghost onClick={this.onCloseDeliverClick}>
              <i className="fal fa-lock"></i> Close &amp; Publish
            </Button>
          </Menu.Item>
        )}
      </Menu>
    );
  }
  getMobileMenu(cardData, category, selectedCategory) {
    return (
      <Dropdown
        overlay={this.getMenu(cardData, category, selectedCategory)}
        className="mobile-header"
        overlayClassName="mobile-header-overlay"
        trigger={["click"]}
      >
        <Button
          type="link"
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <i className="fal fa-bars"></i>
          <i className="fal fa-times"></i>
        </Button>
      </Dropdown>
    );
  }
  componentDidMount() {
    this.setState({ modalContent: this.getConfirmModalContent() });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      prevProps.cardData?.recipient?.email !==
        this.props.cardData?.recipient?.email
    ) {
      this.setState({ modalContent: this.getConfirmModalContent() });
    }
  }
  onLogoClick() {
    nav.gotoMain();
  }
  onCopyLinkClicked() {
    copyLink("HeaderDraft:onCopyLinkClicked");
  }
  onModalClose() {
    this.setState({
      deliveryModalVisible: false,
    });
  }
  afterModalClosed() {
    this.setState({ modalContent: this.getConfirmModalContent() });
  }
  onConfirmAndDeliver() {
    this.setState({ modalContent: this.getSuccessModalContent(true) });
  }
  onConfirmAndCopyLink() {
    copyLink("HeaderDraft:onConfirmAndCopyLink");
    this.setState({ modalContent: this.getSuccessModalContent(false) });
  }
  onCloseDeliverClick() {
    this.setState({ deliveryModalVisible: true });
  }
  onPreviewClick() {
    const { onPreview } = this.props;
    onPreview && onPreview();
  }
  getConfirmModalContent() {
    const { cardData } = this.props;
    return (
      <PrepareDelivery
        disablePosts={this.props.disablePosts}
        card={cardData}
        onConfirmAndDeliver={this.onConfirmAndDeliver}
        onConfirmAndCopyLink={this.onConfirmAndCopyLink}
      />
    );
  }
  getSuccessModalContent(sent) {
    const { cardData } = this.props;
    return (
      <SentDelivery sent={sent} card={cardData} onDismiss={this.onModalClose} />
    );
  }
  getTag(cardData) {
    const { postsDisabled } = cardData;
    if (postsDisabled) {
      if (cardData.delivery && cardData.delivery.utc) {
        return (
          <span className={`status ${"status-published"}`}>
            <i className={"fal fa-lock"}></i> {"Published & Sent"}{" "}
          </span>
        );
      } else {
        return (
          <span className={`status ${"status-published"}`}>
            <i className={"fal fa-lock"}></i> {"Published"}{" "}
          </span>
        );
      }
    } else {
      if (cardData.delivery && cardData.delivery.utc) {
        return (
          <Tooltip placement="top" title={zoneName}>
            <span className={`status ${"status-scheduled"}`}>
              <i className="fal fa-paper-plane"></i>
              {moment
                .utc(cardData.delivery.utc)
                .local()
                .format("MMM DD [at] hh:mm a")}
            </span>
          </Tooltip>
        );
      } else {
        return (
          <span className={`status ${"status-open"}`}>
            <i className={"fal fa-lock-open"}></i> {"Open for posts"}{" "}
          </span>
        );
      }
    }
  }
  getMobileTag(cardData) {
    const { postsDisabled } = cardData;
    if (postsDisabled) {
      if (cardData.delivery && cardData.delivery.utc) {
        return (
          <span className={`status ${"status-published"}`}>
            <i className={"fal fa-lock"}></i> {"Published & Sent"}{" "}
          </span>
        );
      } else {
        return (
          <span className={`status ${"status-published"}`}>
            <i className={"fal fa-lock"}></i> {"Published"}{" "}
          </span>
        );
      }
    } else {
      if (cardData.delivery && cardData.delivery.utc) {
        return (
          <Tooltip placement="top" title={zoneName}>
            <span className={`status ${"status-scheduled"}`}>
              <i className="fal fa-paper-plane"></i>
              {moment
                .utc(cardData.delivery.utc)
                .local()
                .format("MMM DD [at] hh:mm a")}
            </span>
          </Tooltip>
        );
      } else {
        return (
          <span className={`status ${"status-open"}`}>
            <i className={"fal fa-lock-open"}></i> {"Open for posts"}{" "}
          </span>
        );
      }
    }
  }
  render() {
    const { cardData, categoriesList } = this.props;
    const { category, postsDisabled } = cardData;
    const selectedCategory =
      categoriesList && categoriesList !== undefined
        ? categoriesList.find((element) => element.key === cardData.category)
        : null;
    const isGroup = cardData.isGroupType ? cardData.isGroupType() : false;
    return (
      <div className="HeaderDraft">
        <Layout>
          <Header theme="light">
            <div className="logo-parent">
              <div className="logo">
                <Button type="link" onClick={this.onLogoClick}>
                  <img src={logo} alt={OC_PROD_TITLE} />
                </Button>
              </div>
              <div className="cardfor">
                <Space size={0}>
                  <EmojiHandler>
                    {cardData && !cardData.postsDisabled ? (
                      <span role="img" aria-label="emoji" className="emoji">
                        {category && categoryData[category].icon}
                      </span>
                    ) : (
                      ""
                    )}
                  </EmojiHandler>
                  {cardData && !cardData.postsDisabled ? (
                    <span className="selected-category">
                      {selectedCategory && selectedCategory.name}
                    </span>
                  ) : (
                    ""
                  )}
                  {cardData && !cardData.postsDisabled ? (
                    <span
                      className="name"
                      style={isGroup ? { display: "none" } : null}
                    >
                      <UserAvatar
                        key={`header_avatar_${
                          cardData && cardData.recipient
                            ? cardData.recipient.email
                            : null
                        }`}
                        size="small"
                        fetchPath={cardData.getAvatarFetchPath()}
                        name={cardData.getRecipientName()}
                      />{" "}
                      <span className="ellipsis">
                        {cardData.getRecipientName()}
                      </span>
                    </span>
                  ) : (
                    ""
                  )}

                  {this.getMobileMenu(cardData, category, selectedCategory)}

                  <CardInitButton
                    style={
                      isGroup || postsDisabled ? { display: "none" } : null
                    }
                    editMode={true}
                    buttonProps={{
                      type: "link",
                      size: "normal",
                      className: "btn-settings",
                    }}
                  >
                    <i className="fal fa-cog"></i>Settings
                  </CardInitButton>
                  <Button
                    className="btn-preview"
                    disabled={!this.props.cardData}
                    onClick={this.onPreviewClick}
                    type="link"
                  >
                    <i className="fal fa-eye"></i> Preview
                  </Button>
                </Space>
              </div>
            </div>

            <div className="actions">
              <Space size={18}>
                {!cardData.postsDisabled ? (
                  <Tooltip
                    zIndex={1}
                    trigger="hover"
                    title="Invite others to make posts."
                  >
                    <Button
                      type="link"
                      className="copy-link"
                      onClick={this.props.onInvitesModal}
                    >
                      <i className="fal fa-share"></i>&nbsp;Invite Others
                    </Button>
                  </Tooltip>
                ) : null}
                {/* <Button
                  // loading={this.state.contingencyFetchBusy}
                  type="link"
                  className="copy-link"
                  onClick={this.onCopyLinkClicked}
                >
                  <i className="fal fa-link"></i> Copy Link
                </Button> */}
                {postsDisabled && (
                  <Tooltip
                    trigger="hover"
                    zIndex={1}
                    title={"Share this card."}
                  >
                    <Button
                      type="link"
                      className="copy-link"
                      onClick={this.onCopyLinkClicked}
                    >
                      <i className="fal fa-link"></i> Copy Link
                    </Button>
                  </Tooltip>
                )}
                {this.getTag(cardData)}

                {!cardData.postsDisabled && (
                  <Tooltip
                    trigger="hover"
                    title="Disable further postings &amp; make this card visible to the recipient."
                  >
                    <Button
                      type="primary"
                      className="close-deliver"
                      ghost
                      onClick={this.onCloseDeliverClick}
                    >
                      <i className="fal fa-lock"></i> Close &amp; Publish
                    </Button>
                  </Tooltip>
                )}
                <AuthHeader />
              </Space>
            </div>
          </Header>
        </Layout>

        <Modal
          afterClose={this.afterModalClosed}
          maskClosable={true}
          visible={this.state.deliveryModalVisible}
          footer={null}
          onCancel={this.onModalClose}
          wrapClassName="generic-modal"
          centered
          closeIcon={<i className="fal fa-times"></i>}
        >
          {this.state.modalContent}
        </Modal>
      </div>
    );
  }
  _logCopyLinkFailed(company, cardData) {
    Logger.logAnalysis(
      PRIORITY_CRITICAL,
      "copy link failed",
      "HeaderDraft:onCopyLinkClicked",
      {
        company: company ? company : null,
        domain: company && company.domain ? company.domain : null,
        cardId: cardData && cardData.id ? cardData.id : null,
        cardData: cardData ? cardData : null,
      }
    );
  }
  _logNavigatorFailed() {
    Logger.logAnalysis(
      PRIORITY_CRITICAL,
      "copy link: navigator object failed",
      "HeaderDraft:onCopyLinkClicked",
      {
        navigator: !!navigator,
        clipboard: !!(navigator && navigator.clipboard),
        writeText: !!(
          navigator &&
          navigator.clipboard &&
          navigator.clipboard.writeText
        ),
      }
    );
  }
}

export default HeaderDraft;
