import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/payment";
import { Space, Button, Steps, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import OC from "../oc";
import Logger, { PRIORITY_CRITICAL } from "../shared/logger";
import {
  OC_CARD_SINGULAR,
  OC_CARD_PLURAL,
  PLAN_TYPE_FREE_COMPANY,
} from "../shared/constants";
import moment from "moment";

const { Step } = Steps;

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      busyCancelling: false,
      planRequestDispatched: false,
      invoices: [],
      busyCustomerPortal: false,
    };
    this.onUpgradeRenewClicked = this.onUpgradeRenewClicked.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.onCustomerPortal = this.onCustomerPortal.bind(this);    
  }
  async onCustomerPortal() {
    this.setState({ busyCustomerPortal: true });
    const data = await OC.getInstance().createStripePortalLink();
    this.setState({ busyCustomerPortal: false });
    if (!data || !data.url) {
      message.error(
        "Could not continue due to an error. We are investigating the issue. Please try again later.",
        8
      );
      Logger.logStripe(
        PRIORITY_CRITICAL,
        "Error occurred while create stripe portal link",
        "Billing:onCustomerPortal",
        {
          data: data || null,
          err: data?.err || null,
        }
      );
      return;
    }
    window.location.assign(data.url);
  }
  onUpgradeRenewClicked() {
    this.props.actions.togglePaymentModal(true);
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  async cancelSubscription() {
    const { activePlan } = this.props.payment;
    const subId = activePlan?.renewingSubId;
    if (!subId) {
      return;
    }
    this.setState({ busyCancelling: true });
    const err = await OC.getInstance().cancelSubscription(subId);
    if (!err) {
      setTimeout(() => {
        // To provide enough time for stripe hook to update the collection doc.
        this.props.actions.loadActiveSubscription((res) => {
          this.props.actions.loadActivePlan(res);
          setTimeout(() => {
            this.loadInvoices(res);
          });
        });
        this.setState({ busyCancelling: false });
      }, 5000);
    } else {
      this.setState({ busyCancelling: false });
      message.error(
        "Unable to cancel subscription. We are investigating the issue. Please try again later.",
        8
      );
      Logger.logStripe(
        PRIORITY_CRITICAL,
        err.message || "unable to cancel subscription",
        "Billing:cancelSubscription",
        { subId: subId }
      );
    }
  }
  getRemainingCardsLabel(remainingCards) {
    if (remainingCards === 0) {
      return `No ${OC_CARD_PLURAL}`;
    } else {
      return `${remainingCards} ${
        remainingCards === 1 ? OC_CARD_SINGULAR : OC_CARD_PLURAL
      }`;
    }
  }
  loadInvoices(subscriptions) {
    if (!subscriptions || !subscriptions.length) {
      return;
    }
    this.setState({ invoices: [] });
    for (const sub of subscriptions) {
      if (sub.refInvoices && sub.refInvoices.get) {
        sub.refInvoices.get().then((list) => {
          if (list && list.docs && list.docs.length) {
            const { invoices = [] } = this.state;
            invoices.push(...list.docs);
            this.setState({ invoices: invoices });
          }
        });
      }
    }
  }
  renderInvoices() {
    const { invoices } = this.state;
    if (!invoices || !invoices.length) {
      return null;
    }
    return invoices.map((item) => {
      const data = (item.data && item.data()) || null;
      if (!data || !data.created || !data.invoice_pdf) {
        return null;
      }
      return (
        <Step
          key={`invoice_${data.id}`}
          title={moment.unix(data.created).format("M/D/YYYY")}
          description={
            <Button type="link" href={data.invoice_pdf} download>
              <i className="fal fa-file-download"></i>
            </Button>
          }
        />
      );
    });
  }
  renderCardsList(cardsUsage) {
    if (!cardsUsage || !cardsUsage.length) {
      return null;
    }
    return cardsUsage.map((card, i) => {
      return (
        <Step
          key={`card_used_for_${i}`}
          title={moment(card.createdAt).format("M/D/YYYY")}
          description={
            <div>
              {card.getCategoryName()} <span>for</span>{" "}
              {card.getRecipientName()}
            </div>
          }
        />
      );
    });
  }
  async loadCardsUsage() {
    const cardsUsage = await OC.getInstance().fetchCardsUsage();
    this.setState({ cardsUsage: cardsUsage });
  }
  componentDidMount() {
    const { activePlan, activeSubscriptions, customerExists } = this.props.payment;
    if (customerExists === null) {
      this.props.actions.authCustomerExists();
    }
    this.loadCardsUsage();
    if (!activePlan || !activeSubscriptions) {
      this.props.actions.loadActiveSubscription((res) => {
        this.props.actions.loadActivePlan(res);
        this.setState({ planRequestDispatched: true });
        setTimeout(() => {
          this.loadInvoices(res);
        });
      });
    } else {
      this.setState({ planRequestDispatched: true });
      setTimeout(() => {
        this.loadInvoices(activeSubscriptions);
      });
    }
  }
  render() {
    const { activePlan, activeSubscriptions, customerExists } = this.props.payment;
    const { cardsUsage } = this.state;
    const { planRequestDispatched, busyCancelling, invoices, busyCustomerPortal} = this.state;
    if (!activePlan || !activeSubscriptions || !planRequestDispatched) {
      return this.renderLoader();
    }
    const exp = activePlan.getExpiryMoment();
    return (
      <div className="billing">
        <Space direction="vertical" size={0}>
          <div className="section">
            <h2>
              <span style={{ fontWeight: 400 }}>Plan:</span> {activePlan.name}
            </h2>
            <p>
              {activePlan.type !== PLAN_TYPE_FREE_COMPANY ? (
                <>
                  <span style={{ marginRight: 4 }}>
                    <strong>
                      {this.getRemainingCardsLabel(activePlan.remainingCards)}
                    </strong>{" "}
                    Remaining.
                  </span>
                  <Button type="link" onClick={this.onUpgradeRenewClicked}>
                    Reload or Upgrade for More
                  </Button>
                </>
              ) : null}
            </p>

            {activePlan.renewingSubId && exp ? (
              <small>
                {activePlan.renewingCardsCount}{" "}
                {activePlan.renewingCardsCount > 1
                  ? OC_CARD_PLURAL
                  : OC_CARD_SINGULAR}{" "}
                will be reloaded on {exp.format("M/D/YYYY")}.{" "}
                <Button
                  disabled={busyCancelling}
                  loading={busyCancelling}
                  onClick={this.cancelSubscription}
                  type="link"
                  className="cancel"
                >
                  Cancel
                </Button>
              </small>
            ) : null}
            {customerExists ? <p>
              <small>
                Edit payment method in{" "}
                <Button
                  disabled={busyCustomerPortal}
                  loading={busyCustomerPortal}
                  onClick={this.onCustomerPortal}
                  type="link"
                  className="cancel"
                >
                  Customer Portal
                </Button>
              </small>
            </p> : null}
          </div>

          {/* <div className="section">
            <h2>Payment Method</h2>
            <p>
              Ending in 1234
              <Button type="link">Update</Button>
            </p>
          </div> */}

          {invoices && invoices.length ? (
            <div className="section">
              <h2>Invoices</h2>
              <Steps progressDot direction="vertical">
                {this.renderInvoices()}
              </Steps>
            </div>
          ) : null}
          {cardsUsage && cardsUsage.length ? (
            <div className="section">
              <h2>Cards Used</h2>
              <Steps progressDot direction="vertical">
                {this.renderCardsList(cardsUsage)}
              </Steps>
            </div>
          ) : null}
        </Space>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { payment } = state;
  return { payment };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
