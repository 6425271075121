import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/auth";
import * as metadataActions from "../../actions/metadata";
import * as cardActions from "../../actions/card";
import "../styles/ZeroState.less";
import { Form, Input, Button, Space, message as antdMessage } from "antd";
import PendingEmail from "../PendingEmail"; //mz-todo: move this component to /card folder.
import OC from "../../oc";
import Post from "../../oc/models/post";
import moment from "moment";
import { OC_TYPE_POST, OC_TYPE_JOIN_POST } from "../../shared/constants";
import { LoadingOutlined } from "@ant-design/icons";
import Logger, { PRIORITY_HIGH, PRIORITY_CRITICAL } from "../../shared/logger";

class PostAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      isPasswordView: false,
      busy: false,
      signinLinkSent: false,
      joinLinkSent: false,
    };
    this.onNextClick = this.onNextClick.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.sendSigninLink = this.sendSigninLink.bind(this);
    this.sendJoinLink = this.sendJoinLink.bind(this);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    const { sendJoinLink } = this.props;
    this.setState({ isPasswordView: !sendJoinLink });
    if (sendJoinLink) {
      setTimeout(() => {
        this.sendJoinLink();
      });
    }
  }
  onPasswordChanged(e) {
    this.setState({ password: e.target.value });
  }
  sendJoinLink() {
    this.sendLink(OC_TYPE_JOIN_POST);
  }
  sendSigninLink() {
    this.sendLink(OC_TYPE_POST);
  }
  sendLink(ocType) {
    const { email, name, cardId, message, themeId, selectedGifyId, onLinkSent } = this.props;
    if (!email || !name) {
      return;
    }
    this.setState({ busy: true });
    localStorage.setItem("post_redirect_url", window.location.pathname);
    localStorage.setItem("post_message", message);
    localStorage.setItem("post_theme_id", themeId);
    localStorage.setItem("post_email", email.toLowerCase());
    localStorage.setItem("card_id", cardId);
    selectedGifyId && localStorage.setItem("post_gify_id", selectedGifyId);
    //mz-todo: After MVP, add all the localstorage fields in url.
    //         Create single object and base64 encode it for url.
    const fieldsMap = new Map();
    fieldsMap.set("name", name);
    OC.getInstance()
      .sendPostAuthLink(email, ocType, fieldsMap)
      .then((err) => {
        if (err) {
          antdMessage.error(
            `Failed to send ${
              ocType === OC_TYPE_POST ? "sign in" : "email verification"
            } link.`
          );
          setTimeout(() => {
            onLinkSent && onLinkSent(false);
          });
          Logger.logError(
            PRIORITY_HIGH,
            "Error while sending auth-post email.",
            "PostAuth:sendLink:sendPostAuthLink",
            { email: email, name: name, cardId: cardId, ocType: ocType }
          );
        } else {
          ocType === OC_TYPE_POST
            ? this.setState({ signinLinkSent: true, busy: false })
            : this.setState({ joinLinkSent: true, busy: false });

          setTimeout(() => {
            onLinkSent && onLinkSent(true);
          });
        }
      });
  }
  createUserPost(user, executionCallback) {
    if (!user) {
      executionCallback && executionCallback(false);
      return;
    }
    const { email, message, themeId, cardId, selectedGifyId } = this.props;
    if (message && email && user.email === email) {
      const post = new Post();
      post.ownerInfo = {
        ref: user.ref,
        name: user.name,
        location: user.location ? user.location.name : null,
        ownerId: user.email,
      };
      post.message = message;
      post.themeId = themeId;
      post.gify = selectedGifyId || null;
      post.createdAt = moment.utc().format();
      post.liked = [];
      this.props.cardActions.savePost(post, cardId, (err) => {
        if (err) {
          Logger.logError(
            PRIORITY_HIGH,
            "Error while creating post via post-auth-password flow. Check previous logs to find error log from store.",
            "PostAuth:createUserPost:savePost",
            { fetchedUser: user.email, cardId: cardId, postObj: post }
          );
          executionCallback && executionCallback(false);
          return;
        }
        antdMessage.success("Post Successful!");
        executionCallback && executionCallback(true);
        if (user && user.hasDOB && !user.hasDOB()) {
          const { toggleBirthdayModal } = this.props.metadataActions;
          toggleBirthdayModal && toggleBirthdayModal(true);
        }
      });
    } else {
      executionCallback && executionCallback(false);
    }
  }
  onNextClick() {
    const { email, name, message, themeId, cardId, onPostCreated, selectedGifyId } = this.props;
    const { password } = this.state;
    if (!email || !name || !password) {
      return;
    }
    this.setState({ busy: true });
    this.props.actions.login(email, password, (err) => {
      if (err) {
        antdMessage.error("Invalid password");
        this.setState({ busy: false });
        return;
      }
      this.props.actions.fetchUserInfo((user) => {
        if (!user) {
          antdMessage.error("Login failed");
          Logger.logError(
            PRIORITY_CRITICAL,
            "fetchUserInfo did not return user obj after signing in with password.",
            "PostAuth:onNextClick",
            {
              email: email,
              name: name,
              cardId: cardId,
              message: message,
              themeId: themeId,
              gifyId: selectedGifyId || null,
            }
          );
          this.setState({ busy: false });
          return;
        }
        this.createUserPost(user, (success) => {
          if (!success) {
            Logger.logAnlysis(
              PRIORITY_HIGH,
              "createUserPost callback returned success=false",
              "PostAuth:onNextClick",
              {
                email: email,
                name: name,
                cardId: cardId,
                message: message,
                themeId: themeId,
                gifyId: selectedGifyId || null,
              }
            );
          }
          if (this._isMounted) {
            this.setState({ busy: false });
            setTimeout(() => {
              onPostCreated && onPostCreated(success);
            });
          }
        });
      });
    });
  }
  renderPasswordView() {
    return (
      <div>
        <div className="ZeroState">
          <h2>Sign In</h2>
          <p>Looks like you already have an account. Let's sign you in.</p>
          <p>{this.props.email}</p>
          <Form name="basic">
            <Form.Item
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <h4>Enter your password</h4>
              <Input
                autoFocus
                type="password"
                value={this.state.password}
                onChange={this.onPasswordChanged}
                placeholder="Enter your password"
                prefix={<i className="fal fa-key"></i>}
              />
            </Form.Item>

            <Form.Item>
              <Button
                disabled={this.state.busy}
                type="primary"
                size="large"
                block
                htmlType="submit"
                onClick={this.onNextClick}
              >
                Next
              </Button>
              <Button
                style={{ display: "block" }}
                disabled={this.state.busy}
                type="link"
                onClick={this.sendSigninLink}
                className="sign-in-link"
              >
                Send me a sign-in link
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
  render() {
    const { signinLinkSent, joinLinkSent } = this.state;
    if (signinLinkSent || joinLinkSent) {
      return (
        <PendingEmail
          busy={this.state.busy}
          onResend={signinLinkSent ? this.sendSigninLink : this.sendJoinLink}
        />
      );
    }
    if (this.state.isPasswordView) {
      return this.renderPasswordView();
    }
    return (
      <div>
        <Space className="loader" direction="vertical">
          <LoadingOutlined />
        </Space>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    cardActions: bindActionCreators(cardActions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(PostAuth);
