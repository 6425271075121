import React from "react";
import "../../containers/styles/CardDraft.less";
import { Layout, Button, Row, Col } from "antd";
import HeaderRecipientView from "./HeaderRecipientView";
import CardUserView from "./CardUserView";
import PostView from "../PostView";
import { _getSortedPosts } from "../../shared/card_utils";

const { Content } = Layout;

class CardPreview extends React.Component {
  constructor(props) {
    super(props);
    this.endPreview = this.endPreview.bind(this);
    this.onKeyDownCheck = this.onKeyDownCheck.bind(this);
  }
  endPreview() {
    this.props.onEndPreview && this.props.onEndPreview();
  }
  isLoading() {
    return this.props.card.draftCard === null || this.props.company === null;
  }
  onKeyDownCheck(e) {
    if (e.key === "Escape") {
      this.props.onEndPreview && this.props.onEndPreview();
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDownCheck);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDownCheck);
  }
  render() {
    const { company, authorPost, currentUser } = this.props;
    const { draftCard, posts } = this.props.card;
    if (this.isLoading()) {
      this.endPreview();
    }
    const sortedPosts = _getSortedPosts(posts);
    return (
      <div className="CardDraft" onClick={this.endPreview}>
        <Layout>
          <HeaderRecipientView />
          <Layout>
            <Content>
              <Button className="exit-preview">
                <i className="fal fa-eye"></i> Click to exit preview.
              </Button>
              <CardUserView company={company} draftCard={draftCard} />
              <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={16} className="posts">
                  {sortedPosts &&
                    sortedPosts.map((item) => {
                      return (
                        <PostView
                          isAuthorPost={
                            authorPost ? item.id === authorPost.id : false
                          }
                          currentUser={currentUser}
                          key={`postview_preview${
                            item.id ||
                            (item.ownerInfo && item.ownerInfo.ownerId) ||
                            Math.random()
                          }`}
                          data={item}
                        />
                      );
                    })}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default CardPreview;
