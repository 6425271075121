import React from "react";
import "./styles/VerifyEmail.less";
import { Button, Space } from "antd";
import fly from "./img/artwork-fly.png";
import gmail from "./img/icon-gmail.svg";
import outlook from "./img/icon-outlook.svg";
import yahoo from "./img/icon-yahoo.svg";
import logo from "./img/logo-greeted.svg";
import { STATE_VERIFICATION_LINK, OC_PROD_TITLE } from "../shared/constants";

class ZeroStateVerify extends React.Component {
  //mz-todo: warning... inline styles for the message element. Fix later.
  constructor(props) {
    super(props);
    this.state = {
      busy: false,
    };
    this.onResendClick = this.onResendClick.bind(this);
  }
  onResendClick() {
    const { email, resendLink, resendType } = this.props;
    if (!email || !resendLink || !resendType) {
      return;
    }
    this.setState({ busy: true });
    resendLink(email, resendType, () => {
      this.setState({ busy: false });
    });
  }
  renderLogo() {
    const { companyLogo } = this.props;
    if (companyLogo) {
      return (
        <div className="brand-logo">
          <img src={companyLogo} alt={OC_PROD_TITLE} />
        </div>
      );
    } else {
      return (
        <div className="brand-logo">
          <span className="product">
            <img src={logo} alt={OC_PROD_TITLE} />
          </span>
        </div>
      );
    }
  }
  render() {
    return (
      <div className="VerifyEmail">
        <div className="title">
          {this.renderLogo()}
          <div className="artwork">
            <img src={fly} alt={OC_PROD_TITLE} />
          </div>
          <h2>We’ve emailed you a sign-in link</h2>
          {this.props.authFlow === STATE_VERIFICATION_LINK ? (
            <p style={{ textAlign: "center" }}>
              Please verify your email address by signing in with that link.
            </p>
          ) : (
            <p style={{ display: "inline" }}>
              Simply click the link in the email to sign-in.
            </p>
          )}
        </div>

        <div className="services">
          <Space size="large">
            <Button size="large" href="http://gmail.com" target="blank">
              <img src={gmail} alt={OC_PROD_TITLE} /> Open Gmail
            </Button>
            <Button size="large" href="http://outlook.com" target="blank">
              <img src={outlook} alt={OC_PROD_TITLE} /> Open Outlook
            </Button>
            <Button size="large" href="http://login.yahoo.com" target="blank">
              <img src={yahoo} alt={OC_PROD_TITLE} /> Open Yahoo
            </Button>
          </Space>
        </div>
        <p className="text-center">
          Didn’t receive email?&nbsp;
          <Button
            disabled={this.state.busy}
            loading={this.state.busy}
            onClick={this.onResendClick}
            type="link"
          >
            Resend Email
          </Button>
        </p>
      </div>
    );
  }
}

export default ZeroStateVerify;
