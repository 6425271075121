import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as metadataActions from "../actions/metadata";
import { LoadingOutlined } from "@ant-design/icons";
import OC from "../oc";
import { message } from "antd";
import {
  WS_RES_NO_USERS,
  WS_RES_USER_COUNT_EXCEEDS,
  WS_RES_GWS_FORBIDDEN,
  MAX_GWS_USERS_ALLOWED,
} from "../shared/constants";
import * as nav from "../routes/nav";

class AutomationGW extends React.Component {
  async componentDidMount() {
    const code = this.props.location.query?.code;
    if (code) {
      const res = await OC.getInstance().syncGWUsers(code);
      if (res?.err) {
        const errCode = res?.err_code || "";
        switch (errCode) {
          case WS_RES_USER_COUNT_EXCEEDS:
            message.info(
              `Could not initiate Workspace integration. Workspaces with more than ${MAX_GWS_USERS_ALLOWED} users are not supported.`,
              8
            );
            break;
          case WS_RES_NO_USERS:
            const companyId = OC.getInstance().getCurrentUserCompanyId();
            message.info(
              `Could not initiate Workspace integration. No users were found in the Workspace${
                companyId ? ` for ${companyId}` : "."
              }`,
              8
            );
            break;
          case WS_RES_GWS_FORBIDDEN:
            message.info(
              "Could not initiate Workspace integration. Only a Goolge Workspace admin can enable this feature.",
              8
            );
            break;
          default:
            message.error("Could not initiate Workspace integration.");
            break;
        }
      } else {
        this.props.metadataActions.fetchCompany(() => {
          nav.gotoWithReplace(nav.URL_HOMEPAGE);
        });
        message.info("Google Workspace integration enabled.");
        return; // <- Do not remove. Prevents redirection before company data is fetched.
      }
    } else {
      //todo: log here
      message.error("Could not initiate Workspace integration.");
      console.log("error: Invalid auth url. Missing code parameter."); //warn
    }
    nav.gotoWithReplace(nav.URL_HOMEPAGE);
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  render() {
    return <div>{this.renderLoader()}</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AutomationGW);
