import React from "react";
import { Button, Form, Input, Upload, message, Checkbox } from "antd";
//import LocationSelect from "./custom/LocationSelect";
import "./styles/Modal.less";
import OC from "../oc";
import Location from "../oc/models/location";
import { OC_TYPE_FORGOT } from "../shared/constants";
import ImgCrop from "antd-img-crop";
import Fade from "react-reveal/Fade";
import DayMonthPicker from "./DayMonthPicker";

const { Dragger } = Upload;

class ModalUpdateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileChanged: false,
      previewImage: null,
      busy: false,
      name: "",
      password: "",
      currentPassword: "",
      isChecked: this.props.isPasswordUpdateFlow || false,
      location: null,
      dobDay: null,
      dobMonth: null,
      isValidDOB: false,
      isValidAnniversary: false,
      anniversaryDay: null,
      anniversaryMonth: null,
      nameError: null,
      dobError: null,
      currentPasswordError: null,
      newPasswordError: null,
    };
    this.beforeUpload = this.beforeUpload.bind(this);
    this.onImageRemoved = this.onImageRemoved.bind(this);
    this.onDoneClicked = this.onDoneClicked.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onCurrentPasswordChanged = this.onCurrentPasswordChanged.bind(this);
    this.onCheckChanged = this.onCheckChanged.bind(this);
    this.onAddNewLocation = this.onAddNewLocation.bind(this);
    this.onLocationSelect = this.onLocationSelect.bind(this);
    this.onSetPasswordClick = this.onSetPasswordClick.bind(this);
    this.onDobChanged = this.onDobChanged.bind(this);
    this.onAnniversaryChanged = this.onAnniversaryChanged.bind(this);

    this.dobPickerRef = React.createRef();
  }
  onDobChanged(val) {
    this.setState({
      dobDay: val.day,
      dobMonth: val.month,
      isValidDOB: val.isValid,
    });
  }
  onAnniversaryChanged(val) {
    this.setState({
      anniversaryDay: val.day,
      anniversaryMonth: val.month,
      isValidAnniversary: val.isValid,
    });
  }
  async onSetPasswordClick() {
    const { showEmailSentModal } = this.props;
    if (this.state.busy) {
      return;
    }
    const { user } = this.props;
    if (!user) {
      return;
    }
    const emailSuccess = await this.sendCreatePasswordEmail();
    if (emailSuccess) {
      showEmailSentModal && showEmailSentModal(true);
    }
  }
  onAddNewLocation(name) {
    const { onAddNewLocation } = this.props;
    const loc = new Location();
    loc.name = name;
    onAddNewLocation && onAddNewLocation(loc);
  }
  onLocationSelect(location) {
    this.setState({ location: location });
  }
  onImageRemoved() {
    if (!this.state.busy) {
      this.setState({ file: null, previewImage: null, fileChanged: true });
    }
  }
  uploadImage(file, callback) {
    if (!file) {
      callback && callback();
      return;
    }
    const { user } = this.props;
    const existingFilename = user && user.avatar;
    const reader = new FileReader();
    reader.onload = (e) => {
      OC.getInstance()
        .uploadImage(e.target.result, existingFilename || null)
        .then((fullpath) => {
          if (!fullpath) {
            callback && callback(null);
            return;
          }
          callback && callback(fullpath);
        });
    };
    reader.readAsArrayBuffer(file);
  }
  getEmptyImage() {
    const byteString =
      "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";
    const mimeString = "image/png";
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    const f = new File([blob], `image`, { type: mimeString });
    f.uid = "default-" + Math.random().toString();
    return f;
  }
  loadCurrentAvatar() {
    const { user } = this.props;
    const currentAvatar = user && user.avatar;
    if (currentAvatar) {
      OC.getInstance()
        .getImageURL(currentAvatar)
        .then((url) => {
          if (!url) {
            return;
          }
          //mz-todo: Handle with this._isMounted if memory leak error occurs again.
          this.setState({
            previewImage: url,
            file: this.getEmptyImage(),
          });
        });
    }
  }
  loadPreview(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      //mz-todo: Handle with this._isMounted if memory leak error occurs again.
      this.setState({ previewImage: e.target.result });
    };
    reader.readAsDataURL(file);
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Only JPG/PNG files are allowed");
      return false;
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("Image must smaller than 1MB");
      return false;
    }
    this.setState({ file: file, fileChanged: true });
    this.loadPreview(file);
    return false;
  }
  saveUserInfo(avatarURL, removeAvatar) {
    var hasChange = false;
    const { user, onSave } = this.props;
    if (!user) {
      this.setState({ busy: false });
      return;
    }
    if (
      user.name !== this.state.name ||
      user.dob.day !== this.state.dobDay ||
      user.dob.month !== this.state.dobMonth ||
      user.anniversary.day !== this.state.anniversaryDay ||
      user.anniversary.month !== this.state.anniversaryMonth ||
      this.state.password ||
      this.state.fileChanged ||
      removeAvatar
    ) {
      hasChange = true;
    }
    user.name = this.state.name;
    user.dob.day = this.state.dobDay;
    user.dob.month = this.state.dobMonth;
    user.anniversary.day = this.state.anniversaryDay;
    user.anniversary.month = this.state.anniversaryMonth;
    if (removeAvatar) {
      user.avatar = null;
    } else if (avatarURL && avatarURL !== undefined) {
      user.avatar = avatarURL;
    }
    // if (this.state.location) {
    //   user.locationRef = this.state.location.ref || user.locationRef;
    //   if (user.location && user.location.id !== this.state.location.id) {
    //     hasChange = true;
    //   }
    // }
    user.locationRef = null;
    if (onSave) {
      onSave(
        user,
        this.state.isChecked ? this.state.password : null,
        hasChange,
        () => {
          this.setState({ busy: false });
        }
      );
    } else {
      this.setState({ busy: false });
    }
  }
  async authenticateWithPassword(email, password) {
    if (!email || !password) {
      return false;
    }
    const err = await OC.getInstance().relogin(email, password);
    if (err) {
      message.error("Failed to authenticate user.");
      return false;
    } else {
      return true;
    }
  }
  async sendCreatePasswordEmail() {
    const { user } = this.props;
    if (!user) {
      return false;
    }
    const err = await OC.getInstance().sendMagicLink(
      user.email,
      OC_TYPE_FORGOT
    );
    if (err) {
      message.error(
        "Could not send email with password creation link. Please check network connection."
      );
      return false;
    }
    return true;
  }
  async authenticateUser() {
    const { user } = this.props;
    if (!user) {
      return false;
    }
    if (user.hasPass) {
      return await this.authenticateWithPassword(
        user.email,
        this.state.currentPassword
      );
    } else {
      return await this.sendCreatePasswordEmail();
    }
  }
  isValidName(name) {
    if (!name || name === undefined) {
      this.setState({ nameError: "The name field is required" });
      return false;
    } else return true;
  }
  async onDoneClicked() {
    const { name, isValidDOB, isValidAnniversary } = this.state;
    if (this.state.busy) {
      return;
    }
    const { user } = this.props;
    if (!user) {
      return;
    }
    const isValidName = this.isValidName(name);
    if (!isValidName || !isValidAnniversary) {
      return;
    }
    if (!isValidDOB) {
      const { current } = this.dobPickerRef;
      current && current.validate();
      return;
    }
    if (this.props.isPasswordUpdateFlow) {
      this.updateProfile();
      return;
    } else if (this.state.isChecked) {
      if (!this.state.currentPassword && user.hasPass) {
        this.setState({
          currentPasswordError: "Please provide current password",
        });
        return;
      }
      if (!this.state.password && user.hasPass) {
        this.setState({ newPasswordError: "Please provide a new password" });
        return;
      }
      this.setState({ busy: true });
      OC.getInstance().authAPI.pauseAuthStateCallback();
      const done = await this.authenticateUser();
      OC.getInstance().authAPI.resumeAuthStateCallback();
      if (done) {
        this.updateProfile();
      } else {
        this.setState({ busy: false });
      }
    } else {
      this.setState({ busy: true });
      this.updateProfile();
    }
  }
  removeProfilePhoto(callback) {
    const { user } = this.props;
    if (!user || !user.avatar) {
      callback && callback();
      return;
    }
    OC.getInstance()
      .deleteImage(user.avatar)
      .then((err) => {
        if (err) {
          message.error("Could not remove profile photo. Please try again.");
        }
        callback && callback();
      });
  }
  updateProfile() {
    if (this.props.isPasswordUpdateFlow && !this.state.password) {
      message.info("Please provide a new password");
      this.setState({ busy: false });
      return;
    }
    if (this.state.fileChanged && this.state.file) {
      this.uploadImage(this.state.file, (fullpath) => {
        if (!fullpath) {
          message.error(
            "Failed to upload image file. Please check network connection."
          );
          this.setState({ busy: false });
          return;
        }
        this.saveUserInfo(fullpath);
      });
    } else if (this.state.fileChanged && !this.state.file) {
      this.removeProfilePhoto(() => {
        this.saveUserInfo(null, true);
      });
    } else {
      this.saveUserInfo(null);
    }
  }
  onNameChanged(e) {
    this.setState({ name: e.target.value, nameError: null });
  }
  onPasswordChanged(e) {
    this.setState({ password: e.target.value, newPasswordError: null });
  }
  onCurrentPasswordChanged(e) {
    this.setState({
      currentPassword: e.target.value,
      currentPasswordError: null,
    });
  }
  onCheckChanged(e) {
    this.setState({
      isChecked: e.target.checked,
      password: "",
      currentPassword: "",
    });
  }
  componentDidMount() {
    this.loadCurrentAvatar();
    const { user } = this.props;
    if (user) {
      this.setState({
        name: user.name,
        // dob && anniversary values will be loaded via DayMonthPicker:componentDidMount callbacks
      });
    }
  }
  render() {
    const { user, isPasswordUpdateFlow } = this.props;
    const fileItem = [];
    if (this.state.file) {
      fileItem.push(this.state.file);
    }
    if (!user) {
      return null;
    }
    const { nameError, currentPasswordError, newPasswordError } = this.state;
    return (
      <div className="ModalUpdateProfile">
        <div className="title">
          <h2>Update Profile</h2>
          <p>{user.email}</p>
        </div>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <Form.Item>
            <ImgCrop shape="round" rotate={true} grid={true}>
              <Dragger
                fileList={fileItem}
                onRemove={this.onImageRemoved}
                multiple={false}
                beforeUpload={this.beforeUpload}
              >
                {this.state.previewImage ? (
                  <Fade>
                    <div className="profile-pic">
                      <img src={this.state.previewImage} alt="Profile" />
                    </div>
                  </Fade>
                ) : (
                  <p className="ant-upload-text">
                    Drag &amp; Drop a Profile Photo or <Button>Browse</Button>
                  </p>
                )}
              </Dragger>
            </ImgCrop>
          </Form.Item>
          <Form.Item>
            <h4>Your Name</h4>
            <Input
              autoFocus
              value={this.state.name}
              onChange={this.onNameChanged}
              placeholder="Pam Beesly"
              prefix={<i className="fal fa-user"></i>}
            />
            {nameError && <span className="error-state">{nameError}</span>}
          </Form.Item>
          <h4>Your Birthday</h4>
          <DayMonthPicker
            onValueChange={this.onDobChanged}
            day={user?.dob?.day}
            month={user?.dob?.month}
            isNullable={false}
            ref={this.dobPickerRef}
          />
          <br></br>
          <h4>
            Work Anniversary <span>(Optional)</span>
          </h4>
          <DayMonthPicker
            day={user?.anniversary?.day}
            month={user?.anniversary?.month}
            isNullable={true}
            onValueChange={this.onAnniversaryChanged}
          />
          {/* Hidden input element to prevent auto-filling username */}
          <Input
            style={{
              opacity: 0,
              width: 0,
              height: 0,
              padding: 0,
              border: 0,
              position: "absolute",
            }}
            value=""
            placeholder=""
          />

          <Form.Item className="pwd">
            {user.hasPass ? (
              <div className="pwd-check">
                <Checkbox
                  disabled={this.props.isPasswordUpdateFlow}
                  onChange={this.onCheckChanged}
                  checked={this.state.isChecked}
                >
                  {"Set a New Password"}
                </Checkbox>
              </div>
            ) : (
              <div>
                <Button block type="link" onClick={this.onSetPasswordClick}>
                  Set a Password
                </Button>
              </div>
            )}
            {/* Hidden input element to prevent auto-filling password */}
            <Input
              style={{
                opacity: 0,
                width: 0,
                height: 0,
                padding: 0,
                border: 0,
                position: "absolute",
              }}
              name="fakepasswordremembered"
              type="password"
              value=""
              placeholder=""
            />

            {!isPasswordUpdateFlow && this.state.isChecked && user.hasPass && (
              <Fade>
                <div
                  className="pwd-textfield-wrapper"
                  style={{ marginTop: 50 }}
                >
                  <h4>Current Password</h4>
                  <Input
                    className="pwd-textfield"
                    value={this.state.currentPassword}
                    onChange={this.onCurrentPasswordChanged}
                    type="password"
                    placeholder="Current Password"
                    prefix={<i className="fal fa-key"></i>}
                  />
                </div>
              </Fade>
            )}
            {currentPasswordError && (
              <span className="error-state">{currentPasswordError}</span>
            )}

            {this.state.isChecked && user.hasPass && (
              <Fade>
                <div
                  className="pwd-textfield-wrapper"
                  style={{ marginTop: 20 }}
                >
                  <h4>New Password</h4>
                  <Input
                    className="pwd-textfield"
                    value={this.state.password}
                    onChange={this.onPasswordChanged}
                    type="password"
                    placeholder="New Password"
                    prefix={<i className="fal fa-key"></i>}
                  />
                </div>
              </Fade>
            )}
            {newPasswordError && (
              <span className="error-state">{newPasswordError}</span>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              disabled={this.state.busy}
              loading={this.state.busy}
              onClick={this.onDoneClicked}
              type="primary"
              size="large"
              block
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ModalUpdateProfile;
