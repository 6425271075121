import React, { Component } from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/auth";
import * as metadataActions from "../actions/metadata";
import * as paymentActions from "../actions/payment";
import LandingPage from "./LandingPage";
import AuthHeader from "../components/AuthHeader"; //mz-todo mz-warn : Removing this import is causing weird styles error.
import OC from "../oc";
import * as nav from "../routes/nav";
import "@fortawesome/fontawesome-pro/css/all.css";
import "./styles/Override.less";
import ModalBirthdayCollection from "../components/ModalBirthdayCollection";
import ModalPayment from "../components/ModalPayment";
import { Row, Col, Skeleton } from "antd";

const MAX_METADATA_FETCHES = 4;

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authAttemptCompleted: false,
      isLoggedIn: false,
      resyncing: false,
      metadataFetches: 0,
      active: true,
      size: "default",
      buttonShape: "default",
      avatarShape: "circle",
    };
    this.onLoginStateChanged = this.onLoginStateChanged.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }
  componentDidMount() {
    this.props.metadataActions.fetchCategories(); //mz-todo: Remove this action and use shared constant everywhere.
    OC.getInstance().authAPI.addAuthStateListener(this.onLoginStateChanged);
  }
  removeStripeEvents() {
    if (this._unsubStripeEvents) {
      this._unsubStripeEvents();
      this._unsubStripeEvents = null;
    }
  }
  componentWillUnmount() {
    OC.getInstance().authAPI.removeAuthStateListener();
    this.removeStripeEvents();
  }
  logoutUser() {
    OC.getInstance().authAPI.signout();
  }
  processSnapshot(snap) {
    const data = snap?.data ? snap.data() : null;
    if (data?.email && data?.defaultPlan?.remainingCards >= 0) {
      this.props.metadataActions.setUserDefaultPlan(data.defaultPlan);
    }
  }
  onLoginStateChanged(user) {
    this.props.actions.changeAuthUserTo(user);
    if (user) {
      this._unsubStripeEvents = OC.getInstance().attachStripeEventListener(
        (snap) => {
          this.props.paymentActions.loadActivePlan();
          this.processSnapshot(snap);
        }
      );
      const _that = this;
      window.addEventListener("beforeunload", function () {
        _that.removeStripeEvents();
      });
      this.setState({ isLoggedIn: true, authAttemptCompleted: true });
      if (!nav.isMagicLinkURL()) {
        this.props.actions.fetchUserInfo((user) => {
          this.addOne();
          user && this.props.actions.fetchAvatarURL(user.avatar);
        });
        this.props.metadataActions.fetchLocations(() => this.addOne());
        this.props.metadataActions.fetchUsers(() => this.addOne());
        this.props.metadataActions.fetchCompany(() => this.addOne());
      } else {
        this.setState({ metadataFetches: MAX_METADATA_FETCHES });
      }
    } else {
      this.removeStripeEvents();
      this.setState({ isLoggedIn: false, authAttemptCompleted: true });
      !nav.isAuthURL() &&
        !nav.isUnprotectedURL() &&
        !nav.isRemindersURL() &&
        browserHistory.push("/");
    }
  }
  renderLoading() {
    return (
      <div className="skeleton greetingpage-dynamic-structure">
        <Row align="center">
          <Col xs={22} sm={18} md={18}>
            <Skeleton active paragraph={{ rows: 6 }} />
          </Col>
        </Row>
      </div>
    );
  }
  delayedFetch(fetchType) {
    if (!this.state.isLoggedIn) {
      return;
    }
    console.log("re-sync attempt...");
    this.state.resyncing && this.setState({ resyncing: false });
    const {
      locationsFetched,
      usersListFetched,
      userFetched,
      companyFetched,
    } = this.props.metadata;
    const {
      fetchLocations,
      fetchUsers,
      fetchCompany,
    } = this.props.metadataActions;
    const { fetchUserInfo } = this.props.actions;
    switch (fetchType) {
      case "fetchLocations":
        !locationsFetched && fetchLocations();
        break;
      case "fetchUsers":
        !usersListFetched && fetchUsers();
        break;
      case "fetchUserInfo":
        !userFetched &&
          fetchUserInfo((user) => {
            user && this.props.actions.fetchAvatarURL(user.avatar);
          });
        break;
      case "fetchCompany":
        !companyFetched && fetchCompany();
        break;
      default:
        break;
    }
  }
  addOne() {
    const { metadataFetches } = this.state;
    if (metadataFetches >= MAX_METADATA_FETCHES) {
      return;
    }
    this.setState({ metadataFetches: metadataFetches + 1 });
  }
  metadataFetchCompleted() {
    return this.state.metadataFetches >= MAX_METADATA_FETCHES;
  }
  renderGlobalComponents() {
    return (
      <>
        <ModalBirthdayCollection
          metadata={this.props.metadata}
          metadataActions={this.props.metadataActions}
          authActions={this.props.actions}
          card={this.props.card}
        />
        <ModalPayment user={this.props.metadata.user} />
      </>
    );
  }
  render() {
    const { isLoggedIn, authAttemptCompleted, resyncing } = this.state;
    if (!authAttemptCompleted) {
      return this.renderLoading();
    }
    if (isLoggedIn && !nav.isMagicLinkURL()) {
      if (!this.metadataFetchCompleted()) {
        return this.renderLoading();
      }
      const {
        locationsFetched,
        usersListFetched,
        userFetched,
        companyFetched,
      } = this.props.metadata;
      if (!resyncing) {
        !locationsFetched &&
          setTimeout(this.delayedFetch.bind(this, "fetchLocations"), 8000);
        !usersListFetched &&
          setTimeout(this.delayedFetch.bind(this, "fetchUsers"), 8000);
        !userFetched &&
          setTimeout(this.delayedFetch.bind(this, "fetchUserInfo"), 8000);
        !companyFetched &&
          setTimeout(this.delayedFetch.bind(this, "fetchCompany"), 8000);
      }
      if (
        !(locationsFetched && usersListFetched && userFetched && companyFetched)
      ) {
        !resyncing && console.log("fetch attempt in 8 seconds...");
        !resyncing && setTimeout(() => this.setState({ resyncing: true }));
        return this.renderLoading();
      }
    }
    return (
      <div className="greetingpage-dynamic-structure">
        {this.props.content || <LandingPage isLoggedIn={isLoggedIn} />}
        {this.renderGlobalComponents()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth, metadata, card } = state;
  return { auth, metadata, card };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
