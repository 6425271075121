import React from "react";
import { Button } from "antd";
import { OC_CARD_SINGULAR } from "../shared/constants";
import "./styles/Modal.less";

class ModalRouteCard extends React.Component {
  render() {
    const { category, recipient, routeToDuplicateCard, handleCancel } = this.props;
    return (
      <div className="ModalCardSettings AlreadyExists">
        <h2>Card already exists</h2>
        <p>
          An unpublished <strong>{category}</strong> {OC_CARD_SINGULAR} already exists for <strong>{recipient}</strong>.
        </p>

        <div>
          <Button
            block
            type="primary"
            onClick={routeToDuplicateCard}
            size="large"
          >
            Take Me There
          </Button>
          <Button block onClick={handleCancel} type="link" size="large">
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default ModalRouteCard;
