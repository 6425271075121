import React from "react";
import {
  Row,
  Col,
  Button,
  Steps,
  Card,
  Avatar,
  Carousel,
} from "antd";

import "./styles/LandingPage.less";
import * as nav from "../routes/nav";
import EmojiHandler from "../components/EmojiHandler";
import greetedLogo from "./img/logo-greeted.svg";
import splashImg from "./img/lp-splash.png";
import artCards from "./img/art-cards.png";
import artReminders from "./img/art-reminder-wishes.png";
import testimony1 from "./img/testimony1.png";
import testimony2 from "./img/testimony2.png";
import testimony3 from "./img/testimony3.png";
import testimony4 from "./img/testimony4.png";
import testimony5 from "./img/testimony5.png";
import MainFooter from "../components/MainFooter";
import Confetti from "react-dom-confetti";

const config = {
  angle: 90,
  spread: 500,
  startVelocity: 30,
  elementCount: 80,
  dragFriction: 0.23,
  width: "6px",
  height: "6px",
  perspective: "500px",
  //colors: ["#4285F4", "#DB4437", "#F4B400", "#0F9D58", "#5B51D8"],
  colors: ["#F759AB", "#BAE7FF", "#5976A6", "#FFCF4D", "#FFD6E7"],
};

const { Step } = Steps;
const { Meta } = Card;

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.triggerConfetti = this.triggerConfetti.bind(this);
    this.state = {
      confetti1: false,
      confetti2: false,
      confetti3: false,
      confetti4: false,
    };
  }
  onLogoClick() {
    nav.goto("/");
  }
  triggerConfetti() {
    if (!document.hasFocus()) {
      return;
    }
    setTimeout(() => {
      this.setState({
        confetti1: false,
        confetti2: false,
        confetti3: false,
        confetti4: false,
      });
    });
    setTimeout(() => {
      this._mounted && this.setState({ confetti1: true });
    }, 1000);
    setTimeout(() => {
      this._mounted && this.setState({ confetti2: true });
    }, 2000);
    setTimeout(() => {
      this._mounted && this.setState({ confetti3: true });
    }, 3000);
    setTimeout(() => {
      this._mounted && this.setState({ confetti4: true });
    }, 4000);
  }
  componentWillUnmount() {
    this._mounted = false;
    this.confettiTimeout && clearTimeout(this.confettiTimeout);
    this.confettiTimeout = null;
  }
  componentDidMount() {
    this._mounted = true;
    this.triggerConfetti();
    this.confettiTimeout = setTimeout(this.triggerConfetti, 10000);
  }
  render() {
    if (this.props.isLoggedIn) {
      setTimeout(() => nav.goto(nav.URL_HOMEPAGE));
      return <div />;
    }
    return (
      <div className="LandingPage">
        <div className="gradient-patch-top"></div>
        <div className="gradient-patch-top-right"></div>
        <div className="gradient-patch-bottom-left"></div>
        <div className="gradient-patch-bottom"></div>
        <Button className="sign-in" href={nav.URL_AUTH_FLOW_SIGNIN}>
          Sign In
        </Button>

        <div className="lp-container">
          <Row
            className="hero"
            justify="center"
            style={{ textAlign: "center" }}
          >
            <Col>
              <Button type="link" className="logo">
                <img src={greetedLogo} alt="Greeted" />
              </Button>
              <div className="tagline">
                Team <span className="gradient">Celebration</span> Sidekick
              </div>
              <div className="splash">
                <img src={splashImg} alt="Greeted" />
              </div>
              <div className="subtext">
                Send cards filled with warm wishes from coworkers everywhere.
              </div>
              <div>
                <Button
                  className="primary-border"
                  size="large"
                  target="_blank"
                  href={
                    "https://greeted.io/c/TL9OWgCwcgZDicOWDgVU_ZHVuZGVybWlmZmxpbmluYy5uZXQ=?welcome=1"
                  }
                >
                  <i className="fal fa-eye"></i>See Example
                </Button>
                <Button
                  type="primary"
                  size="large"
                  href={nav.URL_AUTH_FLOW_JOIN}
                >
                  Get Started
                </Button>
              </div>
            </Col>
          </Row>

          <Row
            className="content one"
            justify="center"
            align="middle"
            gutter={32}
          >
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <div className="img">
                <img src={artCards} alt="Greeted" />
                <Confetti
                  className="confetti first"
                  active={this.state.confetti1}
                  config={config}
                />
                <Confetti
                  className="confetti second"
                  active={this.state.confetti2}
                  config={config}
                />
                <Confetti
                  className="confetti third"
                  active={this.state.confetti3}
                  config={config}
                />
                <Confetti
                  className="confetti fourth"
                  active={this.state.confetti4}
                  config={config}
                />
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="text">
                <h2>Their special day deserves more than a chat shoutout.</h2>
                <div className="paragraph">
                  Easy to miss, chats often exclude busy folks and timezones.
                  Greeted gives everyone days to post{" "}
                  <span className="accent">
                    <EmojiHandler>🌈</EmojiHandler> Creative
                  </span>{" "}
                  wishes on a{" "}
                  <span className="accent">
                    <EmojiHandler>🙌</EmojiHandler> Collaborative Card
                  </span>
                  . Participation is 90%. Wishes, lovelier. Your company
                  branding is on all cards and emails.
                </div>
                <Button
                  className="primary-border"
                  target="_blank"
                  href={
                    "https://greeted.io/c/TL9OWgCwcgZDicOWDgVU_ZHVuZGVybWlmZmxpbmluYy5uZXQ=?welcome=1"
                  }
                >
                  <i className="fal fa-eye"></i>Live Example
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="content two" justify="center" align="middle">
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={8}
              lg={8}
              xl={8}
            >
              <div className="text">
                <h2>No more remembering dates, huddling teammates.</h2>
                <div className="paragraph">
                  <span className="accent">
                    <EmojiHandler>🔔</EmojiHandler> Reminders
                  </span>{" "}
                  let you create Birthday &amp; Work Anniversary cards with a
                  click, inviting coworkers to post wishes on them.{" "}
                  <span className="accent">
                    <EmojiHandler>⏲ </EmojiHandler> Scheduled Delivery
                  </span>{" "}
                  even delivers them on the special day. You can always create
                  cards for any occasion yourself.
                </div>
                <Button
                  className="primary-border"
                  href={nav.URL_AUTH_FLOW_JOIN}
                >
                  <i className="fal fa-birthday-cake"></i>Create a Card
                </Button>
              </div>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 8, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xl={{ span: 8, order: 2 }}
            >
              <div className="img">
                <img src={artReminders} alt="Greeted" />
              </div>
            </Col>
          </Row>

          <Row className="content three" justify="center">
            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
              <h2>Greatness follows gratefulness.</h2>
              <div>
                <Steps>
                  <Step
                    description={
                      <span>
                        Create a card with a delivery date{" "}
                        <EmojiHandler>🗓</EmojiHandler>{" "}
                      </span>
                    }
                  />
                  <Step
                    description={
                      <span>
                        Invite coworkers to post wishes on it{" "}
                        <EmojiHandler>🖌</EmojiHandler>{" "}
                      </span>
                    }
                  />
                  <Step
                    description={
                      <span>
                        The card is auto-delivered, brightening their day{" "}
                        <EmojiHandler>😇</EmojiHandler>{" "}
                      </span>
                    }
                  />
                </Steps>
              </div>
              <div className="custom-divider">
                <span>
                  <EmojiHandler>🤖</EmojiHandler>
                </span>
              </div>
              <p className="subtext secondary">
                Automate by setting up Birthdays &amp; Work Anniversaries.
              </p>
            </Col>
          </Row>

          <Row className="content four" justify="center">
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <h3>Pricing</h3>
              <h5>Free</h5>
              <p>
                We pledge to only introduce paid plans deemed reasonable by our
                free users, remain 100% ad-free, and keep your data strictly
                private.
              </p>
            </Col>
          </Row>

          <Row className="content five" gutter={[30, 45]} justify="center">
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card>
                <Meta
                  avatar={<Avatar src={testimony1} />}
                  title="Lydia Rosser"
                  description="Office Administrator"
                />
                <div className="text">
                  “One less thing for me to manage is a win in my book! Simple
                  to stay on top of special occasions and gather people to cheer
                  on!”
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card>
                <Meta
                  avatar={<Avatar src={testimony2} />}
                  title="Brandon Herwitz"
                  description="Enginneering Manager"
                />
                <div className="text">
                  “Amped up my team’s culture” ... “Now no special days goes
                  uncelebrated and it takes almost no effort on my part.”
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card>
                <Meta
                  avatar={<Avatar src={testimony3} />}
                  title="Erin Bator"
                  description="Office Manager"
                />
                <div className="text">
                  “The reminders are awesome and I love how quickly I can get
                  everyone involved. Makes my life easier!”
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card>
                <Meta
                  avatar={<Avatar src={testimony4} />}
                  title="Amy Zhuo"
                  description="HR Coordinator"
                />
                <div className="text">
                  “Beautiful, fun, yet professional. The best part... everyone
                  gets to pick their own card! I also like the auto-sending
                  feature.”
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card>
                <Meta
                  avatar={<Avatar src={testimony5} />}
                  title="Ann Franci"
                  description="People &amp; Diversity Leader"
                />
                <div className="text">
                  “While Greeted is easy for me, and fun for all, what it really
                  does best is bring out the appreciation people have for one
                  another.”
                </div>
              </Card>
            </Col>
          </Row>

          {/* mobile view below */}
          <Row className="content five mobile" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Carousel autoplay dots={false} >
                <div>
                  <Card>
                    <Meta
                      avatar={<Avatar src={testimony1} />}
                      title="Lydia Rosser"
                      description="Office Administrator"
                    />
                    <div className="text">
                      “One less thing for me to manage is a win in my book!
                      Simple to stay on top of special occasions and gather
                      people to cheer on!”
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <Meta
                      avatar={<Avatar src={testimony2} />}
                      title="Brandon Herwitz"
                      description="Enginneering Manager"
                    />
                    <div className="text">
                      “Amped up my team’s culture” ... “Now no special days goes
                      uncelebrated and it takes almost no effort on my part.”
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <Meta
                      avatar={<Avatar src={testimony3} />}
                      title="Erin Bator"
                      description="Office Manager"
                    />
                    <div className="text">
                      “The reminders are awesome and I love how quickly I can
                      get everyone involved. Makes my life easier!”
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <Meta
                      avatar={<Avatar src={testimony4} />}
                      title="Amy Zhuo"
                      description="HR Coordinator"
                    />
                    <div className="text">
                      “Beautiful, fun, yet professional. The best part...
                      everyone gets to pick their own card! I also like the
                      auto-sending feature.”
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <Meta
                      avatar={<Avatar src={testimony5} />}
                      title="Ann Franci"
                      description="People &amp; Diversity Leader"
                    />
                    <div className="text">
                      “While Greeted is easy for me, and fun for all, what it
                      really does best is bring out the appreciation people have
                      for one another.”
                    </div>
                  </Card>
                </div>
              </Carousel>
            </Col>
          </Row>

          <Row gutter={30} className="content six" justify="center">
            <Col xs={24} sm={24} md={7} lg={7} xl={7} className="first-col">
              <div className="tag-wrapper">
                <span className="tag">You'll love it</span>
              </div>
              <ul>
                <li>
                  <span>
                    <EmojiHandler>🎨</EmojiHandler>
                  </span>
                  Your company branding on cards &amp; emails.
                </li>
                <li>
                  <span>
                    <EmojiHandler>📮</EmojiHandler>
                  </span>
                  Invite all but the recipient with a click.
                </li>
                <li>
                  <span>
                    <EmojiHandler>🎂</EmojiHandler>
                  </span>
                  Add birth dates or auto-collect them.
                </li>
                <li>
                  <span>
                    <EmojiHandler>🗓</EmojiHandler>
                  </span>
                  Team occasion reminders.
                </li>
                <li>
                  <span>
                    <EmojiHandler>🚨</EmojiHandler>
                  </span>
                  Domain based privacy.
                </li>
              </ul>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} className="second-col">
              <div className="tag-wrapper">
                <span className="tag">So will your team</span>
              </div>
              <ul>
                <li>
                  <span>
                    <EmojiHandler>⏳</EmojiHandler>
                  </span>
                  Extra time to post wishes before delivery.
                </li>
                <li>
                  <span>
                    <EmojiHandler>🌎</EmojiHandler>
                  </span>
                  Engage local and remote coworkers.
                </li>
                <li>
                  <span>
                    <EmojiHandler>🌈</EmojiHandler>
                  </span>
                  50+ styles &amp; themes for wishes.
                </li>
                <li>
                  <span>
                    <EmojiHandler>😍</EmojiHandler>
                  </span>
                  More personalized than chat shout-outs.
                </li>
                <li>
                  <span>
                    <EmojiHandler>🤒</EmojiHandler>
                  </span>
                  Free of Covid, Germs, and Ads!
                </li>
              </ul>
            </Col>
          </Row>

          <Row justify="center">
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{ textAlign: "center" }}
            >
              <Button type="primary" size="large" href={nav.URL_AUTH_FLOW_JOIN}>
                Get Started
              </Button>
            </Col>
          </Row>

          <MainFooter />
        </div>
      </div>
    );
  }
}

export default LandingPage;
