import React, { useContext } from "react";
import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from "@giphy/react-components";
import "./styles/GiphySDK.less";
import { gifyAPIKey } from "../shared/util";

import powered from "./img/giphy-logo.svg";

const EmptyResult = () => {
  const { searchKey } = useContext(SearchContext);
  return (
    <div style={{ padding: 20 }}>
      <p style={{ textAlign: "center" }}>
        No gifs were found for keyword: {searchKey}
      </p>
    </div>
  );
};

const Components = (props) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  return (
    <>
      <div className="searchbar-wrapper">
        <SearchBar />
        <img src={powered} className="powered-by" alt="Powered by Giphy" />
      </div>
      <Grid
        noResultsMessage={<EmptyResult />}
        key={searchKey}
        fetchGifs={fetchGifs}
        noLink={true}
        width={502}
        columns={2}
        gutter={6}
        hideAttribution={true}
        onGifClick={props && props.onGifSelected}
      />
    </>
  );
};

const SearchExperience = (props) => (
  <SearchContextManager
    apiKey={gifyAPIKey}
    options={{ limit: 6 }}
    initialTerm={props && props.search}
  >
    <Components onGifSelected={props && props.onGifSelected} />
  </SearchContextManager>
);

const GiphySDK = (props) => {
  return (
    <div className="giphy-wrapper">
      <SearchExperience {...props} />
    </div>
  );
};

export default GiphySDK;
