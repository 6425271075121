import React from "react";
import PropTypes from "prop-types";
import "../containers/styles/CardDraft.less";
import {
  Button,
  Input,
  Form,
  Modal,
  message as antdMessage,
  Avatar,
  Tooltip,
} from "antd";
import "./styles/Editor.less";
import { LoadingOutlined } from "@ant-design/icons";
import EmojiHandler from "./EmojiHandler";
import PostAuth from "./card/PostAuth";
import { themes, postEmojis, OC_PROD_TITLE } from "../shared/constants";
import Post from "../oc/models/post";
import OC from "../oc";
import moment from "moment";
import UserAvatar from "./custom/UserAvatar";
import GiphySDK from "./GiphySDK";
import InitializeTwemojiInput from "./custom/emoji_editor";
import { showTwemoji } from "../shared/util";
import iconMore from "./img/icon-more.svg";
import { gf } from "../shared/util";
import { Gif } from "@giphy/react-components";
import Fade from "react-reveal/Fade";
import { categories } from "../shared/constants";
import { preloadThemes } from "../shared/preload";
import { isNonNegativeInteger } from "../shared/util";

const { TextArea } = Input;
const EMOJI_CHAR_LENGTH = 2;

class Editor extends React.Component {
  static propTypes = {
    onPost: PropTypes.func.isRequired,
    card: PropTypes.any.isRequired,
  };

  randomizedThemes = [];

  constructor(props) {
    super(props);
    this.initRandominzedThemes();
    this.state = {
      message: "",
      selectedThemeItem: this.randomizedThemes[0],
      name: "",
      email: "",
      authModalVisible: false,
      emailError: null,
      busyPosting: false,
      sendJoinLinkState: false,
      postPendingVerification: false,
      cursorPos: 0,
      emojiButtons: null,
      themeButtons: null,
      selectedGifyId: null,
      gifyData: null,
      gifyModalVisible: false,
      isLoadingGify: false,
      themesVisible: false,
      messageTooltip: false,
      nameTooltip: false,
      messageFocused: false,
      nameFocused: false,
    };
    this.messageRef = React.createRef();
    this.nameRef = React.createRef();
    this.onEmojiClicked = this.onEmojiClicked.bind(this);
    this.onTwemojiClicked = this.onTwemojiClicked.bind(this);
    this.onMessageChanged = this.onMessageChanged.bind(this);
    this.onEditorMessageChanged = this.onEditorMessageChanged.bind(this);
    this.onMessageFocus = this.onMessageFocus.bind(this);
    this.onMessageBlur = this.onMessageBlur.bind(this);
    this.onPostClicked = this.onPostClicked.bind(this);
    this.onThemeClicked = this.onThemeClicked.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.onEmailFocus = this.onEmailFocus.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);
    this.onAuthPostCreated = this.onAuthPostCreated.bind(this);
    this.onAuthPostLinkSent = this.onAuthPostLinkSent.bind(this);
    this.onModalActionCall = this.onModalActionCall.bind(this);
    this.onGifSelected = this.onGifSelected.bind(this);
    this.giphyModalOpen = this.giphyModalOpen.bind(this);
    this.giphyModalClose = this.giphyModalClose.bind(this);
    this.onRemoveGify = this.onRemoveGify.bind(this);
    this.onEmailKeyDownClick = this.onEmailKeyDownClick.bind(this);
    this.focusTwemojiEditor = this.focusTwemojiEditor.bind(this);
  }
  initRandominzedThemes() {
    if (this.randomizedThemes.length === 0) {
      for (var i = 0; i < themes.length; i++) {
        this.randomizedThemes.push(themes[i]);
      }
    }
    var currentIndex = this.randomizedThemes.length,
      temporaryValue,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = this.randomizedThemes[currentIndex];
      this.randomizedThemes[currentIndex] = this.randomizedThemes[randomIndex];
      this.randomizedThemes[randomIndex] = temporaryValue;
    }
    const msg = localStorage.getItem("editor_post_message");
    const cachedTheme = this.findThemeItem(
      localStorage.getItem("editor_selected_theme")
    );
    if (msg && cachedTheme && cachedTheme.id) {
      const ind = this.randomizedThemes.findIndex(
        (elem) => elem.id === cachedTheme.id
      );
      if (ind > 0) {
        [this.randomizedThemes[0], this.randomizedThemes[ind]] = [
          this.randomizedThemes[ind],
          this.randomizedThemes[0],
        ];
      }
    }
  }
  onRemoveGify() {
    this.setState({
      selectedGifyId: null,
      gifyData: null,
      isLoadingGify: false,
    });
    localStorage.removeItem("editor_gify_id");
  }
  giphyModalOpen() {
    this.setState({
      gifyModalVisible: true,
    });
  }
  giphyModalClose() {
    this.setState({
      gifyModalVisible: false,
    });
  }
  async onGifSelected(gify) {
    this.setState({
      selectedGifyId: gify.id,
      gifyModalVisible: false,
      gifyData: null,
      isLoadingGify: true,
    });
    const { data } = await gf.gif(gify.id);
    this.setState({ gifyData: data, isLoadingGify: false });
    localStorage.setItem("editor_gify_id", gify.id);
  }
  onMessageFocus(e) {
    const pos = this.state.cursorPos + EMOJI_CHAR_LENGTH;
    e.target.selectionStart = pos;
    e.target.selectionEnd = pos;
    this.setState({ messageFocused: true });
  }
  onMessageBlur(e) {
    this.setState({
      cursorPos: e.target.selectionStart,
      messageFocused: false,
    });
  }
  onModalActionCall(e) {
    const { onOpenUserModal } = this.props;
    onOpenUserModal && onOpenUserModal(true);
    try {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    } catch {}
  }
  findThemeItem(themeId) {
    if (!themeId) {
      return null;
    }
    const themeItem = themes.find((element) => element.id === themeId);
    return themeItem && themeItem !== undefined ? themeItem : null;
  }
  onEmailFocus() {
    this.setState({ emailError: null });
  }
  onEmailBlur(e) {
    if (!e.target.value) {
      return;
    }
    const { company } = this.props;
    if (!this.isValidEmail(e.target.value, company)) {
      const domain = company || "valid domain names";
      const error = (
        <span>
          Only <strong>{domain}</strong> email addresses are allowed.
        </span>
      );
      this.setState({ emailError: error });
      return;
    }
  }
  isValidEmail(email, domain) {
    if (!domain || !email || email.indexOf("@") === -1) {
      return false;
    }
    return email.split("@").pop() === domain;
  }
  loadEditMode(post) {
    if (!post) {
      return;
    }
    const { message, themeId, gify } = post;
    const themeItem = this.findThemeItem(themeId);
    if (gify) {
      this.setState({ isLoadingGify: true });
      gf.gif(gify).then((res) => {
        this.setState({ gifyData: res.data, isLoadingGify: false });
      });
    }
    const msg = message ? message : "";
    if (showTwemoji()) {
      this.twemojiInput.Editor.setEmpty(msg ? false : true);
      this.twemojiInput.Editor.setMessage(this.textToHtml(msg));
      this.twemojiInput.Editor.updatePaletteClass(
        themeItem ? themeItem.className : this.randomizedThemes[0].className
      );
    }
    this.setState({
      message: msg,
      cursorPos: msg.length,
      selectedThemeItem: themeItem || this.randomizedThemes[0],
      selectedGifyId: gify,
      themeButtons: this.getThemesButton(
        themeItem ? themeItem.id : this.randomizedThemes[0].id
      ),
    });
  }
  getThemesButton(selectedThemeId, optionalIsRandom) {
    const isRandom =
      optionalIsRandom !== null && optionalIsRandom !== undefined
        ? optionalIsRandom
        : !this.state.themesVisible;
    const ds = isRandom ? this.randomizedThemes : themes;
    return ds.map((item, i) => {
      return (
        <Button
          tabIndex="3"
          className={`${item.className} ${
            item.id === selectedThemeId ? "selected-palette" : ""
          }`}
          key={`theme_button_${i}`}
          data-themeid={item.id}
          onClick={this.onThemeClicked}
        >
          {i}
        </Button>
      );
    });
  }
  componentWillUnmount() {
    showTwemoji() && this.twemojiInput.Editor.removeListeners();
  }
  resetPostCache(user, card) {
    const expirySet = moment.utc();
    expirySet.add(12, "hour");
    localStorage.setItem("editor_cache_expiry", expirySet.format());
    localStorage.setItem("editor_user_email", user ? user.email : "*");
    localStorage.setItem("editor_card_id", card ? card.id : "");
    localStorage.removeItem("editor_post_message");
    localStorage.removeItem("editor_gify_id");
    localStorage.setItem("editor_selected_theme", this.randomizedThemes[0].id);
  }
  loadFromPostCache() {
    const { user, card } = this.props;
    const dtExpiry = moment(localStorage.getItem("editor_cache_expiry"));
    const msg = localStorage.getItem("editor_post_message");
    const gifyId = localStorage.getItem("editor_gify_id");
    if (
      msg &&
      dtExpiry.isValid() &&
      dtExpiry.isAfter(moment.utc()) &&
      card &&
      localStorage.getItem("editor_card_id") === card.id
    ) {
      const cachedUser = localStorage.getItem("editor_user_email");
      if (cachedUser !== "*" && user && user.email !== cachedUser) {
        this.resetPostCache(user, card);
        return false;
      } else if (cachedUser !== "*" && !user) {
        this.resetPostCache(user, card);
        return false;
      } else if (user && cachedUser === "*") {
        localStorage.setItem("editor_user_email", user.email);
      }
      const theme =
        this.findThemeItem(localStorage.getItem("editor_selected_theme")) ||
        this.randomizedThemes[0];
      if (!theme) {
        return false;
      }
      this.setState({
        message: msg || "",
        selectedThemeItem: theme,
        selectedGifyId: gifyId,
        isLoadingGify: gifyId ? true : false, // for gify loader.
      });
      // warn: Duplicate code. Refactor later.
      if (gifyId) {
        this.setState({ isLoadingGify: true }); //mz-todo: Already being set above. Remove and test.
        gf.gif(gifyId).then((res) => {
          this.setState({ gifyData: res.data, isLoadingGify: false });
        });
      }
      // warn end.
      if (showTwemoji()) {
        this.twemojiInput.Editor.setEmpty(msg ? false : true);
        this.twemojiInput.Editor.setMessage(this.textToHtml(msg));
        this.twemojiInput.Editor.updatePaletteClass(theme.className);
      }
      return true;
    } else {
      this.resetPostCache(user, card);
      return false;
    }
  }
  componentDidMount() {
    preloadThemes();
    const isTwemoji = showTwemoji();
    const { editingPost } = this.props;
    if (isTwemoji) {
      this.twemojiInput = InitializeTwemojiInput(
        ".post_textarea",
        this.onEditorMessageChanged,
        () => {
          if (editingPost) {
            setTimeout(() => this.loadEditMode(editingPost));
          } else {
            if (!this.loadFromPostCache()) {
              setTimeout(() => {
                this.twemojiInput.Editor.setEmpty(true);
                this.twemojiInput.Editor.updatePaletteClass(
                  this.randomizedThemes[0].className
                );
              });
            }
          }
        }
      );
    } else {
      const { editingPost } = this.props;
      if (editingPost) {
        this.loadEditMode(editingPost);
      } else {
        this.loadFromPostCache();
      }
    }
    const emojiButtons = (
      <EmojiHandler>
        {postEmojis.map((item, i) => {
          return (
            <Button
              key={i}
              data-emoji={item.id}
              onClick={isTwemoji ? this.onTwemojiClicked : this.onEmojiClicked}
            >
              <span role="img" aria-label="emoji">
                {item.emoji}
              </span>
            </Button>
          );
        })}
      </EmojiHandler>
    );
    if (!editingPost) {
      //mz-info: Added condition to reduce the number of times the render() is called.
      //         In edit mode, themeButtons is set inside loadEditMode()
      this.setState({
        emojiButtons: emojiButtons,
        themeButtons: this.getThemesButton(this.randomizedThemes[0].id),
      });
    } else {
      this.setState({ emojiButtons: emojiButtons });
    }
  }
  onAuthPostCreated() {
    this.setState({
      authModalVisible: false,
      busyPosting: false,
      sendJoinLinkState: false,
      postPendingVerification: false,
    });
  }
  onAuthPostLinkSent(success) {
    if (success) {
      this.setState({
        busyPosting: false,
        sendJoinLinkState: false,
        postPendingVerification: true,
      });
    } else {
      this.setState({
        busyPosting: false,
        sendJoinLinkState: false,
        postPendingVerification: false,
      });
    }
  }
  onModalClose() {
    this.setState({
      authModalVisible: false,
      busyPosting: false,
      sendJoinLinkState: false,
    });
  }
  onNameChanged(e) {
    const text = e.target.value || "";
    this.setState({ name: text });
  }
  onEmailChanged(e) {
    this.setState({ email: e.target.value });
  }
  asyncSetBusy(busy) {
    setTimeout(() => {
      this.setState({ busyPosting: busy });
    });
  }
  runPostAuthFlow() {
    const { email, name } = this.state;
    if (!email || !name) {
      return;
    }
    //mz-todo: figure out where to clear editor or asyncSetBusy(false)
    this.asyncSetBusy(true);
    OC.getInstance()
      .findUserBy(email)
      .then((user) => {
        if (user && user.hasPass) {
          this.setState({
            sendJoinLinkState: false,
            authModalVisible: true,
          });
        } else {
          this.setState({
            sendJoinLinkState: true,
            authModalVisible: true,
          });
        }
      });
  }
  postAlreadyExists(email) {
    const { postList } = this.props;
    if (!postList) {
      return false;
    }
    for (var i = 0; i < postList.length; i++) {
      const { ownerInfo } = postList[i];
      if (ownerInfo && ownerInfo.ownerId && ownerInfo.ownerId === email) {
        return true;
      }
    }
    return false;
  }
  validatesForPostAuthFlow(user, name, email) {
    if (user) {
      return true;
    }
    if (!name) {
      this.setState({ nameTooltip: true });
      try {
      this.nameRef?.current?.input &&
        this.nameRef.current.input.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      } catch {}
      setTimeout(() => this.setState({ nameTooltip: false }), 3000);
      return false;
    }
    if (!email) {
      const error = <span>Please enter your email.</span>;
      this.setState({ emailError: error });
      return false;
    }
    if (this.postAlreadyExists(email)) {
      antdMessage.info(
        "You've already posted. Please log in to edit your post."
      );
      return false;
    }
    if (this.checkRecipientPost(email)) {
      antdMessage.info("Recipients can't post on their own cards");
      return false;
    }
    return true;
  }
  onEmailKeyDownClick(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      this.onPostClicked();
    }
  }
  onPostClicked() {
    const { user } = this.props;
    const { message, selectedThemeItem, emailError, name, email } = this.state;
    if (!message) {
      this.setState({ messageTooltip: true });
      setTimeout(() => this.setState({ messageTooltip: false }), 3000);
      return;
    }
    if (!this.validatesForPostAuthFlow(user, name, email)) {
      return;
    }
    if (emailError) {
      return;
    }
    if (!user) {
      this.runPostAuthFlow();
      return;
    }
    //mz-info: An old function, which was being used to disable the Post button earlier,
    // but now using to to block execution only.
    if (this.postButtonDisabled()) {
      return;
    }
    const post = this.props.editingPost ? this.props.editingPost : new Post();
    post.ownerInfo = {
      ref: user.ref,
      name: user.name,
      location: user.location ? user.location.name : null,
      ownerId: user.email,
    };
    post.message = message;
    post.themeId = selectedThemeItem
      ? selectedThemeItem.id
      : this.randomizedThemes[0].id;
    post.gify = this.state.selectedGifyId;
    if (!this.props.editingPost) {
      post.createdAt = moment.utc().format();
    }
    post.liked = post.liked ? post.liked : [];
    this.props.onPost &&
      this.props.onPost(post, this.props.editingPost ? true : false);
  }
  htmlToText(html) {
    //warn mz-todo: Use compiled regex. Also, only process text while sending post and not with every character.
    return html
      .replace(/<div>/g, "\n")
      .replace(/<\/div>/g, "")
      .replace(/<br>/g, "")
      .replace(/&nbsp;/g, " ");
  }
  textToHtml(msg) {
    if (!msg) {
      return "";
    }
    const parts = msg.split("\n");
    if (parts.length === 1) {
      return msg;
    }
    var nMsg = parts[0];
    for (var i = 1; i < parts.length; i++) {
      if (parts[i] === "") {
        nMsg += "<div><br></div>";
      } else {
        nMsg += "<div>" + parts[i] + "</div>";
      }
    }
    return nMsg;
  }
  onEditorMessageChanged(html) {
    const msg = this.htmlToText(html);
    this.twemojiInput.Editor.setEmpty(msg ? false : true);
    this.setState({ message: msg });
    localStorage.setItem("editor_post_message", msg);
  }
  onMessageChanged(e) {
    this.setState({ message: e.target.value });
    localStorage.setItem("editor_post_message", e.target.value);
  }
  getPlaceholderText() {
    return "Write Your Message Here";

    // DO NOT REMOVE THIS DISABLED CODE.
    // Temp. changing placeholder for editor.
    // Import categoryData from "../shared/constants" after enabling this code.

    // const { card } = this.props;
    // if (!card || !card.getRecipientName()) {
    //   return "";
    // }
    // const text = categoryData[card.category].placeHolder;
    // return text.replace("%FIRST_NAME%", card.getRecipientName());
  }
  onThemeClicked(e) {
    const item = this.findThemeItem(e.target.dataset.themeid);
    if (!item) {
      return;
    }
    localStorage.setItem("editor_selected_theme", e.target.dataset.themeid);
    this.setState({
      selectedThemeItem: item,
      themeButtons: this.getThemesButton(e.target.dataset.themeid),
    });
    showTwemoji() &&
      this.twemojiInput.Editor.updatePaletteClass(item.className);
  }
  onEmojiClicked(e) {
    if (this.messageRef.current) {
      const index = this.state.cursorPos;
      if (isNonNegativeInteger(index)) {
        const emojiId = e.target.dataset.emoji;
        const emojiItem = postEmojis.find((element) => element.id === emojiId);
        if (emojiItem) {
          const { message } = this.state;
          const newMessage =
            message.slice(0, index) + emojiItem.emoji + message.slice(index);
          this.setState({ message: newMessage });
          localStorage.setItem("editor_post_message", newMessage);
          setTimeout(() => {
            this.messageRef.current.focus();
          });
        }
      }
    }
  }
  onTwemojiClicked(e) {
    const emojiId = e.target.dataset.emoji;
    const emojiItem = postEmojis.find((element) => element.id === emojiId);
    if (emojiItem) {
      this.twemojiInput.Editor.insertEmoji(emojiItem.emoji);
      this.twemojiInput.Editor.setEmpty(false);
    }
  }
  renderUser(user) {
    const { message, nameTooltip } = this.state;
    if (!user) {
      return (
        <Tooltip
          visible={
            nameTooltip || (this.state.nameFocused && !this.state.name?.trim())
          }
          title="Please write your name here"
        >
          <Input
            ref={this.nameRef}
            tabIndex="2"
            className={message ? "editor-name" : "editor-name name-hidden"}
            onChange={this.onNameChanged}
            placeholder="Your Name Here"
            value={this.state.name}
            onFocus={() => this.setState({ nameFocused: true })}
            onBlur={() => this.setState({ nameFocused: false })}
            onClick={(e) => {
              try {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              } catch {}
            }}
          />
        </Tooltip>
      );
    }
    return (
      <div
        className="user-info"
        onClick={this.onModalActionCall}
        style={{ cursor: "pointer" }}
      >
        <Tooltip title="Update Profile" trigger="hover">
          <span className="avatar">
            {!user.avatar && !user.avatarDownloadURL ? (
              <Avatar
                className="add-profilepic"
                size={30}
                icon={<i className="fal fa-camera"></i>}
              />
            ) : (
              <UserAvatar
                key={`editor_avatar_${user.email}`}
                size={30}
                downloadURL={user.avatarDownloadURL}
                fetchPath={user.avatar}
                name={user && user.name}
              />
            )}
          </span>
          <span className="name">
            {user.name ? user.name : "Your Full Name"}
          </span>
        </Tooltip>
      </div>
    );
  }
  renderSigninForm() {
    const { emailError } = this.state;
    const { company } = this.props;
    return (
      <Form
        className="sign-in"
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        layout="vertical"
      >
        <Form.Item>
          {/* <Input
            value={this.state.name}
            onChange={this.onNameChanged}
            placeholder="Your Full Name"
            prefix={<i className="fal fa-user"></i>}
          /> */}

          <Input
            tabIndex="3"
            style={
              emailError && {
                borderColor: "#F5222D",
                borderWidth: 1,
              }
            }
            value={this.state.email}
            onChange={this.onEmailChanged}
            onBlur={this.onEmailBlur}
            onFocus={this.onEmailFocus}
            placeholder={`Your Work Email @${company ? company : "xyz.com"}`}
            onKeyDown={this.onEmailKeyDownClick}
            // prefix={<i className="fal fa-envelope"></i>}
          />

          {emailError && <span className="error-state">{emailError}</span>}
        </Form.Item>
      </Form>
    );
  }
  // mz-info: Not using this function to disable post button anymore and not updating it's code either.
  // Using it to block execution on Post function. Post button will be enabled at all times.
  postButtonDisabled() {
    const { busyPosting, postPendingVerification, message } = this.state;
    const { busyPostButton } = this.props;
    if (busyPosting || postPendingVerification || busyPostButton) {
      return true;
    }
    return message ? false : true;
  }
  // getAnchorTitle() {
  //   return this.state.postPendingVerification ? "My Post" : "Post Something";
  // }
  getPostButtonText() {
    const { editingPost } = this.props;
    const { postPendingVerification } = this.state;
    if (editingPost) {
      return "Update";
    }
    return postPendingVerification ? "Awaiting verification" : "Post";
  }
  renderGifyLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  checkRecipientPost(email) {
    const { card } = this.props;
    if (card.getRecipientEmail && card.getRecipientEmail() === email) {
      return true;
    }
    return false;
  }
  getGify() {
    const { gifyData, isLoadingGify } = this.state;
    if (isLoadingGify) {
      return <div>{this.renderGifyLoader()}</div>;
    }
    if (!gifyData) {
      return null;
    }
    return (
      <div className="giphy-image">
        <span>
          <Button
            className="giphy-remove"
            style={{ pointerEvents: "auto" }}
            onClick={this.onRemoveGify}
          >
            <i className="fal fa-times"></i>
          </Button>
          <Gif gif={gifyData} height={"auto"} width={"auto"} />
        </span>
      </div>
    );
  }
  hasOtherPosts() {
    const { otherPostCount, editingPost } = this.props;
    if (!editingPost && otherPostCount) {
      return true;
    } else if (editingPost && otherPostCount && otherPostCount > 1) {
      return true;
    }
    return false;
  }
  focusTwemojiEditor() {
    try {
      this.twemojiInput &&
        this.twemojiInput.Editor &&
        this.twemojiInput.Editor.focus();
    } catch {}
  }
  focusNormalEditor() {
    if (this.messageRef && this.messageRef.current) {
      setTimeout(() => this.messageRef.current.focus());
    }
  }
  // focusEditor ... Called via ref from parent container.
  focusEditor() {
    const isTwemoji = showTwemoji();
    isTwemoji ? this.focusTwemojiEditor() : this.focusNormalEditor();
  }
  render() {
    const { user, card } = this.props;
    if (card.postsDisabled) {
      return null;
    }
    const { message, selectedThemeItem, messageTooltip } = this.state;
    const disabledStyles = {
      pointerEvents: "none",
      opacity: 0.5,
    };
    const isTwemoji = showTwemoji();
    const cat = categories.find((element) => element.key === card.category);
    return (
      <div
        className={`editor ${selectedThemeItem && selectedThemeItem.className}`}
        id="mp"
      >
        <div style={this.state.postPendingVerification ? disabledStyles : null}>
          <div className="emojis">
            <span className="giffy-btn-wrapper">
              <Button
                tabIndex="0"
                type="secondary"
                className="btn-gif"
                onClick={this.giphyModalOpen}
              >
                <i className="fal fa-plus"></i> GIF
              </Button>

              <Modal
                className="giphy-modal"
                width={550}
                footer={null}
                onCancel={this.giphyModalClose}
                maskClosable={true}
                visible={this.state.gifyModalVisible}
                centered
                closeIcon={<i className="fal fa-times"></i>}
              >
                <GiphySDK
                  onGifSelected={this.onGifSelected}
                  search={cat && cat.name ? cat.name : ""}
                />
              </Modal>
            </span>
            {this.state.emojiButtons}
          </div>
          {isTwemoji ? (
            <Tooltip
              visible={messageTooltip}
              title="Please write your message here"
            >
              <div className="text-box" onClick={this.focusTwemojiEditor}>
                <textarea
                  className="_twemoji_input post_textarea"
                  rows="7"
                ></textarea>
                {this.renderUser(user)}
                {this.getGify()}
              </div>
            </Tooltip>
          ) : (
            <div className="text-box">
              <label htmlFor="post"></label>
              <Tooltip
                visible={
                  messageTooltip ||
                  (this.state.messageFocused && !message?.trim())
                }
                title="Please write your message here"
              >
                <TextArea
                  id="post"
                  onFocus={this.onMessageFocus}
                  onBlur={this.onMessageBlur}
                  autoSize={true}
                  maxLength={2000}
                  ref={this.messageRef}
                  value={this.state.message}
                  onChange={this.onMessageChanged}
                  placeholder={this.getPlaceholderText()}
                  tabIndex="1"
                />
              </Tooltip>
              {this.renderUser(user)}
              {this.getGify()}
            </div>
          )}

          <div className="theme-wrapper">
            <div className="colors">
              {!this.state.themesVisible ? this.state.themeButtons : null}
              <Button
                className="more"
                onClick={() =>
                  this.setState({
                    themesVisible: true,
                    themeButtons: this.getThemesButton(
                      this.state.selectedThemeItem.id,
                      false
                    ),
                  })
                }
              >
                <img src={iconMore} alt={OC_PROD_TITLE} />
              </Button>
            </div>
            <div className="user">{!user ? this.renderSigninForm() : null}</div>
            <div className="button">
              <Button
                htmlType="submit"
                tabIndex="4"
                loading={this.state.busyPosting || this.props.busyPostButton}
                block
                size="large"
                type="primary"
                // disabled={this.postButtonDisabled()}
                onClick={this.onPostClicked}
              >
                {this.getPostButtonText()}
              </Button>
            </div>
            {this.hasOtherPosts() ? (
              <div className="button others-posts">
                <Button href="#pl" block size="large" type="link">
                  See what others posted <EmojiHandler>👇</EmojiHandler>
                </Button>
              </div>
            ) : null}

            {this.state.themesVisible ? (
              <Fade top cascade duration={250}>
                <div className="theme-selector">
                  <div className="selector-header">
                    <p className="heading">Pick a theme</p>
                    <Button
                      type="link"
                      onClick={() =>
                        this.setState({
                          themesVisible: false,
                          themeButtons: this.getThemesButton(
                            this.state.selectedThemeItem.id,
                            true
                          ),
                        })
                      }
                    >
                      Done
                    </Button>
                  </div>
                  <div className="colors all-themes">
                    {this.state.themesVisible ? this.state.themeButtons : null}
                  </div>
                </div>
              </Fade>
            ) : null}
          </div>
        </div>
        <Modal
          width={580}
          maskClosable={true}
          visible={this.state.authModalVisible}
          footer={null}
          onCancel={this.onModalClose}
          className="PostAuth"
          centered
          closeIcon={<i className="fal fa-times"></i>}
        >
          {this.state.authModalVisible ? (
            <PostAuth
              onPostCreated={this.onAuthPostCreated}
              onLinkSent={this.onAuthPostLinkSent}
              sendJoinLink={this.state.sendJoinLinkState}
              cardId={card && card.id}
              selectedGifyId={this.state.selectedGifyId}
              name={this.state.name}
              email={this.state.email}
              message={message}
              themeId={
                selectedThemeItem
                  ? selectedThemeItem.id
                  : this.randomizedThemes[0].id
              }
            />
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default Editor;
