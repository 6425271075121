
export default class Model {
  getDoc() {
    const jsonString = JSON.stringify(this);
    return JSON.parse(jsonString);
  }
  toJSON(proto) {
    let jsoned = {};
    let toConvert = proto || this;
    Object.getOwnPropertyNames(toConvert).forEach((prop) => {
      const val = toConvert[prop];
      if (prop === 'toJSON' || prop === 'constructor' || prop === 'id') {
        return;
      }
      if (typeof val === 'function') {
        return;
      }
      jsoned[prop] = val;
    });

    const inherited = Object.getPrototypeOf(toConvert);
    if (inherited !== null) {
      Object.keys(this.toJSON(inherited)).forEach(key => {
        if (!!jsoned[key] || key === 'constructor' || key === 'toJSON') {
          return;
        }
        if (typeof inherited[key] === 'function') {
          return;
        }
        jsoned[key] = inherited[key];
      });
    }
    return jsoned;
  }
  isCached() {
    return this.__cached === true;
  }
}