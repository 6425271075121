import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router, browserHistory } from "react-router";
import routerElement from "./routes";
import configureStore from "./store";
import "./App.css";

const store = configureStore();

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={browserHistory} routes={routerElement} />
      </Provider>
    );
  }
}
