import * as types from "../constants";
import { categories } from "../shared/constants";
import OC from "../oc";

export function toggleBirthdayModal(visible) {
  return (dispatch) => {
    return dispatch({
      type: types.ON_TOGGLE_BIRTHDAY_MODAL,
      visible: visible,
    });
  };
}

export function toggleProfileModal(visible, args) {
  return (dispatch) => {
    return dispatch({
      type: types.ON_TOGGLE_PROFILE_MODAL,
      visible: visible,
      args: args ? args : null,
    });
  };
}

export function clearActionArgs() {
  return (dispatch) => {
    return dispatch({
      type: types.ON_CLEAR_ACTION_ARGS,
    });
  };
}

export function deleteUser(email, statusCallback) {
  return (dispatch) => {
    OC.getInstance()
      .deleteUser(email)
      .then(() => {
        statusCallback && statusCallback(null);
        return dispatch(deleteUserDispatch(email));
      });
  };
}

export function toggleAdmin(email, value, statusCallback) {
  return (dispatch) => {
    OC.getInstance()
      .toggleAdmin(email, value)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          return dispatch({ type: "" });
        } else {
          statusCallback && statusCallback(null);
          return dispatch(toggleAdminDispatch(email, value));
        }
      });
  };
}

export function toggleReminders(email, value, statusCallback) {
  return (dispatch) => {
    OC.getInstance()
      .toggleReminders(email, value)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          return dispatch({ type: "" });
        } else {
          statusCallback && statusCallback(null);
          return dispatch(toggleRemindersDispatch(email, value));
        }
      });
  };
}

export function fetchCategories() {
  return (dispatch) => {
    return dispatch(fetchCategoriesDispatch(categories));
  };
}

export function fetchUsers(callback) {
  return (dispatch) => {
    OC.getInstance()
      .fetchUsers()
      .then((res) => {
        callback && setTimeout(() => callback(null));
        return dispatch(fetchUsersDispatch(res || []));
      });
  };
}

export function fetchUsersByDomain(companyId, callback) {
  return (dispatch) => {
    OC.getInstance()
      .fetchUsersByDomain(companyId)
      .then((res) => {
        callback && callback(res || []);
        return dispatch(fetchUsersDispatch(res || []));
      });
  };
}

export function includeUser(user) {
  return (dispatch) => {
    return dispatch(includeUserDispatch(user));
  };
}

export function setUserDefaultPlan(defaultPlan) {
  return (dispatch) => {
    return dispatch({
      type: types.SET_USER_DEFAULT_PLAN,
      defaultPlan: defaultPlan,
    });
  };
}

export function fetchLocations(callback) {
  return (dispatch) => {
    OC.getInstance()
      .fetchLocations()
      .then((res) => {
        callback && setTimeout(() => callback(null));
        return dispatch(fetchLocationsDispatch(res || []));
      });
  };
}

export function saveLocation(newLocation) {
  // mz-todo: Handle else case for error.
  return (dispatch) => {
    OC.getInstance()
      .saveLocation(newLocation)
      .then((res) => {
        if (res) {
          return dispatch(saveLocationDispatch(newLocation));
        }
      });
  };
}

export function updateCompany(company, statusCallback) {
  return (dispatch) => {
    OC.getInstance()
      .updateCompany(company)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          return dispatch(updateCompanyDispatch(null));
        } else {
          statusCallback && statusCallback(null);
          return dispatch(updateCompanyDispatch(company));
        }
      });
  };
}

export function fetchCompany(callback) {
  return (dispatch) => {
    OC.getInstance()
      .fetchCompany()
      .then((res) => {
        callback && setTimeout(() => callback(res));
        return dispatch(fetchCompanyDispatch(res));
      });
  };
}

//mz-todo: warn: check security implications.
export function fetchCompanyByDomain(domain) {
  return (dispatch) => {
    OC.getInstance()
      .fetchCompanyById(domain)
      .then((res) => {
        return dispatch(fetchCompanyDispatch(res));
      });
  };
}

function deleteUserDispatch(res) {
  return {
    type: types.ON_DELETE_USER,
    email: res,
  };
}

function fetchCompanyDispatch(res) {
  return {
    type: types.ON_COMPANY_FETCHED,
    company: res,
  };
}

function updateCompanyDispatch(res) {
  return {
    type: types.ON_COMPANY_UPDATE,
    company: res,
  };
}

function toggleAdminDispatch(res, value) {
  return {
    type: types.ON_MAKE_ADMIN,
    email: res,
    value: value,
  };
}

function toggleRemindersDispatch(res, value) {
  return {
    type: types.ON_TOGGLE_REMINDERS,
    email: res,
    value: value,
  };
}

function fetchUsersDispatch(res) {
  return {
    type: types.ON_USERS_FETCHED,
    usersList: res,
  };
}

function saveLocationDispatch(loc) {
  return {
    type: types.ON_LOCATION_SAVED,
    newLocation: loc,
  };
}

function fetchCategoriesDispatch(res) {
  function compare(a, b) {
    if (a.seq > b.seq) {
      return 1;
    } else if (b.seq > a.seq) {
      return -1;
    }
    return 0;
  }
  return {
    type: types.ON_CATEGORIES_FETCHED,
    categories: res.sort(compare),
  };
}

function fetchLocationsDispatch(res) {
  return {
    type: types.ON_LOCATIONS_FETCHED,
    locations: res,
  };
}

function includeUserDispatch(res) {
  return {
    type: types.ON_INCLUDE_USER,
    user: res,
  };
}
