import React from "react";
import { Modal, Input, Button, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import deleteArtwork from "./img/artwork-delete.png";
import OC from "../oc";
import {
  categories,
  OC_PROD_TITLE,
  OC_CARD_SINGULAR,
} from "../shared/constants";
const CONFIRMATION_TEXT = "DELETE";

class DeleteCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      postsCount: -1,
      confirmationText: "",
      busy: false,
    };
    this.afterClose = this.afterClose.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.card && this.props.card) {
      this.setState({ visible: true });
      this.loadCardPosts(this.props.card);
    } else if (prevProps.card && !this.props.card) {
      this.setState({ visible: false, postsCount: -1, confirmationText: "" });
    }
  }
  onDeleteClick() {
    const { card, deleteCard, onClose } = this.props;
    if (this.state.confirmationText !== CONFIRMATION_TEXT || !card) {
      return;
    }
    if (this.state.busy) {
      return;
    }
    this.setState({ busy: true });
    deleteCard &&
      deleteCard(card.id, () => {
        this.setState({ busy: false });
        onClose && onClose();
      });
  }
  afterClose() {
    this.setState({ postsCount: -1, confirmationText: "", busy: false });
    this.props.onClose && this.props.onClose();
  }
  async loadCardPosts(card) {
    if (!card || !card.id) {
      return null;
    }
    const res = await OC.getInstance().fetchPosts(card.id);
    if (res) {
      this.setState({ postsCount: res.length });
    } else {
      this.props.onClose();
    }
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  renderModalContent(card) {
    const { postsCount } = this.state;
    if (!card || postsCount < 0) {
      return null;
    }
    const fullName = card.getRecipientName && card.getRecipientName();
    const cat = categories.find((element) => element.key === card.category);
    if (!cat || !cat.name) {
      return null;
    }
    return (
      <div className="DeleteCardModal">
        <div className="title">
          <div className="artwork">
            <img src={deleteArtwork} alt={OC_PROD_TITLE} />
          </div>
          <h2>Confirm Deletion</h2>
          <p>
            Once deleted, this {OC_CARD_SINGULAR} for{" "}
            <strong>
              {fullName} {cat.name}
            </strong>{" "}
            with{" "}
            <strong>
              {postsCount} {postsCount === 1 ? "post" : "posts"}{" "}
            </strong>
            will be discarded and no longer retrievable. To confirm this action,
            please type in “DELETE” below.
          </p>
        </div>

        <Form.Item>
          <h4>Enter “DELETE” to confirm deletion</h4>
          <Input
            placeholder="Verification Text"
            value={this.state.confirmationText}
            onChange={(e) =>
              this.setState({ confirmationText: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={this.state.busy}
            disabled={this.state.confirmationText !== CONFIRMATION_TEXT}
            onClick={this.onDeleteClick}
            block
            type="danger"
            size="large"
          >
            Delete
          </Button>
        </Form.Item>
      </div>
    );
  }
  render() {
    const { card } = this.props;
    if (!card) {
      return null;
    }
    return (
      <Modal
        width={475}
        afterClose={this.afterClose}
        maskClosable={true}
        visible={this.state.visible}
        footer={null}
        onCancel={() => this.setState({ visible: false })}
        centered
      >
        {this.state.postsCount === -1
          ? this.renderLoader()
          : this.renderModalContent(card)}
      </Modal>
    );
  }
}

export default DeleteCardModal;
