import React from "react";
import "./styles/HeaderOne.less";
import { Layout, Button, Menu, Dropdown, Space } from "antd";
import logo from "./img/logo-greeted.svg";
import AuthHeader from "./AuthHeader";
import CardInitButton from "./CardInitButton";
import * as nav from "../routes/nav";
import { OC_PROD_TITLE, OC_CARD_SINGULAR, OC_CARD_PLURAL } from "../shared/constants";

const { Header } = Layout;

class HeaderLogin extends React.Component {
  constructor(props) {
    super(props);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.onMenuTabChanged = this.onMenuTabChanged.bind(this);
  }
  onLogoClick() {
    nav.gotoMain();
  }
  onMenuTabChanged(item) {
    const { onTabChanged } = this.props;
    onTabChanged && onTabChanged(item.key);
  }
  getMenu() {
    return (
      <Menu onClick={this.onMenuTabChanged}>
        <Menu.Item key="1">{OC_CARD_PLURAL}</Menu.Item>
        <Menu.Item key="2">Team &amp; Reminders</Menu.Item>
      </Menu>
    );
  }
  getMobileMenu(cardData, category, selectedCategory) {
    return (
      <Dropdown
        overlay={this.getMenu(cardData, category, selectedCategory)}
        className="mobile-header"
        overlayClassName="mobile-header-overlay"
        trigger={["click"]}
      >
        <Button
          type="link"
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <i className="fal fa-bars"></i>
          <i className="fal fa-times"></i>
        </Button>
      </Dropdown>
    );
  }
  renderAddTeammateButton() {
    return (
      <Button
        className="new-greeting"
        type="primary"
        onClick={this.props.onNewTeammate}
      >
        <i className="fal fa-plus"></i>&nbsp;New Teammate
      </Button>
    );
  }
  render() {
    const { isAdmin, activeTabId } = this.props;
    return (
      <div className="HeaderLogin">
        <Layout>
          <Header theme="light">
            <div className="logo-parent">
              <div className="logo">
                <Space size={8}>
                  <Button type="link" onClick={this.onLogoClick}>
                    <img src={logo} alt={OC_PROD_TITLE} />
                  </Button>
                  {isAdmin ? this.getMobileMenu() : null}
                </Space>
              </div>
              {/* SK: this we can delete, this was a placeholder for brand-logo. Not sure if we are going to do that anymore. */}
              {/* <div className="logo" /> */}
            </div>

            <div style={{ position: "relative", zIndex: 999 }}>
              {activeTabId === "2" ? (
                this.renderAddTeammateButton()
              ) : (
                <CardInitButton
                  autoTriggerRedirectPath={nav.URL_HOMEPAGE}
                  autoTrigger={nav.isCardFlowAutoTriggerURL()}
                  style={{ display: "inline-block" }}
                  buttonProps={{
                    type: "primary",
                    size: "normal",
                    className: "new-greeting",
                  }}
                  urlLocation={this.props.urlLocation}
                >
                  <i className="fal fa-plus"></i>&nbsp;New {OC_CARD_SINGULAR}
                </CardInitButton>
              )}
              <AuthHeader />
            </div>
          </Header>
        </Layout>
      </div>
    );
  }
}

export default HeaderLogin;
