import React from "react";
import "./styles/ZeroState.less";
import { Form, Input, Button } from "antd";
import Location from "../oc/models/location";
import artworkFinishSignup from "./img/artwork-finishsignup.png";
import logo from "./img/logo-greeted.svg";
import { OC_PROD_TITLE } from "../shared/constants";
import DayMonthPicker from "./DayMonthPicker";

class ZeroStateSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      location: null,
      password: "",
      companyLogo: null,
      dobDay: null,
      dobMonth: null,
      nameError: null,
      dobError: null,
      isValidDOB: false,
      isValidAnniversary: false,
      anniversaryDay: null,
      anniversaryMonth: null,
      busy: false,
    };
    this.onNameChanged = this.onNameChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onSignupClicked = this.onSignupClicked.bind(this);
    this.onAddNewLocation = this.onAddNewLocation.bind(this);
    this.onLocationSelect = this.onLocationSelect.bind(this);
    this.onDobChanged = this.onDobChanged.bind(this);
    this.onAnniversaryChanged = this.onAnniversaryChanged.bind(this);

    this.dobPickerRef = React.createRef();
  }
  onDobChanged(val) {
    this.setState({
      dobDay: val.day,
      dobMonth: val.month,
      isValidDOB: val.isValid,
    });
  }
  onAnniversaryChanged(val) {
    this.setState({
      anniversaryDay: val.day,
      anniversaryMonth: val.month,
      isValidAnniversary: val.isValid,
    });
  }
  onAddNewLocation(name) {
    const { onAddNewLocation } = this.props;
    const loc = new Location();
    loc.name = name;
    onAddNewLocation && onAddNewLocation(loc);
  }
  onLocationSelect(location) {
    this.setState({ location: location });
  }
  onSignupClicked() {
    const { onNext } = this.props;
    const {
      name,
      password,
      dobDay,
      dobMonth,
      isValidDOB,
      isValidAnniversary,
      anniversaryDay,
      anniversaryMonth,
    } = this.state;
    const isValidName = this.isValidName(name);
    if (!isValidDOB) {
      const { current } = this.dobPickerRef;
      current && current.validate();
      return;
    }
    if (isValidName && isValidAnniversary) {
      this.setState({ busy: true });
      const dob = {};
      dob.day = dobDay;
      dob.month = dobMonth;
      const anniversary = {};
      anniversary.day = anniversaryDay;
      anniversary.month = anniversaryMonth;
      onNext && onNext(name, null, password, dob, anniversary);
    } else return;
  }
  isValidName(name) {
    if (!name || name === undefined) {
      this.setState({ busy: false, nameError: "Please provide your full name" });
      return false;
    } else return true;
  }
  onNameChanged(e) {
    this.setState({ name: e.target.value, nameError: null });
  }
  onPasswordChanged(e) {
    this.setState({ password: e.target.value });
  }
  renderLogo() {
    const { companyLogo } = this.props;
    if (companyLogo) {
      return (
        <div className="brand-logo">
          <img src={companyLogo} alt={OC_PROD_TITLE} />
        </div>
      );
    } else {
      return (
        <div className="brand-logo">
          <span className="product">
            <img src={logo} alt={OC_PROD_TITLE} />
          </span>
        </div>
      );
    }
  }
  componentDidMount() {
    const { user } = this.props;
    if (user?.hasDOB()) {
      const { dob } = user;
      this.setState({ dobDay: dob.day, dobMonth: dob.month });
    }
  }
  render() {
    const { nameError } = this.state;
    const { user } = this.props;
    return (
      <div>
        <div className="ZeroState">
          <div className="title">
            {this.renderLogo()}
            <div className="artwork">
              <img src={artworkFinishSignup} alt={OC_PROD_TITLE} />
            </div>
            <h2>Finish Signing Up</h2>
            <p
            // mz-todo: Temp. disabling edit option. Confirm before making it editable.
            // className="editable"
            // contentEditable={true}
            // suppressContentEditableWarning={true}
            >
              {user && user.email}
            </p>
          </div>

          <Form name="basic">
            <Form.Item
              rules={[{ required: true, message: "Please enter full name" }]}
            >
              <h4>Your Full Name</h4>
              <Input
                autoFocus
                onChange={this.onNameChanged}
                placeholder="Pam Beesly"
                prefix={<i className="fal fa-user"></i>}
              />
              {nameError && <span className="error-state">{nameError}</span>}
            </Form.Item>

            {/* <Form.Item
              rules={[{ required: false, message: "Please enter location" }]}
            >
              <h4>Which city do you work from?</h4>
              <LocationSelect
                onLocationSelect={this.onLocationSelect}
                onAddNewLocation={this.onAddNewLocation}
                locations={this.props.locationList}
              />
            </Form.Item> */}

            {/* Hidden input element to prevent auto-filling username */}
            <Form.Item>
              <h4>Your Birthday</h4>
              <DayMonthPicker
                day={user?.dob?.day}
                month={user?.dob?.month}
                isNullable={false}
                onValueChange={this.onDobChanged}
                ref={this.dobPickerRef}
              />
              <br></br>
              <h4>
                Work Anniversary <span>(Optional)</span>
              </h4>
              <DayMonthPicker
                day={user?.anniversary?.day}
                month={user?.anniversary?.month}
                isNullable={true}
                onValueChange={this.onAnniversaryChanged}
              />
            </Form.Item>
            <Input
              style={{
                opacity: 0,
                width: 0,
                height: 0,
                padding: 0,
                border: 0,
                position: "absolute",
              }}
              value=""
              placeholder=""
            />

            {/* Hidden input element to prevent auto-filling password */}
            <Input
              style={{
                opacity: 0,
                width: 0,
                height: 0,
                padding: 0,
                border: 0,
                position: "absolute",
              }}
              name="fakepasswordremembered"
              type="password"
              value=""
              placeholder=""
            />

            {!user?.hasPass ? (
              <Form.Item
                rules={[{ required: true, message: "Please enter Password" }]}
              >
                <h4>Set a Password</h4>
                <Input
                  onChange={this.onPasswordChanged}
                  type="password"
                  placeholder="New Password"
                  prefix={<i className="fal fa-key"></i>}
                />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                onClick={this.onSignupClicked}
                loading={this.state.busy}
                type="primary"
                size="large"
                block
                htmlType="submit"
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default ZeroStateSettings;
