import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/auth";
import * as metadataActions from "../actions/metadata";
import * as cardActions from "../actions/card";
import OC from "../oc";
import url from "url";
import { message as antdMessage, Space } from "antd";
import {
  OC_TYPE_CAT,
  OC_TYPE_JUBILI_VERIFIED,
  OC_TYPE_MAIN,
  OC_TYPE_JOIN_VERIFIED,
  OC_TYPE_POST,
  OC_TYPE_JOIN_POST,
  OC_TYPE_FORGOT,
} from "../shared/constants";
import * as nav from "../routes/nav";
import { LoadingOutlined } from "@ant-design/icons";
import Logger, { PRIORITY_HIGH, PRIORITY_CRITICAL } from "../shared/logger";
import Post from "../oc/models/post";
import moment from "moment";
import CreateNewPassword from "../components/CreateNewPassword";

class MagicAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNewPasswordFlow: false,
      userObject: null,
    };
  }
  invalidLinkError() {
    antdMessage.error("Invalid or expired link. Login failed.");
    setTimeout(() => nav.goto("/"));
  }
  async componentDidMount() {
    const continueUrl = this.props.location.query.continueUrl;
    if (!continueUrl) {
      this.invalidLinkError();
      return;
    }
    const cURL = url.parse(continueUrl, true);
    const userEmail = cURL.query.email ? cURL.query.email.toLowerCase() : null;
    const userName = cURL.query.name;
    const ocType = cURL.query.oc_type;
    if (userEmail) {
      await OC.getInstance().logout();
      OC.getInstance()
        .loginRegisterWithMagicLink(userEmail, userName, window.location.href, true)
        .then((err) => {
          if (err) {
            this.invalidLinkError();
            return;
          } else {
            //warn: Running without "await" to reduce delay time.
            this.props.metadataActions.fetchUsers();
            this.props.metadataActions.fetchLocations();
            this.props.metadataActions.fetchCompany();
            this.props.actions.fetchUserInfo((user) => {
              if (!user) {
                Logger.logError(
                  PRIORITY_CRITICAL,
                  "fetchUserInfo did not return user obj after loginRegisterWithMagicLink succeeded",
                  "MagicAuth:componentDidMount",
                  {
                    email: userEmail,
                    name: userName,
                    continueURL: cURL,
                    href: window.location.href,
                  }
                );
                setTimeout(() => nav.goto("/"));
                return;
              }
              user && this.props.actions.fetchAvatarURL(user.avatar);
              switch (ocType) {
                case OC_TYPE_FORGOT:
                  this.setState({
                    showCreateNewPasswordFlow: true,
                    userObject: user,
                  });
                  break;
                case OC_TYPE_MAIN:
                  setTimeout(() => nav.goto("/"));
                  break;
                case OC_TYPE_CAT:
                  setTimeout(() => nav.goto(nav.URL_HOMEPAGE_JUBILI));
                  break;
                case OC_TYPE_JUBILI_VERIFIED:
                  setTimeout(() => nav.goto(nav.URL_AUTH_FLOW_JUBILI_VERIFIED));
                  break;
                case OC_TYPE_JOIN_VERIFIED:
                  setTimeout(() => nav.goto(nav.URL_AUTH_FLOW_JOIN_VERIFIED));
                  break;
                case OC_TYPE_POST:
                  this.executePostAuthFlow(user, userEmail);
                  break;
                case OC_TYPE_JOIN_POST:
                  this.executePostAuthFlow(user, userEmail);
                  break;
                default:
                  setTimeout(() => nav.goto("/"));
                  break;
              }
            });
          }
        });
    } else {
      switch (ocType) {
        case OC_TYPE_MAIN:
          setTimeout(() => nav.goto("/"));
          break;
        case OC_TYPE_CAT:
          setTimeout(() => nav.goto(nav.URL_HOMEPAGE_JUBILI));
          break;
        case OC_TYPE_JUBILI_VERIFIED:
          nav.goto(nav.URL_AUTH_FLOW_JUBILI_VERIFIED);
          break;
        default:
          break;
      }
    }
  }
  createUserPost(user, executionCallback) {
    if (!user) {
      executionCallback && executionCallback(false);
      return;
    }
    const message = localStorage.getItem("post_message");
    const themeId = localStorage.getItem("post_theme_id");
    const cachedEmail = localStorage.getItem("post_email");
    const cardId = localStorage.getItem("card_id");
    const selectedGifyId = localStorage.getItem("post_gify_id");
    //mz-todo: Remove post_email and user.email === cachedEmail check after
    //         post info has been moved from localStorage to cURL.
    if (message && cachedEmail && user.email === cachedEmail) {
      localStorage.removeItem("post_message");
      localStorage.removeItem("post_theme_id");
      localStorage.removeItem("post_gify_id");
      localStorage.removeItem("post_email");
      localStorage.removeItem("card_id");
      //mz-todo: Make sure to write security rule in collection to prevent duplicate post entries.
      const post = new Post();
      post.ownerInfo = {
        ref: user.ref,
        name: user.name,
        location: user.location ? user.location.name : null,
        ownerId: user.email,
      };
      post.message = message;
      post.themeId = themeId;
      if (selectedGifyId) {
        post.gify = selectedGifyId;
      }
      post.createdAt = moment.utc().format();
      post.liked = [];
      this.props.cardActions.savePost(post, cardId, (err) => {
        if (err) {
          Logger.logError(
            PRIORITY_HIGH,
            "Error while creating post via post-auth flow. Check previous logs to find error log from store.",
            "MagicAuth:createUserPost:savePost",
            { fetchedUser: user.email, cardId: cardId, postObj: post }
          );
        } else {
          antdMessage.success("Post Successful!");
          if(user && user.hasDOB && !user.hasDOB()) {
            const { toggleBirthdayModal } = this.props.metadataActions;
            toggleBirthdayModal && toggleBirthdayModal(true);
          }
        }
        executionCallback && executionCallback(true);
      });
    } else {
      executionCallback && executionCallback(false);
    }
  }
  executePostAuthFlow(user, cUrlEmail) {
    const redirect_url = localStorage.getItem("post_redirect_url");
    if (user && user.email === cUrlEmail) {
      this.createUserPost(user, (executed) => {
        if (executed) {
          localStorage.removeItem("post_redirect_url");
        }
        this.redirectToURL(redirect_url);
      });
    } else {
      this.redirectToURL(redirect_url);
    }
  }
  redirectToURL(url) {
    if (url) {
      setTimeout(() => nav.goto(url));
      return;
    }
    setTimeout(() => nav.goto("/"));
  }
  renderCreatePasswordFlow() {
    return <CreateNewPassword user={this.state.userObject} />;
  }
  render() {
    if (this.state.showCreateNewPasswordFlow) {
      return this.renderCreatePasswordFlow();
    }
    return (
      <div>
        <Space className="loader" direction="vertical">
          <LoadingOutlined />
        </Space>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    cardActions: bindActionCreators(cardActions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(MagicAuth);
