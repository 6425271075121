import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/auth";
import "./styles/ZeroState.less";
import { Form, Input, Button, message } from "antd";
// import signin from "./img/artwork-signin.png";
// import logo from "./img/logo.svg";
import * as nav from "../routes/nav";
import * as errc from "../oc/error_codes";
import logo from "./img/logo.svg";
import { OC_PROD_TITLE } from "../shared/constants";

class CreateNewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      busy: false,
    };
    this.onDoneClick = this.onDoneClick.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }
  onPasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  saveProfile(user, password) {
    this.setState({ busy: true });
    this.props.actions.updateProfile(user, password || null, (err) => {
      this.setState({ busy: false });
      if (err) {
        if (err.code === errc.AUTH_REQUIRES_LOGIN) {
          message.error(
            "Authentication link expired. Updating password requires recent login."
          );
          return;
        }
        message.error("Failed to update password. Please try again.");
        return;
      }
      nav.goto(nav.URL_HOMEPAGE);
    });
  }
  onDoneClick() {
    const { user } = this.props;
    if (!user) {
      //mz-todo: add code analysis log.
      message.error("Invalid user");
      nav.goto(nav.URL_HOMEPAGE);
      return;
    }
    if (!this.state.password) {
      message.info("Please provide a new password");
      return;
    }
    this.saveProfile(user, this.state.password);
  }
  render() {
    const { user } = this.props;
    if (!user) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="ZeroState">
          <div className="title">
            <div className="brand-logo">
              <span className="product">
                <img src={logo} alt={OC_PROD_TITLE} />
              </span>
            </div>
            <h2>
              {user.hasPass ? "Set your new password" : "Set your password"}
            </h2>
            <p>{user.email}</p>
          </div>

          <Form name="basic">
            <Form.Item
              rules={[
                { required: true, message: "Please set your new password" },
              ]}
            >
              <h4>New Password</h4>
              <Input
                autoFocus
                type="password"
                onChange={this.onPasswordChange}
                placeholder="Password"
                prefix={<i className="fal fa-key"></i>}
              />
            </Form.Item>

            <Form.Item>
              <Button
                disabled={this.state.busy}
                loading={this.state.busy}
                type="primary"
                size="large"
                block
                htmlType="submit"
                onClick={this.onDoneClick}
              >
                Done
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return { auth };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewPassword);
