import { Input } from "antd";
import React from "react";
import { OC_CARD_SINGULAR } from "../shared/constants";

class EmailParser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      value: "",
      error: null,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur() {
    this.keyDownOrBlur();
  }

  handleKeyDown(evt) {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      this.keyDownOrBlur();
    }
  };

  keyDownOrBlur() {
    if (!this.state.value) {
      return;
    }
    var value = this.state.value.trim().toLowerCase();
    const newItems = [...this.state.items, value];

    if (value && this.isValid(value)) {
      this.setState({
        items: newItems,
        value: "",
      });
      this.props.saveEmail(newItems);
    }
  }

  handleChange(evt) {
    this.setState({
      value: evt.target.value,
      error: null,
    });
  };

  handleDelete(item) {
    const { deleteEmail } = this.props;
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
    deleteEmail && deleteEmail(item);
  };

  handlePaste(evt) {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    if (paste) {
      paste = paste.toLowerCase();
    }
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      var toBeAdded = emails.filter(
        (item, pos, self) => self.indexOf(item) === pos && !this.isInList(item) && !this.isRestricted(item)
      );
      const newItems = [...this.state.items, ...toBeAdded];
      this.setState({
        items: newItems,
      });
      this.props.saveEmail(newItems);
    }
  };

  isValid(email) {
    let error = null;
    const { domainName } = this.props;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (email.split("@").pop() !== domainName) {
      error = `${email} does not end with ${domainName ? domainName : "correct domain name."}`;
    }

    if (this.isRestricted(email)) {
      error = `You can’t invite this ${OC_CARD_SINGULAR}’s recipient, owner, or yourself.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isRestricted(email) {
    const { recipientEmail, ownerEmail, selfEmail } = this.props;
    if (email === recipientEmail || email === ownerEmail || email === selfEmail) {
      return true;
    }
    return false;
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  render() {
    const { domainName } = this.props;
    return (
      <>
        <Input
          autoFocus
          className={"input " + (this.state.error && " has-error")}
          value={this.state.value}
          placeholder="Type or paste email addresses and press Enter or Return"
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onPaste={this.handlePaste}
          onBlur={this.handleBlur}
        />
        <p className="subtext">
          Addresses must end with {domainName ? domainName : "correct domain name."}
        </p>
        <span className="invitee-list">
          {this.state.items.map((item) => (
            <div className="tag-item" key={item}>
              {item}
              <button
                type="button"
                className="button"
                onClick={() => this.handleDelete(item)}
              >
                &times;
              </button>
            </div>
          ))}
        </span>

        {this.state.error && <p className="error">{this.state.error}</p>}
      </>
    );
  }
}

export default EmailParser;
