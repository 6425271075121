import OC from "../oc";
import {
  getZoneNameLong,
  getZoneNameIANA,
  getTimezoneOffsetInSeconds,
  getSecondsTill,
} from "./timezones";
import Card from "../oc/models/card";
import moment from "moment";
import { categoryData, CAT_ANNIVERSARY } from "./constants";
import { message } from "antd";
import * as nav from "../routes/nav";
import DOB from "./dob";

const SCHEDULE_THRESHOLD_REMAINING_MINUTES = 30;
const SCHEDULE_THRESHOLD_HOURS_AHEAD = 288; // 12 days

// createAutoAnniversaryCard ... Creates anniversary card from anniversary reminder email link.
export async function createAutoAnniversaryCard(
  params,
  currentUser,
  company,
  saveCard,
  onFailure
) {
  const recipientSnapshot = await OC.getInstance()
    .getDocumentByPath(params.recipientPath)
    .get();
  if (!recipientSnapshot.exists) {
    onFailure && onFailure();
    return;
  }
  const recipientObj = recipientSnapshot.data();
  const verified = _verifyAutoAnniversaryCardRequest(
    params,
    currentUser,
    recipientObj,
    company
  );
  if (!verified) {
    onFailure && onFailure();
    return;
  }

  const dupCard = await _findDuplicate(recipientObj.email);
  if (dupCard) {
    setTimeout(() => {
      nav.goto(nav.urlCardPageWith(dupCard.id, company.domain));
    });
    return;
  }

  const anniversaryMoment = _getAnniversaryMoment(recipientObj);
  if (_anniversaryAlreadyPassed(anniversaryMoment)) {
    message.info("This event has already expired", 6);
    onFailure && onFailure();
    return;
  }

  var delivery = null;
  if (_anniversaryNotTooClose(anniversaryMoment)) {
    if (anniversaryMoment) {
      const dateStringISO8601 = anniversaryMoment.format();
      delivery = {
        ownerZoneName: getZoneNameLong(),
        ownerZoneNameIANA: getZoneNameIANA(),
        ownerOffset: getTimezoneOffsetInSeconds(),
        utc: dateStringISO8601,
        triggerAt: getSecondsTill(dateStringISO8601),
      };
    }
  }
  if (delivery === null) {
    message.info(
      "Scheduled delivery time should be at least 30 minutes from now. Please set scheduled delivery from Settings menu or publish, manually.",
      6
    );
  }

  const c = new Card();
  c.category = params.category;
  c.title = _getAnniversaryCardTitle(recipientObj);
  c.message = _getAnniversaryCardSubtitle(company.name);
  c.delivery = delivery;
  c.owner = currentUser.ref;
  c.recipientRef = recipientSnapshot.ref;
  c.recipient = recipientObj;
  c.postsDisabled = false;
  c.categoryData = null;
  c.createdAt = moment.utc().format();

  saveCard &&
    saveCard(c, (newCard) => {
      if (!newCard) {
        message.error(
          "Could not perform the requested action. Please try again."
        );
        onFailure && onFailure();
        return;
      }
      setTimeout(() => {
        nav.goto(nav.urlCardPageWith(newCard.id, company.domain));
      });
    });
}

function _anniversaryAlreadyPassed(anniversaryMoment) {
  try {
    const dtNow = moment();
    var duration = moment.duration(anniversaryMoment.diff(dtNow));
    var hours = duration.asHours();
    if (hours >= SCHEDULE_THRESHOLD_HOURS_AHEAD) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

function _anniversaryNotTooClose(dtAnniversary) {
  try {
    const dtNow = moment();
    if (dtAnniversary === null) {
      return false;
    }
    var duration = moment.duration(dtAnniversary.diff(dtNow));
    var minutes = duration.asMinutes();
    if (minutes >= SCHEDULE_THRESHOLD_REMAINING_MINUTES) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

function _getAnniversaryMoment(recipientObj) {
  try {
    const { anniversary } = recipientObj;
    if (
      !anniversary.day ||
      anniversary.month === null ||
      anniversary.month === undefined
    ) {
      return null;
    }
    const dtAnniversary = new DOB(anniversary.day, anniversary.month).toMoment();
    return dtAnniversary ? dtAnniversary.utc() : null;
  } catch {
    return null;
  }
}

async function _findDuplicate(recipientEmail) {
  const cards = await OC.getInstance().findCardFor(
    recipientEmail,
    CAT_ANNIVERSARY
  );
  if (cards && cards.length) {
    const dtLimit = moment().subtract(6, "months");
    for (let c of cards) {
      if (c.createdAt) {
        const dt = moment(c.createdAt);
        if (dt.isAfter(dtLimit)) {
          return c;
        }
      }
    }
  }
  return null;
}

function _verifyAutoAnniversaryCardRequest(params, owner, recipientObj, company) {
  const { anniversary } = recipientObj;
  if (
    !anniversary ||
    !anniversary.day ||
    anniversary.month === null ||
    anniversary.month === undefined ||
    !owner ||
    owner.email !== params.ownerId ||
    !company ||
    company.domain !== params.companyId ||
    params.category !== CAT_ANNIVERSARY
  ) {
    return false;
  }
  if (
    params.anniversaryDay !== anniversary.day ||
    params.anniversaryMonth !== anniversary.month
  ) {
    message.info("Recipient's anniversary does not match anymore", 6);
    return false;
  }
  return true;
}

function _getAnniversaryCardTitle(recipientData) {
  const category = CAT_ANNIVERSARY;
  const defaultTitle =
    (categoryData[category] && categoryData[category].defaultCardTitle) || "";
  if (!defaultTitle) {
    //mz-todo: This is probably not required since we are handling placeholder via css. Remove later
    // return CARD_TITLE_PLACEHOLDER;
    return "";
  }
  return defaultTitle.replace("%FIRST_NAME%", recipientData.name);
}
function _getAnniversaryCardSubtitle(companyName) {
  const category = CAT_ANNIVERSARY;
  const defaultCardSubtitle =
    (categoryData[category] && categoryData[category].defaultCardSubtitle) ||
    "";
  if (!companyName || !defaultCardSubtitle) {
    //mz-todo: This is probably not required since we are handling placeholder via css. Remove later
    // return CARD_MESSAGE_PLACEHOLDER;
    return "";
  }
  return defaultCardSubtitle.replace("%COMPANY_NAME%", companyName);
}
