import twemoji from "twemoji";

const IFRAME_INITIAL_HEIGHT = 32;

var TwemojiInput = function (obj, onTextChangedCallback, onInitialiazed) {
  var prefix = "_twemoji",
    onTextChanged = onTextChangedCallback,
    listernerFunc = null,
    saveAsImg = false,
    wrapper = document.createElement("div"),
    currentRowsCount = -1,
    customTextarea = document.createElement("iframe"); //mz-todo: Figure out why it is not null.

  var Editor = function () {
    var t = this;

    t.init();
  };

  Editor.prototype.addStyles = function () {
    var t = this;
    t.styles = document.createElement("style");
    t.styles.setAttribute("type", "text/css");
    t.styles.innerText = `
body {
  overflow: hidden;
  line-height: 18px; 
  text-align: center;
}

img.emoji{
  height: 22px;
    width: 22px;
    padding: 0 2px;
}

._twemojicustom_editor {

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin:0;
  cursor: text;
  /* padding: 60px 52px 0 52px; */
}

._twemojicustom_editor.empty:after {
  content: "Write Your Message Here";
  opacity: 0.5;
  width: 250px;
  position: absolute;
  top: 0;
  margin-left: -125px;
  left: 50%;  
}

.palette-0._twemojicustom_editor {
  color: #808080;
}

.palette-1._twemojicustom_editor {
  color: #23881a;
}

.palette-2._twemojicustom_editor {
  color: #007780;
}

.palette-3._twemojicustom_editor {
  color: #00529d;
}

.palette-4._twemojicustom_editor {
  color: #7e5aca;
}

.palette-5._twemojicustom_editor {
  color: #ab39a0;
}

.palette-6._twemojicustom_editor {
  color: #aa0f59;
}

.palette-7._twemojicustom_editor {
  color: #ad2a00;
}

.palette-8._twemojicustom_editor {
  color: #8c6e04;
}

.palette-9._twemojicustom_editor {
  color: #96683d;
}

.palette-10._twemojicustom_editor {
  color: #ed5050;
}

.palette-11._twemojicustom_editor {
  color: #9b5d13;
}

.palette-12._twemojicustom_editor {
  color: #991d24;
}

.palette-13._twemojicustom_editor {
  color: #176ead;
}

.palette-14._twemojicustom_editor {
  color: #165197;
}

.palette-15._twemojicustom_editor {
  color: #a3290f;
}

.palette-16._twemojicustom_editor {
  color: #9f5e23;
}

.palette-17._twemojicustom_editor {
  color: #db508a;
}

.palette-18._twemojicustom_editor {
  color: #e24e3a;
}

.palette-19._twemojicustom_editor {
  color: #854f1d;
}

.palette-20._twemojicustom_editor {
  color: #36aa9c;
}

.palette-21._twemojicustom_editor {
  color: #d74768;
}

.palette-22._twemojicustom_editor {
  color: #187fad;
}

.palette-23._twemojicustom_editor {
  color: #cb2355;
}

.palette-24._twemojicustom_editor {
  color: #31a73c;
}

.palette-25._twemojicustom_editor {
  color: #9052de;
}

.palette-26._twemojicustom_editor {
  color: #338dbf;
}

.palette-27._twemojicustom_editor {
  color: #8664ea;
}

.palette-28._twemojicustom_editor {
  color: #587fac;
}

.palette-29._twemojicustom_editor {
  color: #9c5e00;
}

.palette-30._twemojicustom_editor {
  color: #626262;
}

.palette-31._twemojicustom_editor {
  color: #0e875b;
}

.palette-32._twemojicustom_editor {
  color: #9c1b3a;
}

.palette-33._twemojicustom_editor {
  color: #3969b1;
}

.palette-34._twemojicustom_editor {
  color: #92750f;
}

.palette-35._twemojicustom_editor {
  color: #0c7a9d;
}

.palette-36._twemojicustom_editor {
  color: #8954cc;
}

.palette-37._twemojicustom_editor {
  color: #108d51;
}

.palette-38._twemojicustom_editor {
  color: #128b60;
}

.palette-39._twemojicustom_editor {
  color: #9a704b;
}

.palette-40._twemojicustom_editor {
  color: #a6703d;
}

.palette-41._twemojicustom_editor {
  color: #168999;
}

.palette-42._twemojicustom_editor {
  color: #4d8678;
}

.palette-43._twemojicustom_editor {
  color: #7748a7;
}

.palette-44._twemojicustom_editor {
  color: #cb4b4b;
}

.palette-45._twemojicustom_editor {
  color: #15895f;
}

.palette-46._twemojicustom_editor {
  color: #c63d77;
}

.palette-47._twemojicustom_editor {
  color: #a14b99;
}

.palette-48._twemojicustom_editor {
  color: #d26262;
}

.palette-49._twemojicustom_editor {
  color: #9c630e;
}

.palette-50._twemojicustom_editor {
  color: #a43c25;
}

.palette-51._twemojicustom_editor {
  color: #2ca7c1;
}

.palette-52._twemojicustom_editor {
  color: #219d9d;
}

.palette-53._twemojicustom_editor {
  color: #a9653f;
}

`;
    t.content.head.appendChild(t.styles);
  };

  Editor.prototype.focus = function () {
    var t = this;
    t.content.body.focus();
  };

  Editor.prototype.insertEmoji = function (char) {
    this.insertImage(twemoji.parse(char));
  };

  Editor.prototype.setMessage = function (text) {
    customTextarea.value(twemoji.parse(text));
  };

  Editor.prototype.insertImage = function (img) {
    var t = this;

    function insert() {
      var id = "rand" + (Math.random() + "").slice(2),
        sel;

      if (t.content.queryCommandSupported("InsertHTML")) {
        t.content.execCommand("insertHTML", false, img);
      } else if ((sel = t.content.selection) && sel.type !== "Control") {
        var range = sel.createRange();
        range.pasteHTML(img);
        range.collapse(false);
        range.select();
      }
      t.updateMirror();
      return t.content.getElementById(id);
    }
    t.focus();
    return insert();
  };

  Editor.prototype.value = function (value) {
    var t = this;

    if (value) {
      t.content.body.innerHTML = value;
      t.updateMirror();
      return value;
    } else if (t.content) {
      return t.content.body.innerHTML;
    }
  };

  Editor.prototype.updateMirror = function () {
    var t = this,
      value = t.value();

    const divs = t.frame.contentDocument.querySelectorAll("body div");
    if (divs && divs.length !== currentRowsCount) {
      currentRowsCount = divs.length;
      var height = IFRAME_INITIAL_HEIGHT;
      divs.forEach((element) => {
        if (element.querySelector && !element.querySelector("div")) {
          height += element.clientHeight;
        }
      });
      t.frame.setAttribute("height", height + "px");
    }

    value = saveAsImg
      ? value
      : value.replace(/<img.*?alt=".*?/g, "").replace(/".?src=.*?>/g, "");
    t.mirror.value = value;
  };

  Editor.prototype.listenChanges = function () {
    var t = this;
    if (listernerFunc === null) {
      listernerFunc = t.editorEventListener.bind(t);
    }
    ["mouseup", "touchend", "keyup", "keydown", "blur"].map(function (event) {
      t.content.body.addEventListener(event, listernerFunc);
      return null;
    });
  };

  Editor.prototype.removeListeners = function () {
    var t = this;
    ["mouseup", "touchend", "keyup", "keydown", "blur"].map(function (event) {
      t.content.body.removeEventListener(event, listernerFunc);
      return null;
    });
    listernerFunc = null;
  };

  Editor.prototype.editorEventListener = function (e) {
    if (e.type === "keydown") {
      this.isEmpty() && this.setEmpty(false);
      return;
    }
    this.updateMirror();
    if (onTextChanged) {
      onTextChanged(this.mirror.value);
    }
  };

  Editor.prototype.init = function () {
    var t = this;

    t.frame = document.createElement("iframe");
    t.frame.className = prefix + "_textarea";
    t.frame.setAttribute("id", "twemoji-textarea");
    t.frame.setAttribute("tabindex", "-1");
    t.frame.setAttribute("width", "100%");
    t.frame.setAttribute("height", IFRAME_INITIAL_HEIGHT + "px");
    t.frame.setAttribute("style", "transition: height 0.1s ease");
    t.mirror = obj;

    t.frame.onload = function () {
      t.content = t.frame.contentDocument || t.frame.document;
      t.content.body.setAttribute("contenteditable", "true");
      t.content.body.className = prefix + "custom_editor";
      t.addStyles();
      t.listenChanges();
      setTimeout(() => {
        onInitialiazed && onInitialiazed();
      });
    };
  };

  Editor.prototype.isEmpty = function () {
    var t = this;
    return t.content.body.className.indexOf("empty") > -1;
  };

  Editor.prototype.setEmpty = function (state) {
    var t = this;
    const baseClasses = t.content.body.className
      .replace(" empty", "")
      .replace("empty", "");
    state
      ? (t.content.body.className = baseClasses + " empty")
      : (t.content.body.className = baseClasses);
  };

  Editor.prototype.updatePaletteClass = function (paletteClass) {
    var t = this;
    const hasEmpty = t.content.body.className.indexOf("empty") > -1;
    t.content.body.className = prefix + "custom_editor " + paletteClass;
    if (hasEmpty) {
      t.content.body.className += " empty";
    }
  };

  function wrap() {
    wrapper.className = prefix + "_wrap";
    customTextarea = new Editor();
    obj.parentNode.insertBefore(wrapper, obj);
    wrapper.appendChild(customTextarea.frame);
  }

  function init() {
    wrap();
    customTextarea.value(twemoji.parse(obj.value));
  }

  init();

  return {
    Editor: customTextarea,
  };
};

export default function InitializeTwemojiInput(
  textAreaSelector,
  onTextChangedCallback,
  onInitialiazed
) {
  return new TwemojiInput(
    document.querySelector(textAreaSelector),
    onTextChangedCallback,
    onInitialiazed
  );
}
