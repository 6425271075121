import firebase from "firebase/app";
// import 'firebase/analytics'; // Removing firebase analytics
import Auth from "./auth";
import Store from "./store";
import Storage from "./storage";
import * as errHandler from "./error_handler";
import { v4 as uuidv4 } from "uuid";
import 'firebase/functions';
import { StripeManager } from "./stripe_manager";

export default class OC {
  /**
   * @type {OC}
   */
  static instance = null;

  /**
   * @type {Store}
   */
  storeAPI = null;

  /**
   * @type {Auth}
   */  
  authAPI = null;

  /**
   * @type {Storage}
   */
  storageAPI = null;
  
  // analytics = null; // Removing firebase analytics
  activePlan = null;

  async deleteImage(path) {
    return await this.storageAPI.deleteImage(path);
  }

  async deleteWithFilename(filename) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    const path = this.storageAPI.getPath(companyId, filename);
    return await this.storageAPI.deleteImage(path);
  }

  async getImageURLForFile(filename) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    const path = this.storageAPI.getPath(companyId, filename);
    return await this.storageAPI.getDownloadURL(path);
  }

  async getImageURLForFileAndCompany(filename, companyId) {
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    const path = this.storageAPI.getPath(companyId, filename);
    return await this.storageAPI.getDownloadURL(path);
  }

  async getImageURL(path) {
    return await this.storageAPI.getDownloadURL(path);
  }

  async updateImageFile(byteArray, filename) {
    //mz-i: adm_01
    this.restrictShadow();
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    return await this.storageAPI.upload(byteArray, companyId, filename);
  }

  async uploadImage(byteArray, existingPath) {
    //mz-i: adm_01
    this.restrictShadow();
    if (existingPath) {
      return await this.storageAPI.update(byteArray, existingPath);
    } else {
      const companyId = this.getCurrentUserCompanyId();
      if (!companyId) {
        return errHandler.ERR_INVALID_COMPANY_ID;
      }
      const randomName = this.__createRandomFilename();
      if (!randomName) {
        return null;
      }
      return await this.storageAPI.upload(byteArray, companyId, randomName);
    }
  }

  // findUserBy ... Returns User object or null. No auth required.
  async findUserBy(email) {
    //mz-i: adm_01
    this.restrictShadow();
    if (!email || email.indexOf("@") === -1) {
      return null;
    }
    const companyId = email.split("@").pop();
    return await this.storeAPI.getUserBy(email, companyId.toLowerCase());
  }

  getCurrentAuthUser() {
    //mz-i: adm_01
    const shadow = this.getShadow();
    if (shadow) {
      return { email: shadow };
    }
    return this.authAPI.auth.currentUser;
  }

  // getCurrentAuthUserEmail ... Returns email or null.
  getCurrentAuthUserEmail() {
    //mz-i: adm_01
    if (this.getShadow()) {
      return this.getShadow();
    }
    return this.authAPI.auth.currentUser
      ? this.authAPI.auth.currentUser.email
      : null;
  }

  getCurrentUserCompanyId() {
    const email = this.getCurrentAuthUserEmail();
    if (!email || email.indexOf("@") === -1) {
      return null;
    }
    const companyId = email.split("@").pop();
    return companyId.toLowerCase();
  }

  // logout ... Returns err or null.
  async logout() {
    return await this.authAPI.signout();
  }

  // login ... Returns err or null.
  async login(username, password) {
    await this.authAPI.signout();
    return await this.authAPI.loginWith(username, password);
  }

  // relogin ... Returns err or null.
  async relogin(username, password) {
    //mz-i: adm_01
    this.restrictShadow();
    //mz-todo: Implement Firebase's user.reauthenticateWithCredential functionality.
    return await this.authAPI.loginWith(username, password);
  }

  // loginRegisterWithMagicLink ... Returns err or null.
  async loginRegisterWithMagicLink(email, name, link, skipSignout) {
    //mz-i: adm_01
    this.restrictShadow();
    if (skipSignout !== true) {
      await this.authAPI.signout();
    }
    const err = await this.authAPI.loginWithMagicLink(email, link);
    if (err) {
      return err;
    }
    await this.__delay(2000);
    var user = null;
    for (var i = 0; i < 10; i++) {
      user = await this.findCompanyUser(email);
      if (user) {
        break;
      }
      await this.__delay(2000);
    }
    if (!user) {
      await errHandler.logError(
        errHandler.ERR_AUTH_WITHOUT_USER,
        "loginRegisterWithMagicLink:index",
        errHandler.PRIORITY_CRITICAL,
        { email: email, link: link }
      );
      await this.logout();
      return errHandler.ERR_AUTH_WITHOUT_USER;
    }
    if (name && !user.name) {
      const companyId = this.getCurrentUserCompanyId();
      if (!companyId) {
        errHandler.logError(
          errHandler.ERR_INVALID_COMPANY_ID,
          "loginRegisterWithMagicLink:index",
          errHandler.PRIORITY_CRITICAL,
          { email: email, name: name, link: link }
        );
        return null;
      }
      user.name = name;
      const errUpdateUser = await this.storeAPI.updateUser(user, companyId);
      if (errUpdateUser) {
        errHandler.logError(
          errUpdateUser,
          "loginRegisterWithMagicLink:index - setting user's name after sign-up, failed.",
          errHandler.PRIORITY_CRITICAL,
          { email: email, name: name, link: link }
        );
        return null;
      }
    }
    return null;
  }

  // sendMagicLink ... Returns err or null.
  async sendMagicLink(email, ocType) {
    //mz-i: adm_01
    this.restrictShadow();
    return await this.authAPI.sendMagicLink(email, ocType);
  }

  // sendPostAuthLink ... Returns err or null.
  async sendPostAuthLink(email, ocType, urlFieldMap) {
    //mz-i: adm_01
    this.restrictShadow();
    return await this.authAPI.sendPostAuthLink(email, ocType, urlFieldMap);
  }

  // findCompanyUser ... Returns User object or null
  async findCompanyUser(email) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.getUserBy(email, companyId);
  }

  async toggleAdmin(email, value) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    if (this.getCurrentAuthUserEmail() === email) {
      return null;
    }
    return await this.storeAPI.toggleAdmin(email, companyId, value);
  }

  async toggleReminders(email, value) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    return await this.storeAPI.toggleReminders(email, companyId, value);
  }

  async deleteUser(email) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    await this.storeAPI.deleteUser(email, companyId);
    return null;
  }

  // updateProfile ... Returns err or null.
  async updateProfile(user, password) {
    //mz-i: adm_01
    this.restrictShadow();
    if (password) {
      const err = await this.authAPI.updatePassword(password);
      if (err) {
        return err;
      }
      user.hasPass = true;
    }
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    const err = await this.storeAPI.updateUser(user, companyId);
    if (!err) {
      if (user.refreshRefs) {
        await user.refreshRefs();
      }
      if (user.avatar) {
        const url = await this.getImageURL(user.avatar);
        user.avatarDownloadURL = url;
      }
    }
    return err;
  }

  // addUser ... Returns err or null.
  async addUser(user, isExistingUser) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    if (isExistingUser) {
      return await this.storeAPI.updateUser(user, companyId);
    }
    return await this.storeAPI.addUser(user, companyId);
  }

  // mz-todo: Remove after new Signup flow is complete.
  // signupCompletion ... Returns err or null.
  async signupCompletion(
    locationID,
    departmentID,
    birthDate,
    birthMonth,
    password
  ) {
    // var err = await this.authAPI.updatePassword(password);
    // if (err) {
    //   return err;
    // }
    // const companyId = this.getCurrentUserCompanyId();
    // if(!companyId) {
    //   return ERR_INVALID_COMPANY_ID;
    // }
    // err = await this.storeAPI.updateUserInfoAndHassPass(
    //   this.getCurrentAuthUserEmail(), locationID, departmentID, birthDate, birthMonth, companyId);
    // if(err) {
    //   logError(err, "signupCompletion:index", PRIORITY_CRITICAL, {
    //     user: this.getCurrentAuthUserEmail(),
    //     locationID: locationID,
    //     departmentID: departmentID,
    //     birthDate: birthDate,
    //     birthMonth: birthMonth,
    //     msg: "password updated but other info failed.",
    //   });
    //   return err;
    // }
    // return null;
  }

  // saveLocation ... Returns err or null.
  async saveLocation(location) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    return await this.storeAPI.saveLocation(location, companyId);
  }

  // saveCard ... Returns err or null.
  async saveCard(card) {
    //mz-i: adm_01
    this.restrictShadow();
    var activePlan = this.activePlan;
    if (!activePlan) {
      activePlan = await this.getActivePlan();
    }
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    if (card.postsDisabled === true) {
      return await this.storeAPI.publishCard(card, companyId);
    }
    return await this.storeAPI.saveCard(card, activePlan, companyId);
  }

  // findCard ... Returns card object or null.
  async findCard(id) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.findCard(id, companyId);
  }

  // findExternalCardFor ... Returns card array or null.
  async findExternalCardFor(name, category) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId || !category) {
      return null;
    }
    return await this.storeAPI.findExternalCardFor(name, category, companyId);
  }

  async findCardFor(recipientEmail, category) { 
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId || !recipientEmail || !category) {
      return null;
    }
    return await this.storeAPI.findCardFor(recipientEmail, category, companyId);
  }

  // findCompanyCard ... Returns card object or null.
  async findCompanyCard(id, companyId) {
    if (!id || !companyId) {
      return null;
    }
    const authDomain = this.getCurrentUserCompanyId();
    if (authDomain && authDomain !== companyId.toLowerCase()) {
      return null;
    }
    return await this.storeAPI.findCard(id, companyId.toLowerCase());
  }

  // fetchPosts ... Returns post array or null.
  async fetchPosts(cardId) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.fetchPosts(cardId, companyId);
  }

  // fetchCompanyPosts ... Returns post array or null.
  async fetchCompanyPosts(cardId, companyId) {
    if (!cardId || !companyId) {
      return null;
    }
    return await this.storeAPI.fetchPosts(cardId, companyId.toLowerCase());
  }

  // savePost ... Returns err or null.
  async savePost(post, cardId) {
    //mz-i: adm_01
    this.restrictShadow();
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    const userEmail = this.getCurrentAuthUserEmail();
    if (!userEmail) {
      return errHandler.ERR_INVALID_USER_EMAIL;
    }
    return await this.storeAPI.savePost(post, cardId, companyId, userEmail);
  }

  // likePost ... Returns err or null.
  async likePost(post, cardId) {
    //mz-i: adm_01
    this.restrictShadow();
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return errHandler.ERR_INVALID_COMPANY_ID;
    }
    const userEmail = this.getCurrentAuthUserEmail();
    if (!userEmail) {
      return errHandler.ERR_INVALID_USER_EMAIL;
    }
    return await this.storeAPI.likePost(post, cardId, userEmail, companyId);
  }

  //fetchCategories ... Returns categories array or null.
  async fetchCategories() {
    return await this.storeAPI.fetchCategories();
  }

  //fetchLocations ... Returns categories array or null.
  async fetchLocations() {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.fetchLocations(companyId);
  }

  //fetchUsers ... Returns Users array or null.
  async fetchUsers() {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.fetchUsers(companyId);
  }

  //fetchUsersByDomain ... Returns users array by companyId or null.
  async fetchUsersByDomain(companyId) {
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.fetchUsers(companyId.toLowerCase());
  }

  //fetchCards ... Returns Cards array or null.
  async fetchCards(cacheHook) {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.fetchCards(
      companyId,
      this.getCurrentAuthUserEmail(),
      cacheHook
    );
  }

  async deleteCardAndPosts(cardId) {
    //mz-i: adm_01
    this.restrictShadow();
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.deleteCardAndPosts(cardId, companyId);
  }

  //updateCompany ... Returns error or null.
  async updateCompany(company) {
    //mz-i: adm_01
    this.restrictShadow();
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    const { logo } = company;
    if (logo) {
      const permanentUrl = await this.getImageURL(logo);
      company.permanentUrl = permanentUrl || null;
    } else {
      company.permanentUrl = null;
    }
    return await this.storeAPI.updateCompany(company, companyId);
  }

  //fetchCompany ... Returns Company object or null.
  async fetchCompany() {
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.fetchCompany(companyId);
  }

  //fetchCompanyById ... Returns Company object or null.
  async fetchCompanyById(companyId) {
    if (!companyId) {
      return null;
    }
    return await this.storeAPI.fetchCompany(companyId.toLowerCase());
  }


  // fetchAuthStripeCustomer ... Returns logged in user's Stripe Customer doc, or null 
  async fetchAuthStripeCustomer() {
    const uid = this.authAPI?.auth?.currentUser?.uid;
    if (!uid) {
      return null;
    }
    return await this.storeAPI.fetchAuthStripeCustomer(uid);
  }

  //fetchProducts ... Returns array of object or null.
  async fetchProducts() {
    return await this.storeAPI.fetchProducts();
  }

  async startSubscription(priceId, isRecurring, successUrl, callback) {
    const email = this.getCurrentAuthUserEmail();
    const uid = this.authAPI?.auth?.currentUser?.uid;
    if (!uid || !email) {
      return null;
    }
    return await this.storeAPI.startSubscription(priceId, isRecurring, successUrl, uid, email, callback);
  }

  async fetchActiveSubscriptions() {
    const uid = this.authAPI?.auth?.currentUser?.uid;
    if (!uid) {
      return null;
    }
    return await this.storeAPI.fetchActiveSubscriptions(uid);
  }

  // fetchCardsUsage ... Returns CardUsage array or null.
  async fetchCardsUsage() {
    const email = this.getCurrentAuthUserEmail();
    const companyId = this.getCurrentUserCompanyId();
    if (!companyId || !email) {
      return null;
    }
    return await this.storeAPI.fetchCardsUsage(email, companyId);
  }  

  async getActivePlan(activeSubs) {
    const uid = this.authAPI?.auth?.currentUser?.uid;
    const email = this.getCurrentAuthUserEmail();
    const company = await this.fetchCompany();
    if (!uid || !email || !company) {
      return null;
    }
    const activeSubscriptions =
      activeSubs && activeSubs.length
        ? activeSubs
        : await this.storeAPI.fetchActiveSubscriptions(uid);
    const succeededPayments = await this.storeAPI.fetchSucceededPayments(uid);
    const user = await this.findCompanyUser(email);
    const stripeMan = new StripeManager(user, company, activeSubscriptions, succeededPayments);
    if (stripeMan.hasMultipleRenewalDanger()) {
      await errHandler.logError(
        errHandler.ERR_MULTI_RECURRING_SUB,
        "stripe_multi_recurring_sub",
        errHandler.PRIORITY_CRITICAL,
        { uid: uid, email: email, company: company ? company.domain : "" }
      );
    }
    this.activePlan = await stripeMan.getActivePlan();
    return this.activePlan;
  }

  attachStripeEventListener(callback) {
    const uid = this.authAPI?.auth?.currentUser?.uid;
    const email = this.getCurrentAuthUserEmail();
    const company = this.getCurrentUserCompanyId();
    if (!uid || !email || !company) {
      return null;
    }
    return this.storeAPI.attachStripeEventListener(uid, email, company, callback);
  }

  async createStripePortalLink() {
    try {
      const functionRef = firebase
      .app()
      .functions("us-west3")
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
      const { data } = await functionRef({ returnUrl: window.location.origin });
      return data;
    } catch (err) {
      return { err: err };
    }
  }
  
  // cancelSubscription ... Returns err or null.
  async cancelSubscription(subscriptionId) {
    try {
      const functionRef = firebase
        .app()
        .functions("us-central1")
        .httpsCallable("cancelSubscriptionHttpOnCall");
      const result = await functionRef({ subId: subscriptionId });
      const { data } = result;
      const { status, err } = data;
      if (err === null && status === "ok") {
        return null;
      } else {
        return err ? err : errHandler.ERR_SUBSCRIPTION_CANCEL_FAILED;
      };
    } catch(err) {
      return err;
    }
  }

  // getGCPAuthURL ...
  async getGCPAuthURL() {
    const data = await this._executeServerSide({ action: "get-gcp-auth-url"})
    return data?.url ? data.url : null;
  }

  // syncGWUsers ...
  async syncGWUsers(code) {
    return await this._executeServerSide({ action: "sync-gw-users", code: code});
  }

  // disableGWIntegration ...
  async disableGWIntegration() {
    return await this._executeServerSide({ action: "disable-gw-integration"});
  }

  // _executeServerSide ...
  async _executeServerSide(param) {
    try {
      const functionRef = firebase
        .app()
        .functions("us-central1")
        .httpsCallable("executeServerSideHttpOnCall");
      const result = await functionRef(param);
      const { data } = result;
      const { err } = data;
      if (err !== null) {
        //todo: error log err ? err : errHandler.ERR_EXECUTE_SERVER_SIDE_FUNC
      }
      return data;
    } catch(err) {
      //todo: error log err ? err : errHandler.ERR_EXECUTE_SERVER_SIDE_FUNC
      return null;
    }
  }

  getDocumentByPath(path) {
    return firebase.firestore().doc(path);
  }
  static getInstance() {
    if (OC.instance == null) {
      var firebaseConfig = {
        apiKey: "AIzaSyDrQjBJGRePG7Y_oriewxjjfZ7mk76-HME",
        authDomain: "jubili.firebaseapp.com",
        databaseURL: "https://jubili.firebaseio.com",
        projectId: "jubili",
        storageBucket: "jubili.appspot.com",
        messagingSenderId: "830874660138",
        appId: "1:830874660138:web:a4a60b7fbc552e59658253",
        // measurementId: "G-77W37TMVJQ" // Removing firebase analytics
      };
      firebase.initializeApp(firebaseConfig);
      const _this = new OC();
      // _this.analytics = firebase.analytics(); // Removing firebase analytics
      _this.storeAPI = new Store(firebase);
      _this.authAPI = new Auth(firebase);
      _this.storageAPI = new Storage(firebase);
      OC.instance = _this;
      window.jubiliApp = _this; //warn
      window.shadow = _this.__enableShadowUser.bind(_this); //mz-i: adm_01
      // _this.storeAPI.db.useEmulator("localhost", 8080); //warn
      // _this.authAPI.auth.useEmulator("http://localhost:9099"); //warn
      // firebase.functions().useEmulator("localhost", 5001); //warn
    }
    return OC.instance;
  }

  //mz-i: adm_01
  getShadow() {
    try {
      const shadow = localStorage.getItem("shadow_user");
      if (shadow && this.authAPI.auth.currentUser) {
        return shadow;
      }
    } catch {}
    return null;
  }

  //mz-i: adm_01
  restrictShadow() {
    if (this.getShadow()) {
      alert("Warning: Operation not allowed in shadow mode.");
      throw new Error("operation not allowed in shadow mode");
    }
  }

  async __delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  __createRandomFilename() {
    const uuid = uuidv4();
    const userId =
      this.authAPI.auth.currentUser && this.authAPI.auth.currentUser.uid;
    if (!uuid || !userId) {
      return "";
    }
    return userId + "-" + uuid.replace(/-/g, "");
  }

  //mz-i: adm_01
  async __enableShadowUser(email) {
    if (!email) {
      window.localStorage.removeItem("shadow_user");
      console.log("Terminating shadow session");
      window.location.reload();
    }
    window.localStorage.removeItem("shadow_user");
    const u = await this.findUserBy(email);
    if (u) {
      const cmp = this.getCurrentUserCompanyId();
      cmp && localStorage.removeItem(`cache_${cmp}_cards`);
      window.localStorage.setItem("shadow_user", email);
      console.log("Done. Reloading...");
      window.location.reload();
    } else {
      console.log("User not found");
    }
  }
}
