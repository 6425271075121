import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/card";
import * as nav from "../routes/nav";
import { LoadingOutlined } from "@ant-design/icons";
import { createAutoBirthdayCard } from "../shared/card_utils";
import { createAutoAnniversaryCard } from "../shared/auto_anniversary";
import { CAT_BIRTHDAY, CAT_ANNIVERSARY } from "../shared/constants";

class RedirectHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };
  }
  redirectHome() {
    setTimeout(() => {
      nav.goto(nav.URL_HOMEPAGE);
    });
  }
  componentDidMount() {
    if (this.checkForCreateCardUrl()) {
      return;
    }
    this.checkForLegacyCardUrl();
  }
  checkForCreateCardUrl() {
    try {
      const { location } = this.props;
      if (
        location &&
        location.pathname &&
        location.pathname === nav.URL_CREATE
      ) {
        // mz-info: Temporary fix. Disable this condition and check "Create Card" link in the email.
        //          while the user is logged out.
        const { user } = this.props.metadata;
        if (!user) {
          nav.goto("/");
          return true;
        }
        ////////////
        const { param } = location.query;
        if (param) {
          const createInfo = JSON.parse(atob(param));
          setTimeout(() => this.setState({ showLoader: true }));
          if (createInfo.category === CAT_BIRTHDAY) {
            this.createBirthdayCard(createInfo);
          }
          if (createInfo.category === CAT_ANNIVERSARY) {
            this.createAnniversaryCard(createInfo);
          }
        }
        return true;
      }
    } catch {}
    return false;
  }
  checkForLegacyCardUrl() {
    const cardID = this.props.routeParams ? this.props.routeParams.id : null;
    const domain = this.props.routeParams
      ? this.props.routeParams.domain
      : null;
    if (!cardID) {
      this.redirectHome();
      return;
    }
    const { user, company } = this.props.metadata;
    if (user) {
      if (company && company.domain) {
        nav.goto(nav.urlCardPageWith(cardID, company.domain));
        return;
      } else if (domain) {
        nav.goto(nav.urlCardPageWith(cardID, domain));
        return;
      } else {
        this.redirectHome();
        return;
      }
    } else {
      if (nav.isPostingURL() && domain) {
        nav.goto(nav.urlCardPageWith(cardID, domain));
        return;
      } else {
        this.redirectHome();
        return;
      }
    }
  }

  async createBirthdayCard(data) {
    const { user, company } = this.props.metadata;
    await createAutoBirthdayCard(
      data,
      user,
      company,
      this.props.actions.saveCard,
      this.redirectHome
    );
  }
  async createAnniversaryCard(data) {
    const { user, company } = this.props.metadata;
    await createAutoAnniversaryCard(
      data,
      user,
      company,
      this.props.actions.saveCard,
      this.redirectHome
    );
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  render() {
    if (this.state.showLoader) {
      return this.renderLoader();
    }
    return <div />;
  }
}
function mapStateToProps(state) {
  const { metadata } = state;
  return { metadata };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RedirectHandler);
