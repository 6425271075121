import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/card";
import * as metadataActions from "../actions/metadata";
import * as paymentActions from "../actions/payment";
import { Modal, Button, message } from "antd";
import ModalCategory from "../components/ModalCategory";
import ModalCardSettings from "../components/ModalCardSettings";
import ModalRouteCard from "../components/ModalRouteCard";
import ModalCompanySettings from "../components/ModalCompanySettings";
import WooCandidateSettings from "../components/WooCandidateSettings";
import {
  categoryData,
  MODAL_CATEGORY_COMPONENT,
  MODAL_CARD_SETTINGS_COMPONENT,
  MODAL_COMPANY_SETTINGS_COMPONENT,
  MODAL_ROUTE_CARD,
  categories,
  CAT_WOO_CANDIDATE,
  isGroupedCategory,
  OC_CARD_SINGULAR,
  CAT_OTHER,  
} from "../shared/constants";
import * as nav from "../routes/nav";
import Card from "../oc/models/card";
import moment from "moment-timezone";
import Company from "../oc/models/company";
import OC from "../oc";
import {
  findDuplicateOpenCard,
  findDuplicateExternalCard,
} from "../shared/card_utils";
import {
  getZoneNameLong,
  getZoneNameIANA,
  getTimezoneOffsetInSeconds,
  getSecondsTill,
} from "../shared/timezones";
import User from "../oc/models/user";
import Logger, { PRIORITY_HIGH } from "../shared/logger";

class CardInitButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initializingCard: false,
      busyUploadingLogo: false,
      visible: false,
      screen: MODAL_CATEGORY_COMPONENT,
      selectedCategory: "",
      cardSettingsData: {},
      companySettingsData: {},
      wooUsername: "",
      mountModal: false,
      duplicateCardId: null,
      isWithCompanyName: true,
    };
    this.saveCategoryData = this.saveCategoryData.bind(this);
    this.saveCardData = this.saveCardData.bind(this);
    this.saveCompanyData = this.saveCompanyData.bind(this);
    this.onCardInitClicked = this.onCardInitClicked.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.initializeCard = this.initializeCard.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.afterModalClosed = this.afterModalClosed.bind(this);
    this.removeCompanyLogo = this.removeCompanyLogo.bind(this);
    this.editCategoryReroute = this.editCategoryReroute.bind(this);
    this.onGroupCardSelected = this.onGroupCardSelected.bind(this);
    this.routeToDuplicateCard = this.routeToDuplicateCard.bind(this);
    this.onWithCompanyNameToggle = this.onWithCompanyNameToggle.bind(this);
    this.modalCardSettingsRef = React.createRef();
  }
  cardFieldsDirty() {
    if (!this.props.editMode) {
      return true;
    }
    const { cardSettingsData, selectedCategory, wooUsername } = this.state;
    const { deliveryUTC } = cardSettingsData;
    const { recipient, delivery, category, categoryData } = this.props.card.draftCard;
    //mz-todo: Replce the the condition before "?" with draftCard.isEmailLessCard() to cover all bases in future.
    const oldRecipient = (category === CAT_WOO_CANDIDATE || 
      (category === CAT_OTHER && (recipient === null || recipient === undefined))) ?
      categoryData?.name : recipient?.email; 
    const newRecipient = cardSettingsData?.recipient?.email || wooUsername;
    const oldDelivery = delivery?.utc || null;
    const newDelivery = deliveryUTC?.format() || null;
    if (oldRecipient !== newRecipient
      || category !== selectedCategory
      || oldDelivery !== newDelivery) {
      return true;
    }
    return false;
  }
  decodeAndApplyCardSettings(encodedData) {
    const decodedData = Buffer.from(encodedData, "base64").toString("ascii");
    const data = JSON.parse(decodedData);
    const { usersList, user } = this.props.metadata;
    var recipient = null;
    if (data.draftUsername && data.recipientEmail) {
      recipient = new User();
      recipient.name = data.draftUsername;
      recipient.email = data.recipientEmail;
      recipient.isDraft = true;
    } else {
      recipient = usersList.find(
        (element) =>
          element.email.toLowerCase() === data.recipientEmail?.toLowerCase() &&
          element.email.toLowerCase() !== user.email?.toLowerCase()
      );
    }
    this.reloadCardSettings(
      data.selectedCategory,
      recipient || null,
      data.deliveryUTC || null,
      data.wooUsername || null
    );
    if (recipient?.isDraft) {
      setTimeout(() => {
        if (this.modalCardSettingsRef.current) {
          this.modalCardSettingsRef.current.refillDraftUserOnPaymentCallback(
            recipient
          );
        }
      });
    }
    if (this.props.auth && this.props.auth.currentUser) {
      this.showModal();
      setTimeout(() => nav.gotoWithReplace(nav.URL_HOMEPAGE));
    } else {
      nav.gotoLandingPageWithRefresh();
    }
  }
  editCategoryReroute() {
    this.setState({ screen: MODAL_CATEGORY_COMPONENT });
  }
  routeToDuplicateCard() {
    const { duplicateCardId } = this.state;
    const { company } = this.props.metadata;
    nav.goto(nav.urlCardPageWith(duplicateCardId, company.domain));
    this.handleCancel();
  }
  removeCompanyLogo() {
    const { company } = this.props.metadata;
    if (!company) {
      return;
    }
    this.updateCompanyItem(company.name, company.brandColor, null);
  }
  paymentIntentCallbackExecuted() {
    var logPi = null;
    try {
      const pi = this.props.urlLocation?.query?.pi;
      logPi = pi;
      if (pi) {
        this.decodeAndApplyCardSettings(pi);
        return true;
      }
      return false;
    } catch (err) {
      Logger.logAnalysis(
        PRIORITY_HIGH,
        "exception occurred",
        "CardInitButton:paymentIntentCallbackExecuted",
        {
          pi: logPi ? logPi : null,
          err: err & err.message ? err.message : "n/a",
        }
      );
    }
  }
  componentDidMount() {
    if (this.paymentIntentCallbackExecuted()) {
      return;
    }
    const { user } = this.props.metadata;
    if (user && this.props.autoTrigger) {
      this.showModal();
      if (this.props.autoTriggerRedirectPath) {
        setTimeout(() => {
          nav.goto(this.props.autoTriggerRedirectPath);
        });
      }
    }
  }
  afterModalClosed() {
    this.setState({
      screen: MODAL_CATEGORY_COMPONENT,
      selectedCategory: "",
      cardSettingsData: {},
      companySettingsData: {},
      mountModal: false,
      duplicateCardId: null,
    });
  }
  getUserByEmail(email) {
    const { usersList } = this.props.metadata;
    var selectedUser = usersList.find(
      (element) => element.email && element.email.toLowerCase() === email
    );
    return selectedUser || null;
  }
  showExternalRecipientModal() {
    const { selectedCategory, isWithCompanyName } = this.state;
    if (selectedCategory === CAT_WOO_CANDIDATE) {
      return true;
    } else if (selectedCategory === CAT_OTHER && isWithCompanyName === false) {
      return true;
    } else {
      return false;
    }
  }
  enableEditMode() {
    const { recipient, delivery, category } = this.props.card.draftCard;
    this.reloadCardSettings(
      category,
      recipient,
      delivery && delivery.utc ? delivery.utc : null,
      this.props.card.draftCard.getRecipientName()
    );
  }
  reloadCardSettings(category, recipient, utcString, wooUsername) {
    switch (category) {
      case CAT_WOO_CANDIDATE:
        this.setState({
          screen: MODAL_CARD_SETTINGS_COMPONENT,
          selectedCategory: category,
          wooUsername: wooUsername,
        });
        break;

      default:
        if (category === CAT_OTHER && wooUsername) {
          this.setState({
            screen: MODAL_CARD_SETTINGS_COMPONENT,
            selectedCategory: category,
            wooUsername: wooUsername,
            isWithCompanyName: false,
          });
        } else if (recipient && recipient.email) {
          this.setState({
            screen: MODAL_CARD_SETTINGS_COMPONENT,
            selectedCategory: category,
            cardSettingsData: {
              recipient: this.getUserByEmail(recipient.email),
              utc: utcString || null,
            },
          });
        } else {
          this.setState({ visible: false, mountModal: false });
          message.info(
            `The recipient for this ${OC_CARD_SINGULAR} no longer exists.`
          );
        }
        break;
    }
  }
  onWithCompanyNameToggle(value) {
    this.setState({ 
      isWithCompanyName: value,
      wooUsername: value ? "" : this.state.wooUsername
    });
  }
  onCardInitClicked() {
    if (this.props.auth && this.props.auth.currentUser) {
      this.showModal();
    } else {
      nav.gotoLandingPageWithRefresh();
    }
    this.props.editMode && this.enableEditMode();
  }
  getDefaultTitle() {
    const { recipient } = this.state.cardSettingsData;
    const defaultTitle =
      (categoryData[this.state.selectedCategory] &&
        categoryData[this.state.selectedCategory].defaultCardTitle) ||
      "";
    if (this.state.selectedCategory !== CAT_WOO_CANDIDATE && !recipient) {
      return "";
      //mz-todo: This is probably not required since we are handling placeholder via css. Remove later
      //return CARD_TITLE_PLACEHOLDER;
    }
    if (!defaultTitle) {
      //mz-todo: This is probably not required since we are handling placeholder via css. Remove later
      // return CARD_TITLE_PLACEHOLDER;
      return "";
    }
    const name =
      this.state.selectedCategory === CAT_WOO_CANDIDATE
        ? this.state.wooUsername
        : recipient.name;
    return defaultTitle.replace("%FIRST_NAME%", name || "");
  }
  getDefaultCardSubtitle() {
    const { company } = this.props.metadata;
    const name = company ? company.name : "";
    const defaultCardSubtitle =
      (categoryData[this.state.selectedCategory] &&
        categoryData[this.state.selectedCategory].defaultCardSubtitle) ||
      "";
    if (!name || !defaultCardSubtitle) {
      //mz-todo: This is probably not required since we are handling placeholder via css. Remove later
      // return CARD_MESSAGE_PLACEHOLDER;
      return "";
    }
    return defaultCardSubtitle.replace("%COMPANY_NAME%", name);
  }
  getDefaultGroupCardTitle() {
    const defaultTitle =
      (categoryData[this.state.selectedCategory] &&
        categoryData[this.state.selectedCategory].defaultCardTitle) ||
      "";
    return defaultTitle;
  }
  getDefaultGroupCardSubtitle() {
    const { company } = this.props.metadata;
    const name = company ? company.name : "";
    const defaultCardSubtitle =
      (categoryData[this.state.selectedCategory] &&
        categoryData[this.state.selectedCategory].defaultCardSubtitle) ||
      "";
    if (!name || !defaultCardSubtitle) {
      return "";
    }
    return defaultCardSubtitle.replace("%COMPANY_NAME%", name);
  }
  makeAPIRequest(card) {
    if (this.state.initializingCard) {
      return;
    }
    this.setState({ initializingCard: true });
    const { company } = this.props.metadata;
    const domain = company && company.domain ? company.domain : "";
    this.props.actions.saveCard(card, (newCard) => {
      if (!newCard) {
        message.error(
          "Could not perform the requested action. Please try again."
        );
        this.setState({ initializingCard: false });
        return;
      }
      if (this.props.editMode) {
        this.setState({
          visible: false,
          screen: MODAL_CATEGORY_COMPONENT,
          initializingCard: false,
        });
      } else {
        this.setState({ initializingCard: false });
        setTimeout(() => {
          nav.goto(nav.urlCardPageWith(newCard.id, domain));
        });
      }
    });
  }
  initializeCard() {
    if (!this.cardFieldsDirty()) {
      this.handleCancel();
      return;
    }
    const { selectedCategory, isWithCompanyName } = this.state;
    if (isGroupedCategory(selectedCategory)) {
      this.initializeGroupCard();
      return;
    }
    switch (this.state.selectedCategory) {
      case CAT_OTHER:
        if (isWithCompanyName) {
          this.initializeNormalCard();
          return;
        } else {
          this.initializeWooCandidateCard();
          return;
        }
      case CAT_WOO_CANDIDATE:
        this.initializeWooCandidateCard();
        return;

      default:
        this.initializeNormalCard();
        break;
    }
  }
  initializeGroupCard() {
    const { user } = this.props.metadata;
    if (!user) {
      return;
    }
    //mz-todo: Figure out if edit mode required.
    const title = this.getDefaultGroupCardTitle();
    const subtitle = this.getDefaultGroupCardSubtitle();
    const c = new Card();
    c.category = this.state.selectedCategory;
    c.title = title;
    c.message = subtitle;
    // c.photo = null; //Deprecated
    // c.art = null; //Deprecated
    c.delivery = null;
    c.owner = user.ref;
    c.recipientRef = null;
    c.postsDisabled = false;
    c.categoryData = null;
    if (!this.props.editMode) {
      c.createdAt = moment.utc().format();
    }
    this.makeAPIRequest(c);
  }
  async initializeWooCandidateCard() {
    const { user } = this.props.metadata;
    const { selectedCategory } = this.state;
    if (!user) {
      return;
    }
    if (selectedCategory === CAT_WOO_CANDIDATE) {
      const duplicateExternalCard = await findDuplicateExternalCard(this.state.wooUsername, selectedCategory);
      if (duplicateExternalCard) {
        this.setState({
          duplicateCardId: duplicateExternalCard.id,
          screen: MODAL_ROUTE_CARD
        });
        return;
      }
    }
    const c = this.props.editMode
      ? this.props.card.draftCard.cloneNew()
      : new Card();
    c.category = this.state.selectedCategory;
    c.title = this.getDefaultTitle();
    c.message = this.getDefaultCardSubtitle();
    // c.photo = null; //Deprecated
    // c.art = null; //Deprecated
    c.delivery = null;
    c.owner = user.ref;
    c.recipientRef = null;
    c.postsDisabled = false;
    c.categoryData = { name: this.state.wooUsername };
    if (!this.props.editMode) {
      c.createdAt = moment.utc().format();
    }
    this.makeAPIRequest(c);
  }
  async initializeNormalCard() {
    const { recipient, deliveryUTC } = this.state.cardSettingsData;
    const { selectedCategory } = this.state;
    const { user } = this.props.metadata;
    const { card } = this.props;
    if (!user) {
      return;
    }
    const duplicateCard = await findDuplicateOpenCard(recipient.email, selectedCategory);
    if (duplicateCard) {
      if (card?.draftCard?.id !== duplicateCard.id) {
        this.setState({ duplicateCardId: duplicateCard.id, screen: MODAL_ROUTE_CARD });
        return;
      }
    }
    const c = this.props.editMode
      ? this.props.card.draftCard.cloneNew()
      : new Card();
    c.category = this.state.selectedCategory;
    c.active = false;
    c.title = this.getDefaultTitle();
    c.message = this.getDefaultCardSubtitle();
    // c.photo = null; //Deprecated
    // c.art = null; //Deprecated
    if (deliveryUTC) {
      const dateStringISO8601 = deliveryUTC.format();
      c.delivery = {
        ownerZoneName: getZoneNameLong(),
        ownerZoneNameIANA: getZoneNameIANA(),
        ownerOffset: getTimezoneOffsetInSeconds(),
        utc: dateStringISO8601,
        triggerAt: getSecondsTill(dateStringISO8601),
      };
    } else {
      c.delivery = null;
    }
    c.owner = user.ref;
    c.recipientRef = recipient.ref;
    c.recipient = null;
    c.postsDisabled = c.postsDisabled || false;
    c.categoryData = null;
    if (!this.props.editMode) {
      c.createdAt = moment.utc().format();
    }
    recipient.ref.get().then((res) => {
      if (res && res.data) {
        c.recipient = res.data();
      }
      this.makeAPIRequest(c);
    });
  }
  saveLocation(newLocation) {
    this.props.metadataActions.saveLocation(newLocation);
  }
  onGroupCardSelected(selectedCategory) {
    if (this.state.initializingCard) {
      return;
    }
    this.setState({ selectedCategory: selectedCategory });
    setTimeout(() => this.saveCardData(selectedCategory));
  }
  saveCategoryData(selectedCategory) {
    this.setState({
      screen: MODAL_CARD_SETTINGS_COMPONENT,
      selectedCategory: selectedCategory,
    });
  }
  storeCardSettingsData(data) {
    const { selectedCategory, isWithCompanyName } = this.state;
    if (isGroupedCategory(selectedCategory)) {
      return;
    }
    switch (selectedCategory) {
      case CAT_WOO_CANDIDATE:
        this.setState({ wooUsername: data });
        return;
      case CAT_OTHER:
        if (isWithCompanyName) {
          this.setState({ cardSettingsData: data });
        } else {
          this.setState({ wooUsername: data });
        }
        return;
      default:
        this.setState({ cardSettingsData: data });
        break;
    }
  }
  saveCardData(data) {
    if (this.state.initializingCard) {
      return;
    }
    this.storeCardSettingsData(data);
    const { company } = this.props.metadata;
    if (!company.name || !company.brandColor || !company.logo) {
      this.setState({
        screen: MODAL_COMPANY_SETTINGS_COMPONENT,
      });
    } else {
      setTimeout(() => this.initializeCard());
    }
  }
  uploadCompanyLogo(file, currentURL, callback) {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      OC.getInstance()
        .uploadImage(e.target.result, currentURL || null)
        .then((fullpath) => {
          if (!fullpath) {
            callback && callback(null);
            return;
          }
          callback && callback(fullpath);
        });
    };
    reader.readAsArrayBuffer(file);
  }
  updateCompanyItem(name, brandColor, logoURL, callback) {
    const { company } = this.props.metadata;
    const c = company ? company : new Company();
    c.brandColor = brandColor;
    c.logo = logoURL;
    c.name = name;
    this.props.metadataActions.updateCompany(c, (err) => {
      callback && callback(err);
    });
  }
  saveCompanyData(companySettingsData) {
    if (this.state.initializingCard || this.state.busyUploadingLogo) {
      return;
    }
    const { brandColor, name, logoFile } = companySettingsData;
    const { company } = this.props.metadata;
    if (logoFile) {
      this.setState({ busyUploadingLogo: true });
    }
    setTimeout(() => {
      this.setState({
        companySettingsData: companySettingsData,
        busyUploadingLogo: logoFile ? true : false,
      });
    });
    if (logoFile) {
      this.uploadCompanyLogo(logoFile, company.logo || null, (fullpath) => {
        this.setState({ busyUploadingLogo: false });
        if (!fullpath) {
          message.info("Could not update company logo.");
        }
        this.updateCompanyItem(
          name,
          brandColor,
          fullpath || company.logo,
          () => {
            !this.state.busyUploadingLogo &&
              setTimeout(() => this.initializeCard());
          }
        );
      });
    } else {
      this.updateCompanyItem(name, brandColor, company.logo);
      !this.state.busyUploadingLogo && setTimeout(() => this.initializeCard());
    }
  }
  showModal() {
    this.setState({ visible: true, mountModal: true });
  }
  handleCancel() {
    this.setState({ visible: false });
  }
  render() {
    const { usersList, user } = this.props.metadata;
    const { selectedCategory, cardSettingsData, wooUsername } = this.state;
    const cat = categories.find((element) => element.key === selectedCategory);
    const categoryName = cat?.name;
    const { recipient } = cardSettingsData;
    var recipientsList;
    if (usersList && user) {
      recipientsList = usersList.filter(
        (element) => element.email !== user.email
      );
    }
    return (
      <div style={this.props.style}>
        {this.props.children && (
          <Button {...this.props.buttonProps} onClick={this.onCardInitClicked}>
            {this.props.children}
          </Button>
        )}
        {this.state.mountModal && (
          <Modal
            afterClose={this.afterModalClosed}
            maskClosable={true}
            keyboard={false}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}
            centered
            closeIcon={<i className="fal fa-times"></i>}
          >
            {this.state.screen === MODAL_CATEGORY_COMPONENT && (
              <ModalCategory
                hideGroupCategories={this.props.editMode}
                busyInitCard={this.state.initializingCard}
                selectedCategory={this.state.selectedCategory}
                onSubmit={this.saveCategoryData}
                onGroupCardSelected={this.onGroupCardSelected}
                metadata={this.props.metadata}
              />
            )}
            {this.state.screen === MODAL_CARD_SETTINGS_COMPONENT &&
              (this.showExternalRecipientModal() ? (
                <WooCandidateSettings
                  busyInitCard={this.state.initializingCard}
                  onEditCategoryClick={this.editCategoryReroute}
                  wooUsername={wooUsername}
                  metadata={this.props.metadata}
                  onSubmit={this.saveCardData}
                  onWithCompanyNameToggle={this.onWithCompanyNameToggle}
                  selectedCategory={this.state.selectedCategory}
                  payment={this.props.payment}
                  paymentActions={this.props.paymentActions}
                  isEditMode={this.props.editMode}
                  closeModal={this.handleCancel}
                />
              ) : (
                <ModalCardSettings
                  includeNewUser={this.props.metadataActions.includeUser}
                  busyInitCard={this.state.initializingCard}
                  recipientsList={recipientsList}
                  onEditCategoryClick={this.editCategoryReroute}
                  cardSettingsData={this.state.cardSettingsData}
                  onSubmit={this.saveCardData}
                  selectedCategory={this.state.selectedCategory}
                  metadata={this.props.metadata}
                  onAddNewLocation={this.saveLocation}
                  isWithCompanyName={this.state.isWithCompanyName}
                  onWithCompanyNameToggle={this.onWithCompanyNameToggle}
                  payment={this.props.payment}
                  paymentActions={this.props.paymentActions}
                  isEditMode={this.props.editMode}
                  ref={this.modalCardSettingsRef}
                  isPaymentCallback={
                    this.props.urlLocation?.query?.pi ? true : false
                  }
                  closeModal={this.handleCancel}
                />
              ))}
            {this.state.screen === MODAL_COMPANY_SETTINGS_COMPONENT && (
              <ModalCompanySettings
                onRemoveLogo={this.removeCompanyLogo}
                busyInitCard={
                  this.state.initializingCard || this.state.busyUploadingLogo
                }
                onSubmit={this.saveCompanyData}
                metadata={this.props.metadata}
              />
            )}
            {this.state.screen === MODAL_ROUTE_CARD && (
              <ModalRouteCard
                routeToDuplicateCard={this.routeToDuplicateCard}
                recipient={selectedCategory === CAT_WOO_CANDIDATE ? wooUsername: recipient?.name}
                category={categoryName}
                handleCancel={this.handleCancel}
              />
            )}
          </Modal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { metadata, card, auth, payment } = state;
  return { metadata, card, auth, payment };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardInitButton);
