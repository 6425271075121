import { browserHistory } from "react-router";
export const URL_AUTH_FLOW_JUBILI = "/auth/jubili";
export const URL_AUTH_FLOW_JUBILI_VERIFIED = "/auth/jubili/verified"; //mz-todo: Find out if that's still relevant
export const URL_AUTH_FLOW_JOIN = "/auth/join";
export const URL_AUTH_FLOW_JOIN_VERIFIED = "/auth/join/verified";
export const URL_AUTH_FLOW_SIGNIN = "/auth/signin";
export const URL_AUTH_SIGNUP_COMPLETETION = "/auth/signup-completion"; //mz-todo: remove with AuthContainer.
export const URL_CARD_SETUP = "/cardsetup";
export const URL_CARD_SELECTION = "/cardselection";
export const URL_HOMEPAGE = "/homepage";
export const URL_HOMEPAGE_JUBILI = "/homepage/new";
export const URL_PRIVACY = "/privacy";
export const URL_TERMS = "/terms";
export const URL_AUTH_ACTION = "/auth_action"; // warn: Also defined in index.html
export const URL_DRAFT = "/draft/:id";
export const URL_VIEW = "/view/:id";
export const URL_POST = "/post/:id/domain/:domain";
export const URL_PAGE = "/c/:id";
export const URL_CREATE = "/create";
export const URL_REMINDERS = "/reminders";
export const URL_SETTINGS = "/settings";
export const AUTOMATION_GW = "/automation/gw"

export const QUERY_REDIRECT = "redirect";
export const QUERY_REMINDERS = "reminders";

export function urlCardPage(cardDomainId) {
  return URL_PAGE.replace(":id", cardDomainId);
}
export function urlCardPageWith(cardId, domain) {
  const cardDomainId = cardId + "_" + btoa(domain);
  return urlCardPage(cardDomainId);
}
export function urlDraft(cardId) {
  return URL_DRAFT.replace(":id", cardId);
}
export function gotoWithReplace(url) {
  browserHistory.replace(url);
}
export function goto(url) {
  if (isMagicLinkURL()) {
    browserHistory.replace(url);
    return;
  }
  browserHistory.push(url);
}
export function gotoMain() {
  const { protocol, host } = window.location;
  const url = `${protocol}//${host}${URL_HOMEPAGE}`;
  window.location.href = url;
}
export function gotoLandingPageWithRefresh() {
  const { protocol, host } = window.location;
  const url = `${protocol}//${host}`;
  window.location.href = url;
}
export function isAuthURL() {
  return (
    isMagicLinkURL() ||
    isAuthFlowJubiliURL() ||
    isAuthFlowJoinURL() ||
    isAuthFlowSigninURL()
  );
}
export function isUnprotectedURL() {
  return matchesPartially(URL_POST) 
    || matchesPartially(URL_PAGE)
    || matchesURL(URL_PRIVACY)
    || matchesURL(URL_TERMS);
}
export function isPostingURL() {
  return matchesPartially(URL_POST);
}
export function isSignupCompletionURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_AUTH_SIGNUP_COMPLETETION.split("/").join("")
  );
}
export function isMagicLinkURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_AUTH_ACTION.split("/").join("")
  );
}
export function isAuthFlowJubiliURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_AUTH_FLOW_JUBILI.split("/").join("")
  );
}
export function isAuthFlowJoinURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_AUTH_FLOW_JOIN.split("/").join("")
  );
}
export function isAuthFlowSigninURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_AUTH_FLOW_SIGNIN.split("/").join("")
  );
}
export function isAuthFlowJubiliVerifiedURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_AUTH_FLOW_JUBILI_VERIFIED.split("/").join("")
  );
}
export function isAuthFlowJoinVerifiedURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_AUTH_FLOW_JOIN_VERIFIED.split("/").join("")
  );
}
export function isCardFlowAutoTriggerURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_HOMEPAGE_JUBILI.split("/").join("")
  );
}
export function isRemindersURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_REMINDERS.split("/").join("")
  );
}
export function isSettingsURL() {
  return (
    window.location.pathname.split("/").join("") ===
    URL_SETTINGS.split("/").join("")
  );
}

function matchesURL(path) {
  if (!path) {
    return false;
  }
  return window.location.pathname.split("/").join("") ===
    path.split("/").join("")
}

// Warning: While using this function, make sure the intended url starts with unique character set.
// e.g: both /p & /post will return true if urlConstant=/p/:id
function matchesPartially(urlConstant) {
  const parts = urlConstant.split(":");
  const url = parts.length ? parts[0] : "n/a";
  return (
    window.location.pathname
      .split("/")
      .join("")
      .indexOf(url.split("/").join("")) === 0
  );
}
