import OC from "../oc";
import {
  getZoneNameLong,
  getZoneNameIANA,
  getTimezoneOffsetInSeconds,
  getSecondsTill,
} from "./timezones";
import Card from "../oc/models/card";
import moment from "moment";
import { categoryData, PLAN_TYPE_FREE_COMPANY, } from "./constants";
import { message } from "antd";
import * as nav from "../routes/nav";
import DOB from "./dob";
import Logger, { PRIORITY_CRITICAL } from "./logger";
import { ERR_PRODUCT_PRICE_NOT_FOUND } from "../oc/error_handler";

const SCHEDULE_THRESHOLD_REMAINING_MINUTES = 30;
const SCHEDULE_THRESHOLD_HOURS_AHEAD = 288; // 12 days


// findDuplicateOpenCard ... for cards with recipients field.
export async function findDuplicateOpenCard(email, category) {
  const cards = await OC.getInstance().findCardFor(email, category);
  if (cards && cards.length) {
    var oldest = cards[0];
    for (let c of cards) {
      if (moment(c.createdAt).isBefore(moment(oldest.createdAt)))
        oldest = c;
    }
    return oldest;
  }
}

// findDuplicateExternalCard ... for email-less cards with categoryData field.
export async function findDuplicateExternalCard(name, category) {
  const cards = await OC.getInstance().findExternalCardFor(name, category);
  if (cards && cards.length) {
    var oldest = cards[0];
    for (let c of cards) {
      if (moment(c.createdAt).isBefore(moment(oldest.createdAt)))
        oldest = c;
    }
    return oldest;
  }
}

// createAutoBirthdayCard ... Creates birthday card from birthday reminder email link.
export async function createAutoBirthdayCard(
  params,
  currentUser,
  company,
  saveCard,
  onFailure
) {
  const recipientSnapshot = await OC.getInstance()
    .getDocumentByPath(params.recipientPath)
    .get();
  if (!recipientSnapshot.exists) {
    onFailure && onFailure();
    return;
  }
  const recipientObj = recipientSnapshot.data();
  const verified = _verifyAutoBirthdayCardRequest(
    params,
    currentUser,
    recipientObj,
    company
  );
  if (!verified) {
    onFailure && onFailure();
    return;
  }

  const dupCard = await _findDuplicate(recipientObj.email);
  if (dupCard) {
    setTimeout(() => {
      nav.goto(nav.urlCardPageWith(dupCard.id, company.domain));
    });
    return;
  }

  const dobMoment = _getDOBMoment(recipientObj);
  if (_dobAlreadyPassed(dobMoment)) {
    message.info("This event has already expired", 6);
    onFailure && onFailure();
    return;
  }

  var delivery = null;
  if (_dobNotTooClose(dobMoment)) {
    if (dobMoment) {
      const dateStringISO8601 = dobMoment.format();
      delivery = {
        ownerZoneName: getZoneNameLong(),
        ownerZoneNameIANA: getZoneNameIANA(),
        ownerOffset: getTimezoneOffsetInSeconds(),
        utc: dateStringISO8601,
        triggerAt: getSecondsTill(dateStringISO8601),
      };
    }
  }
  if (delivery === null) {
    message.info(
      "Scheduled delivery time should be at least 30 minutes from now. Please set scheduled delivery from Settings menu or publish, manually.",
      6
    );
  }

  const c = new Card();
  c.category = params.category;
  c.title = _getBirthdayCardTitle(recipientObj);
  c.message = _getBirthdayCardSubtitle(company.name);
  c.delivery = delivery;
  c.owner = currentUser.ref;
  c.recipientRef = recipientSnapshot.ref;
  c.recipient = recipientObj;
  c.postsDisabled = false;
  c.categoryData = null;
  c.createdAt = moment.utc().format();

  saveCard &&
    saveCard(c, (newCard) => {
      if (!newCard) {
        message.error(
          "Could not perform the requested action. Please try again."
        );
        onFailure && onFailure();
        return;
      }
      setTimeout(() => {
        nav.goto(nav.urlCardPageWith(newCard.id, company.domain));
      });
    });
}

function _dobAlreadyPassed(dobMoment) {
  try {
    const dtNow = moment();
    var duration = moment.duration(dobMoment.diff(dtNow));
    var hours = duration.asHours();
    if (hours >= SCHEDULE_THRESHOLD_HOURS_AHEAD) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

function _dobNotTooClose(dtDOB) {
  try {
    const dtNow = moment();
    if (dtDOB === null) {
      return false;
    }
    var duration = moment.duration(dtDOB.diff(dtNow));
    var minutes = duration.asMinutes();
    if (minutes >= SCHEDULE_THRESHOLD_REMAINING_MINUTES) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

function _getDOBMoment(recipientObj) {
  try {
    const { dob } = recipientObj;
    if (!dob.day || dob.month === null || dob.month === undefined) {
      return null;
    }
    const dtDOB = new DOB(dob.day, dob.month).toMoment();
    return dtDOB ? dtDOB.utc() : null;
  } catch {
    return null;
  }
}

async function _findDuplicate(recipientEmail) {
  const cards = await OC.getInstance().findCardFor(recipientEmail, "birthday");
  if (cards && cards.length) {
    const dtLimit = moment().subtract(6, "months");
    for (let c of cards) {
      if (c.createdAt) {
        const dt = moment(c.createdAt);
        if (dt.isAfter(dtLimit)) {
          return c;
        }
      }
    }
  }
  return null;
}

function _verifyAutoBirthdayCardRequest(params, owner, recipientObj, company) {
  const { dob } = recipientObj;
  if (
    !dob ||
    !dob.day ||
    dob.month === null ||
    dob.month === undefined ||
    !owner ||
    owner.email !== params.ownerId ||
    !company ||
    company.domain !== params.companyId ||
    params.category !== "birthday"
  ) {
    return false;
  }
  if (params.dobDay !== dob.day || params.dobMonth !== dob.month) {
    message.info("Recipient's birthday does not match anymore", 6);
    return false;
  }
  return true;
}

function _getBirthdayCardTitle(recipientData) {
  const category = "birthday";
  const defaultTitle =
    (categoryData[category] && categoryData[category].defaultCardTitle) || "";
  if (!defaultTitle) {
    //mz-todo: This is probably not required since we are handling placeholder via css. Remove later
    // return CARD_TITLE_PLACEHOLDER;
    return "";
  }
  return defaultTitle.replace("%FIRST_NAME%", recipientData.name);
}
function _getBirthdayCardSubtitle(companyName) {
  const category = "birthday";
  const defaultCardSubtitle =
    (categoryData[category] && categoryData[category].defaultCardSubtitle) ||
    "";
  if (!companyName || !defaultCardSubtitle) {
    //mz-todo: This is probably not required since we are handling placeholder via css. Remove later
    // return CARD_MESSAGE_PLACEHOLDER;
    return "";
  }
  return defaultCardSubtitle.replace("%COMPANY_NAME%", companyName);
}
export function _getSortedPosts(posts, authorPost, editingAuthorPost) {
  if (authorPost) {
    const postList = [];
    posts.forEach((element) => {
      if (
        authorPost &&
        element.ownerInfo.ownerId === authorPost.ownerInfo.ownerId
      ) {
        return;
      }
      postList.push(element);
    });
    const updatedPostsList =
      postList &&
      postList.sort(function (a, b) {
        return moment(a.createdAt).diff(moment(b.createdAt));
      });
    if (!editingAuthorPost) {
      updatedPostsList.unshift(authorPost);
    }
    return updatedPostsList;
  } else {
    const postList =
      posts &&
      posts.sort(function (a, b) {
        return moment(a.createdAt).diff(moment(b.createdAt));
      });
    return postList;
  }
}

export async function startSingleCardSubscription(product, encodedData) {
  const priceId = product.getPriceId();
  if (!priceId) {
    Logger.logStripe(
      PRIORITY_CRITICAL,
      ERR_PRODUCT_PRICE_NOT_FOUND.message,
      "card_utils:startSingleCardSubscription",
      { productId: product?.id || null, encodedData: encodedData || null }
    );    
    return;
  }
  const { protocol, host } = window.location;
  var successUrl = `${protocol}//${host}`;
  if (successUrl.endsWith("/")) {
    successUrl = successUrl.slice(0,-1);
  }
  successUrl = encodedData ? `${successUrl}${nav.URL_HOMEPAGE}?pi=${encodedData}`: successUrl;
  await OC.getInstance().startSubscription(priceId, false, successUrl, (url, err) => {
    if (err || !url) {
      message.error(
        "Could not continue due to an error. We are investigating the issue. Please try again later.",
        8
      );
      Logger.logStripe(
        PRIORITY_CRITICAL,
        err ? err.message : "invalid url",
        "card_utils:startSingleCardSubscription",
        { productId: product?.id || null, encodedData: encodedData || null }
      );
      return;
    }
    window.location.assign(url);
  });
}

export function availableCardsCount(activePlan) {
  if (!activePlan || !activePlan.remainingCards) {
    return 0;
  }
  return activePlan.remainingCards;
}
export function isRunningLow(activePlan) {
  if (!activePlan || !activePlan.isRunningLow) {
    return false;
  }
  return activePlan.type === PLAN_TYPE_FREE_COMPANY ? false : activePlan.isRunningLow();
}
export function getBuyButtonText(singleCardProduct) {
  return singleCardProduct ? `Create for ${singleCardProduct.getProductPriceTag()}` : "Upgrade Plan";
}