import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/card";
import * as metadataActions from "../actions/metadata";
import "./styles/CardDraft.less";
import { Layout, Col, Row, message } from "antd";
import HeaderPostingView from "../components/card/HeaderPostingView";
import * as nav from "../routes/nav";
import CardUserView from "../components/card/CardUserView";
import Editor from "../components/Editor";
import PostView from "../components/PostView";
import Error404 from "../components/Error404";
import { LoadingOutlined } from "@ant-design/icons";
import Logger, { PRIORITY_HIGH } from "../shared/logger";
import ModalInvites from "../components/ModalInvites";
import { _getSortedPosts } from "../shared/card_utils";
import MainFooter from "../components/MainFooter";
import FloatingFooter from "../components/FloatingFooter";
import ShareFooter from "../components/ShareFooter";

const { Content } = Layout;

class PostingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPostEditor: false,
      editingAuthorPost: false,
      busyPostButton: false,
    };
    this.onPost = this.onPost.bind(this);
    this.editAuthorPost = this.editAuthorPost.bind(this);
    this.editorRef = React.createRef();
  }
  editAuthorPost() {
    this.setState({ editingAuthorPost: true, showPostEditor: true });
  }
  onPost(post, isEditing) {
    const { user } = this.props.metadata;
    const { toggleBirthdayModal } = this.props.metadataActions;
    this.setState({ editingAuthorPost: false });
    setTimeout(() => this.setState({ busyPostButton: true }));
    this.props.actions.savePost(post, this.props.card.draftCard.id, (err) => {
      if (err) {
        Logger.logError(
          PRIORITY_HIGH,
          "Error while creating/updating post in PostingContainer. Check previous logs to find error log from store.",
          "PostingContainer:onPost",
          { cardId: this.props.card.draftCard.id, postObj: post }
        );
        this.setState({ busyPostButton: false });
        return;
      }
      localStorage.removeItem("editor_user_email");
      localStorage.removeItem("editor_card_id");
      localStorage.removeItem("editor_post_message");
      localStorage.removeItem("editor_selected_theme");
      localStorage.removeItem("editor_gify_id");
      if (isEditing) {
        message.success("Post updated!");
        this.setState({ busyPostButton: false });
      } else {
        message.success("Post Successful!");
        this.setState({ busyPostButton: false });
      }
      if (user && user.hasDOB && !user.hasDOB()) {
        toggleBirthdayModal && toggleBirthdayModal(true);
      }
    });
  }
  componentDidMount() {
    const cardID = this.props.routeParams ? this.props.routeParams.id : null;
    const domain = this.props.routeParams
      ? this.props.routeParams.domain
      : null;
    if (!cardID) {
      setTimeout(() => {
        nav.goto(nav.URL_HOMEPAGE);
      });
    }
    const { draftCard } = this.props.card;
    const { company } = this.props.metadata;
    if (!draftCard || draftCard.id !== cardID) {
      this.props.actions.fetchCompanyCard(cardID, domain);
      this.props.actions.fetchCompanyPosts(
        cardID,
        domain,
        this.props.metadata.usersList
      );
    }
    if (!company || company.domain !== domain) {
      this.props.metadataActions.fetchCompanyByDomain(domain);
    }
  }
  componentWillUnmount() {
    this.props.actions.clearDraft();
  }
  isLoading() {
    const { fetchedPostsList } = this.props.card;
    return (
      this.props.card.draftCard === null ||
      this.props.metadata.company === null ||
      !fetchedPostsList
    );
  }
  draftNotFound(user) {
    const { draftCard } = this.props.card;
    if (draftCard && !draftCard.id) {
      return true;
    }
    if (!user) {
      return false;
    }
    if (draftCard && draftCard.getRecipientEmail) {
      return draftCard.getRecipientEmail() === user.email;
    }
    return true;
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  renderDraftNotFound() {
    return <Error404 />;
  }
  findAuthorPost() {
    const { posts } = this.props.card;
    const { user } = this.props.metadata;
    if (!posts || !user) {
      return null;
    }
    const authorPost = posts.find(
      (element) => element.ownerInfo.ownerId === user.email
    );
    return authorPost || null;
  }
  hasWelcomeParam() {
    const welcomeParam = this.props.location?.query?.welcome;
    return welcomeParam ? true : false;
  }
  render() {
    const { draftCard, posts } = this.props.card;
    const { user, company } = this.props.metadata;
    const { showPostEditor, busyPostButton, editingAuthorPost } = this.state;
    const domain = this.props.routeParams
      ? this.props.routeParams.domain
      : null;
    if (this.isLoading()) {
      return this.renderLoader();
    }
    if (this.draftNotFound(user)) {
      return this.renderDraftNotFound();
    }
    const authorPost = this.findAuthorPost();
    if (!editingAuthorPost) {
      if (!showPostEditor && !authorPost) {
        setTimeout(() => {
          this.setState({ showPostEditor: true });
        });
      }
      if (showPostEditor && authorPost) {
        setTimeout(() => {
          this.setState({ showPostEditor: false });
        });
      }
    }
    const sortedPosts = _getSortedPosts(posts, authorPost, editingAuthorPost);
    return (
      <div className="CardDraft">
        <Layout>
          <HeaderPostingView
            isLoggedIn={!!this.props.metadata.user}
            cardData={this.props.card.draftCard}
            categoriesList={this.props.metadata.categories}
            toggleInviteModal={this.props.actions.toggleInviteModal}
          />
          <Layout>
            <Content>
              <CardUserView company={company} draftCard={draftCard} />

              {draftCard.postsDisabled !== true && showPostEditor && (
                <Editor
                  onOpenUserModal={
                    this.props.metadataActions.toggleProfileModal
                  }
                  company={domain}
                  editingPost={this.state.editingAuthorPost ? authorPost : null}
                  card={draftCard}
                  user={user}
                  onPost={this.onPost}
                  postList={posts}
                  busyPostButton={busyPostButton}
                  otherPostCount={posts?.length}
                  ref={this.editorRef}
                />
              )}
              <Row id="pl" justify="center" align="middle">
                <Col xs={24} sm={24} md={16} className="posts">
                  {sortedPosts &&
                    sortedPosts.map((item, i) => {
                      // mz-todo: warn: Using random key as last resort. Remove if not needed.
                      const isAuthorPost = authorPost
                        ? item.id === authorPost.id
                        : false;
                      return (
                        <PostView
                          onOpenUserModal={
                            isAuthorPost
                              ? this.props.metadataActions.toggleProfileModal
                              : null
                          }
                          showAnchor={i === 1 && !draftCard.postsDisabled}
                          onAnchorClick={() => {
                            this.editorRef?.current?.focusEditor &&
                              this.editorRef.current.focusEditor();
                          }}
                          myPostExists={authorPost ? true : false}
                          userList={this.props.metadata.usersList}
                          currentUser={this.props.metadata.user}
                          likeUnlike={this.props.actions.likePost}
                          cardId={draftCard.id}
                          onEditAuthorPost={this.editAuthorPost}
                          isAuthorPost={isAuthorPost}
                          isEditable={isAuthorPost && !draftCard.postsDisabled}
                          key={`postview_posting_${
                            item.id ||
                            (item.ownerInfo && item.ownerInfo.ownerId) ||
                            Math.random()
                          }`}
                          data={item}
                        />
                      );
                    })}
                  {!user && this.hasWelcomeParam() ? (
                    <FloatingFooter />
                  ) : null}
                  {draftCard.postsDisabled ? (
                    <ShareFooter />
                  ) : null}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <ModalInvites />
        <MainFooter />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { card, metadata } = state;
  return { card, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PostingContainer);
