import React from "react";
import Twemoji from "react-twemoji";
import { showTwemoji } from "../shared/util";

class EmojiHandler extends React.Component {
  render() {
    return showTwemoji()
      ? <Twemoji options={{ className: "twemoji" }}>{this.props.children}</Twemoji>
      : <div>{this.props.children}</div>
  }
}

export default EmojiHandler