import React from "react";
import { Button, Steps } from "antd";
import workspace from "./img/artwork-google-workspace.png";
import automateCards from "./img/artwork-automate-birthday-cards.png";
import automateCollection from "./img/artwork-automate-birthdate-collection.png";
import automateReminders from "./img/artwork-reminder-emails.png";
import "./styles/ModalAutomations.less";

const { Step } = Steps;

class ModalAutomations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
    this.onConnectWorkspaceClick = this.onConnectWorkspaceClick.bind(this);
    this.onEnableCollectionClick = this.onEnableCollectionClick.bind(this);
    this.onEnableCardsClick = this.onEnableCardsClick.bind(this);
    this.onEnableRemindersClick = this.onEnableRemindersClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onSkipClick = this.onSkipClick.bind(this);
  }
  onSkipClick() {
    const { current } = this.state;
    const { handleCancel } = this.props;
    if (current === 3) {
      handleCancel();
      setTimeout(() => {
        this.setState({ current: 0 });
      });
      return;
    }
    this.setState({ current: current + 1 });
  }
  onNextClick() {
    const { current } = this.state;
    const { handleCancel } = this.props;
    if (current === 3) {
      handleCancel();
      setTimeout(() => {
        this.setState({ current: 0 });
      });
      return;
    }
    this.setState({ current: current + 1 });
  }

  onConnectWorkspaceClick() {
    const { enableFeature } = this.props;
    enableFeature("connectWorkSpace");
  }
  onEnableCollectionClick() {
    const { enableFeature } = this.props;
    enableFeature("birthdayCollection");
  }
  onEnableCardsClick() {
    const { enableFeature } = this.props;
    enableFeature("birthdayCards");
  }
  onEnableRemindersClick() {
    const { enableFeature } = this.props;
    enableFeature("birthdayReminders");
  }
  getStatus(step) {
    const { current } = this.state;
    const {
      connectWorkSpace,
      birthdayCollection,
      birthdayCards,
      birthdayReminders,
    } = this.props;
    switch (step) {
      case 0:
        if (connectWorkSpace) {
          return "finish";
        } else if (current === 0) {
          return "process";
        } else {
          return "wait";
        }
        break;
      case 1:
        if (birthdayCollection) {
          return "finish";
        } else if (current === 1) {
          return "process";
        } else {
          return "wait";
        }
        break;
      case 2:
        if (birthdayCards) {
          return "finish";
        } else if (current === 2) {
          return "process";
        } else {
          return "wait";
        }
        break;
      case 3:
        if (birthdayReminders) {
          return "finish";
        } else if (current === 3) {
          return "process";
        } else {
          return "wait";
        }
        break;
      default:
        return;
    }
  }
  getSteps() {
    const {
      connectWorkSpace,
      birthdayCollection,
      birthdayCards,
      birthdayReminders,
    } = this.props;
    return [
      {
        status: this.getStatus(0),
        content: connectWorkSpace
          ? this.getFirstSuccessContent()
          : this.getFirstContent(),
      },
      {
        status: this.getStatus(1),
        content: birthdayCollection
          ? this.getSecondSuccessContent()
          : this.getSecondContent(),
      },
      {
        status: this.getStatus(2),
        content: birthdayCards
          ? this.getThirdSuccessContent()
          : this.getThirdContent(),
      },
      {
        status: this.getStatus(3),
        content: birthdayReminders
          ? this.getFourthSuccessContent()
          : this.getFourthContent(),
      },
    ];
  }
  getFirstContent() {
    return (
      <div className="modal">
        <img src={workspace}></img>
        <h2>Google Workplace Integration</h2>
        <p>
          Allows Greeted to access and sync email addresses of everyone in the
          company
        </p>
        <Button
          onClick={this.onConnectWorkspaceClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Connect to Google Workspace
        </Button>
        <Button onClick={this.onSkipClick} type="link" block>
          Skip
        </Button>
      </div>
    );
  }
  getSecondContent() {
    return (
      <div className="modal">
        <img src={automateCollection}></img>
        <h2>Automate Birth Date Collection</h2>
        <p>
          Greeted will now send emails to rest of the company in order to
          collect birth dates. Teammates will have an option to opt-out of their
          birthday celebration.
        </p>
        <Button
          onClick={this.onEnableCollectionClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Send Invites &amp; Enable Automation
        </Button>
        <Button onClick={this.onSkipClick} type="link" block>
          Skip
        </Button>
      </div>
    );
  }
  getThirdContent() {
    return (
      <div className="modal">
        <img src={automateCards}></img>
        <h2>Automate Birthday Cards</h2>
        <p>
          Greeted will automatically create birthday cards and send invitations
          to everyone in the company.
        </p>
        <Button
          onClick={this.onEnableCardsClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Enable Automation
        </Button>
        <Button onClick={this.onSkipClick} type="link" block>
          Skip
        </Button>
      </div>
    );
  }
  getFourthContent() {
    return (
      <div className="modal">
        <img src={automateReminders}></img>
        <h2>Birthday Reminders</h2>
        <p>
          Send email reminders to company administators about upcoming
          birthdays.
        </p>
        <Button
          onClick={this.onEnableRemindersClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Enable Reminders
        </Button>
        <Button onClick={this.onSkipClick} type="link" block>
          Skip
        </Button>
      </div>
    );
  }
  getFirstSuccessContent() {
    return (
      <div>
        <h2>Successfully Integrated Google Workspace</h2>
        <p>
          Looks like we dont have birth dates for 21 of 45 teammates in your
          company. Click Next to Automate birth date collection.
        </p>
        <Button
          onClick={this.onNextClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Next
        </Button>
      </div>
    );
  }
  getSecondSuccessContent() {
    return (
      <div>
        <h2>Succesfully Automated Birth Date Collection</h2>
        <p>
          Greeted have sent email requests to everyone in the company. Click
          Next to Automate Birthday Cards.
        </p>
        <Button
          onClick={this.onNextClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Next
        </Button>
      </div>
    );
  }
  getThirdSuccessContent() {
    return (
      <div>
        <h2>Succesfully Automated Birthday Cards</h2>
        <p>
          Greeted will take care of everything from creating Birthday Cards to
          Sending Invitations. Click Next to enable upcoming birthday reminders.
        </p>
        <Button
          onClick={this.onNextClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Next
        </Button>
      </div>
    );
  }
  getFourthSuccessContent() {
    return (
      <div>
        <h2>Succesfully Automated Birthday Reminders</h2>
        <p>
          Greeted will now send reminder emails to company administrators about
          upcoming birthdays.
        </p>
        <Button
          onClick={this.onNextClick}
          type="primary"
          size="large"
          block
          htmlType="submit"
        >
          Done
        </Button>
      </div>
    );
  }
  render() {
    const { current } = this.state;
    return (
      <div className="ModalAutomations">
        <Steps size="small" current={current}>
          {this.getSteps().map((item) => (
            <Step
              status={item.status}
              key={item.title}
              content={item.content}
            />
          ))}
        </Steps>
        <div className="steps">{this.getSteps()[current].content}</div>
      </div>
    );
  }
}

export default ModalAutomations;
