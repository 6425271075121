import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/card";
import * as metadataActions from "../actions/metadata";
import "./styles/CardDraft.less";
import { Layout, message, Modal, Row, Col } from "antd";
import HeaderDraft from "../components/HeaderDraft";
import * as nav from "../routes/nav";
import CardIntro from "../components/CardIntro";
import CardUserView from "../components/card/CardUserView";
import Editor from "../components/Editor";
import PostView from "../components/PostView";
import Error404 from "../components/Error404";
import CardPreview from "../components/card/CardPreview";
import ModalCompanySettings from "../components/ModalCompanySettings";
import Company from "../oc/models/company";
import { LoadingOutlined } from "@ant-design/icons";
import OC from "../oc";
import Logger, { PRIORITY_HIGH } from "../shared/logger";
import ModalInvites from "../components/ModalInvites";
import { _getSortedPosts } from "../shared/card_utils";
import MainFooter from "../components/MainFooter";
import ShareFooter from "../components/ShareFooter";

const { Content } = Layout;

class CardDraft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDraftPreview: false,
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      showPostEditor: false,
      editingAuthorPost: false,
      companyModalVisible: false,
      busyCompanyEdit: false,
      invitesModalVisible: false,
      busyPostButton: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    // this.handlePreview = this.handlePreview.bind(this); //mz-todo: Dead code. Remove it later along with dead states everywhere.
    this.handleChange = this.handleChange.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.onPost = this.onPost.bind(this);
    this.onCardUpdate = this.onCardUpdate.bind(this);
    this.disablePosts = this.disablePosts.bind(this);
    this.editAuthorPost = this.editAuthorPost.bind(this);
    this.onDraftPreview = this.onDraftPreview.bind(this);
    this.onEndDraftPreview = this.onEndDraftPreview.bind(this);
    this.editCompanySettings = this.editCompanySettings.bind(this);
    this.companyModalClose = this.companyModalClose.bind(this);
    this.removeCompanyLogo = this.removeCompanyLogo.bind(this);
    this.saveCompanyData = this.saveCompanyData.bind(this);
    this.showInvitesModal = this.showInvitesModal.bind(this);
    this.editorRef = React.createRef();
  }
  showInvitesModal() {
    const { toggleInviteModal } = this.props.actions;
    toggleInviteModal && toggleInviteModal(true);
  }
  uploadCompanyLogo(file, currentURL, callback) {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      OC.getInstance()
        .uploadImage(e.target.result, currentURL || null)
        .then((fullpath) => {
          if (!fullpath) {
            callback && callback(null);
            return;
          }
          callback && callback(fullpath);
        });
    };
    reader.readAsArrayBuffer(file);
  }
  saveCompanyData(companySettingsData) {
    if (this.state.busyCompanyEdit) {
      return;
    }
    setTimeout(() => {
      this.setState({ busyCompanyEdit: true });
    });
    const { brandColor, name, logoFile } = companySettingsData;
    const { company } = this.props.metadata;
    if (logoFile) {
      this.uploadCompanyLogo(logoFile, company.logo || null, (fullpath) => {
        if (!fullpath) {
          message.info("Could not update company logo.");
        }
        this.updateCompanyItem(
          name,
          brandColor,
          fullpath || company.logo,
          () => {
            setTimeout(() => {
              this.setState({ busyCompanyEdit: false });
            });
            this.setState({ companyModalVisible: false });
          }
        );
      });
    } else {
      this.updateCompanyItem(name, brandColor, company.logo);
      setTimeout(() => {
        this.setState({ busyCompanyEdit: false });
      });
      this.setState({ companyModalVisible: false });
    }
  }
  updateCompanyItem(name, brandColor, logoURL, callback) {
    const { company } = this.props.metadata;
    const c = company ? company : new Company();
    c.brandColor = brandColor;
    c.logo = logoURL;
    c.name = name;
    this.props.metadataActions.updateCompany(c, (_) => {
      setTimeout(() => this.setState({ busyCompanyEdit: false }));
      callback && callback();
    });
  }
  removeCompanyLogo() {
    const { company } = this.props.metadata;
    if (!company) {
      return;
    }
    setTimeout(() => this.setState({ busyCompanyEdit: true }));
    this.updateCompanyItem(company.name, company.brandColor, null);
  }
  companyModalClose() {
    this.setState({ companyModalVisible: false });
  }
  editCompanySettings() {
    this.setState({ companyModalVisible: true });
  }
  onEndDraftPreview() {
    window.scrollTo(0, 0);
    this.setState({ showDraftPreview: false });
  }
  onDraftPreview() {
    window.scrollTo(0, 0);
    this.setState({ showDraftPreview: true });
  }
  editAuthorPost() {
    this.setState({ editingAuthorPost: true, showPostEditor: true });
  }
  onCardUpdate(card) {
    this.props.actions.saveCard(card);
  }
  onPost(post, isEditing) {
    const { draftCard } = this.props.card;
    const { user } = this.props.metadata;
    const { toggleBirthdayModal } = this.props.metadataActions;
    const { toggleInviteModal } = this.props.actions;

    setTimeout(() => this.setState({ busyPostButton: true }));
    if (!draftCard || !draftCard.id) {
      this.setState({ busyPostButton: false });
      return;
    }
    this.setState({ editingAuthorPost: false });
    this.props.actions.savePost(post, draftCard.id, (err) => {
      if (err) {
        Logger.logError(
          PRIORITY_HIGH,
          "Error while creating/updating post in CardDraft. Check previous logs to find error log from store.",
          "CardDraft:onPost",
          { cardId: draftCard.id, postObj: post }
        );
        this.setState({ busyPostButton: false });
        return;
      }
      localStorage.removeItem("editor_user_email");
      localStorage.removeItem("editor_card_id");
      localStorage.removeItem("editor_post_message");
      localStorage.removeItem("editor_selected_theme");
      localStorage.removeItem("editor_gify_id");
      if (isEditing) {
        message.success("Post updated!");
        this.setState({ busyPostButton: false });
      } else {
        message.success("Post Successful!");
        this.setState({ busyPostButton: false });
      }
      if (user && user.hasDOB && !user.hasDOB()) {
        toggleInviteModal && toggleInviteModal(true);
        toggleBirthdayModal && toggleBirthdayModal(true);
      } else if (!isEditing) {
        toggleInviteModal && toggleInviteModal(true);
      }
    });
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  handleCancel() {
    this.setState({ previewVisible: false });
  }
  //mz-todo: Dead code. Remove it later along with dead states everywhere.
  // async handlePreview(file) {
  //   if (!file.url && !file.preview) {
  //     file.preview = await this.getBase64(file.originFileObj);
  //   }
  //   this.setState({
  //     previewImage: file.url || file.preview,
  //     previewVisible: false,
  //     previewTitle:
  //       file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
  //   });
  // }
  handleChange({ fileList }) {
    this.setState({ fileList });
  }
  makeAPIRequest(card) {
    this.props.actions.saveCard(card, (newCard) => {
      if (!newCard) {
        message.error(
          "Could not update post status. Please check network connection."
        );
      }
    });
  }
  componentDidMount() {
    this._mounted = true;
    const cardID = this.props.routeParams ? this.props.routeParams.id : null;
    if (!cardID) {
      setTimeout(() => {
        nav.goto(nav.URL_HOMEPAGE);
      });
    }
    const { draftCard } = this.props.card;
    if (!draftCard || draftCard.id !== cardID) {
      this.props.actions.fetchCard(cardID);
      this.props.actions.fetchPosts(cardID, this.props.metadata.usersList);
    }
  }
  componentWillUnmount() {
    this._mounted = false;
    this.props.actions.clearDraft();
  }
  isLoading() {
    const { fetchedPostsList } = this.props.card;
    return (
      this.props.card.draftCard === null ||
      this.props.metadata.company === null ||
      !fetchedPostsList
    );
  }
  draftNotFound(user) {
    if (!user) {
      return true;
    }
    const { draftCard } = this.props.card;
    if (draftCard && draftCard.id && draftCard.owner) {
      return draftCard.owner.id !== user.email;
    }
    return true;
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  renderDraftNotFound() {
    return <Error404 />;
  }
  findAuthorPost() {
    const { posts } = this.props.card;
    const { user } = this.props.metadata;
    if (!posts || !user) {
      return null;
    }
    const authorPost = posts.find(
      (element) => element.ownerInfo.ownerId === user.email
    );
    return authorPost || null;
  }
  disablePosts(requestManualDispatch) {
    const c = this.props.card.draftCard.cloneNew();
    c.postsDisabled = true;
    if (requestManualDispatch) {
      c.requestManualDispatch = true;
    }
    this.makeAPIRequest(c);
  }
  shouldShowAnchor(authorPost, index, total, published) {
    if (published) {
      return false;
    }
    if (authorPost && authorPost !== undefined) {
      return total >= 2 ? index === 0 : false;
    } else {
      return total >= 1 ? index === 0 : false;
    }
  }
  render() {
    const { draftCard, posts } = this.props.card;
    const { user, company } = this.props.metadata;
    const {
      showPostEditor,
      previewImage,
      previewTitle,
      fileList,
      previewVisible,
      busyPostButton,
      editingAuthorPost,
    } = this.state;
    if (this.isLoading()) {
      return this.renderLoader();
    }
    if (!user || this.draftNotFound(user)) {
      return this.renderDraftNotFound();
    }
    if (this.state.showDraftPreview) {
      return (
        <CardPreview
          card={this.props.card}
          onEndPreview={this.onEndDraftPreview}
          company={company}
          authorPost={this.findAuthorPost()}
          currentUser={user}
        />
      );
    }
    const authorPost = this.findAuthorPost();
    const sortedPosts = _getSortedPosts(posts, authorPost, editingAuthorPost);
    if (!this.state.editingAuthorPost) {
      if (!showPostEditor && !authorPost) {
        setTimeout(() => {
          this._mounted && this.setState({ showPostEditor: true });
        });
      }
      if (showPostEditor && authorPost) {
        setTimeout(() => {
          this._mounted && this.setState({ showPostEditor: false });
        });
      }
    }
    const uploadButton = (
      <div>
        <div className="ant-upload-text">
          <i className="fal fa-image-polaroid"></i> Drag &amp; drop a cover
          photo or click to browse (optional)
        </div>
      </div>
    );
    return (
      <div className="CardDraft">
        <Layout>
          <HeaderDraft
            contingencyCompanyFetch={this.props.metadataActions.fetchCompany}
            disablePosts={this.disablePosts}
            onPreview={this.onDraftPreview}
            cardData={this.props.card.draftCard}
            auth={this.props.auth}
            actions={this.props.actions}
            categoriesList={this.props.metadata.categories}
            company={this.props.metadata.company}
            onInvitesModal={this.showInvitesModal}
          />
          <Layout>
            <Content>
              {draftCard.postsDisabled ? (
                <CardUserView company={company} draftCard={draftCard} />
              ) : !this.state.busyCompanyEdit ? (
                <CardIntro
                  company={company}
                  previewImage={previewImage}
                  previewTitle={previewTitle}
                  draftCard={draftCard}
                  fileList={fileList}
                  uploadButton={uploadButton}
                  previewVisible={previewVisible}
                  onCardUpdate={this.onCardUpdate}
                  onCompanyEdit={this.editCompanySettings}
                />
              ) : null}
              {draftCard.postsDisabled !== true && showPostEditor && (
                <Editor
                  onOpenUserModal={
                    this.props.metadataActions.toggleProfileModal
                  }
                  editingPost={this.state.editingAuthorPost ? authorPost : null}
                  card={draftCard}
                  user={user}
                  onPost={this.onPost}
                  busyPostButton={busyPostButton}
                  otherPostCount={posts?.length}
                  ref={this.editorRef}
                />
              )}
              <Row id="pl" justify="center" align="middle">
                <Col xs={24} sm={24} md={16} className="posts">
                  {sortedPosts &&
                    sortedPosts.map((item, i) => {
                      // mz-todo: warn: Using random key as last resort. Remove if not needed.
                      const isAuthorPost = authorPost
                        ? item.id === authorPost.id
                        : false;
                      return (
                        <PostView
                          onOpenUserModal={
                            isAuthorPost
                              ? this.props.metadataActions.toggleProfileModal
                              : null
                          }
                          showAnchor={this.shouldShowAnchor(
                            authorPost,
                            i,
                            sortedPosts.length,
                            draftCard.postsDisabled
                          )}
                          onAnchorClick={() => {
                            this.editorRef?.current?.focusEditor &&
                              this.editorRef.current.focusEditor();
                          }}
                          myPostExists={authorPost ? true : false}
                          userList={this.props.metadata.usersList}
                          currentUser={this.props.metadata.user}
                          likeUnlike={this.props.actions.likePost}
                          cardId={draftCard.id}
                          key={`postview_draft_${
                            item.id ||
                            (item.ownerInfo && item.ownerInfo.ownerId) ||
                            Math.random()
                          }`}
                          data={item}
                          onEditAuthorPost={this.editAuthorPost}
                          isAuthorPost={isAuthorPost}
                          isEditable={isAuthorPost && !draftCard.postsDisabled}
                        />
                      );
                    })}
                  {draftCard.postsDisabled ? <ShareFooter /> : null}
                </Col>
              </Row>
            </Content>
          </Layout>
          <span style={{fontFamily: "Poppins", opacity: 0, position: "absolute"}}>.</span>
        </Layout>
        <Modal
          maskClosable={true}
          visible={this.state.companyModalVisible}
          onCancel={this.companyModalClose}
          footer={null}
          centered
          closeIcon={<i className="fal fa-times"></i>}
        >
          <ModalCompanySettings
            onRemoveLogo={this.removeCompanyLogo}
            busy={this.state.busyCompanyEdit}
            onSubmit={this.saveCompanyData}
            metadata={this.props.metadata}
          />
        </Modal>
        <ModalInvites />

        <MainFooter />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { card, metadata } = state;
  return { card, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDraft);
