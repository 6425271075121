import React from "react";
import "./styles/VerifyEmail.less";
import { Button, Space } from "antd";
import fly from "./img/artwork-fly.png";
import gmail from "./img/icon-gmail.svg";
import outlook from "./img/icon-outlook.svg";
import yahoo from "./img/icon-yahoo.svg";
import logo from "./img/logo-greeted.svg";
import { OC_PROD_TITLE } from "../shared/constants";

class ForgotPasswordSent extends React.Component {
  renderLogo() {
    const { companyLogo } = this.props;
    if (companyLogo) {
      return (
        <div className="brand-logo">
          <img src={companyLogo} alt={OC_PROD_TITLE} />
        </div>
      );
    } else {
      return (
        <div className="brand-logo">
          <span className="product">
            <img src={logo} alt={OC_PROD_TITLE} />
          </span>
        </div>
      );
    }
  }
  render() {
    return (
      <div className="VerifyEmail">
        <div className="title">
          {this.renderLogo()}
          <div className="artwork">
            <img src={fly} alt={OC_PROD_TITLE} />
          </div>
          <h2>We've emailed you a sign-in link</h2>
          <p>
            Please verify your identity by signing in with that link. Once
            signed in, you can update your password.
          </p>
        </div>

        <div className="services">
          <Space size="large">
            <Button size="large" href="http://gmail.com" target="blank">
              <img src={gmail} alt={OC_PROD_TITLE} /> Open Gmail
            </Button>
            <Button size="large" href="http://outlook.com" target="blank">
              <img src={outlook} alt={OC_PROD_TITLE} /> Open Outlook
            </Button>
            <Button size="large" href="http://login.yahoo.com" target="blank">
              <img src={yahoo} alt={OC_PROD_TITLE} /> Open Yahoo
            </Button>
          </Space>
        </div>
        <p className="text-center">
          Didn’t receive email?&nbsp;
          <Button
            type="link"
            loading={this.props.busy}
            disabled={this.props.busy}
            onClick={this.props.resend}
          >
            Resend Email
          </Button>
        </p>
      </div>
    );
  }
}

export default ForgotPasswordSent;
