import "firebase/firestore";
import firebase from "firebase/app";
import User from "./models/user";
import Card from "./models/card";
import Post from "./models/post";
import Location from "./models/location";
import Company from "./models/company";
import CardUsage from "./models/cardUsage";
import { StripeSubscription, StripePaymentSingleCard, Product } from "./models/stripe_models";
import {
  logError,
  PRIORITY_NORMAL,
  PRIORITY_HIGH,
  PRIORITY_CRITICAL,
  ERR_INVALID_COMPANY_ID,
  ERR_POST_COUNT_NOT_MATCHED,
  ERR_EXPECTED_USER_NOT_FOUND,
  ERR_POST_COUNT_CORRECTION_PERMISSION_FAILED,
  ERR_CHECKOUT_TIMOUT,
  isPermissionDenied,
} from "./error_handler";
import Category from "./models/category";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import OC from "./"; // For getCurrentAuthUser() function only.

const DELETE_BATCH_LIMIT = 500;

//godmode
const godmodeProduct1 = "prod_KMNwsl4tsaTBbJ";

export default class Store {
  /**
   * @type {firebase.firestore.Firestore}
   */  
  db = null;
  unsubCheckoutListener = null;
  checkoutTimeout = null;
  constructor(fbRef) {
    if (!fbRef) {
      throw new Error("null parameter");
    }
    this.db = fbRef.firestore();
  }

  //////////////////////////////////////////////
  // Users Collection API Endpoints
  //////////////////////////////////////////////

  async fetchUsers(companyId) {
    try {
      var snapshot = await this.getCompanyRef(companyId)
        .collection("users")
        .get(); //
      const usersList = [];
      if (!snapshot.docs.length) {
        return usersList;
      }
      for (var i = 0; i < snapshot.docs.length; i++) {
        const obj = snapshot.docs[i];
        const objData = obj.data();
        const objRef = this.getCompanyRef(companyId)
          .collection("users")
          .doc(objData.email);
        usersList.push(await new User().parse(objData, objRef));
      }
      return usersList;
    } catch (err) {
      logError(err, "fetchUsers:Store");
      return null;
    }
  }

  // getUserBy ... Returns User object or null.
  async getUserBy(id, companyId) {
    try {
      const usersRef = this.getCompanyRef(companyId).collection("users");
      const u = await usersRef.doc(id.toLowerCase()).get();
      if (u.exists) {
        return await new User().parse(u.data(), usersRef.doc(id));
      }
    } catch (err) {
      logError(err, "getUserBy:store");
      return null;
    }
    return null;
  }

  async toggleAdmin(email, companyId, value) {
    try {
      const userRef = this.getCompanyRef(companyId).collection("users");
      await userRef.doc(email).update({
        isAdmin: value,
      });
    } catch (err) {
      logError(err, "toggleAdmin:store");
      return err;
    }
    return null;
  }

  async toggleReminders(email, companyId, value) {
    try {
      const userRef = this.getCompanyRef(companyId).collection("users");
      await userRef.doc(email).update({
        remindersDisabled: value,
      });
    } catch (err) {
      logError(err, "toggleReminders:store");
      return err;
    }
    return null;
  }

  // updateUser ... Returns err or null.
  async updateUser(user, companyId) {
    try {
      const userRef = this.getCompanyRef(companyId).collection("users");
      user._lastUpdated = moment.utc().format();
      await userRef.doc(user.getKey()).update(user.getDoc());
      return null;
    } catch (err) {
      logError(err, "updateUser:store", PRIORITY_HIGH, user);
      return err;
    }
  }

  async deleteUser(email, companyId) {
    try {
      var userRef = this.getCompanyRef(companyId)
        .collection("users")
        .doc(email);
      var userDoc = await userRef.get();
      if (!userDoc.exists) {
        logError(ERR_EXPECTED_USER_NOT_FOUND, "deleteUser:store");
      }
      await userRef.delete();
      // Avatar deletion is handled by cloud function.
      return null;
    } catch (err) {
      logError(err, "deleteUser:store");
    }
    return null;
  }

  // addUser ... Returns err or null.
  async addUser(user, companyId) {
    try {
      const userRef = this.getCompanyRef(companyId).collection("users");
      await userRef.doc(user.getKey()).set(user.getDoc());
      return null;
    } catch (err) {
      logError(err, "addUser:store", PRIORITY_HIGH, user);
      return err;
    }
  }

  //////////////////////////////////////////////
  // Card Collection API Endpoints
  //////////////////////////////////////////////

  // saveLocation ... Returns new Location object or null.
  async saveLocation(location, companyId) {
    try {
      const locationRef = this.getCompanyRef(companyId).collection("locations");
      location._lastUpdated = moment.utc().format();
      const ref = await locationRef.add(location.getDoc());
      location.id = ref.id;
      location.ref = ref;
      return location;
    } catch (err) {
      logError(err, "saveLocation:store", PRIORITY_HIGH);
      return null;
    }
  }

  // publishCard ... Only updates the required field to comply with firestore security rules.
  async publishCard(card, companyId) {
    try {
      const cardsRef = this.getCompanyRef(companyId).collection("cards");
      if (card.id) {
        const data = {
          postsDisabled: true
        }
        if (card.requestManualDispatch) {
          data.requestManualDispatch = true;
        }
        await cardsRef.doc(card.id).update(data);
      } else {
        throw new Error("invalid card id");
      }
      return null;
    } catch (err) {
      logError(err, "saveCard:publishCard", PRIORITY_HIGH, card);
      return err;
    }
  }
  // saveCard ... Returns err or null.
  async saveCard(card, activePlan, companyId) {
    try {
      const cardsRef = this.getCompanyRef(companyId).collection("cards");
      const cardDoc = card.getDoc();
      if (cardDoc.invited && cardDoc.invited.length) {
        cardDoc.invited = firebase.firestore.FieldValue.arrayUnion(
          ...cardDoc.invited, card.owner.id,
        );
      } else {
        cardDoc.invited = [card.owner.id];
      }
      if (card.id) {
        await cardsRef.doc(card.id).update(cardDoc);
      } else {
        cardDoc.planType = activePlan.type;
        cardDoc.planRedeemableDocId = activePlan.redeemableDocId;
        const ref = await cardsRef.add(cardDoc);
        card.id = ref.id;
      }
      try {
        const serCard = card.getSerializable(card.owner.id);
        this._addCardToCache(card.id, serCard, companyId);
      } catch (errCache) {
        logError(errCache, "saveCard:store:cardCache", PRIORITY_NORMAL, card);
      }
      return null;
    } catch (err) {
      logError(err, "saveCard:store", PRIORITY_HIGH, card);
      return err;
    }
  }
  // findCard ... Returns card object or null.
  async findCard(id, companyId) {
    try {
      var cardsRef = this.getCompanyRef(companyId).collection("cards");
      const obj = await cardsRef.doc(id).get();
      if (obj.exists) {
        return await new Card().parse(obj.id, obj.data());
      }
    } catch (err) {
      logError(err, "findCard:store");
      return null;
    }
    return null;
  }

  // findExternalCardFor ... Returns cards array or null.
  async findExternalCardFor(name, category, companyId) {
    try {
      var cardsRef = this.getCompanyRef(companyId).collection("cards");
      const cardsSnapshot = await cardsRef
        .where("category", "==", category)
        .where("postsDisabled", "==", false)
        .get();
      if (cardsSnapshot.docs.length) {
        const allCards = cardsSnapshot.docs;
        const cardsList = [];
        for (var i = 0; i < allCards.length; i++) {
          const obj = allCards[i];
          const objData = obj.data();
          if (objData?.categoryData?.name.toLowerCase() === name?.toLowerCase()) {
            const card = await new Card().parse(obj.id, objData);
            cardsList.push(card);
          };
        }
        return cardsList;
      }
    }
    catch (err) {
      logError(err, "findExternalCardFor:store");
      return null;
    }
  }

  // findCardFor ... Returns cards array or null.
  async findCardFor(recipientEmail, category, companyId) {
    try {
      const recipientRef = this.getCompanyRef(companyId)
        .collection("users")
        .doc(recipientEmail);
      var cardsRef = this.getCompanyRef(companyId).collection("cards");
      //mz-todo: Implement timestamp for createAt field, later. Use it in where() clause.
      const cardsSnapshot = await cardsRef
        .where("recipient", "==", recipientRef)
        .where("category", "==", category)
        .where("postsDisabled", "==", false)
        .get();
      if (cardsSnapshot.docs.length) {
        const allCards = cardsSnapshot.docs;
        const cardsList = [];
        for (var i = 0; i < allCards.length; i++) {
          const obj = allCards[i];
          const objData = obj.data();
          const card = await new Card().parse(obj.id, objData);
          cardsList.push(card);
        }
        return cardsList;
      }
    } catch (err) {
      logError(err, "findCardFor:store");
      return null;
    }
    return null;
  }

  // fetchCards ... Returns cards array or null.
  async fetchCards(companyId, ownerEmail, cacheHook) {
    this._loadfetchCardCache(companyId, cacheHook);
    try {
      if (!ownerEmail || ownerEmail === undefined) {
        return null;
      }
      const ownerRef = this.getCompanyRef(companyId)
        .collection("users")
        .doc(ownerEmail);
      //mz-todo: Probably should add datetime check too, for snapshotReceived.
      const snapshotReceived = await this.getCompanyRef(companyId)
        .collection("cards")
        .where("postsDisabled", "==", true)
        .where("recipient", "==", ownerRef)
        .get();
      const snapshotOwner = await this.getCompanyRef(companyId)
        .collection("cards")
        .where("owner", "==", ownerRef)
        .get();
      const snapshotPostUser = await this.getCompanyRef(companyId)
        .collection("cards")
        .where("postUsers", "array-contains", ownerEmail)
        .get();
      const snapshotInvitedUser = await this.getCompanyRef(companyId)
        .collection("cards")
        .where("invited", "array-contains", ownerEmail)
        .get();
      const allCards = this.__unionById(this.__unionById(
          this.__unionById(snapshotOwner.docs, snapshotPostUser.docs),
          snapshotReceived.docs
        ), snapshotInvitedUser.docs);
      const cardsList = [];
      if (!allCards.length) {
        localStorage.removeItem(`cache_${companyId}_cards`);
        return cardsList;
      }
      const cache = [];
      if (allCards && allCards.length) {
        for (var i = 0; i < allCards.length; i++) {
          const obj = allCards[i];
          const objData = obj.data();
          const card = await new Card().parse(obj.id, objData);
          cache.push(card.getSerializable(ownerEmail));
          cardsList.push(card);
        }
      }
      localStorage.setItem(`cache_${companyId}_cards`, JSON.stringify(cache));
      return cardsList;
    } catch (err) {
      logError(err, "fetchCards:store");
      return null;
    }
  }

  //////////////////////////////////////////////
  // Posts Collection API Endpoints
  //////////////////////////////////////////////

  // findPost ... Returns post object or null.
  //              NOT BEING USED ANYWHERE YET.
  // async findPost(cardId, postId, companyId) {
  //   try {
  //     const postsRef = this.getCompanyRef(companyId).collection("cards").doc(cardId).collection("posts");
  //     const obj = await postsRef.doc(postId).get();
  //     if (obj.exists) {
  //       return new Post().parse(obj.id, obj.data());
  //     }
  //   }
  //   catch (err) {
  //     logError(err, "findPost:store");
  //     return null
  //   }
  //   return null;
  // }
  // fetchPosts ... Returns posts array or null.
  async fetchPosts(cardId, companyId) {
    try {
      const cardsRef = this.getCompanyRef(companyId).collection("cards");
      var snapshot = await cardsRef.doc(cardId).collection("posts").get();
      await this._verifyCardData(snapshot, companyId, cardId, cardsRef);
      return snapshot.docs.map((obj) => new Post().parse(obj.id, obj.data()));
    } catch (err) {
      logError(err, "fetchPosts");
      return null;
    }
  }
  // savePost ... Returns err or null.
  async savePost(post, cardId, companyId, ownerEmail) {
    try {
      const cardsRef = this.getCompanyRef(companyId).collection("cards");
      const cRef = cardsRef.doc(cardId);
      const postsRef = cRef.collection("posts");
      if (post.id) {
        await postsRef.doc(post.id).update(post.getDoc());
      } else {
        const ref = await postsRef.add(post.getDoc());
        post.id = ref.id;

        //mz-todo: Move this logic to the component to reduce document read,
        //         because the card has already been fetched in the component.
        const card = await this.findCard(cardId, companyId);
        card.postsCount = card.getPostsCount() + 1;
        cardsRef.doc(cardId).update({
          postsCount: card.getPostsCount(),
          postUsers: firebase.firestore.FieldValue.arrayUnion(ownerEmail),
        });
      }
      return null;
    } catch (err) {
      logError(err, "savePost:store", PRIORITY_HIGH, post);
      return err;
    }
  }
  // likePost ... Returns err or null.
  async likePost(post, cardId, email, companyId) {
    try {
      if (post.liked === null) {
        post.liked = [];
      }
      var cardsRef = this.getCompanyRef(companyId).collection("cards");
      const postsRef = cardsRef.doc(cardId).collection("posts");
      const ind = post.liked.indexOf(email);
      if (ind === -1) {
        await postsRef.doc(post.id).update({
          liked: firebase.firestore.FieldValue.arrayUnion(email),
        });
        post.liked.push(email);
      } else {
        await postsRef.doc(post.id).update({
          liked: firebase.firestore.FieldValue.arrayRemove(email),
        });
        post.liked.splice(ind, 1);
      }
      return null;
    } catch (err) {
      logError(err, "likePost:store", PRIORITY_HIGH, {
        email: email,
        post: post,
      });
      return err;
    }
  }

  async deleteCardAndPosts(cardId, companyId) {
    try {
      const batch = this.db.batch();
      const cardsRef = this.getCompanyRef(companyId).collection("cards");
      var snapshot = await cardsRef.doc(cardId).collection("posts").get();
      const max =
        snapshot.docs.length <= DELETE_BATCH_LIMIT
          ? snapshot.docs.length
          : DELETE_BATCH_LIMIT;
      for (var i = 0; i < max; i++) {
        batch.delete(snapshot.docs[i].ref);
      }
      await batch.commit();
      if (snapshot.docs.length > DELETE_BATCH_LIMIT) {
        const err = await this.deleteCardAndPosts(cardId, companyId);
        if (err) {
          throw err;
        }
      } else {
        const card = await cardsRef.doc(cardId);
        await card.delete();
        this._removeCardCache(cardId, companyId);
      }
      return null;
    } catch (err) {
      logError(err, "deleteCardAndPosts:store", PRIORITY_HIGH, {
        cardId: cardId,
        companyId: companyId,
      });
      return err;
    }
  }
  _addCardToCache(cardId, serCard, companyId) {
    const jsonData = localStorage.getItem(`cache_${companyId}_cards`);
    if (jsonData){
      const list = JSON.parse(jsonData);
      const index = list.findIndex((element) => element.id === cardId);
      if (index === -1) {
        list.push(serCard);
        localStorage.setItem(`cache_${companyId}_cards`, JSON.stringify(list));
      } else {
        list[index] = serCard;
        localStorage.setItem(`cache_${companyId}_cards`, JSON.stringify(list));
      }
    }
  }
  _removeCardCache(cardId, companyId) {
    try {
      const jsonData = localStorage.getItem(`cache_${companyId}_cards`);
      if (jsonData) {
        const list = JSON.parse(jsonData);
        const ind = list.findIndex((element) => element.id === cardId);
        if (ind > -1) {
          list.splice(ind, 1);
          localStorage.setItem(
            `cache_${companyId}_cards`,
            JSON.stringify(list)
          );
        }
      }
    } catch {}
  }

  // _loadfetchCardCache ... Returns cards list from localStorage
  _loadfetchCardCache(companyId, cacheHook) {
    if (!cacheHook) {
      return;
    }
    try {
      const jsonData = localStorage.getItem(`cache_${companyId}_cards`);
      if (jsonData && cacheHook) {
        const list = JSON.parse(jsonData);
        if (list && list.length) {
          for (var i = 0; i < list.length; i++) {
            list[i] = new Card().deserialize(list[i]);
          }
          cacheHook(list);
        }
      }
    } catch {}
  }

  // fetchLocations ... Returns locations array or null
  async fetchLocations(companyId) {
    try {
      var snapshot = await this.getCompanyRef(companyId)
        .collection("locations")
        .get();
      return snapshot.docs.map((obj) =>
        new Location().parse(obj.id, obj.data(), obj)
      );
    } catch (err) {
      logError(err, "fetchLocations:store");
      return null;
    }
  }
  // fetchCategories ... Returns categories array or null
  async fetchCategories() {
    try {
      var snapshot = await this.db.collection("categories").get();
      return snapshot.docs.map((obj) =>
        new Category().parse(obj.id, obj.data())
      );
    } catch (err) {
      logError(err, "fetchCategories:store");
      return null;
    }
  }

  //////////////////////////////////////////////
  // Company Collection API Endpoints
  //////////////////////////////////////////////

  // updateCompany ... Returns null or error
  async updateCompany(company, companyId) {
    try {
      const docRef = this.getCompanyRef(companyId);
      await docRef.update(company.getDoc());
      return null;
    } catch (err) {
      logError(err, "updateCompany:store", PRIORITY_HIGH, company);
      return err;
    }
  }
  // fetchCompany ... Returns Company object or null.
  async fetchCompany(companyId) {
    try {
      const compRef = await this.getCompanyRef(companyId).get();
      return new Company().parse(compRef.data());
    } catch (err) {
      logError(err, "fetchCompany:store", PRIORITY_HIGH, companyId);
      return null;
    }
  }
  // getCompanyRef ... Returns 'companies' collection ref or throws error.
  getCompanyRef(companyId) {
    if (!companyId) {
      throw ERR_INVALID_COMPANY_ID;
    }
    return this.db.collection("companies").doc(companyId);
  }

  //////////////////////////////////////////////
  // Stripe Related API Endpoints
  //////////////////////////////////////////////

  // godmode : remove later
  async godmodeExists() {
    const authUser = OC.getInstance().getCurrentAuthUser();
    if (authUser) {
      const res = await authUser.getIdTokenResult();
      return Boolean(res.claims?.godmode);
    }
    return false;
  }

  // godmode : remove later
  godmodeProduct(id) {
    switch (id) {
      case godmodeProduct1:
        return true;
      default:
        return false;
    }
  }

  async fetchProducts() {
    try {
      const godmode = await this.godmodeExists(); //godmode : remove later
      const querySnapshot = await this.db.collection('products')
        .where('active', '==', true)
        .get();
      const products = [];
      for (var i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        const priceSnap = await doc.ref
          .collection("prices")
          .where("active", "==", true)
          .limit(1)
          .get();
        if (priceSnap.docs.length) {
          if (!godmode && this.godmodeProduct(doc.id)) {
            continue; //godmode : remove later
          }
          products.push(new Product(doc.id, doc.data(), priceSnap.docs[0].id, priceSnap.docs[0].data()));
        }
      }
      return products;
    } catch (err) {
      logError(err, "fetchProducts:store", PRIORITY_HIGH);
      return null;
    } 
  }

  async fetchAuthStripeCustomer(uid) {
    try {
      const snapshot = await this.db
      .collection('customers')
      .doc(uid)
      .get();
      
      if (snapshot.exists) {
        return snapshot.data();
      }
      return null;
    } catch (err) {
      logError(err, "fetchAuthStripeCustomer:store", PRIORITY_HIGH);
      return null;
    }
  }

  async fetchCardsUsage(email, companyId) {
    try {
      const cardsUsageRef = this.getCompanyRef(companyId).collection("users").doc(email).collection("cardsUsage");
      var snapshot = await cardsUsageRef.get();
      const res = snapshot.docs.map((obj) => new CardUsage().parse(obj.data()));
      return res.sort((a, b) => {
        return moment(b.createdAt).diff(moment(a.createdAt))
      });
    } catch (err) {
      logError(err, "fetchCardsUsage", PRIORITY_HIGH);
      return null;
    }    
  }

  async startSubscription(priceId, isRecurring, successUrl, uid, email, callback) {
    try {
      const data = {
        price: priceId,
        success_url: successUrl || window.location.origin,
        cancel_url: window.location.origin,
      }
      if (!isRecurring) {
        data.mode = "payment";
      }
      const docRef = await this.db
      .collection('customers')
      .doc(uid)
      .collection("checkout_sessions")
      .add(data);
      this.unsubCheckoutListener && this.unsubCheckoutListener();
      this.checkoutTimeout && clearTimeout(this.checkoutTimeout);
      this.checkoutTimeout = null;

      this.unsubCheckoutListener = docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.log("oc_cout_err"); //mz-info: For fs log.
          logError(error, "startSubscription:onSnapshot:store", PRIORITY_CRITICAL, {
            priceId,
            uid,
            email,
          });
          this.unsubCheckoutListener && this.unsubCheckoutListener();
          this.checkoutTimeout && clearTimeout(this.checkoutTimeout);
          this.checkoutTimeout = null;
          callback && callback(null, error);
        } else if (url) {
          console.log("oc_cout_url"); //mz-info: For fs log.
          this.unsubCheckoutListener && this.unsubCheckoutListener();
          this.checkoutTimeout && clearTimeout(this.checkoutTimeout);
          this.checkoutTimeout = null;
          callback && callback(url, null);
        }
        console.log("oc_cout_idle"); //mz-info: For fs log.
      });

      this.checkoutTimeout = setTimeout(() => {
        console.log("oc_cout_to"); //mz-info: For fs log.
        logError(ERR_CHECKOUT_TIMOUT, "startSubscription:store", PRIORITY_CRITICAL, {
          priceId,
          uid,
          email,
        });
        this.unsubCheckoutListener && this.unsubCheckoutListener();
        this.checkoutTimeout = null;
        callback && callback(null, ERR_CHECKOUT_TIMOUT);
      }, 60000);
      
    } catch (err) {
      console.log("oc_cout_exp"); //mz-info: For fs log.
      logError(err, "startSubscription:store", PRIORITY_CRITICAL, {
        priceId,
        uid,
        email,
      });
      return err;
    }
  }

  attachStripeEventListener(uid, email, companyId, callback) {
    const subsRef = this.db
      .collection('customers')
      .doc(uid)
      .collection("subscriptions");

    const piRef = this.db
      .collection('customers')
      .doc(uid)
      .collection("payments");

    const userRef = this.getCompanyRef(companyId)
      .collection("users")
      .doc(email);

    const unsubSubs = subsRef.onSnapshot((snap) => {
      callback && callback(snap);
    });
    const unsubPIs = piRef.onSnapshot((snap) => {
      callback && callback(snap);
    });
    const unsubUsers = userRef.onSnapshot((snap) => {
      callback && callback(snap);
    });
    return () => {
      unsubSubs && unsubSubs();
      unsubPIs && unsubPIs();
      unsubUsers && unsubUsers();
    }
  }

  async fetchActiveSubscriptions(uid) {
    try {
      const snapshot = await this.db
        .collection("customers")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .get();
      const subscriptions = [];
      for (var i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        const refInvoices = doc.ref.collection("invoices");
        subscriptions.push(new StripeSubscription(doc.id, doc.data(), refInvoices));
      }
      return subscriptions;
    } catch (err) {
      logError(err, "fetchActiveSubscription:store", PRIORITY_HIGH, {
        uid,
      });
      return null;
    }
  }

  async fetchSucceededPayments(uid) {
    try {
      const snapshot = await this.db
        .collection("customers")
        .doc(uid)
        .collection("payments")
        .where("status", "==", "succeeded")
        .get();
      const payments = [];
      for (var i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        payments.push(new StripePaymentSingleCard(doc.id, doc.data()));
      }
      return payments;
    } catch (err) {
      logError(err, "fetchSucceededPayments:store", PRIORITY_HIGH, {
        uid,
      });
      return null;
    }
  }

  //////////////////////////////////////////////
  // Misc.
  //////////////////////////////////////////////

  __unionById(list1, list2) {
    const l1 = list1 && list1.length ? list1 : [];
    const l2 = list2 && list2.length ? list2 : [];

    return l2.concat(l1).filter(function (o) {
      return this.has(o.id) ? false : this.add(o.id);
    }, new Set());
  }

  // saveLog ...
  async saveLog(log) {
    if (!log || !log.getDoc) {
      return null;
    }
    if (await this.loggingSuppressed()) {
      console.log("oc_lgs"); //mz-info: For fs log.
      return;
    }
    try {
      const logsRef = this.db.collection("logs");
      await logsRef.doc(log.id).set(log.getDoc());
      return null;
    } catch {
      try {
        if (log.getDocWithSafeValues) {
          const logsRef = this.db.collection("logs");
          await logsRef.doc(log.id).set(log.getDocWithSafeValues());
        } else {
          console.log("logs api bypassed"); //warn: Remove after live alpha.
        }
      } catch {
        console.log("logs api bypassed"); //warn: Remove after live alpha.
        return null;
      }
    }
  }
  async loggingSuppressed() {
    try {
      if (window.OC_BYPASS_LG) {
        return true;
      }
      const dtString = window.localStorage.getItem("lastSaveLog");
      if (dtString) {
        const dt = moment(dtString);
        if (dt.isValid()) {
          const dtNow = moment();
          var duration = moment.duration(dtNow.diff(dt));
          var seconds = duration.asSeconds();
          if (seconds < 90) {
            if (window.OC_LG_COUNT > 0) {
              window.OC_LG_COUNT++;
              if (window.OC_LG_COUNT >= 8) {
                window.OC_BYPASS_LG = true;
                await this.logCriticalTooMany();
              }
            } else {
              window.OC_LG_COUNT = 1;
            }
            return false;
          } else {
            window.OC_LG_COUNT = 1;
            this.setLastSaveLog();
          }
        } else {
          this.setLastSaveLog();
        }
      } else {
        this.setLastSaveLog();
      }
      return false;
    } catch {
      console.log("oc_supp_catch"); //mz-info: For fs log.
      return true;
    }
  }
  async logCriticalTooMany() {
    try {
      const uuid = uuidv4();
      const timestamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      const id = timestamp + " (" + uuid.replace(/-/g, "") + ")";
      const logsRef = this.db.collection("logs");
      await logsRef.doc(id).set({
        priority: PRIORITY_CRITICAL,
        contextTag: "logCriticalTooMany",
        message: "client is generating too many logs",
        userAgent: navigator ? navigator.userAgent : null,
        url: window.location.href || "",
        created: timestamp,
      });
      return null;
    } catch {
      console.log("oc_c2m_catch"); //mz-info: For fs log.
    }
  }
  setLastSaveLog() {
    const dtString = moment.utc().format();
    window.localStorage.setItem("lastSaveLog", dtString);
  }
  async _verifyCardData(snapshot, companyId, cardId, cardsRef) {
    try {
      //mz-todo: Move this logic to the component to reduce document read,
      //         because the card has already been fetched in the component.
      if (!OC.getInstance().getCurrentAuthUser()) {
        return;
      }
      const postsCount = snapshot.docs.length || 0;
      var postUsers = [];
      for (var i = 0; i < snapshot.docs.length; i++) {
        const postOwner =
          snapshot.docs[i].data().ownerInfo &&
          snapshot.docs[i].data().ownerInfo.ref.id;
        postUsers.push(postOwner);
      }
      const card = await this.findCard(cardId, companyId);
      if (!card) {
        return;
      }
      const cardPostUsers = card.getPostUsers();
      const cardPostsCount = card.getPostsCount();
      var arrayMismatch = false;
      if (
        postUsers.length !== cardPostUsers.length ||
        cardPostsCount !== postsCount
      ) {
        arrayMismatch = true;
      } else {
        for (var j = 0; j < postUsers.length; j++) {
          if (!cardPostUsers.includes(postUsers[j])) {
            arrayMismatch = true;
          }
        }
      }
      if (arrayMismatch) {
        const logData = {
          cardId: cardId,
          postsCount: card.getPostsCount(),
          collectionSize: postsCount,
          oldPostUsers: card.getPostUsers(),
          newPostUsers: postUsers,
        };
        try {
          await cardsRef
            .doc(cardId)
            .update({ postsCount: postsCount, postUsers: postUsers });
        } catch(updateErr) {
          if (isPermissionDenied(updateErr)) {
            logError(
              ERR_POST_COUNT_CORRECTION_PERMISSION_FAILED,
              "_verifyCardData:store",
              PRIORITY_CRITICAL,
              logData
            );
            return;
          } else {
            throw updateErr;
          }
        }
        logError(
          ERR_POST_COUNT_NOT_MATCHED,
          "_verifyCardData:store",
          PRIORITY_NORMAL,
          logData
        );
      }
    } catch (err) {
      logError(err, "_verifyCardData:store", PRIORITY_CRITICAL);
      return err;
    }
  }
}
