export const ON_AUTH_CHANGED = "ON_AUTH_CHANGED";
export const ON_LOGIN_FAILED = "ON_LOGIN_FAILED";
export const ON_LOGIN_SUCCESS = "ON_LOGIN_SUCCESS";
export const ON_LOGOUT = "ON_LOGOUT";
export const ON_MAGIC_SIGNUP_SUCCESS = "ON_MAGIC_SIGNUP_SUCCESS";
export const ON_MAGIC_SIGNUP_FAILED = "ON_MAGIC_SIGNUP_FAILED";
export const RESET_AUTH_ERRORS = "RESET_AUTH_ERRORS";
export const ON_SIGNUP_COMPLETION_FAILED = "ON_SIGNUP_COMPLETION_FAILED";
export const ON_SIGNUP_COMPLETION_SUCCESS = "ON_SIGNUP_COMPLETION_SUCCESS";
export const FETCHING_USER_INFO = "FETCHING_USER_INFO";
export const ON_FETCHED_USER_INFO = "ON_FETCHED_USER_INFO";
export const ON_UPDATE_USER_INFO = "ON_UPDATE_USER_INFO";
export const ON_INCLUDE_USER = "ON_INCLUDE_USER";
export const ON_FETCH_AVATAR = "ON_FETCH_AVATAR";
export const SET_USER_DEFAULT_PLAN = "SET_USER_DEFAULT_PLAN";

export const ON_CARD_FETCHED = "ON_CARD_FETCHED";
export const ON_CARD_SAVE_FAILED = "ON_CARD_SAVE_FAILED";
export const ON_CARD_SAVED = "ON_CARD_SAVED";
export const ON_POSTS_FETCHED = "ON_POSTS_FETCHED";
export const ON_POST_SAVED = "ON_POST_SAVED";
export const ON_POST_SAVE_FAILED = "ON_POST_SAVE_FAILED";
export const ON_LOCATIONS_FETCHED = "ON_LOCATIONS_FETCHED";
export const CLEAR_DRAFT = "CLEAR_DRAFT";
export const ON_LOCATION_SAVED = "ON_LOCATION_SAVED";
export const ON_USERS_FETCHED = "ON_USERS_FETCHED";
export const ON_CATEGORIES_FETCHED = "ON_CATEGORIES_FETCHED";
export const ON_CARDS_FETCHED = "ON_CARDS_FETCHED";
export const ON_COMPANY_UPDATE = "ON_COMPANY_UPDATE";
export const ON_COMPANY_FETCHED = "ON_COMPANY_FETCHED";
export const ON_CARD_AND_POSTS_DELETED = "ON_CARD_AND_POSTS_DELETED";
export const ON_MAKE_ADMIN = "ON_MAKE_ADMIN";
export const ON_DELETE_USER = "ON_DELETE_USER";
export const ON_TOGGLE_REMINDERS = "ON_TOGGLE_REMINDERS";

export const ON_TOGGLE_PROFILE_MODAL = "ON_TOGGLE_PROFILE_MODAL";
export const ON_TOGGLE_BIRTHDAY_MODAL = "ON_TOGGLE_BIRTHDAY_MODAL";
export const ON_TOGGLE_INVITE_MODAL = "ON_TOGGLE_INVITE_MODAL";
export const ON_TOGGLE_SETTINGS_VIEW = "ON_TOGGLE_SETTINGS_VIEW";

export const ON_TOGGLE_PAYMENT_MODAL = "ON_TOGGLE_PAYMENT_MODAL";
export const ON_PRODUCTS_FETCHED = "ON_PRODUCTS_FETCHED";
export const ON_ACTIVE_SUBSCRIPTIONS_LOADED = "ON_ACTIVE_SUBSCRIPTIONS_LOADED";
export const ON_ACTIVE_PLAN_LOADED = "ON_ACTIVE_PLAN_LOADED";
export const ON_STRIPE_CUSTOMER_STATUS_FETCHED = "ON_STRIPE_CUSTOMER_STATUS_FETCHED";

//mz-todo: Remove later.
export const CLEAR_TEMPLATE_AND_INSTANCE = "CLEAR_TEMPLATE_AND_INSTANCE";
export const ON_TEMPLATES_FETCHED = "ON_TEMPLATES_FETCHED";
export const ON_CARD_TEMPLATE_SELECTED = "ON_CARD_TEMPLATE_SELECTED";
export const INIT_CARD_INSTANCE = "INIT_CARD_INSTANCE";

// ACTION ARGS:
export const ON_CLEAR_ACTION_ARGS = "ON_CLEAR_ACTION_ARGS";
export const ACTION_FOCUS_LOCATION_SELECT = "ACTION_FOCUS_LOCATION_SELECT";
