import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/card";
import "./styles/CardDraft.less";
import { Layout, Col, Row } from "antd";
import HeaderRecipientView from "../components/card/HeaderRecipientView";
import * as nav from "../routes/nav";
import CardUserView from "../components/card/CardUserView";
import PostView from "../components/PostView";
import Error404 from "../components/Error404";
import { _getSortedPosts } from "../shared/card_utils";
import ShareFooter from "../components/ShareFooter";
import FloatingFooter from "../components/FloatingFooter";
import MainFooter from "../components/MainFooter";

import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;

class CardView extends React.Component {
  componentWillUnmount() {
    this.props.actions.clearDraft();
  }
  componentDidMount() {
    const cardID = this.props.routeParams ? this.props.routeParams.id : null;
    if (!cardID) {
      setTimeout(() => {
        nav.goto(nav.URL_HOMEPAGE);
      });
    }
    const { draftCard } = this.props.card;
    if (!draftCard || draftCard.id !== cardID) {
      this.props.actions.fetchCard(cardID);
      this.props.actions.fetchPosts(
        cardID,
        this.props.metadata.usersList,
        () => {
          // this.detectAuthorPostOnce(); // mz-todo: Remove entirely if author post not needed in CardView.
        }
      );
    }
  }
  isLoading() {
    return (
      this.props.card.draftCard === null || this.props.metadata.company === null
    );
  }
  cardNotFound() {
    const { draftCard } = this.props.card;
    return draftCard && !draftCard.id;
  }
  renderLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  renderCardNotFound() {
    return <Error404 />;
  }
  // mz-todo: Remove entirely if author post not needed in CardView.
  // detectAuthorPostOnce() {
  //   if (this.state.authorPost) {
  //     return;
  //   }
  //   const { posts } = this.props.card;
  //   const { user } = this.props.metadata;
  //   if (!posts || !user) {
  //     return null;
  //   }
  //   const authorPost = posts.find(
  //     (element) => element.ownerInfo.ownerId === user.email
  //   );
  //   if (authorPost && authorPost !== undefined) {
  //     setTimeout(() => this.setState({ authorPost: authorPost }));
  //   }
  // }
  redirectToPost(email, cardId) {
    if (!cardId || !email || email.indexOf("@") === -1) {
      //mz-todo: add analysis log
      setTimeout(() => {
        nav.goto(nav.URL_HOMEPAGE);
      });
      return;
    }
    const domain = email.split("@").pop();
    if (domain) {
      const url = nav.URL_POST.replace(":id", cardId).replace(
        ":domain",
        domain
      );
      setTimeout(() => {
        nav.goto(url);
      });
    } else {
      //mz-todo: add analysis log
      setTimeout(() => {
        nav.goto(nav.URL_HOMEPAGE);
      });
    }
  }
  redirectForNonRecipient(card, user) {
    if (card && card.postsDisabled) {
      // Making the card public if the post is disabled.
      return false;
    }
    if (!card || !user || !user.email) {
      //mz-todo: add analysis log
      setTimeout(() => {
        nav.goto(nav.URL_HOMEPAGE);
      });
      return true;
    }
    const recEmail = card.getRecipientEmail && card.getRecipientEmail();
    if (!recEmail) {
      //mz-todo: add analysis log
      setTimeout(() => {
        nav.goto(nav.URL_HOMEPAGE);
      });
      return true;
    }
    if (recEmail !== user.email) {
      this.redirectToPost(user.email, card.id);
      return true;
    }
    if (!card.postsDisabled) {
      this.redirectToPost(user.email, card.id);
      return true;
    }
    return false;
  }
  hasWelcomeParam() {
    const welcomeParam = this.props.location?.query?.welcome;
    return welcomeParam ? true : false;
  }
  render() {
    const { draftCard, posts } = this.props.card;
    const { company, user } = this.props.metadata;
    if (this.isLoading()) {
      return this.renderLoader();
    }
    if (this.cardNotFound()) {
      return this.renderCardNotFound();
    }
    if (this.redirectForNonRecipient(draftCard, user)) {
      return this.renderLoader();
    }
    const sortedPosts = _getSortedPosts(posts);
    return (
      <div className="CardDraft">
        <Layout>
          <HeaderRecipientView cardData={draftCard} />
          <Layout>
            <Content>
              <CardUserView company={company} draftCard={draftCard} />
              <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={16} className="posts">
                  {sortedPosts &&
                    sortedPosts.map((item) => {
                      return (
                        <PostView
                          userList={this.props.metadata.usersList}
                          currentUser={this.props.metadata.user}
                          likeUnlike={this.props.actions.likePost}
                          cardId={draftCard.id}
                          key={`postview_view${
                            item.id ||
                            (item.ownerInfo && item.ownerInfo.ownerId) ||
                            Math.random()
                          }`}
                          data={item}
                          isAuthorPost={false}
                        />
                      );
                    })}
                  {!user && this.hasWelcomeParam() ? (
                    <FloatingFooter />
                  ) : null}
                  {draftCard.postsDisabled ? (
                    <ShareFooter />
                  ) : null}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { card, metadata } = state;
  return { card, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardView);
