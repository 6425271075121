import * as types from '../constants';

const initialState={
  currentUser: null,
  loginError: "",
  sendMagicLinkError: "",
  signupCompletionError: "",
  showSettingsView: false,
}

export function auth(state = initialState, action) {
  switch (action.type) {
    case types.ON_TOGGLE_SETTINGS_VIEW:
      return {
        ...state,
        showSettingsView: action.visible,
      };    
    case types.ON_AUTH_CHANGED:
      return {...state, currentUser: action.user}
    case types.ON_LOGIN_SUCCESS:
      return {...state, loginError: ""}
    case types.ON_LOGIN_FAILED:
      return {...state, loginError: action.msg}
    case types.ON_LOGOUT:
      return {...state, 
        currentUser: null, 
        loginError: null,
        sendMagicLinkError: null,
        signupCompletionError: null,
      }
    case types.ON_MAGIC_SIGNUP_SUCCESS:
      return {...state, sendMagicLinkError: ""}
    case types.ON_MAGIC_SIGNUP_FAILED:
      return {...state, sendMagicLinkError: action.msg}
    case types.RESET_AUTH_ERRORS:
      return {...state, loginError: "", sendMagicLinkError: "", signupCompletionError: ""}
    case types.ON_SIGNUP_COMPLETION_SUCCESS:
      return {...state, signupCompletionError: ""}
    case types.ON_SIGNUP_COMPLETION_FAILED:
      return {...state, signupCompletionError: action.msg}
    default:
      return state;
  }
}