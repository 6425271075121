import {
  categories,
} from "../../shared/constants";

export default class CardUsage {
  category = null;
  categoryData = {};
  createdAt = null;
  recipientName = null;

  parse(data) {
    this.category = data.category;
    this.categoryData = data.categoryData || {};
    this.createdAt = data.createdAt;
    this.recipientName = data.recipientName;
    return this;
  }
  getCategoryName() {
    const data = categories.find(item => item.key === this.category);
    return data?.name || "";
  }
  getRecipientName() {
    return this.recipientName ? this.recipientName : this.categoryData?.name || "";
  }
}
