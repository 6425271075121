import React from "react";
import "../containers/styles/CardDraft.less";
import "../containers/styles/Themes.less";
import EmojiHandler from "./EmojiHandler";
import { Button, Tooltip, Anchor, Avatar } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { themes } from "../shared/constants";
import UserAvatar from "./custom/UserAvatar";
import * as types from "../constants";
import { gf } from "../shared/util";
import { Gif } from "@giphy/react-components";
import Fade from "react-reveal/Fade";

const { Link } = Anchor;
const MAX_LIKED_USER_NAMES = 8;

class PostView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarDownloadURL: null,
      busyLikeUnlike: false,
      paletteIndexClass: "",
      gifyData: null,
      isLoadingGify: false,
      fade: false,
    };
    this.onPostLiked = this.onPostLiked.bind(this);
    this.onModalActionCall = this.onModalActionCall.bind(this);
    this.onAddLocationClicked = this.onAddLocationClicked.bind(this);
  }
  setThemeItems(themeId) {
    if (!themeId) {
      return;
    }
    var item = themes.find((element) => element.id === themeId);
    if (item) {
      this.setState({
        paletteIndexClass: item.className,
      });
    }
  }
  onAddLocationClicked() {
    const { onOpenUserModal } = this.props;
    onOpenUserModal &&
      onOpenUserModal(true, [types.ACTION_FOCUS_LOCATION_SELECT]);
  }
  onModalActionCall() {
    const { onOpenUserModal } = this.props;
    onOpenUserModal && onOpenUserModal(true);
  }
  onPostLiked() {
    const { busyLikeUnlike } = this.state;
    const { likeUnlike, cardId, data } = this.props;
    if (busyLikeUnlike) {
      return;
    }
    if (data && likeUnlike && cardId) {
      this.setState({ busyLikeUnlike: true });
      likeUnlike(data, cardId, () => {
        setTimeout(() => {
          this.setState({ busyLikeUnlike: false });
        });
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    setTimeout(() => this.setState({ fade: true }));
    const { data } = this.props;
    if (data) {
      if (data.gify) {
        this.setState({ isLoadingGify: true });
        gf.gif(data.gify).then((res) => {
          this._isMounted &&
            this.setState({ gifyData: res.data, isLoadingGify: false });
        });
      }
      this.setThemeItems(data.themeId);
      if (data.getAvatar) {
        const url = data.getAvatar();
        if (url) {
          this.setState({ avatarDownloadURL: url });
        } else {
          data.fetchAvatar &&
            data.fetchAvatar((url) => {
              this._isMounted && this.setState({ avatarDownloadURL: url });
            });
        }
      }
    }
  }
  getTooltipNames(emails) {
    const { userList } = this.props;
    if (!userList || !userList.length || !emails || !emails.length) {
      return null;
    }
    const names = [];
    for (let email of emails) {
      if (names.length >= MAX_LIKED_USER_NAMES) {
        names.push("...");
        break;
      }
      for (let user of userList) {
        if (email === user.email) {
          names.push(user.name);
          break;
        }
      }
    }
    if (!names.length) {
      return 0;
    }
    return names.map((item, i) => {
      return (
        <span key={i}>
          {item}
          {i === names.length - 1 ? null : ","}
          <br />
        </span>
      );
    });
  }
  renderLikeControls(userLikes, currentUser) {
    return (
      <Tooltip
        overlayClassName="tooltip-likes"
        title={this.getTooltipNames(userLikes)}
      >
        <Button
          type="link"
          style={!currentUser ? { cursor: "auto" } : null}
          onClick={currentUser ? this.onPostLiked : null}
        >
          {!currentUser ||
          (currentUser && userLikes.indexOf(currentUser.email) === -1) ? (
            <i className="fal fa-thumbs-up"></i>
          ) : (
            <i className="fas fa-thumbs-up"></i>
          )}
          Like{" "}
          <span className="count">
            {userLikes && userLikes.length ? userLikes.length : null}
          </span>
        </Button>
      </Tooltip>
    );
  }
  renderAuthorAvatar(data, currentUser) {
    const url =
      currentUser && currentUser.avatarDownloadURL
        ? currentUser.avatarDownloadURL
        : this.state.avatarDownloadURL;
    return url ? (
      <UserAvatar
        key={`postview_avatar_${data.ownerInfo && data.ownerInfo.ownerId}`}
        size={30}
        downloadURL={url}
        name={data.ownerInfo && data.ownerInfo.name}
      />
    ) : (
      <Avatar
        className="add-profilepic"
        size={30}
        icon={<i className="fal fa-camera"></i>}
      />
    );
  }
  renderGifyLoader() {
    return (
      <h3 className="loader">
        <LoadingOutlined />
      </h3>
    );
  }
  getGify() {
    const { gifyData, isLoadingGify } = this.state;
    if (isLoadingGify) {
      return <div className="giphy-image">{this.renderGifyLoader()}</div>;
    }
    if (!gifyData) {
      return null;
    }
    return (
      <div className="giphy-image">
        <Gif gif={gifyData} height={"auto"} width={"auto"} />
      </div>
    );
  }
  render() {
    const {
      data,
      currentUser,
      isAuthorPost,
      isEditable,
      showAnchor,
      onEditAuthorPost,
      myPostExists,
    } = this.props;
    if (!data) {
      return;
    }
    return (
      <>
        <Fade when={this.state.fade}>
          <div
            className={`single-post ${this.state.paletteIndexClass}`}
            id={isAuthorPost ? "mp" : null}
          >
            {/* <div className="header">
              <div className="info">
                <div
                  style={isAuthorPost ? { cursor: "pointer" } : null}
                  onClick={isAuthorPost ? this.onModalActionCall : null}
                >
                  {isAuthorPost ? (
                    this.renderAuthorAvatar(data, currentUser)
                  ) : (
                    <UserAvatar
                      key={`postview_avatar_${
                        data.ownerInfo && data.ownerInfo.ownerId
                      }`}
                      size="large"
                      downloadURL={this.state.avatarDownloadURL}
                      name={data.ownerInfo && data.ownerInfo.name}
                    />
                  )}
                </div>
                <div className="name">
                  <span>{data.ownerInfo && data.ownerInfo.name}</span>
                  <span
                    className="location"
                    style={isAuthorPost ? { cursor: "pointer" } : null}
                    onClick={isAuthorPost ? this.onAddLocationClicked : null}
                  >
                    {data.ownerInfo && data.ownerInfo.location ? (
                      data.ownerInfo.location
                    ) : isAuthorPost ? (
                      <span className="add-location">Add location</span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div> */}
            <div className="artwork">
              <EmojiHandler>
                <div className="emoji-text">
                  {data.message}

                  <div className="user-info">
                    <Tooltip
                      title="Update Profile"
                      trigger={isAuthorPost ? "hover" : ""}
                    >
                      <span
                        className="avatar"
                        style={isAuthorPost ? { cursor: "pointer" } : null}
                        onClick={isAuthorPost ? this.onModalActionCall : null}
                      >
                        {isAuthorPost ? (
                          this.renderAuthorAvatar(data, currentUser)
                        ) : this.state.avatarDownloadURL ? (
                          <UserAvatar
                            key={`postview_avatar_${
                              data.ownerInfo && data.ownerInfo.ownerId
                            }`}
                            size={30}
                            downloadURL={this.state.avatarDownloadURL}
                            name={data.ownerInfo && data.ownerInfo.name}
                          />
                        ) : null}
                      </span>
                      <span
                        style={isAuthorPost ? { cursor: "pointer" } : null}
                        onClick={isAuthorPost ? this.onModalActionCall : null}
                        className="name"
                      >
                        {data.ownerInfo && data.ownerInfo.name
                          ? data.ownerInfo.name
                          : "Your Full Name"}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </EmojiHandler>
              {this.getGify()}
            </div>

            {isEditable ? (
              <span className="edit-btn">
                <Button type="link" onClick={onEditAuthorPost}>
                  <i className="fal fa-pen"></i> Edit{" "}
                </Button>
              </span>
            ) : null}
            {/* <span className="edit-btn">
              {!isEditable ? (
                this.renderLikeControls(data.liked || [], currentUser)
              ) : (
                <Button type="link" onClick={onEditAuthorPost}>
                  <i className="fal fa-pen"></i> Edit{" "}
                </Button>
              )}
            </span> */}
            {/* <div className="footer">
              {!isEditable ? (
                this.renderLikeControls(data.liked || [], currentUser)
              ) : (
                <Button type="link" onClick={onEditAuthorPost}>
                  <i className="fal fa-pen"></i> Edit{" "}
                </Button>
              )}
            </div> */}
          </div>
        </Fade>
        {showAnchor && (
          <Anchor
            onClick={() => {
              this.props.onAnchorClick && this.props.onAnchorClick();
            }}
            className={`post-anchor ${
              myPostExists ? "my-post" : "post-something"
            }`}
          >
            <Link
              href="#mp"
              title={myPostExists ? "My Post" : "Post Something"}
            />
          </Anchor>
        )}
      </>
    );
  }
}

export default PostView;
