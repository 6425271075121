import React from "react";
import { Modal, Input, Button, Form } from "antd";
import deleteArtwork from "./img/artwork-delete.png";
import { OC_PROD_TITLE, OC_CARD_PLURAL } from "../shared/constants";
const CONFIRMATION_TEXT = "DELETE";

class DeleteUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmationText: "",
    };
    this.onClose = this.onClose.bind(this);
    this.afterClose = this.afterClose.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }
  onClose() {
    this.setState({ visible: false, confirmationText: "" });
  }
  componentDidUpdate(prevProps) {
    if (this.props.user && !this.state.visible) {
      if (prevProps.user && prevProps.user.email === this.props.user.email) {
        return;
      }
      this.setState({ visible: true });
    } else if (!this.props.user && this.state.visible) {
      this.setState({ visible: false, confirmationText: "" });
    }
  }
  onDeleteClick() {
    const { user, deleteUser, onClose } = this.props;
    if (this.state.confirmationText !== CONFIRMATION_TEXT || !user) {
      return;
    }
    if (this.state.busy) {
      return;
    }
    this.setState({ busy: true });
    deleteUser &&
      deleteUser(user.email, () => {
        this.setState({ busy: false });
        onClose && onClose();
      });
  }
  afterClose() {
    this.props.onClose && this.props.onClose();
  }
  renderModalContent() {
    const { user } = this.props;
    if (!user) {
      return null;
    }
    return (
      <div className="DeleteCardModal">
        <div className="title">
          <div className="artwork">
            <img src={deleteArtwork} alt={OC_PROD_TITLE} />
          </div>
          <h2>Confirm Deletion</h2>
          <p>
            Once deleted, this account for <b>{user.name}</b> will be discarded
            and no longer retrievable. {OC_CARD_PLURAL} they created or posted
            on will not be affected. To confirm this action, please type in
            "DELETE" below.
          </p>
        </div>

        <Form.Item>
          <h4>Enter “DELETE” to confirm deletion</h4>
          <Input
            placeholder="Verification Text"
            value={this.state.confirmationText}
            onChange={(e) =>
              this.setState({ confirmationText: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={this.state.busy}
            disabled={this.state.confirmationText !== CONFIRMATION_TEXT}
            onClick={this.onDeleteClick}
            block
            type="danger"
            size="large"
          >
            Delete
          </Button>
        </Form.Item>
      </div>
    );
  }
  render() {
    return (
      <Modal
        width={475}
        afterClose={this.afterClose}
        visible={this.state.visible}
        footer={null}
        onCancel={this.onClose}
        centered
        closeIcon={<i className="fal fa-times"></i>}
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

export default DeleteUserModal;
