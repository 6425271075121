import React from "react";
import "./styles/VerifyEmail.less";
import { Form, Button, Checkbox } from "antd";
import delivery from "./img/artwork-delivery.png";
import { OC_PROD_TITLE, OC_CARD_SINGULAR } from "../shared/constants";

class PrepareDelivery extends React.Component {
  constructor(props) {
    super(props);
    const { card } = this.props; //mz-todo: postMVP: Enable after supporting email derlivery
    this.state = {
      checkboxChecked: card && card.isEmailLessCard() ? false : true, //mz-todo: postMVP: Enable after supporting email derlivery
    };
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
  }
  onCheckboxChange(e) {
    this.setState({ checkboxChecked: e.target.checked });
  }
  onClickConfirm() {
    const {
      onConfirmAndDeliver,
      onConfirmAndCopyLink,
      disablePosts,
    } = this.props;
    if (!onConfirmAndDeliver || !onConfirmAndCopyLink || !disablePosts) {
      return;
    }
    this.state.checkboxChecked ? onConfirmAndDeliver() : onConfirmAndCopyLink();
    disablePosts(this.state.checkboxChecked);
  }
  render() {
    const { card } = this.props;
    if (
      !card ||
      !card.passesEmailRequirements() ||
      !card.passesNameRequirements()
    ) {
      return null;
    }
    const { recipient } = card; //mz-todo: postMVP: Enable after supporting email derlivery
    return (
      <div className="VerifyEmail Delivery">
        <div className="title">
          <div className="artwork">
            <img src={delivery} alt={OC_PROD_TITLE} />
          </div>
          <h2>Close postings and prepare for delivery</h2>
          <p>
            Once confirmed,{" "}
            {!card.isGroupType() ? (
              <span style={{ fontWeight: "600" }}>
                {card.getRecipientName()}
              </span>
            ) : (
                "everyone"
              )}{" "}
            will be able to see this {OC_CARD_SINGULAR} and visitors won’t be able to add
            new posts. This is irreversable.
          </p>
        </div>

        {/* mz-todo: postMVP: Enable after supporting email derlivery */}
        {!card.isEmailLessCard() && (
          <p className="checkbox-container">
            <span>
              <Checkbox
                onChange={this.onCheckboxChange}
                checked={this.state.checkboxChecked}
              ></Checkbox>{" "}
            </span>

            <span>
              Deliver a sign-in link to{" "}
              <span style={{ fontWeight: "600" }}>
                {recipient && recipient.email}
              </span>{" "}
              <br />
              (Recommended)
            </span>
          </p>
        )}

        <Form.Item>
          <Button
            type="danger"
            size="large"
            block
            htmlType="submit"
            onClick={this.onClickConfirm}
          >
            {this.state.checkboxChecked
              ? "Confirm & Deliver"
              : "Confirm & Copy Link"}
          </Button>
        </Form.Item>
      </div>
    );
  }
}

export default PrepareDelivery;
