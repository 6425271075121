import React from "react";
import "./styles/VerifyEmail.less";
import { Form, Button } from "antd";
import sent from "./img/artwork-sent.png";
import { OC_PROD_TITLE, OC_CARD_SINGULAR } from "../shared/constants";
import { copyLink } from "../shared/util";

class SentDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.onClickCopyLink = this.onClickCopyLink.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
  }
  onClickCopyLink() {
    copyLink("SentDelivery:onClickCopyLink");
  }
  onClickDone() {
    const { onDismiss } = this.props;
    onDismiss && onDismiss();
  }
  render() {
    const { card } = this.props;
    if (
      !card ||
      !card.passesEmailRequirements() ||
      !card.passesNameRequirements()
    ) {
      return null;
    }
    const { recipient } = card;
    var title = "Ready for delivery";
    var message = card.isGroupType() ? (
      <p>
        This {OC_CARD_SINGULAR} is now ready to be sent. A link to it has been copied
        to your clipboard.
      </p>
    ) : (
        <p>
          This {OC_CARD_SINGULAR} is now ready to be sent to{" "}
          <span style={{ fontWeight: "600" }}>{card.getRecipientName()}</span>. A
          link to it has been copied to your clipboard.
      </p>
      );
    if (this.props.sent) {
      title = `${OC_CARD_SINGULAR} Sent`;
      message = (
        <p>
          We’ve sent the {OC_CARD_SINGULAR} to {recipient && recipient.email}. If you
          like, you can always{" "}
          <Button
            onClick={this.onClickCopyLink}
            type="link"
            style={{ padding: 0 }}
          >
            copy the link
          </Button>{" "}
          and deliver it to{" "}
          <span style={{ fontWeight: "600" }}>{card.getRecipientName()}</span>{" "}
          yourself as well.
        </p>
      );
    }

    return (
      <div className="VerifyEmail Delivery">
        <div className="title">
          <div className="artwork">
            <img src={sent} alt={OC_PROD_TITLE} />
          </div>
          <h2>{title}</h2>
          {message}
        </div>

        {!this.props.sent && (
          <Form.Item className="copy-link">
            <Button
              type="link"
              size="large"
              block
              htmlType="submit"
              onClick={this.onClickCopyLink}
            >
              <i className="fal fa-link"></i>&nbsp;Copy link again
            </Button>
          </Form.Item>
        )}

        <Form.Item className="btn-done">
          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            onClick={this.onClickDone}
          >
            Done
          </Button>
        </Form.Item>
      </div>
    );
  }
}

export default SentDelivery;
