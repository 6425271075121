import moment from "moment";

export class Product {
  id = null;
  name = null;
  description = null;
  price = null;
  metadata = null;
  constructor(productId, product, priceId, price) {
    this.id = productId || null;
    this.name = product.name || null;
    this.description = product.description || null;
    this.metadata = product.metadata || null;
    this.price = price || null;
    if (this.price) {
      this.price.id = priceId || null;
    }
    return this;
  }
  getTotalCards() {
    const val = this.metadata?.totalCards || 0;
    return parseInt(val, 10);
  }
  getPriceId() {
    return this.price?.id || null;
  }
  getProductPrice() {
    if (!this.price || !this.price.unit_amount) {
      return null;
    }
    return this.price.unit_amount / 100;
  }
  getSingleCardPrice() {
    const totalCards = this.getTotalCards();
    if (totalCards) {
      const totalPrice = this.getProductPrice();
      if (totalPrice) {
        return totalPrice / totalCards;
      }
    }
    return 0;
  }
  getSingleCardPriceTag() {
    const p = this.getSingleCardPrice();
    if (p) {
      return `$${p}`;
    }
    return "";
  }
  getProductPriceTag() {
    const p = this.getProductPrice();
    if (p === null) {
      return "";
    }
    return `$${p}`;
  }
}

export class StripeSubscription {
  id = null;
  data = null;
  refInvoices = null;
  _product = null;
  constructor(id, data, refInvoices) {
    this.id = id || null;
    this.data = data || null;
    this.refInvoices = refInvoices || null;
    return this;
  }
  hasExpiry() {
    return this.data.current_period_end ? true : false;
  }
  getExpiryValueOf() {
    return this.data.current_period_end
      ? this.data.current_period_end.valueOf()
      : null;
  }
  getExpiryDate() {
    return this.data.current_period_end
      ? this.data.current_period_end.toDate()
      : null;
  }
  isRecurring() {
    try {
      const recurring = this.data.items[0].price.recurring;
      return recurring && recurring.interval ? true : false;
    } catch {
      return false;
    }
  }
  willRenewAfterExpiry() {
    return (
      this.hasExpiry() && !this.data.cancel_at_period_end && this.isRecurring()
    );
  }
  getCreatedValueOf() {
    return this.data.created.valueOf();
  }
  getProductId() {
    return this.data.product.id;
  }
  async getProductName() {
    if (!this._product) {
      this._product = await this.data.product.get();
    }
    return this._product.data().name || null;
  }
  async getAvailableCardsAsync() {
    const consumed = this.getConsumedCardsCount();
    const total = await this.getTotalCardsAsync();
    return total - consumed;
  }
  getConsumedCardsCount() {
    if (this.data.metadata && this.data.metadata.consumedCards) {
      return parseInt(this.data.metadata.consumedCards, 10);
    }
    return 0;
  }
  async getTotalCardsAsync() {
    if (!this._product) {
      this._product = await this.data.product.get();
    }
    const val = this._product.data().metadata.totalCards || 0;
    return parseInt(val, 10);
  }
}

export class StripePaymentSingleCard {
  id = null;
  data = null;
  constructor(id, data) {
    this.id = id || null;
    this.data = data || null;
    return this;
  }
  getCreatedValueOf() {
    return this.data?.created || null;
  }
  getCreatedMoment() {
    if (this.data?.created) {
      const m = moment.unix(this.data.created);
      return m.isValid() ? m : null;
    }
    return null;
  }
  isConsumed() {
    return this.data?.metadata?.isConsumed ? true : false;
  }
}

export class Plan {
  type = null; // Active subscription type.
  name = null; // Active subscription name to display on screen.
  expiry = null; // Use getExpiryMoment() to get the value.
  remainingCards = null; // Remaining cards in all active subscriptions.
  totalCards = null; // Total cards in all active subscriptions.
  redeemableDocId = null; // For active Subscription or PaymentIntent.
  renewingSubId = null; // This might not be the active subscription.
  renewingCardsCount = null; // Total cards from renewing subscription.

  hasExpiry() {
    return this.expiry ? true : false;
  }
  getExpiryMoment() {
    if (!this.expiry) {
      return null;
    }
    try {
      const m = moment(this.expiry.toISOString());
      return m.isValid() ? m : null;
    } catch {
      return null;
    }
  }
  remainingDaysTillExpiry() {
    const m = this.getExpiryMoment();
    if (!m) {
      return null;
    }
    return m.diff(moment.now(), "days");
  }
  isRunningLow() {
    try {
      if (this.remainingCards <= 1) {
        return true;
      } else if (this.remainingCards / this.totalCards < 0.1) {
        return true;
      }
      return false;
    } catch {
      // todo: Probabaly create a log (or external log) if this.totalCards === 0
      // and division by zero error occurred. Log enough data for debugging.
      return false;
    }
  }
}
