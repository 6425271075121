import React from "react";
import { Button } from "antd";
import * as nav from "../routes/nav";
import "./styles/VerifyEmail.less";
import logo from "./img/logo-greeted.svg";
import artwork404 from "./img/artwork-404.png";
import { OC_PROD_TITLE } from "../shared/constants";

class Error404 extends React.Component {
  onLogoClick() {
    nav.gotoMain();
  }
  render() {
    return (
      <div className="VerifyEmail Error">
        <div className="title">
          <div className="brand-logo">
            <span className="product">
              <Button type="link" onClick={this.onLogoClick}>
                <img src={logo} alt={OC_PROD_TITLE} />
              </Button>
            </span>
          </div>
          <div className="artwork">
            <img src={artwork404} alt={OC_PROD_TITLE} />
          </div>
          <h1>404</h1>
          <p className="text-center">
            We can’t seem to find the page you’re looking for.
          </p>
        </div>
      </div>
    );
  }
}

export default Error404;
