import React from "react";
import { Select, Input } from "antd";
import "../styles/Modal.less";
import { isNonNegativeInteger } from "../../shared/util";

const { Option } = Select;

class EmailSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      savingUser: false,
      emailError: null,
    };
    this.onUserChanged = this.onUserChanged.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.onEmailFocus = this.onEmailFocus.bind(this);
  }
  getUserByEmail(email) {
    if (!email) {
      return null;
    }
    const usersList = this.getUsersList();
    if (!usersList) {
      return null;
    }
    var selectedUser = usersList.find(
      (element) => element.email && element.email.toLowerCase() === email
    );
    return selectedUser !== undefined ? selectedUser : null;
  }
  checkUserByName(name) {
    if (!name) {
      return false;
    }
    const { usersList } = this.props;
    if (!usersList) {
      return null;
    }
    var selectedUser = usersList.find(
      (element) =>
        element.name && element.name.toLowerCase() === name.toLowerCase()
    );
    return selectedUser ? true : false;
  }
  onUserChanged(email) {
    const { onUserSelect } = this.props;
    const u = this.getUserByEmail(email);
    this.setState({ selectedUser: u });
    onUserSelect && onUserSelect(u);
  }
  filterUsers(input, option) {
    if (this.state.savingUser) {
      return false;
    }
    return isNonNegativeInteger(option.children.toLowerCase().indexOf(input.toLowerCase()));
  }
  getUsersList() {
    const { usersList, newUser } = this.props;
    if (!usersList) {
      return [];
    }
    if (!newUser) {
      return usersList;
    }
    const newList = [];
    newUser.email !== "?" && newList.push(newUser);
    usersList.forEach((element) => {
      newList.push(element);
    });
    return newList;
  }
  isValidEmail(email, domain) {
    if (!domain || !email || email.indexOf("@") === -1) {
      return false;
    }
    return email.split("@").pop() === domain;
  }
  emailAlreadyExists(email) {
    const { usersList } = this.props;
    if (!usersList) {
      return false;
    }
    for (var i = 0; i < usersList.length; i++) {
      if (usersList[i].email === email) {
        return true;
      }
    }
    return false;
  }
  onEmailFocus() {
    const { resetEmailError } = this.props;
    this.setState({ emailError: null });
    resetEmailError && resetEmailError();
  }
  onEmailBlur(e) {
    const { newUser, company, emailValidated, onAddNewUser, user} = this.props;
    const {email} = user;
    if (!newUser) {
      return null;
    }
    if (e.target.value === email) {
      const error = (
        <span className="error-state">
          You can’t use your own email address as a recipient.
        </span>
      );
      this.setState({ emailError: error });
      emailValidated && emailValidated(false);
      return;
    }
    const newUserEmail = e.target.value ? e.target.value.toLowerCase() : "";
    if (!this.isValidEmail(newUserEmail, company.domain)) {
      const domain =
        company && company.domain ? company.domain : "valid domain names";
      const error = (
        <span className="error-state">
          Only email addresses ending in <strong>{domain}</strong> are
          permitted.
        </span>
      );
      this.setState({ emailError: error });
      emailValidated && emailValidated(false);
      return;
    }
    if (this.emailAlreadyExists(newUserEmail)) {
      onAddNewUser(null);
      this.onUserChanged(newUserEmail);
      emailValidated && emailValidated(true);
      return;
    }
    newUser.email = newUserEmail;
    onAddNewUser && onAddNewUser(newUser);
    emailValidated && emailValidated(true);
  }
  renderEmailSelect() {
    const { recipient } = this.props;
    const aggUsersList = this.getUsersList();
    return (
      <Select
        notFoundContent={null}
        size="large"
        showSearch
        placeholder="Michael.Scott@DunderMifflin.com"
        optionFilterProp="children"
        className="remail"
        dropdownClassName="remail"
        suffixIcon={<i className="fal fa-envelope-open"></i>}
        filterOption={this.filterUsers}
        onChange={this.onUserChanged}
        value={(recipient && recipient.email) || undefined}
      >
        {aggUsersList.map((item, i) => {
          if (!recipient) {
            return (
              <Option key={i} value={item.email}>
                {item.email}
              </Option>
            );
          }
          return recipient.name === item.name ? (
            <Option key={i} value={item.email}>
              {item.email}
            </Option>
          ) : null;
        })}
      </Select>
    );
  }
  renderEmailInput() {
    const { newUser, company, isPaymentCallback } = this.props;
    const { emailError } = this.state;
    const domain = company?.domain || "";
    if (!newUser) {
      return null;
    }
    return (
      <div>
        <Input
          defaultValue={
            isPaymentCallback && newUser && newUser.isDraft
              ? this.isValidEmail(newUser.email, domain)
                ? newUser.email
                : undefined
              : undefined
          }
          placeholder="Michael.Scott@DunderMifflin.com"
          style={emailError && { borderColor: "#f5222d", borderWidth: 1 }}
          onFocus={this.onEmailFocus}
          onBlur={this.onEmailBlur}
          prefix={<i className="fal fa-envelope-open"></i>}
        />
        {emailError && <span>{emailError}</span>}
      </div>
    );
  }
  render() {
    const { newUser } = this.props;
    return !newUser ? this.renderEmailSelect() : this.renderEmailInput();
  }
}

export default EmailSelect;
