import moment from "moment-timezone";

export function getSecondsTill(dateStringISO8601) {
  const dt = moment(dateStringISO8601);
  const localDt = dt.local();
  return localDt.unix();
}

export function getTimezoneOffsetInSeconds() {
  try {
    const dtOffset = new Date().getTimezoneOffset();
    return dtOffset * 60;
  } catch (err) {
    _logError();
  }
}

export function getCurrentTimezoneAbbr() {
  var abbr = "";
  try {
    abbr = moment.tz(moment.tz.guess()).format('z');
    if (abbr) {
      return abbr;
    }
  } catch (err) {
    _logError(err, abbr, "method: moment.tz.guess()");
  }
  return moment().format("ZZ");
}

export function getCurrentTimezoneName() {
  const zoneName = getZoneNameLong();
  if (zoneName) {
    return zoneName;
  }
  return getZoneNameIANA();
}

export function getZoneNameLong() {
  var zoneName = "";
  try {
    zoneName = new Date()
      .toLocaleString("en-US", {
        hour12: false,
        hour: "2-digit",
        timeZoneName: "long",
      })
      .replace(/^\d\d /, "");
    if (!zoneName || _hasNumber(zoneName)) {
      throw new Error("oc_error: invalid zone name extracted");
    }
  } catch (err) {
    _logError(err, zoneName, "func: getZoneNameLong");
    return "";
  }
  return zoneName;
}

export function getZoneNameIANA() {
  var zoneName = "";
  try {
    zoneName = moment.tz.guess();
    if (!zoneName) {
      throw new Error("oc_error: invalid zone name extracted");
    }
  } catch (err) {
    _logError(err, zoneName, "func: getZoneNameIANA");
    return "";
  }
  return zoneName;
}

function _hasNumber(myString) {
  return /\d/.test(myString);
}

function _logError() {
  //mz-todo: Implement the function.
}

// Deprecated timezone code.
/*export const timeZones = [
  {id: "1", abbr: "A", zoneName: "Alpha Time Zone", offset: "UTC +1", offsetHours: 1, offsetMinutes: 0},
  {id: "2", abbr: "ACDT", zoneName: "Australian Central Daylight Time", offset: "UTC +10:30", offsetHours: 10, offsetMinutes: 30},
  {id: "3", abbr: "ACST", zoneName: "Australian Central Standard Time", offset: "UTC +9:30", offsetHours: 9, offsetMinutes: 30},
  {id: "4", abbr: "ACT", zoneName: "Acre Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "5", abbr: "ACT", zoneName: "Australian Central Time", offset: "UTC +9:30 / +10:30", offsetHours: 9, offsetMinutes: 30},
  {id: "6", abbr: "ACWST", zoneName: "Australian Central Western Standard Time", offset: "UTC +8:45", offsetHours: 8, offsetMinutes: 45},
  {id: "7", abbr: "ADT", zoneName: "Arabia Daylight Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "8", abbr: "ADT", zoneName: "Atlantic Daylight Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "9", abbr: "AEDT", zoneName: "Australian Eastern Daylight Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "10", abbr: "AEST", zoneName: "Australian Eastern Standard Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "11", abbr: "AET", zoneName: "Australian Eastern Time", offset: "UTC +10:00 / +11:00", offsetHours: 10, offsetMinutes: 0},
  {id: "12", abbr: "AFT", zoneName: "Afghanistan Time", offset: "UTC +4:30", offsetHours: 4, offsetMinutes: 30},
  {id: "13", abbr: "AKDT", zoneName: "Alaska Daylight Time", offset: "UTC -8", offsetHours: -8, offsetMinutes: 0},
  {id: "14", abbr: "AKST", zoneName: "Alaska Standard Time", offset: "UTC -9", offsetHours: -9, offsetMinutes: 0},
  {id: "15", abbr: "ALMT", zoneName: "Alma-Ata Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "16", abbr: "AMST", zoneName: "Amazon Summer Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "17", abbr: "AMST", zoneName: "Armenia Summer Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "18", abbr: "AMT", zoneName: "Amazon Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "19", abbr: "AMT", zoneName: "Armenia Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "20", abbr: "ANAST", zoneName: "Anadyr Summer Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "21", abbr: "ANAT", zoneName: "Anadyr Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "22", abbr: "AQTT", zoneName: "Aqtobe Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "23", abbr: "ART", zoneName: "Argentina Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "24", abbr: "AST", zoneName: "Arabia Standard Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "25", abbr: "AST", zoneName: "Atlantic Standard Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "26", abbr: "AT", zoneName: "Atlantic Time", offset: "UTC -4:00 / -3:00", offsetHours: -4, offsetMinutes: 0},
  {id: "27", abbr: "AWDT", zoneName: "Australian Western Daylight Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "28", abbr: "AWST", zoneName: "Australian Western Standard Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "29", abbr: "AZOST", zoneName: "Azores Summer Time", offset: "UTC +0", offsetHours: 0, offsetMinutes: 0},
  {id: "30", abbr: "AZOT", zoneName: "Azores Time", offset: "UTC -1", offsetHours: -1, offsetMinutes: 0},
  {id: "31", abbr: "AZST", zoneName: "Azerbaijan Summer Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "32", abbr: "AZT", zoneName: "Azerbaijan Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "33", abbr: "AoE", zoneName: "Anywhere on Earth", offset: "UTC -12", offsetHours: -12, offsetMinutes: 0},
  {id: "34", abbr: "B", zoneName: "Bravo Time Zone", offset: "UTC +2", offsetHours: 2, offsetMinutes: 0},
  {id: "35", abbr: "BNT", zoneName: "Brunei Darussalam Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "36", abbr: "BOT", zoneName: "Bolivia Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "37", abbr: "BRST", zoneName: "Brasília Summer Time", offset: "UTC -2", offsetHours: -2, offsetMinutes: 0},
  {id: "38", abbr: "BRT", zoneName: "Brasília Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "39", abbr: "BST", zoneName: "Bangladesh Standard Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "40", abbr: "BST", zoneName: "Bougainville Standard Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "41", abbr: "BST", zoneName: "British Summer Time", offset: "UTC +1", offsetHours: 1, offsetMinutes: 0},
  {id: "42", abbr: "BTT", zoneName: "Bhutan Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "43", abbr: "C", zoneName: "Charlie Time Zone", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "44", abbr: "CAST", zoneName: "Casey Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "45", abbr: "CAT", zoneName: "Central Africa Time", offset: "UTC +2", offsetHours: 2, offsetMinutes: 0},
  {id: "46", abbr: "CCT", zoneName: "Cocos Islands Time", offset: "UTC +6:30", offsetHours: 6, offsetMinutes: 30},
  {id: "47", abbr: "CDT", zoneName: "Central Daylight Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "48", abbr: "CDT", zoneName: "Cuba Daylight Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "49", abbr: "CEST", zoneName: "Central European Summer Time", offset: "UTC +2", offsetHours: 2, offsetMinutes: 0},
  {id: "50", abbr: "CET", zoneName: "Central European Time", offset: "UTC +1", offsetHours: 1, offsetMinutes: 0},
  {id: "51", abbr: "CHADT", zoneName: "Chatham Island Daylight Time", offset: "UTC +13:45", offsetHours: 13, offsetMinutes: 45},
  {id: "52", abbr: "CHAST", zoneName: "Chatham Island Standard Time", offset: "UTC +12:45", offsetHours: 12, offsetMinutes: 45},
  {id: "53", abbr: "CHOST", zoneName: "Choibalsan Summer Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "54", abbr: "CHOT", zoneName: "Choibalsan Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "55", abbr: "CHUT", zoneName: "Chuuk Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "56", abbr: "CIDST", zoneName: "Cayman Islands Daylight Saving Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "57", abbr: "CIST", zoneName: "Cayman Islands Standard Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "58", abbr: "CKT", zoneName: "Cook Island Time", offset: "UTC -10", offsetHours: -10, offsetMinutes: 0},
  {id: "59", abbr: "CLST", zoneName: "Chile Summer Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "60", abbr: "CLT", zoneName: "Chile Standard Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "61", abbr: "COT", zoneName: "Colombia Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "62", abbr: "CST", zoneName: "Central Standard Time", offset: "UTC -6", offsetHours: -6, offsetMinutes: 0},
  {id: "63", abbr: "CST", zoneName: "China Standard Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "64", abbr: "CST", zoneName: "Cuba Standard Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "65", abbr: "CT", zoneName: "Central Time", offset: "UTC -6:00 / -5:00", offsetHours: -6, offsetMinutes: 0},
  {id: "66", abbr: "CVT", zoneName: "Cape Verde Time", offset: "UTC -1", offsetHours: -1, offsetMinutes: 0},
  {id: "67", abbr: "CXT", zoneName: "Christmas Island Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "68", abbr: "ChST", zoneName: "Chamorro Standard Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "69", abbr: "D", zoneName: "Delta Time Zone", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "70", abbr: "DAVT", zoneName: "Davis Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "71", abbr: "DDUT", zoneName: "Dumont-d'Urville Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "72", abbr: "E", zoneName: "Echo Time Zone", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "73", abbr: "EASST", zoneName: "Easter Island Summer Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "74", abbr: "EAST", zoneName: "Easter Island Standard Time", offset: "UTC -6", offsetHours: -6, offsetMinutes: 0},
  {id: "75", abbr: "EAT", zoneName: "Eastern Africa Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "76", abbr: "ECT", zoneName: "Ecuador Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "77", abbr: "EDT", zoneName: "Eastern Daylight Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "78", abbr: "EEST", zoneName: "Eastern European Summer Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "79", abbr: "EET", zoneName: "Eastern European Time", offset: "UTC +2", offsetHours: 2, offsetMinutes: 0},
  {id: "80", abbr: "EGST", zoneName: "Eastern Greenland Summer Time", offset: "UTC +0", offsetHours: 0, offsetMinutes: 0},
  {id: "81", abbr: "EGT", zoneName: "East Greenland Time", offset: "UTC -1", offsetHours: -1, offsetMinutes: 0},
  {id: "82", abbr: "EST", zoneName: "Eastern Standard Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "83", abbr: "ET", zoneName: "Eastern Time", offset: "UTC -5:00 / -4:00", offsetHours: -5, offsetMinutes: 0},
  {id: "84", abbr: "F", zoneName: "Foxtrot Time Zone", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "85", abbr: "FET", zoneName: "Further-Eastern European Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "86", abbr: "FJST", zoneName: "Fiji Summer Time", offset: "UTC +13", offsetHours: 13, offsetMinutes: 0},
  {id: "87", abbr: "FJT", zoneName: "Fiji Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "88", abbr: "FKST", zoneName: "Falkland Islands Summer Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "89", abbr: "FKT", zoneName: "Falkland Island Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "90", abbr: "FNT", zoneName: "Fernando de Noronha Time", offset: "UTC -2", offsetHours: -2, offsetMinutes: 0},
  {id: "91", abbr: "G", zoneName: "Golf Time Zone", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "92", abbr: "GALT", zoneName: "Galapagos Time", offset: "UTC -6", offsetHours: -6, offsetMinutes: 0},
  {id: "93", abbr: "GAMT", zoneName: "Gambier Time", offset: "UTC -9", offsetHours: -9, offsetMinutes: 0},
  {id: "94", abbr: "GET", zoneName: "Georgia Standard Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "95", abbr: "GFT", zoneName: "French Guiana Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "96", abbr: "GILT", zoneName: "Gilbert Island Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "97", abbr: "GMT", zoneName: "Greenwich Mean Time", offset: "UTC +0", offsetHours: 0, offsetMinutes: 0},
  {id: "98", abbr: "GST", zoneName: "Gulf Standard Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "99", abbr: "GST", zoneName: "South Georgia Time", offset: "UTC -2", offsetHours: -2, offsetMinutes: 0},
  {id: "100", abbr: "GYT", zoneName: "Guyana Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "101", abbr: "H", zoneName: "Hotel Time Zone", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "102", abbr: "HDT", zoneName: "Hawaii-Aleutian Daylight Time", offset: "UTC -9", offsetHours: -9, offsetMinutes: 0},
  {id: "103", abbr: "HKT", zoneName: "Hong Kong Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "104", abbr: "HOVST", zoneName: "Hovd Summer Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "105", abbr: "HOVT", zoneName: "Hovd Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "106", abbr: "HST", zoneName: "Hawaii Standard Time", offset: "UTC -10", offsetHours: -10, offsetMinutes: 0},
  {id: "107", abbr: "I", zoneName: "India Time Zone", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "108", abbr: "ICT", zoneName: "Indochina Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "109", abbr: "IDT", zoneName: "Israel Daylight Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "110", abbr: "IOT", zoneName: "Indian Chagos Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "111", abbr: "IRDT", zoneName: "Iran Daylight Time", offset: "UTC +4:30", offsetHours: 4, offsetMinutes: 30},
  {id: "112", abbr: "IRKST", zoneName: "Irkutsk Summer Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "113", abbr: "IRKT", zoneName: "Irkutsk Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "114", abbr: "IRST", zoneName: "Iran Standard Time", offset: "UTC +3:30", offsetHours: 3, offsetMinutes: 30},
  {id: "115", abbr: "IST", zoneName: "India Standard Time", offset: "UTC +5:30", offsetHours: 5, offsetMinutes: 30},
  {id: "116", abbr: "IST", zoneName: "Irish Standard Time", offset: "UTC +1", offsetHours: 1, offsetMinutes: 0},
  {id: "117", abbr: "IST", zoneName: "Israel Standard Time", offset: "UTC +2", offsetHours: 2, offsetMinutes: 0},
  {id: "118", abbr: "JST", zoneName: "Japan Standard Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "119", abbr: "K", zoneName: "Kilo Time Zone", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "120", abbr: "KGT", zoneName: "Kyrgyzstan Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "121", abbr: "KOST", zoneName: "Kosrae Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "122", abbr: "KRAST", zoneName: "Krasnoyarsk Summer Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "123", abbr: "KRAT", zoneName: "Krasnoyarsk Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "124", abbr: "KST", zoneName: "Korea Standard Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "125", abbr: "KUYT", zoneName: "Kuybyshev Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "126", abbr: "L", zoneName: "Lima Time Zone", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "127", abbr: "LHDT", zoneName: "Lord Howe Daylight Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "128", abbr: "LHST", zoneName: "Lord Howe Standard Time", offset: "UTC +10:30", offsetHours: 10, offsetMinutes: 30},
  {id: "129", abbr: "LINT", zoneName: "Line Islands Time", offset: "UTC +14", offsetHours: 14, offsetMinutes: 0},
  {id: "130", abbr: "M", zoneName: "Mike Time Zone", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "131", abbr: "MAGST", zoneName: "Magadan Summer Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "132", abbr: "MAGT", zoneName: "Magadan Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "133", abbr: "MART", zoneName: "Marquesas Time", offset: "UTC -9:30", offsetHours: -9, offsetMinutes: -30},
  {id: "134", abbr: "MAWT", zoneName: "Mawson Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "135", abbr: "MDT", zoneName: "Mountain Daylight Time", offset: "UTC -6", offsetHours: -6, offsetMinutes: 0},
  {id: "136", abbr: "MHT", zoneName: "Marshall Islands Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "137", abbr: "MMT", zoneName: "Myanmar Time", offset: "UTC +6:30", offsetHours: 6, offsetMinutes: 30},
  {id: "138", abbr: "MSD", zoneName: "Moscow Daylight Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "139", abbr: "MSK", zoneName: "Moscow Standard Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "140", abbr: "MST", zoneName: "Mountain Standard Time", offset: "UTC -7", offsetHours: -7, offsetMinutes: 0},
  {id: "141", abbr: "MT", zoneName: "Mountain Time", offset: "UTC -7:00 / -6:00", offsetHours: -7, offsetMinutes: 0},
  {id: "142", abbr: "MUT", zoneName: "Mauritius Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "143", abbr: "MVT", zoneName: "Maldives Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "144", abbr: "MYT", zoneName: "Malaysia Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "145", abbr: "N", zoneName: "November Time Zone", offset: "UTC -1", offsetHours: -1, offsetMinutes: 0},
  {id: "146", abbr: "NCT", zoneName: "New Caledonia Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "147", abbr: "NDT", zoneName: "Newfoundland Daylight Time", offset: "UTC -2:30", offsetHours: -2, offsetMinutes: -30},
  {id: "148", abbr: "NFDT", zoneName: "Norfolk Daylight Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "149", abbr: "NFT", zoneName: "Norfolk Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "150", abbr: "NOVST", zoneName: "Novosibirsk Summer Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "151", abbr: "NOVT", zoneName: "Novosibirsk Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "152", abbr: "NPT", zoneName: "Nepal Time", offset: "UTC +5:45", offsetHours: 5, offsetMinutes: 45},
  {id: "153", abbr: "NRT", zoneName: "Nauru Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "154", abbr: "NST", zoneName: "Newfoundland Standard Time", offset: "UTC -3:30", offsetHours: -3, offsetMinutes: -30},
  {id: "155", abbr: "NUT", zoneName: "Niue Time", offset: "UTC -11", offsetHours: -11, offsetMinutes: 0},
  {id: "156", abbr: "NZDT", zoneName: "New Zealand Daylight Time", offset: "UTC +13", offsetHours: 13, offsetMinutes: 0},
  {id: "157", abbr: "NZST", zoneName: "New Zealand Standard Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "158", abbr: "O", zoneName: "Oscar Time Zone", offset: "UTC -2", offsetHours: -2, offsetMinutes: 0},
  {id: "159", abbr: "OMSST", zoneName: "Omsk Summer Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "160", abbr: "OMST", zoneName: "Omsk Standard Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "161", abbr: "ORAT", zoneName: "Oral Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "162", abbr: "P", zoneName: "Papa Time Zone", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "163", abbr: "PDT", zoneName: "Pacific Daylight Time", offset: "UTC -7", offsetHours: -7, offsetMinutes: 0},
  {id: "164", abbr: "PET", zoneName: "Peru Time", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "165", abbr: "PETST", zoneName: "Kamchatka Summer Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "166", abbr: "PETT", zoneName: "Kamchatka Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "167", abbr: "PGT", zoneName: "Papua New Guinea Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "168", abbr: "PHOT", zoneName: "Phoenix Island Time", offset: "UTC +13", offsetHours: 13, offsetMinutes: 0},
  {id: "169", abbr: "PHT", zoneName: "Philippine Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "170", abbr: "PKT", zoneName: "Pakistan Standard Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "171", abbr: "PMDT", zoneName: "Pierre & Miquelon Daylight Time", offset: "UTC -2", offsetHours: -2, offsetMinutes: 0},
  {id: "172", abbr: "PMST", zoneName: "Pierre & Miquelon Standard Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "173", abbr: "PONT", zoneName: "Pohnpei Standard Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "174", abbr: "PST", zoneName: "Pacific Standard Time", offset: "UTC -8", offsetHours: -8, offsetMinutes: 0},
  {id: "175", abbr: "PST", zoneName: "Pitcairn Standard Time", offset: "UTC -8", offsetHours: -8, offsetMinutes: 0},
  {id: "176", abbr: "PT", zoneName: "Pacific Time", offset: "UTC -8:00 / -7:00", offsetHours: -8, offsetMinutes: 0},
  {id: "177", abbr: "PWT", zoneName: "Palau Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "178", abbr: "PYST", zoneName: "Paraguay Summer Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "179", abbr: "PYT", zoneName: "Paraguay Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "180", abbr: "PYT", zoneName: "Pyongyang Time", offset: "UTC +8:30", offsetHours: 8, offsetMinutes: 30},
  {id: "181", abbr: "Q", zoneName: "Quebec Time Zone", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "182", abbr: "QYZT", zoneName: "Qyzylorda Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "183", abbr: "R", zoneName: "Romeo Time Zone", offset: "UTC -5", offsetHours: -5, offsetMinutes: 0},
  {id: "184", abbr: "RET", zoneName: "Reunion Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "185", abbr: "ROTT", zoneName: "Rothera Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "186", abbr: "S", zoneName: "Sierra Time Zone", offset: "UTC -6", offsetHours: -6, offsetMinutes: 0},
  {id: "187", abbr: "SAKT", zoneName: "Sakhalin Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "188", abbr: "SAMT", zoneName: "Samara Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "189", abbr: "SAST", zoneName: "South Africa Standard Time", offset: "UTC +2", offsetHours: 2, offsetMinutes: 0},
  {id: "190", abbr: "SBT", zoneName: "Solomon Islands Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "191", abbr: "SCT", zoneName: "Seychelles Time", offset: "UTC +4", offsetHours: 4, offsetMinutes: 0},
  {id: "192", abbr: "SGT", zoneName: "Singapore Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "193", abbr: "SRET", zoneName: "Srednekolymsk Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "194", abbr: "SRT", zoneName: "Suriname Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "195", abbr: "SST", zoneName: "Samoa Standard Time", offset: "UTC -11", offsetHours: -11, offsetMinutes: 0},
  {id: "196", abbr: "SYOT", zoneName: "Syowa Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "197", abbr: "T", zoneName: "Tango Time Zone", offset: "UTC -7", offsetHours: -7, offsetMinutes: 0},
  {id: "198", abbr: "TAHT", zoneName: "Tahiti Time", offset: "UTC -10", offsetHours: -10, offsetMinutes: 0},
  {id: "199", abbr: "TFT", zoneName: "French Southern and Antarctic Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "200", abbr: "TJT", zoneName: "Tajikistan Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "201", abbr: "TKT", zoneName: "Tokelau Time", offset: "UTC +13", offsetHours: 13, offsetMinutes: 0},
  {id: "202", abbr: "TLT", zoneName: "East Timor Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "203", abbr: "TMT", zoneName: "Turkmenistan Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "204", abbr: "TOST", zoneName: "Tonga Summer Time", offset: "UTC +14", offsetHours: 14, offsetMinutes: 0},
  {id: "205", abbr: "TOT", zoneName: "Tonga Time", offset: "UTC +13", offsetHours: 13, offsetMinutes: 0},
  {id: "206", abbr: "TRT", zoneName: "Turkey Time", offset: "UTC +3", offsetHours: 3, offsetMinutes: 0},
  {id: "207", abbr: "TVT", zoneName: "Tuvalu Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "208", abbr: "U", zoneName: "Uniform Time Zone", offset: "UTC -8", offsetHours: -8, offsetMinutes: 0},
  {id: "209", abbr: "ULAST", zoneName: "Ulaanbaatar Summer Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "210", abbr: "ULAT", zoneName: "Ulaanbaatar Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "211", abbr: "UTC", zoneName: "Coordinated Universal Time", offset: "UTC", offsetHours: 0, offsetMinutes: 0},
  {id: "212", abbr: "UYST", zoneName: "Uruguay Summer Time", offset: "UTC -2", offsetHours: -2, offsetMinutes: 0},
  {id: "213", abbr: "UYT", zoneName: "Uruguay Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "214", abbr: "UZT", zoneName: "Uzbekistan Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "215", abbr: "V", zoneName: "Victor Time Zone", offset: "UTC -9", offsetHours: -9, offsetMinutes: 0},
  {id: "216", abbr: "VET", zoneName: "Venezuelan Standard Time", offset: "UTC -4", offsetHours: -4, offsetMinutes: 0},
  {id: "217", abbr: "VLAST", zoneName: "Vladivostok Summer Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "218", abbr: "VLAT", zoneName: "Vladivostok Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "219", abbr: "VOST", zoneName: "Vostok Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "220", abbr: "VUT", zoneName: "Vanuatu Time", offset: "UTC +11", offsetHours: 11, offsetMinutes: 0},
  {id: "221", abbr: "W", zoneName: "Whiskey Time Zone", offset: "UTC -10", offsetHours: -10, offsetMinutes: 0},
  {id: "222", abbr: "WAKT", zoneName: "Wake Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "223", abbr: "WARST", zoneName: "Western Argentine Summer Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "224", abbr: "WAST", zoneName: "West Africa Summer Time", offset: "UTC +2", offsetHours: 2, offsetMinutes: 0},
  {id: "225", abbr: "WAT", zoneName: "West Africa Time", offset: "UTC +1", offsetHours: 1, offsetMinutes: 0},
  {id: "226", abbr: "WEST", zoneName: "Western European Summer Time", offset: "UTC +1", offsetHours: 1, offsetMinutes: 0},
  {id: "227", abbr: "WET", zoneName: "Western European Time", offset: "UTC +0", offsetHours: 0, offsetMinutes: 0},
  {id: "228", abbr: "WFT", zoneName: "Wallis and Futuna Time", offset: "UTC +12", offsetHours: 12, offsetMinutes: 0},
  {id: "229", abbr: "WGST", zoneName: "Western Greenland Summer Time", offset: "UTC -2", offsetHours: -2, offsetMinutes: 0},
  {id: "230", abbr: "WGT", zoneName: "West Greenland Time", offset: "UTC -3", offsetHours: -3, offsetMinutes: 0},
  {id: "231", abbr: "WIB", zoneName: "Western Indonesian Time", offset: "UTC +7", offsetHours: 7, offsetMinutes: 0},
  {id: "232", abbr: "WIT", zoneName: "Eastern Indonesian Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "233", abbr: "WITA", zoneName: "Central Indonesian Time", offset: "UTC +8", offsetHours: 8, offsetMinutes: 0},
  {id: "234", abbr: "WST", zoneName: "West Samoa Time", offset: "UTC +14", offsetHours: 14, offsetMinutes: 0},
  {id: "235", abbr: "WST", zoneName: "Western Sahara Summer Time", offset: "UTC +1", offsetHours: 1, offsetMinutes: 0},
  {id: "236", abbr: "WT", zoneName: "Western Sahara Standard Time", offset: "UTC +0", offsetHours: 0, offsetMinutes: 0},
  {id: "237", abbr: "X", zoneName: "X-ray Time Zone", offset: "UTC -11", offsetHours: -11, offsetMinutes: 0},
  {id: "238", abbr: "Y", zoneName: "Yankee Time Zone", offset: "UTC -12", offsetHours: -12, offsetMinutes: 0},
  {id: "239", abbr: "YAKST", zoneName: "Yakutsk Summer Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "240", abbr: "YAKT", zoneName: "Yakutsk Time", offset: "UTC +9", offsetHours: 9, offsetMinutes: 0},
  {id: "241", abbr: "YAPT", zoneName: "Yap Time", offset: "UTC +10", offsetHours: 10, offsetMinutes: 0},
  {id: "242", abbr: "YEKST", zoneName: "Yekaterinburg Summer Time", offset: "UTC +6", offsetHours: 6, offsetMinutes: 0},
  {id: "243", abbr: "YEKT", zoneName: "Yekaterinburg Time", offset: "UTC +5", offsetHours: 5, offsetMinutes: 0},
  {id: "244", abbr: "Z", zoneName: "Zulu Time Zone", offset: "UTC +0", offsetHours: 0, offsetMinutes: 0},
];

// Removing map search
// export const timeZoneSearchMap = new Map();
// timeZones.forEach((item)=>{
//   timeZoneSearchMap[item.id] = {
//     id: item.id, 
//     abbr: item.abbr.toLowerCase(), 
//     zoneName: item.zoneName.toLowerCase(), 
//     offset: item.offset.toLowerCase(),
//   }
// });

export function getTimeZoneById(id) {
  // return timeZoneSearchMap[id] || null; // Removing map search for now.

  const item = timeZones.find((element) => element.id === id);
  if (item === undefined) {
    return null;
  }
  return item;
}

export function getTimeZoneByName(name) {
  if (!name) {
    return null;
  }
  const item = timeZones.find((element) => element.zoneName.toLowerCase() === name.toLowerCase());
  if (item === undefined) {
    return null;
  }
  return item;
}*/
