import * as types from '../constants';

const initialState={
  list: [],
  fetched: false,
  selected: null,
  cardInstance: null,
  cardSaveError: "",
}

export function cardTemplates(state = initialState, action) {
  switch (action.type) {
    case types.ON_TEMPLATES_FETCHED:
      return {...state, list: action.data, fetched: true}
    case types.ON_CARD_TEMPLATE_SELECTED:
      return {...state, selected: action.card}
    case types.CLEAR_TEMPLATE_AND_INSTANCE:
      return {...state, selected: null, cardInstance: null, cardSaveError: ""}
    case types.ON_CARD_SAVE_FAILED:
      return {...state, cardSaveError: action.msg}
    case types.ON_CARD_SAVED:
      return {...state, cardInstance: action.card, cardSaveError: ""}
    case types.INIT_CARD_INSTANCE:
      return {...state, cardInstance: action.card, cardSaveError: ""}      
    default:
      return state;
  }
}