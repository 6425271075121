import img9 from "../containers/img/themes/birthday/9.png";
import img10 from "../containers/img/themes/birthday/10.png";
import img11 from "../containers/img/themes/birthday/11.jpg";
import img12 from "../containers/img/themes/birthday/12.jpg";
import img13 from "../containers/img/themes/birthday/13.jpg";
import img14 from "../containers/img/themes/birthday/14.jpg";
import img15 from "../containers/img/themes/birthday/15.jpg";
import img16 from "../containers/img/themes/birthday/16.jpg";
import img17 from "../containers/img/themes/birthday/17.jpg";
import img18 from "../containers/img/themes/birthday/18.png";
import img19 from "../containers/img/themes/birthday/19.png";
import img20 from "../containers/img/themes/birthday/20.jpg";
import img21 from "../containers/img/themes/birthday/21.png";
import img22 from "../containers/img/themes/birthday/22.jpg";
import img23 from "../containers/img/themes/party/23.png";
import img24 from "../containers/img/themes/party/24.png";
import img25 from "../containers/img/themes/party/25.png";
import img26 from "../containers/img/themes/party/26.png";
import img27 from "../containers/img/themes/party/27.png";
import img28 from "../containers/img/themes/party/28.png";
import img29 from "../containers/img/themes/topics/29.png";
import img30 from "../containers/img/themes/topics/30.png";
import img31 from "../containers/img/themes/topics/31.png";
import img32 from "../containers/img/themes/topics/32.png";
import img33 from "../containers/img/themes/topics/33.jpg";
import img34 from "../containers/img/themes/topics/34.png";
import img35 from "../containers/img/themes/floral/35.png";
import img36 from "../containers/img/themes/floral/36.png";
import img37 from "../containers/img/themes/floral/37.jpg";
import img38 from "../containers/img/themes/floral/38.png";
import img39 from "../containers/img/themes/floral/39.png";
import img40 from "../containers/img/themes/floral/40.png";
import img41 from "../containers/img/themes/floral/41.png";
import img42 from "../containers/img/themes/floral/42.png";
import img43 from "../containers/img/themes/floral/43.png";
import img44 from "../containers/img/themes/floral/44.png";
import img45 from "../containers/img/themes/floral/45.png";
import img46 from "../containers/img/themes/floral/46.png";
import img47 from "../containers/img/themes/floral/47.png";
import img48 from "../containers/img/themes/floral/48.png";
import img49 from "../containers/img/themes/floral/49.png";
import img50 from "../containers/img/themes/floral/50.png";
import img51 from "../containers/img/themes/floral/51.png";
import img52 from "../containers/img/themes/floral/52.png";
import img53 from "../containers/img/themes/floral/53.png";

const themesList = [
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
];

export function preloadThemes() {
  themesList.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
}
