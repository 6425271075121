import OC from "../oc";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const PRIORITY_NORMAL = "JUB_NORMAL";
export const PRIORITY_HIGH = "JUB_HIGH"; //Erros that indicate failure in essential functionalities.
export const PRIORITY_CRITICAL = "JUB_CRITICAL"; //Errors that might break data state integrity.
export const TYPE_ERROR = "error";
export const TYPE_INFO = "info";
export const TYPE_LOG_FAILED = "log_failed";
export const TYPE_ANALYSIS = "analysis"; //logs to study code behavior.
export const TYPE_STRIPE_LOG = "stripe_log";

class JubiliLog {
  id = null;
  created = null;
  type = null;
  authUserEmail = null;
  company = null;
  priority = null;
  message = null;
  contextTag = null;
  contextData = null;
  userAgent = null;
  url = null;
  constructor(type, priority, message, contextTag, contextData) {
    if (type === undefined) {
      type = null;
    }
    if (priority === undefined) {
      priority = null;
    }
    if (message === undefined) {
      message = null;
    }
    if (contextTag === undefined) {
      contextTag = null;
    }
    if (contextData === undefined) {
      contextData = null;
    }
    const timestamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
    const uuid = uuidv4();
    const email = OC.getInstance().getCurrentAuthUserEmail();
    const company = OC.getInstance().getCurrentUserCompanyId();
    const ua = navigator ? navigator.userAgent : null;
    var data = null;
    if (contextData) {
      try {
        if (typeof contextData === "string" || typeof contextData === "number") {
          data = contextData;
        } else {
          if (contextData.getDoc && typeof contextData.getDoc === "function") {
            data = contextData.getDoc();
          } else {
            const res = JSON.stringify(contextData);
            if (typeof res === "string") {
              data = res;
            }
          }
        }
      } catch (err) {
        data = { err: err && err.message ? err.message : null, catchContext: "contextData exception" }
      }
    }

    this.id = timestamp + " (" + uuid.replace(/-/g, '') + ")";
    this.created = timestamp;
    this.type = type;
    this.authUserEmail = email;
    this.company = company;
    this.priority = priority;
    this.message = message;
    this.contextTag = contextTag;
    this.contextData = data;
    this.userAgent = ua;
    this.url = window.location.href || "";
  }
  getDoc() {
    return {
      created: this.created || null,
      type: this.type || null,
      authUserEmail: this.authUserEmail || null,
      company: this.company || null,
      priority: this.priority || null,
      message: this.message || null,
      contextTag: this.contextTag || null,
      contextData: this.contextData || null,
      userAgent: this.userAgent || null,
      url: this.url || null,
    }
  }
  getDocWithSafeValues() {
    var cData = "NONE";
    try {
      if (this.contextData) {
        cData = JSON.stringify(this.contextData);
      }
    } catch {
      cData = "JSON.stringify Exception.";
    }
    return {
      created: this.created || null,
      type: this.type || null,
      authUserEmail: this.authUserEmail || null,
      company: this.company || null,
      priority: this.priority || null,
      message: this.message || null,
      contextTag: this.contextTag || null,
      contextData: cData,
      contextDataSafeMode: true,
      userAgent: this.userAgent || null,
      url: this.url || null,
    }
  }
}

const Logger = {
  async logError(priority, message, tag, data) {
    try {
      const l = new JubiliLog(TYPE_ERROR, priority || PRIORITY_NORMAL, message, tag, data);
      await OC.getInstance().storeAPI.saveLog(l);
      console.log("logger executed"); //warn: Remove after live alpha.
    } catch {
      this.logBypassError("logError");
      console.log("logger execution bypassed"); //warn: Remove after live alpha.
    }
  },
  async logStripe(priority, message, tag, data) {
    try {
      const l = new JubiliLog(TYPE_STRIPE_LOG, priority || PRIORITY_NORMAL, message, tag, data);
      await OC.getInstance().storeAPI.saveLog(l);
      console.log("logger executed"); //warn: Remove after live alpha.
    } catch {
      this.logBypassError("logStripe");
      console.log("logger execution bypassed"); //warn: Remove after live alpha.
    }
  },
  logInfo(priority, message, tag, data) {
    try {
      const l = new JubiliLog(TYPE_INFO, priority || PRIORITY_NORMAL, message, tag, data);
      OC.getInstance().storeAPI.saveLog(l);
      console.log("logger executed"); //warn: Remove after live alpha.
    } catch {
      this.logBypassError("logInfo");
      console.log("logger execution bypassed"); //warn: Remove after live alpha.
    }
  },
  async logAnalysis(priority, message, tag, data) {
    try {
      const l = new JubiliLog(TYPE_ANALYSIS, priority || PRIORITY_NORMAL, message, tag, data);
      await OC.getInstance().storeAPI.saveLog(l);
      console.log("logger executed"); //warn: Remove after live alpha.
    } catch {
      this.logBypassError("logAnalysis");
      console.log("logger execution bypassed"); //warn: Remove after live alpha.
    }
  },
  async logBypassError(tag) {
    if (tag === undefined) {
      tag = null;
    }
    try {
      const l = new JubiliLog(TYPE_LOG_FAILED, PRIORITY_HIGH, "error while creating log", tag, null);
      await OC.getInstance().storeAPI.saveLog(l);
      console.log("logger executed"); //warn: Remove after live alpha.
    } catch {
      console.log("TYPE_LOG_FAILED bypassed"); //warn: Remove after live alpha.
    }
  },
}

export default Logger;