import React from "react";
import { Button, message } from "antd";
import "./styles/Modal.less";
import EmailParser from "../components/EmailParser";

class ModalAddTeammates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
    };
    this.onAddClicked = this.onAddClicked.bind(this);
    this.saveArray = this.saveArray.bind(this);
    this.deleteEmail = this.deleteEmail.bind(this);
  }

  saveArray(emails) {
    this.setState({ emails: emails });
  }
  deleteEmail(email) {
    var array = [...this.state.emails];
    var index = array.indexOf(email);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ emails: array });
    }
  }
  onAddClicked() {
    const { emails } = this.state;
    const { card, company, updateCard, onInviteSent, selfEmail } = this.props;
    if (!selfEmail || !emails || !emails.length) {
      return;
    }
    const newCard = card.cloneNew();
    if (!newCard.invited) {
      newCard.invited = [];
    }
    for (let email of emails) {
      if (!this.isValidEmail(email, company.domain)) {
        message.info(
          "Only email addresses ending in " + company.domain + " are permitted."
        );
        return false;
      } else {
        newCard && newCard.invited.push(email);
      }
    }
    newCard.inviterLastEmail = selfEmail;
    updateCard(newCard, (res) => {
      if (!res) {
        message.error(
          "Could not invite teammates. Please check network connection and try again."
        );
      } else {
        const inviteCount = emails.length || ""
        message.success(`${inviteCount} ${inviteCount > 1 ? "Invites" : "Invite"} Sent`);
        onInviteSent && onInviteSent();
      }
    });
  }
  isValidEmail(email, domain) {
    if (!domain || !email || email.indexOf("@") === -1) {
      return false;
    }
    return email.split("@").pop() === domain;
  }
  render() {
    const { company, recipientEmail, ownerEmail, selfEmail } = this.props;
    const { emails } = this.state;
    return (
      <div className="ModalAddTeammates">
        <h2>Add Teammates</h2>
        <p>Paste in the comma separated email addresses of the teammates.</p>

        <div>
          <EmailParser
            saveEmail={this.saveArray}
            deleteEmail={this.deleteEmail}
            domainName={company ? company.domain : ""}
            recipientEmail={recipientEmail}
            ownerEmail={ownerEmail}
            selfEmail={selfEmail}
          />
        </div>

        <Button
          type="primary"
          size="large"
          block
          htmlType="submit"
          className="add-invites"
          onClick={this.onAddClicked}
        >
          <i className="fal fa-paper-plane"></i>
          {`Invite ${(emails && emails.length) || ""}`}
        </Button>
      </div>
    );
  }
}

export default ModalAddTeammates;
