import React from "react";
import "./styles/FloatingFooter.less";
import * as nav from "../routes/nav";
import EmojiHandler from "./EmojiHandler";
import { Button } from "antd";
import greetedLogo from "./img/logo-greeted.svg";
import Fade from "react-reveal/Fade";

class FloatingFooter extends React.Component {
  render() {
    return (
      <Fade bottom delay={6000}>
        <div className="floating-footer-wrapper">
          <div className="floating-card-footer">
            <Button type="link" href={nav.URL_HOMEPAGE} className="logo">
              <span>Made with</span>
              <img src={greetedLogo} alt="Greeted" />
            </Button>
            <div className="text">
              <span className="title">
                Make a card like this <EmojiHandler>👆</EmojiHandler>
              </span>
              <span className="subtitle">Celebrate Birthdays and more.</span>
            </div>
            <Button type="primary" href={nav.URL_AUTH_FLOW_JOIN}>
              Get Started
            </Button>
          </div>
        </div>
      </Fade>
    );
  }
}

export default FloatingFooter;
