import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from './App';
import Logger, { PRIORITY_CRITICAL } from "./shared/logger";
import { isMagicLinkURL } from "./routes/nav";
import moment from "moment";

var intervalHandler = null;
var logExecuted = false;

async function logCrash() {
  console.info("rud_handler - tier2 - log");
  await Logger.logAnalysis(
    PRIORITY_CRITICAL,
    "greetingpage-dynamic-structure not found",
    "src/index",
    null,
  );
}

// Rapid Unscheduled Disassembly Handler
function crashChecker() {
  try {
    if (document.readyState === "complete") {
      if (!document.querySelector(".greetingpage-dynamic-structure")) {
        if (!rudThresholdPassed()) {
          return;
        }
        console.info("rud_handler - tier2 - init_recovery");
        if (!logExecuted) {
          logExecuted = true;
          clearInterval(intervalHandler);
          intervalHandler = null;
          window.appUDHandle = intervalHandler;
          window.appUDStatus = "handled";
          console.info("rud_handler - tier2 - cleared");
          setLastRUD();
          logCrash().then(() => {
            try {
              if (isMagicLinkURL()) {
                console.info("rud_handler - tier2 - skipped");
                return;
              }
            } catch { 
              console.info("rud_handler - tier2 - exception");
            }
            window.appUDStatus = "reloaded";
            console.info("rud_handler - tier2 - reload");
            window.location.reload();
          });
        }
      }
    }
  } catch { }
}

function rudThresholdPassed() {
  const dtString = window.localStorage.getItem("lastRUD");
  if (dtString) {
    const dt = moment(dtString);
    if (dt.isValid()) {
      const dtNow = moment();
      var duration = moment.duration(dtNow.diff(dt));
      var seconds = duration.asSeconds();
      if (seconds < 60) {
        if (window.RUDCount > 0) {
          window.RUDCount++;
          if (window.RUDCount > 5) {
            logExecuted = true;
            clearInterval(intervalHandler);
            intervalHandler = null;  
          }
        } else {
          window.RUDCount = 1;
        }
        return false;
      }
    }
  }
  return true;
}

function setLastRUD() {
  const dtString = moment.utc().format();
  window.localStorage.setItem("lastRUD", dtString);
}

if (!intervalHandler) {
  intervalHandler = setInterval(crashChecker, 4000);
  window.appUDHandle = intervalHandler;
  window.appUDStatus = "attached"; // Don't remove. Being used in index.html
  console.info("rud_handler - tier2 - attached");
}

window.addEventListener('beforeunload', function () {
  clearInterval(intervalHandler);
  intervalHandler = null;
  window.appUDHandle = intervalHandler;
  window.appUDStatus = "detached";
  console.info("rud_handler - tier2 - detached");
});

ReactDOM.render(<App />, document.getElementById('root'));
