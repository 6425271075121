import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/auth";
import * as cardActions from "../actions/card";
import * as metadataActions from "../actions/metadata";
import "./styles/HomePage.less";
import "../components/styles/AdminTabs.less";
import {
  Layout,
  Row,
  Col,
  Table,
  Skeleton,
  message,
  Dropdown,
  Menu,
  Tooltip,
  Tabs,
  Switch,
  Space,
  Modal,
  Button,
} from "antd";
import HeaderLogin from "../components/HeaderLogin";
import Automations from "../components/Automations";
import StartState from "../components/StartState";
import { categoryData, OC_CARD_PLURAL } from "../shared/constants";
import * as nav from "../routes/nav";
import UserAvatar from "../components/custom/UserAvatar";
import EmojiHandler from "../components/EmojiHandler";
import DeleteCardModal from "../components/DeleteCardModal";
import AddEditTeammate from "../components/AddEditTeammate";
import { getCurrentTimezoneName } from "../shared/timezones";
import moment from "moment-timezone";
import DeleteUserModal from "../components/DeleteUserModal";
import DOB from "../shared/dob";
import MainFooter from "../components/MainFooter";
import Payment from "../containers/Payment";
import { isInternalUser } from "../shared/util";

const { Content } = Layout;
const zoneName = getCurrentTimezoneName();
const { TabPane } = Tabs;

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalCardItem: null,
      deleteModalUserItem: null,
      activeTabId: "1",
      showTeammateModal: false,
      teammateModalMounted: false,
      teammateUser: null,
    };
    this.onTabChanged = this.onTabChanged.bind(this);
    this.onSwitchChange = this.onSwitchChange.bind(this);
    this.hideTeammateModal = this.hideTeammateModal.bind(this);
    this.onNewTeammate = this.onNewTeammate.bind(this);
    this.afterTeammateModalClose = this.afterTeammateModalClose.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps?.location?.pathname !== this.props.location?.pathname) {
      const { toggleSettingsView } = this.props.actions;
      if (nav.isSettingsURL()) {
        toggleSettingsView(true);
      } else {
        toggleSettingsView(false);
      }
    }
  }
  getActiveTabId() {
    const { auth } = this.props;
    return auth.showSettingsView ? null : this.state.activeTabId;
  }
  onNewTeammate() {
    this.setState({
      showTeammateModal: true,
      teammateModalMounted: true,
      teammateUser: null,
    });
  }
  onEditTeammateClicked(user) {
    this.setState({
      showTeammateModal: true,
      teammateModalMounted: true,
      teammateUser: user,
    });
  }
  hideTeammateModal() {
    this.setState({ showTeammateModal: false });
  }
  afterTeammateModalClose() {
    this.setState({ teammateModalMounted: false, teammateUser: null });
  }
  onTabChanged(key) {
    if (nav.isSettingsURL()) {
      nav.goto(nav.URL_HOMEPAGE);
    }
    if (this.props.auth.showSettingsView) {
      this.props.actions.toggleSettingsView(false);
    }
    this.setState({ activeTabId: key });
  }
  componentWillUnmount() {
    const { toggleSettingsView } = this.props.actions;
    toggleSettingsView(false);
    this._isMounted = false;
  }
  componentDidMount() {
    if (nav.isRemindersURL()) {
      const { user } = this.props.metadata;
      if (!user) {
        nav.goto(
          `${nav.URL_AUTH_FLOW_SIGNIN}?${nav.QUERY_REDIRECT}=${nav.QUERY_REMINDERS}`
        );
        return;
      }
      this.setState({ activeTabId: "2" });
    }
    if (nav.isSettingsURL()) {
      const { toggleSettingsView } = this.props.actions;
      toggleSettingsView(true);
    }
    this._isMounted = true;
    this.props.cardActions.fetchCards(null, (res) => {
      this._isMounted && this.props.cardActions.loadCardsListFrom(res);
    });
  }
  getCategoryNameBy(key) {
    const { categories } = this.props.metadata;
    if (!categories) {
      return "";
    }
    const cat = categories.find((element) => element.key === key);
    if (cat === null || cat === undefined) {
      return "";
    }
    return cat.name;
  }
  getTag(record) {
    var text = "";
    var icon = "";
    var color = "";
    if (record.isRecipient && record.isRecipient()) {
      text = "Received";
      icon = "fa-heart";
      color = "magenta";
    } else if (record.postsDisabled) {
      text = record.delivery ? "Published & Sent" : "Published";
      icon = record.delivery ? "fa-paper-plane" : "fa-lock";
      color = "grey";
    } else {
      text =
        record.delivery && record.delivery.utc ? (
          <Tooltip placement="top" title={zoneName}>
            <span>
              {moment
                .utc(record.delivery.utc)
                .local()
                .format("MMM DD [at] hh:mm a")}
            </span>
          </Tooltip>
        ) : (
          "Open for Posts"
        );
      icon = record.delivery ? "fa-paper-plane" : "fa-lock-open";
      color = "green";
    }
    const isAdmin = record.isAdmin ? record.isAdmin() : false;
    return (
      <div>
        {isAdmin ? (
          <span className={`custom-tag gold`} style={{ marginRight: 12 }}>
            Owned
          </span>
        ) : null}
        <span className={`custom-tag ${color}`}>
          <i className={`fal ${icon}`} style={{ paddingRight: "9px" }}></i>
          {text}
        </span>
      </div>
    );
  }

  onToggleAdminClick(record, opt) {
    opt && opt.domEvent && opt.domEvent.preventDefault();
    opt && opt.domEvent && opt.domEvent.stopPropagation();
    opt &&
      opt.domEvent &&
      opt.domEvent.nativeEvent &&
      opt.domEvent.nativeEvent.stopImmediatePropagation();
    this.props.metadataActions.toggleAdmin(record.email, !record.isAdmin);
  }

  onSwitchChange(checked) {
    const { user } = this.props.metadata;
    if (!user || !user.email) {
      return;
    }
    this.props.metadataActions.toggleReminders(user.email, !checked);
  }

  onDeleteUserClicked(data, e) {
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();
    this.setState({ deleteModalUserItem: data });
  }
  getTeamMatesDropDown(record) {
    const { currentUser } = this.props.auth;
    return (
      <Menu>
        <Menu.Item onClick={this.onEditTeammateClicked.bind(this, record)}>
          <i className="fal fa-pen"></i> Edit
        </Menu.Item>
        {currentUser && currentUser.email !== record.email && (
          <Menu.Item onClick={this.onToggleAdminClick.bind(this, record)}>
            <i className="fal fa-crown"></i>{" "}
            {record.isAdmin ? "Remove Admin" : "Make Admin"}
          </Menu.Item>
        )}
        {currentUser && currentUser.email !== record.email && <Menu.Divider />}
        {currentUser && currentUser.email !== record.email && (
          <Menu.Item
            className="delete"
            onClick={this.onDeleteUserClicked.bind(this, record)}
          >
            <i className="fal fa-trash"></i> Delete
          </Menu.Item>
        )}
      </Menu>
    );
  }

  getRowContextMenu(record) {
    const { postsDisabled } = record;
    const isAdmin = record.isAdmin ? record.isAdmin() : false;
    const isRecipient = record.isRecipient ? record.isRecipient() : false;
    return (
      <Menu>
        {!isRecipient ? (
          <Menu.Item
            className="copy"
            key="0"
            onClick={this.onCopyClicked.bind(this, record)}
          >
            <i className="fal fa-link"></i>&nbsp;Copy Link
          </Menu.Item>
        ) : null}
        {isAdmin && !postsDisabled ? <Menu.Divider /> : null}
        {isAdmin && !postsDisabled ? (
          <Menu.Item
            className="delete"
            key="1"
            onClick={this.onDeleteClicked.bind(this, record)}
          >
            <i className="fal fa-trash"></i>&nbsp;Delete
          </Menu.Item>
        ) : null}
      </Menu>
    );
  }
  getAvatar(user) {
    return (
      <UserAvatar
        key={`homelist_avatar_${user && user.email ? user.email : ""}`}
        size={28}
        downloadURL={user.avatarDownloadURL}
        fetchPath={user.avatar}
        name={user.name}
        versionTag={user._lastUpdated || null}
      />
    );
  }
  getColumns() {
    return [
      {
        width: "50%",
        title: "users",
        key: "users",
        className: "users-wrapper",
        render: (_, record) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="users">
              <EmojiHandler>
                <span className="emoji">
                  {record.category &&
                    categoryData[record.category] &&
                    categoryData[record.category].icon}{" "}
                </span>
              </EmojiHandler>
              <span className="category">
                {this.getCategoryNameBy(record.category)}
              </span>
              {record.isGroupType && record.isGroupType() ? null : (
                <UserAvatar
                  key={`homelist_avatar_${
                    record.recipient ? record.recipient.email : null
                  }`}
                  size={28}
                  fetchPath={
                    record.getAvatarFetchPath && record.getAvatarFetchPath()
                  }
                  name={record.getRecipientName && record.getRecipientName()}
                />
              )}
              <span className="recipient">
                {record.getRecipientName
                  ? record.getRecipientName() + " "
                  : " "}
              </span>
            </span>
            <span className="custom-tag grey post-count">
              <i className="fal fa-comment-alt-smile"></i>{" "}
              {record.getPostsCount ? record.getPostsCount() : "0"}
            </span>
          </div>
        ),
      },
      {
        width: "50%",
        title: "Tag",
        key: "tags",
        className: "tag-wrapper",
        render: (_, record) => {
          return this.getTag(record);
        },
      },
      {
        width: "50px",
        title: "actions",
        key: "actions",
        className: "actions-wrapper",
        render: (_, record) => (
          <span
            style={
              record.isRecipient && record.isRecipient()
                ? { display: "none" }
                : null
            }
          >
            <Dropdown
              overlay={this.getRowContextMenu.bind(this, record)}
              className="actions"
              overlayClassName="action-menu"
              trigger={["click"]}
            >
              <Button
                type="link"
                style={{ padding: 0 }}
                className="ant-dropdown-link"
                onClick={this.onMenuClicked}
              >
                <i className="far fa-ellipsis-h"></i>
              </Button>
            </Dropdown>
          </span>
        ),
      },
    ];
  }

  fetchBirthday(record) {
    if (!record) {
      return null;
    }
    const { dob } = record;
    if (!dob || !dob.day || dob.month === null || dob.month === undefined) {
      return null;
    }
    const dtDOB = new DOB(dob.day, dob.month);
    const dobString = dtDOB.toString();
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="users">
          <EmojiHandler>
            <span className="emoji">{categoryData.birthday.icon}</span>
          </EmojiHandler>
          <span className="category">{dobString}</span>
        </span>
      </div>
    );
  }
  getColumnsTeams() {
    return [
      {
        width: "60%",
        title: "users",
        key: "users",
        className: "users-wrapper",
        render: (_, record) => (
          <div>
            <span className="users">
              {this.getAvatar(record)}
              <span className="user-name">{record.name || " "}</span>
              <span className="user-email">{record.email}</span>
            </span>
          </div>
        ),
      },

      {
        width: "20%",
        title: "count",
        key: "count",
        className: "post-count-cell",
        render: (_, record) => <>{this.fetchBirthday(record)}</>,
      },

      {
        width: "40%",
        title: "Action",
        key: "action",
        className: "tag-wrapper",
        render: (_, record) => {
          return (
            <div>
              {record.isAdmin ? (
                <span className="custom-tag blue">
                  {" "}
                  <i className="fal fa-crown"></i> Admin
                </span>
              ) : (
                <span></span>
              )}
            </div>
          );
        },
      },
      {
        width: "50px",
        title: "actions",
        key: "actions",
        className: "actions-wrapper",
        render: (_, record) => (
          <span
            style={
              record.isRecipient && record.isRecipient()
                ? { display: "none" }
                : null
            }
          >
            <Dropdown
              overlay={this.getTeamMatesDropDown.bind(this, record)}
              className="actions"
              overlayClassName="action-menu"
              trigger={["click"]}
            >
              <Button
                type="link"
                style={{ padding: 0 }}
                className="ant-dropdown-link"
                onClick={this.onMenuClicked}
              >
                <i className="far fa-ellipsis-h"></i>
              </Button>
            </Dropdown>
          </span>
        ),
      },
    ];
  }

  onTeamRowClick(data) {
    this.onEditTeammateClicked(data);
  }

  onRowClick(data) {
    const { company } = this.props.metadata;
    if (!company || !company.domain) {
      return;
    }
    nav.goto(nav.urlCardPageWith(data.id, company.domain));
  }

  onMenuClicked(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  onCopyClicked(data, e) {
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();

    const { company } = this.props.metadata;
    if (!company || !company.domain) {
      return;
    }
    const { protocol, host } = window.location;
    const url = `${protocol}//${host}${nav.urlCardPageWith(
      data.id,
      company.domain
    )}`;
    navigator &&
      navigator.clipboard &&
      navigator.clipboard.writeText &&
      navigator.clipboard.writeText(url) &&
      message.info("Link Copied!");
  }
  onDeleteClicked(data, e) {
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();
    this.setState({ deleteModalCardItem: data });
  }
  renderLoader() {
    return (
      <div className="HomePage">
        <Layout>
          <HeaderLogin
            auth={this.props.auth}
            actions={this.props.actions}
            onNewTeammate={this.onNewTeammate}
            urlLocation={this.props.location}
          />

          <div className="skeleton">
            <Row align="center">
              <Col xs={22} sm={18} md={18}>
                <Skeleton active paragraph={{ rows: 6 }} />
              </Col>
            </Row>
          </div>
        </Layout>
      </div>
    );
  }
  getDaysToDOB(user, today) {
    if (!(user.hasDOB && user.hasDOB())) {
      return 365;
    }
    const { dob } = user;
    const dd = moment();
    dd.month(dob.month);
    dd.date(dob.day);
    if (dd.isBefore(today)) {
      dd.year(dd.year() + 1);
    }
    var duration = moment.duration(dd.diff(today.utc()));
    var daysToDOB = duration.asDays();
    return daysToDOB;
  }
  getSortedUsersList(users) {
    const usersList = [...users];
    const today = moment();
    return usersList.sort((a, b) => {
      const l1 = this.getDaysToDOB(a, today);
      const l2 = this.getDaysToDOB(b, today);
      if (l1 < l2) {
        return -1;
      }
      if (l1 > l2) {
        return 1;
      }
      return 0;
    });
  }
  renderCardsList() {
    const { cardsPostsList } = this.props.card;
    return cardsPostsList?.length > 0 ? (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={20} lg={18} xl={18}>
          <Table
            rowKey={"id"}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: this.onRowClick.bind(this, record),
              };
            }}
            columns={this.getColumns()}
            dataSource={cardsPostsList}
          />
        </Col>
      </Row>
    ) : (
      <StartState />
    );
  }
  getAutomationsFlow() {
    const { company, user } = this.props.metadata;
    return (
      <Automations
        company={company}
        user={user}
        enableReminders={this.onSwitchChange}
      />
    );
  }
  renderTeammates(usersList) {
    const { user } = this.props.metadata;
    return (
      <>
        {!isInternalUser() ? <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={20} lg={18} xl={18}>
            <div className="teams-table-header">
              <Space size={14}>
                <Tooltip
                  placement="bottomRight"
                  title={
                    "Turning on will email you a reminder 10 days before each birthday."
                  }
                >
                  <Switch
                    checked={!user.remindersDisabled}
                    onChange={this.onSwitchChange}
                    size="small"
                    style={{ marginTop: "-2px" }}
                  />
                </Tooltip>

                <span className="reminder-switch">
                  <i className="fal fa-envelope"></i>{" "}
                  <span>Receive Reminders</span>
                </span>
              </Space>
            </div>
          </Col>
        </Row> : null }
        <Row justify="center" align="middle" className="teams-table">
          <Col xs={24} sm={24} md={20} lg={18} xl={18}>
            <Table
              rowKey={"email"}
              pagination={false}
              columns={this.getColumnsTeams()}
              onRow={(record) => {
                return {
                  onClick: this.onTeamRowClick.bind(this, record),
                };
              }}
              dataSource={usersList}
            />
          </Col>
        </Row>
      </>
    );
  }
  getTabSharedModals() {
    const { usersList, company } = this.props.metadata;
    const {
      showTeammateModal,
      teammateUser,
      teammateModalMounted,
      deleteModalCardItem,
      deleteModalUserItem,
    } = this.state;
    return (
      <>
        <DeleteCardModal
          deleteCard={this.props.cardActions.deleteCardAndPosts}
          card={deleteModalCardItem}
          onClose={() => this.setState({ deleteModalCardItem: null })}
        />

        <DeleteUserModal
          deleteUser={this.props.metadataActions.deleteUser}
          user={deleteModalUserItem}
          onClose={() => this.setState({ deleteModalUserItem: null })}
        />

        {teammateModalMounted ? (
          <Modal
            width={520}
            maskClosable={true}
            visible={showTeammateModal}
            afterClose={this.afterTeammateModalClose}
            footer={null}
            onCancel={this.hideTeammateModal}
            centered
            closeIcon={<i className="fal fa-times"></i>}
          >
            <AddEditTeammate
              user={teammateUser}
              onSave={this.onProfileSave}
              company={company}
              usersList={usersList}
              onAllDone={this.hideTeammateModal}
              includeNewUser={this.props.metadataActions.includeUser}
            />
          </Modal>
        ) : null}
      </>
    );
  }
  render() {
    const { fetchedCardsList } = this.props.card;
    const { usersList, user } = this.props.metadata;
    const { showSettingsView } = this.props.auth;
    if (!fetchedCardsList) {
      return this.renderLoader();
    }
    var isAdmin = false;
    if (user) {
      isAdmin = !!user.isAdmin;
      if (!user.name) {
        setTimeout(() => nav.goto(nav.URL_AUTH_FLOW_JOIN_VERIFIED));
      }
    }
    return (
      <div className={`HomePage ${isAdmin ? "admin-user" : "non-admin-user"}`}>
        <Layout>
          <HeaderLogin
            auth={this.props.auth}
            actions={this.props.actions}
            isAdmin={isAdmin}
            activeTabId={this.getActiveTabId()}
            onTabChanged={this.onTabChanged}
            onNewTeammate={this.onNewTeammate}
            urlLocation={this.props.location}
          />
          <Layout>
            <Content className="activity">
              {isAdmin ? (
                <>
                  <Tabs
                    defaultActiveKey="1"
                    activeKey={this.getActiveTabId()}
                    onChange={this.onTabChanged}
                  >
                    <TabPane tab={OC_CARD_PLURAL} key="1">
                      {!showSettingsView && this.renderCardsList()}
                    </TabPane>
                    <TabPane tab={isInternalUser() ? `Team` : `Team & Reminders`} key="2">
                      {!showSettingsView &&
                        this.renderTeammates(
                          this.getSortedUsersList(usersList)
                        )}
                    </TabPane>
                    {isInternalUser() ? (
                      <TabPane tab="Automations" key="3">
                        {!showSettingsView && this.getAutomationsFlow()}
                      </TabPane>
                    ) : null}
                  </Tabs>
                  {showSettingsView ? <Payment /> : null}
                </>
              ) : showSettingsView ? (
                <Payment />
              ) : (
                this.renderCardsList()
              )}
            </Content>
          </Layout>
        </Layout>
        {this.getTabSharedModals()}
        <MainFooter />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth, card, metadata } = state;
  return { auth, card, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    cardActions: bindActionCreators(cardActions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
