import React from "react";
import { Button, Form, Input, message } from "antd";
import "./styles/Modal.less";
import OC from "../oc";
import User from "../oc/models/user";
import DayMonthPicker from "./DayMonthPicker";

class AddEditTeammate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      name: "",
      email: "",
      dobDay: null,
      dobMonth: null,
      isValidDOB: false,
      isValidAnniversary: false,
      anniversaryDay: null,
      anniversaryMonth: null,
      emailError: null,
      nameError: null,
    };
    this.onDoneClicked = this.onDoneClicked.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.onEmailFocus = this.onEmailFocus.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onDobChanged = this.onDobChanged.bind(this);
    this.onAnniversaryChanged = this.onAnniversaryChanged.bind(this);
  }
  onDobChanged(val) {
    this.setState({
      dobDay: val.day,
      dobMonth: val.month,
      isValidDOB: val.isValid,
    });
  }
  onAnniversaryChanged(val) {
    this.setState({
      anniversaryDay: val.day,
      anniversaryMonth: val.month,
      isValidAnniversary: val.isValid,
    });
  }
  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  isValidEmail(email, domain) {
    if (!domain || !email || email.indexOf("@") === -1) {
      return false;
    }
    return email.split("@").pop() === domain;
  }
  emailAlreadyExists(email) {
    const { usersList } = this.props;
    if (!usersList) {
      return false;
    }
    for (var i = 0; i < usersList.length; i++) {
      if (usersList[i].email === email) {
        return true;
      }
    }
    return false;
  }
  validate(email) {
    const { company } = this.props;
    if (!this.isValidEmail(email, company.domain)) {
      const domain =
        company && company.domain ? company.domain : "valid domain names";
      const error = (
        <span className="error-state">
          Only email addresses ending in <strong>{domain}</strong> are
          permitted.
        </span>
      );
      this.setState({ emailError: error });
      return false;
    }
    if (this.emailAlreadyExists(email)) {
      const error = <span className="error-state">User already exists.</span>;
      this.setState({ emailError: error });
      return false;
    }
    return true;
  }
  onEmailBlur(e) {
    this.validate(e.target.value);
    return;
  }
  onEmailFocus() {
    this.setState({ emailError: null });
  }
  addUpdateUser(user, isExistingUser) {
    const { includeNewUser, onAllDone } = this.props;
    OC.getInstance()
      .addUser(user, isExistingUser)
      .then((err) => {
        if (err) {
          message.error(
            "Could not update user info. Please check network connection."
          );
          setTimeout(() => {
            this.setState({ busy: false });
          });
          return;
        }
        OC.getInstance()
          .findCompanyUser(user.email)
          .then((newUser) => {
            setTimeout(() => {
              this.setState({ busy: false });
              onAllDone && onAllDone();
            });
            if (newUser) {
              includeNewUser && includeNewUser(newUser);
              return;
            } else {
              message.error(
                "There was a problem updating user info. Please try again."
              );
              return;
            }
          });
      });
  }
  isValidName(name) {
    if (!name || name === undefined) {
      this.setState({ nameError: "The name field is required" });
      return false;
    } else return true;
  }
  isEmail(newTeammate) {
    if (newTeammate && !this.state.email) {
      const error = (
        <span className="error-state">The email field is required</span>
      );
      this.setState({ emailError: error });
      return false;
    } else if (newTeammate && !this.validate(this.state.email)) {
      return false;
    } else return true;
  }
  async onDoneClicked() {
    if (this.state.busy) {
      return;
    }
    const { onAllDone } = this.props;
    const { name, isValidDOB, isValidAnniversary } = this.state;
    const newTeammate = !this.props.user;
    const user = this.props.user ? this.props.user : new User();
    const isValidName = this.isValidName(name);
    const isEmail = this.isEmail(newTeammate);
    if (!isValidName || !isValidDOB || !isEmail || !isValidAnniversary) {
      return;
    }
    if (!newTeammate) {
      if (
        user.name === this.state.name &&
        user.dob.day === this.state.dobDay &&
        user.dob.month === this.state.dobMonth &&
        user.anniversary.day === this.state.anniversaryDay &&
        user.anniversary.month === this.state.anniversaryMonth
      ) {
        onAllDone && onAllDone();
        return;
      }
    } else {
      user.email = this.state.email;
    }
    user.name = this.state.name;
    user.dob.day = this.state.dobDay;
    user.dob.month = this.state.dobMonth;
    user.anniversary.day = this.state.anniversaryDay;
    user.anniversary.month = this.state.anniversaryMonth;
    this.setState({ busy: true });
    this.addUpdateUser(user, !newTeammate);
  }
  onNameChanged(e) {
    this.setState({ name: e.target.value, nameError: null });
  }
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        name: user.name,
        dobDay: user.dob ? user.dob.day : null,
        dobMonth: user.dob ? user.dob.month : null,
      });
    }
  }
  renderEmailInput() {
    const { emailError } = this.state;
    return (
      <Form.Item>
        <h4>Work Email Address</h4>
        <Input
          placeholder="Michael.Scott@DunderMifflin.com"
          style={emailError && { borderColor: "#f5222d", borderWidth: 1 }}
          onChange={this.onEmailChange}
          onFocus={this.onEmailFocus}
          onBlur={this.onEmailBlur}
          prefix={<i className="fal fa-envelope-open"></i>}
          value={this.state.email}
        />
        {emailError && <span>{emailError}</span>}
      </Form.Item>
    );
  }
  render() {
    const newTeammate = !this.props.user;
    const { user } = this.props;
    const { nameError } = this.state;
    return (
      <div className="ModalUpdateProfile">
        <div className="title">
          <h2>{newTeammate ? "New Teammate" : "Edit Teammate"}</h2>
          {newTeammate ? null : <p>{user.email}</p>}
        </div>

        <Form name="basic">
          <Form.Item>
            <h4>Name</h4>
            <Input
              value={this.state.name}
              onChange={this.onNameChanged}
              placeholder="Pam Beesly"
              prefix={<i className="fal fa-user"></i>}
            />
            {nameError && <span className="error-state">{nameError}</span>}
          </Form.Item>
          {newTeammate ? this.renderEmailInput() : null}
          <h4>
            Birthday <span>(Optional)</span>
          </h4>
          <DayMonthPicker
            onValueChange={this.onDobChanged}
            day={user?.dob?.day}
            month={user?.dob?.month}
            isNullable={true}
          />
          <br></br>
          <h4>
            Work Anniversary <span>(Optional)</span>
          </h4>
          <DayMonthPicker
            onValueChange={this.onAnniversaryChanged}
            day={user?.anniversary?.day}
            month={user?.anniversary?.month}
            isNullable={true}
          />

          <Form.Item>
            <Button
              disabled={this.state.busy}
              loading={this.state.busy}
              onClick={this.onDoneClicked}
              type="primary"
              size="large"
              block
              htmlType="submit"
            >
              Done
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default AddEditTeammate;
