import * as types from '../constants';

const initialState = {
  draftCard: null,
  cardSaveError: "",
  posts: [],
  fetchedPostsList: false,
  cardsPostsList: [], //mz-todo: Rename to cardsList. Posts are no longer part of card object.
  fetchedCardsList: false,
  inviteModalVisible: false,
}

export function card(state = initialState, action) {
  switch (action.type) {
    case types.ON_TOGGLE_INVITE_MODAL:
      return {
        ...state,
        inviteModalVisible: action.visible,
      };    
    case types.ON_CARD_FETCHED:
      return { ...state, draftCard: action.card, cardSaveError: "" }
    case types.ON_CARD_SAVE_FAILED:
      return { ...state, cardSaveError: action.msg }
    case types.ON_CARD_SAVED:
      const newList = state.cardsPostsList;
      newList.unshift(action.card);
      return { ...state, draftCard: action.card, cardsPostsList: newList, cardSaveError: "" }
    case types.ON_POSTS_FETCHED:
      return { ...state, posts: action.posts, fetchedPostsList: true }
    case types.ON_POST_SAVED:
      const newPosts = state.posts;
      const ind = newPosts.findIndex(element => {
        return element.id === action.post.id
      });
      if (ind !== -1) {
        newPosts[ind] = action.post;
      } else {
        newPosts.push(action.post);
      }
      return { ...state, posts: newPosts }
    case types.CLEAR_DRAFT:
      return { ...state, draftCard: null, cardSaveError: "", posts: [], fetchedPostsList: false }
    case types.ON_CARDS_FETCHED:
      return { ...state, cardsPostsList: action.cards, fetchedCardsList: true }
    case types.ON_UPDATE_USER_INFO:
      const newPostsList = state.posts;
      const foundPost = newPostsList.find(element => {
        return element.ownerInfo && element.ownerInfo.ownerId === action.userInfo.email
      });
      if (foundPost && foundPost !== undefined) {
        const { location, name } = action.userInfo;
        foundPost.ownerInfo.location = location && location.name ? location.name : "";
        foundPost.ownerInfo.name = name;
      }
      return { ...state, posts: newPostsList }
    case types.ON_CARD_AND_POSTS_DELETED:
      const newCardsPostsList = [];
      state.cardsPostsList.forEach(element => {
        element.id !== action.cardId && newCardsPostsList.push(element);
      });
      return { ...state, cardsPostsList: newCardsPostsList }
    default:
      return state;
  }
}