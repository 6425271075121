import React from "react";
import { Route } from "react-router";
import * as nav from "./nav";

import AppContainer from "../containers/AppContainer";
import HomePage from "../containers/HomePage";
import MagicAuth from "../containers/MagicAuth";
import AuthFlowContainer from "../containers/AuthFlowContainer";
import CardMainContainer from "../containers/CardMainContainer";
import RedirectHandler from "../containers/RedirectHandler";
import Privacy from "../containers/Privacy";
import Terms from "../containers/Terms";
import AutomationGW from "../containers/AutomationGW";

export default (
  <Route exact path="/" component={AppContainer}>
    <Route path={nav.URL_PAGE} components={{ content: CardMainContainer }} />
    <Route path={nav.URL_HOMEPAGE} components={{ content: HomePage }} />
    <Route path={nav.URL_PRIVACY} components={{ content: Privacy }} />
    <Route path={nav.URL_TERMS} components={{ content: Terms }} />
    <Route path={nav.URL_HOMEPAGE_JUBILI} components={{ content: HomePage }} />
    <Route path={nav.URL_DRAFT} components={{ content: RedirectHandler }} />
    <Route path={nav.URL_VIEW} components={{ content: RedirectHandler }} />
    <Route path={nav.URL_POST} components={{ content: RedirectHandler }} />
    <Route path={nav.URL_CREATE} components={{ content: RedirectHandler }} />
    <Route path={nav.URL_REMINDERS} components={{ content: HomePage }} />
    <Route path={nav.URL_SETTINGS} components={{ content: HomePage }} />
    {/* <Route
      path={nav.URL_CARD_SELECTION}
      components={{ content: CardSelection }}
    /> */}
    <Route path={nav.URL_AUTH_ACTION} components={{ content: MagicAuth }} />
    <Route
      path={nav.URL_AUTH_FLOW_JUBILI}
      components={{ content: AuthFlowContainer }}
    />
    <Route
      path={nav.URL_AUTH_FLOW_JOIN}
      components={{ content: AuthFlowContainer }}
    />
    <Route
      path={nav.URL_AUTH_FLOW_SIGNIN}
      components={{ content: AuthFlowContainer }}
    />
    <Route
      path={nav.URL_AUTH_FLOW_JUBILI_VERIFIED}
      components={{ content: AuthFlowContainer }}
    />
    <Route
      path={nav.URL_AUTH_FLOW_JOIN_VERIFIED}
      components={{ content: AuthFlowContainer }}
    />
    <Route path={nav.AUTOMATION_GW} components={{ content: AutomationGW }} />
    {/* <Route path="/planselection" components={{ content: PlanSelection }} /> */}

    {/* <Route path="/components/sider" components={{ content: SiderLogin }} /> */}

    {/* <Route path="/cardflow" components={{ content: CardFlow }} /> */}
    {/* <Route path="*" component={NoMatch}/> */}
    <Route path="*" />
  </Route>
);
