import React from "react";
import { Row, Col, Button } from "antd";
import "./styles/ShareFooter.less";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { getLink, copyLink } from "../shared/util";

class ShareFooter extends React.Component {
  constructor(props) {
    super(props);
    this.onClickCopyLink = this.onClickCopyLink.bind(this);
  }
  onClickCopyLink() {
    copyLink("ShareFooter:onClickCopyLink");
  }
  render() {
    return (
      <Row justify="center" align="middle">
        <Col span={24}>
          <div className="share-footer">
            <h4>Share this with your friends!</h4>
            <div className="social">
              <LinkedinShareButton
                className="linkedin"
                url={`${getLink("ShareFooter:render:ln")}?welcome=1`}
                quote="Office Birthdays Cards for the New Normal"
                hashtag="#greeted"
              >
                <i className="fab fa-linkedin-in"></i> LinkedIn
              </LinkedinShareButton>

              <FacebookShareButton
                className="facebook"
                url={`${getLink("ShareFooter:render:fb")}?welcome=1`}
                quote="Office Birthdays Cards for the New Normal"
                hashtag="#greeted"
              >
                <i className="fab fa-facebook-f"></i> Facebook
              </FacebookShareButton>

              <TwitterShareButton
                className="twitter"
                url={`${getLink("ShareFooter:render:tw")}?welcome=1`}
                quote="Office Birthdays Cards for the New Normal"
                hashtag="#greeted"
              >
                <i className="fab fa-twitter"></i> Twitter
              </TwitterShareButton>
            </div>
            <Button
              className="copy-link"
              size="large"
              block="true"
              onClick={this.onClickCopyLink}
            >
              <i className="far fa-link"></i> Copy Link
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ShareFooter;
