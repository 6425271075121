import React from "react";
import { Select, Divider, Button } from "antd";
import "../styles/Modal.less";
import User from "../../oc/models/user";
import UserAvatar from "./UserAvatar";

const { Option } = Select;

class UserSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      selectedUser: null,
      savingUser: false,
    };
    this.onUserChanged = this.onUserChanged.bind(this);
    // this.filterUsers = this.filterUsers.bind(this); //mz-todo: Removing custom implementation. Remove entirely after through testing.
    this.onFilterTextChanged = this.onFilterTextChanged.bind(this);
    this.onAddItemClicked = this.onAddItemClicked.bind(this);
    this.onVisibleChange = this.onVisibleChange.bind(this);
    this.onSelectKeyDown = this.onSelectKeyDown.bind(this);
    this.selectRef = React.createRef();
  }
  partialMatchesUserName(name) {
    if (!name) {
      return false;
    }
    const { usersList } = this.props;
    if (!usersList) {
      return null;
    }
    var selectedUser = usersList.find((element) =>
      element.name &&
      element.name.toLowerCase().indexOf(name.toLowerCase()) > -1
        ? true
        : false
    );
    return selectedUser ? true : false;
  }
  onSelectKeyDown(e) {
    if (e.key === "Tab" || e.key === "Enter") {
      if (!this.partialMatchesUserName(this.state.filterText)) {
        this.onAddItemClicked();
      }
    }
  }
  onVisibleChange(open) {
    if (!open) {
      this.setState({ filterText: "" });
    }
  }
  getUserByEmail(email) {
    if (!email) {
      return null;
    }
    const usersList = this.getUsersList();
    if (!usersList) {
      return null;
    }
    var selectedUser = usersList.find(
      (element) => element.email && element.email.toLowerCase() === email
    );
    return selectedUser ? selectedUser : null;
  }
  checkUserByName(name) {
    if (!name) {
      return false;
    }
    const { usersList } = this.props;
    if (!usersList) {
      return null;
    }
    var selectedUser = usersList.find(
      (element) =>
        element.name && element.name.toLowerCase() === name.toLowerCase()
    );
    return selectedUser ? true : false;
  }
  onUserChanged(email) {
    const { onUserSelect } = this.props;
    const u = this.getUserByEmail(email);
    this.setState({ selectedUser: u });
    onUserSelect && onUserSelect(u);
  }
  //mz-todo: Removing custom implementation. Remove entirely after through testing.
  // filterUsers(input, option) {
  //   if (this.state.savingUser) {
  //     return false;
  //   }
  //   if (!option.children.length || option.children.length < 2) {
  //     return false;
  //   }
  //   return (
  //     option.children[1] &&
  //     option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
  //   );
  // }
  onFilterTextChanged(e) {
    if (this.state.savingUser) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    this.setState({ filterText: e.target.value });
  }
  onAddItemClicked() {
    const { filterText } = this.state;
    const { onAddNewUser, onUserSelect, newUser } = this.props;
    if (!filterText || !onAddNewUser || !onUserSelect) {
      return;
    }
    const u = newUser && newUser.isDraft ? newUser : new User();
    u.name = filterText;
    u.email = u.email || "?";
    u.isDraft = true;
    onAddNewUser(u);
    this.setState({ filterText: "" });
    setTimeout(() => {
      if (this.selectRef && this.selectRef.current) {
        this.selectRef.current.blur && this.selectRef.current.blur();
      }
    });
  }
  getLabel(filterText, isSaving) {
    const nameExists = this.checkUserByName(filterText);
    const label = isSaving
      ? nameExists
        ? "Adding another "
        : "Adding "
      : nameExists
      ? "Add another "
      : "Add ";
    return (
      <span>
        {label}
        <strong>{filterText}</strong>
      </span>
    );
  }
  getUsersList() {
    const { usersList, newUser } = this.props;
    if (!usersList) {
      return [];
    }
    if (!newUser) {
      return usersList;
    }
    const newList = [];
    newList.push(newUser);
    usersList.forEach((element) => {
      newList.push(element);
    });
    return newList;
  }
  render() {
    const { savingUser, filterText } = this.state;
    const { recipient } = this.props;
    const aggUsersList = this.getUsersList();
    return (
      <Select
        ref={this.selectRef}
        onDropdownVisibleChange={this.onVisibleChange}
        value={(recipient && recipient.email) || undefined}
        size="large"
        showSearch
        allowClear
        placeholder="Michael Scott"
        optionFilterProp="data-search"
        // filterOption={this.filterUsers} //mz-todo: Removing custom implementation. Remove entirely after through testing.
        className="rname"
        dropdownClassName="rname-dropdown"
        suffixIcon={<i className="fal fa-user"></i>}
        onChange={this.onUserChanged}
        onKeyUp={this.onFilterTextChanged}
        onKeyDown={this.onSelectKeyDown}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {filterText && <Divider style={{ margin: "4px 0" }} />}
            {filterText && (
              <div
                className="custom-add"
                style={
                  !this.partialMatchesUserName(this.state.filterText)
                    ? { backgroundColor: "#E6F7FF" }
                    : null
                }
              >
                <Button
                  type="link"
                  disabled={savingUser}
                  onClick={this.onAddItemClicked}
                >
                  <i className="fal fa-plus-circle"></i>{" "}
                  {this.getLabel(filterText, savingUser)}
                </Button>
              </div>
            )}
          </div>
        )}
      >
        {aggUsersList.map((item) => {
          return (
            <Option
              key={`user_option_${item.email}`}
              value={item.email}
              data-search={item.name}
            >
              <UserAvatar
                key={`avatar_${item.email}`}
                size={30}
                downloadURL={item.avatarDownloadURL}
                fetchPath={item.avatar}
                imageStyles={{ marginRight: "12px" }}
                showUserOutline={true}
                versionTag={item._lastUpdated || null}
                onFetchUrl={
                  item.avatarDownloadURL
                    ? null
                    : (url) => {
                        item.avatarDownloadURL = url;
                      }
                }
              />
              <span className="user-name">{item.name}</span>
              {/* mz-info: Removing location */}
              {/* <span className="location">{item.location.name}</span> */}
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default UserSelect;
