import * as types from "../constants";

const initialState = {
  locationsFetched: false,
  usersListFetched: false,
  userFetched: false,
  companyFetched: false,
  categories: [],
  locations: [],
  usersList: [],
  user: null,
  company: null,
  userProfileModalVisible: false,
  actionArgs: [],
  brithdayModalVisible: false,
};

export function metadata(state = initialState, action) {
  switch (action.type) {
    case types.ON_TOGGLE_BIRTHDAY_MODAL:
      return {
        ...state,
        brithdayModalVisible: action.visible,
      };
    case types.ON_TOGGLE_PROFILE_MODAL:
      return {
        ...state,
        userProfileModalVisible: action.visible,
        actionArgs: action.args ? action.args : [],
      };
    case types.ON_CLEAR_ACTION_ARGS:
      return { ...state, actionArgs: [] };
    case types.ON_CATEGORIES_FETCHED:
      return { ...state, categories: action.categories };
    case types.ON_LOCATIONS_FETCHED:
      action.locations = action.locations.sort((a, b) => {
        const l1 =
          a.name && a.name.toLowerCase ? a.name.toLowerCase() : a.name || "";
        const l2 =
          b.name && b.name.toLowerCase ? b.name.toLowerCase() : b.name || "";
        if (l1 < l2) {
          return -1;
        }
        if (l1 > l2) {
          return 1;
        }
        return 0;
      });
      return { ...state, locations: action.locations, locationsFetched: true };
    case types.ON_LOCATION_SAVED:
      var newLoc = state.locations;
      newLoc.push(action.newLocation);
      newLoc = newLoc.sort((a, b) => {
        const l1 =
          a.name && a.name.toLowerCase ? a.name.toLowerCase() : a.name || "";
        const l2 =
          b.name && b.name.toLowerCase ? b.name.toLowerCase() : b.name || "";
        if (l1 < l2) {
          return -1;
        }
        if (l1 > l2) {
          return 1;
        }
        return 0;
      });
      return { ...state, locations: newLoc };
    case types.ON_UPDATE_USER_INFO:
      const newUsersList = [];
      newUsersList.push(...state.usersList);
      const ind = newUsersList.findIndex((element) => {
        return element.email === action.userInfo.email;
      });
      if (ind !== -1) {
        newUsersList[ind] = action.userInfo;
      } else {
        newUsersList.push(action.userInfo);
      }
      return { ...state, user: action.userInfo, usersList: newUsersList };
    case types.SET_USER_DEFAULT_PLAN:
      if (state.user) {
        state.user.defaultPlan = action.defaultPlan;
      }
      return { ...state, user: state.user };
    case types.ON_MAKE_ADMIN:
      const updatedUsersList = state.usersList;
      const adminInd = updatedUsersList.findIndex((element) => {
        return element.email === action.email;
      });
      if (adminInd > -1) {
        updatedUsersList[adminInd].isAdmin = action.value;
      }
      return { ...state, usersList: updatedUsersList };

    case types.ON_TOGGLE_REMINDERS:
      const toggleUpdatedUsersList = state.usersList;
      const currentUser = state.user;
      if (!currentUser) {
        return { ...state };
      }
      const toggleInd = toggleUpdatedUsersList.findIndex((element) => {
        return element.email === action.email;
      });
      if (toggleInd > -1) {
        toggleUpdatedUsersList[toggleInd].remindersDisabled = action.value;
      }
      currentUser.remindersDisabled = action.value;
      return { ...state, usersList: toggleUpdatedUsersList, user: currentUser };

    case types.ON_DELETE_USER:
      const deleteUsersList = [];
      state.usersList.forEach((element) => {
        element.email !== action.email && deleteUsersList.push(element);
      });
      return { ...state, usersList: deleteUsersList };

    case types.ON_FETCHED_USER_INFO:
      return { ...state, user: action.userInfo, userFetched: true };
    case types.ON_USERS_FETCHED:
      return { ...state, usersList: action.usersList, usersListFetched: true };
    case types.ON_COMPANY_UPDATE:
    case types.ON_COMPANY_FETCHED:
      const fetched = action.company !== undefined && action.company;
      return { ...state, company: action.company, companyFetched: !!fetched };
    case types.ON_AUTH_CHANGED:
    case types.ON_LOGOUT:
    case types.FETCHING_USER_INFO:
      return {
        ...state,
        user: null,
        userFetched: false,
        locationsFetched: false,
        usersListFetched: false,
        locations: [],
        usersList: [],
      };
    case types.ON_FETCH_AVATAR:
      const { user } = state;
      if (!user) {
        return { ...state };
      }
      user.avatarDownloadURL = action.downloadURL;
      return { ...state, user: user };
    case types.ON_INCLUDE_USER:
      const includedUsersList = [];
      includedUsersList.push(...state.usersList);
      const indNewUser = state.usersList.findIndex((element) => {
        return element.email === action.user.email;
      });
      if (indNewUser === -1) {
        includedUsersList.push(action.user);
      }
      return { ...state, usersList: includedUsersList };
    default:
      return state;
  }
}
