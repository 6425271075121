import React from "react";
import "./../styles/HeaderOne.less";
import { Layout, Button, Space, Tooltip } from "antd";
import AuthHeader from "./../AuthHeader";
import logo from "./../img/logo-greeted.svg";
import * as nav from "../../routes/nav";
import { OC_PROD_TITLE } from "../../shared/constants";
import { copyLink } from "../../shared/util";

const { Header } = Layout;

class HeaderRecipientView extends React.Component {
  constructor(props) {
    super(props);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.onCopyLinkClicked = this.onCopyLinkClicked.bind(this);
  }
  onLogoClick() {
    nav.gotoMain();
  }
  onCopyLinkClicked() {
    copyLink("HeaderRecipientView:onCopyLinkClicked");
  }
  getTag(cardData) {
    if (cardData.delivery && cardData.delivery.utc) {
      return (
        <span className={`status ${"status-published"}`}>
          <i className={"fal fa-lock"}></i> {"Published & Sent"}{" "}
        </span>
      );
    } else {
      return (
        <span className={`status ${"status-published"}`}>
          <i className={"fal fa-lock"}></i> {"Published"}{" "}
        </span>
      );
    }
  }
  render() {
    const { cardData } = this.props;
    return (
      <div className="HeaderDraft">
        <Layout>
          <Header theme="light">
            <div className="logo-parent">
              <div className="logo">
                <Button type="link" onClick={this.onLogoClick}>
                  <img src={logo} alt={OC_PROD_TITLE} />
                </Button>
              </div>
            </div>

            <div className="actions">
              <Space size={18}>
                <Tooltip trigger="hover" title={"Share this card."}>
                  <Button
                    type="link"
                    className="copy-link"
                    onClick={this.onCopyLinkClicked}
                  >
                    <i className="fal fa-link"></i> Copy Link
                  </Button>
                </Tooltip>
                {cardData && !cardData.isRecipient()
                  ? this.getTag(cardData)
                  : null}
                <AuthHeader />
              </Space>
            </div>
          </Header>
        </Layout>
      </div>
    );
  }
}

export default HeaderRecipientView;
