import { message } from "antd";
import Logger from "../shared/logger";
import { isMagicLinkURL, goto as redirectTo } from "../routes/nav";
export const PRIORITY_UNKNOWN = "FB_UNKNOWN";
export const PRIORITY_NORMAL = "FB_NORMAL";
export const PRIORITY_HIGH = "FB_HIGH"; //Erros that indicate failure in essential functionalities.
export const PRIORITY_CRITICAL = "FB_CRITICAL"; //Errors that might break data state integrity.
export const PRIORITY_PERMISSION_DENIED = "FB_PERMISSION_DENIED";
export const ERR_INVALID_COMPANY_ID = new Error("invalid company id");
export const ERR_INVALID_USER_EMAIL = new Error("invalid user email");
export const ERR_AUTH_WITHOUT_USER = new Error(
  "invalid auth without user item"
);
export const ERR_POST_COUNT_CORRECTION_PERMISSION_FAILED = new Error("couldn't correct postsCount due to missing or insufficient firestore permissions");
export const ERR_POST_COUNT_NOT_MATCHED = new Error("postsCount didn't match");
export const ERR_EXPECTED_USER_NOT_FOUND = new Error("expected user not found");
export const ERR_CHECKOUT_TIMOUT = new Error("timeout while waiting for checkout url");
export const ERR_MULTI_RECURRING_SUB = new Error("multiple recurring subscriptions found");
export const ERR_SUBSCRIPTION_CANCEL_FAILED = new Error("cancel subscription request did not return with status: ok");
export const ERR_PRODUCT_PRICE_NOT_FOUND = new Error("could not find Price document in Product");
export const ERR_EXECUTE_SERVER_SIDE_FUNC = new Error("error while executing cloud func: executeServerSide");

export async function logError(err, tag, priority, errData) {
  if (err) {
    if (isClientOffline(err)) {
      setTimeout(() => {
        try {
          if (isMagicLinkURL()) {
            redirectTo("/");
          } else {
            window.location.reload();
          }
        } catch {
          window.location.reload();
        }
      }, 2500);
      //mz-todo: After observing for some time, if consecutive "client offline" errors
      //         stop occurring, bypass Error log for this condition entirely.
      //         Because this would mean "location.reload" is taking care of this issue.
      //         and we don't need to log these errors anymore.
      //         This way, we won't have to worry about client side browser polling and
      //         generating multiple logs. <- warn: Remove after live alpha.
    }
    if (isPermissionDenied(err)) {
      message.error("This operation requires specific user rights.", 8);
    }
  }
  console.log(err); //warn: Remove after live alpha.
  try {
    await Logger.logError(
      isPermissionDenied(err) ? PRIORITY_PERMISSION_DENIED : (priority || PRIORITY_UNKNOWN),
      err && err.message ? err.message : "",
      tag,
      errData
    );
  } catch {}
}

export function isPermissionDenied(err) {
  if (err?.code === "permission-denied" && err?.name === "FirebaseError") {
    return true;
  }
  return false;
}

function isClientOffline(err) {
  if (!err || !err.message || !err.name) {
    return false;
  }
  const errName = "FirebaseError";
  const actualMessage = "Failed to get document because the client is offline";
  const hotKeyword = "client is offline";
  if (
    err.name === errName &&
    (err.message.toLowerCase().indexOf(actualMessage.toLowerCase()) !== -1 ||
      err.message.toLowerCase().indexOf(hotKeyword) !== -1)
  ) {
    return true;
  }
  return false;
}
