import Model from './model';

export default class Category extends Model {
  id = null;
  key = null;
  seq = null;
  icon = null;
  name = null;
  
  getKey() {
    return this.id;
  }

  parse(id, data) {
    this.id = id;
    this.key = data.key;
    this.seq = data.seq;
    this.icon = data.icon;
    this.name = data.name;
    return this;
  }
}