import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as metadataActions from "../actions/metadata";
import "./styles/HomePage.less";
import "./styles/Payment.less";
import { Layout, Menu } from "antd";
import Billing from "../components/Billing";
import Branding from "../components/Branding";

const { Content, Sider } = Layout;

const KEY_MENU_BILLING = "menu_billing";
const KEY_MENU_BRANDING = "menu_branding";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuKey: KEY_MENU_BILLING,
    };
    this.onMenuSelect = this.onMenuSelect.bind(this);
  }
  onMenuSelect(item) {
    this.setState({ selectedMenuKey: item.key });
  }
  renderBilling() {
    return (
      <Content style={{ padding: "0 24px", minHeight: 280 }}>
        <Billing />
      </Content>
    );
  }
  renderBranding() {
    return (
      <div style={{ padding: "0 24px", width: 500 }}>
        <Branding
          metadata={this.props.metadata}
          metadataActions={this.props.metadataActions}
        />
      </div>
    );
  }
  render() {
    return (
      <div className="Payment">
        <Layout>
          <Sider theme="light" width={250}>
            <Menu
              onSelect={this.onMenuSelect}
              mode="inline"
              defaultSelectedKeys={[KEY_MENU_BILLING]}
            >
              {/* <Menu.Item key="1">
                  <i className="fal fa-tachometer-fast"></i> Usage
                </Menu.Item> */}
              <Menu.Item key={KEY_MENU_BILLING}>
                <i className="fal fa-receipt"></i> Billing
              </Menu.Item>
              <Menu.Item key={KEY_MENU_BRANDING}>
                <i className="fal fa-palette"></i> Branding
              </Menu.Item>
            </Menu>
          </Sider>
          {this.state.selectedMenuKey === KEY_MENU_BILLING
            ? this.renderBilling()
            : this.renderBranding()}
        </Layout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { metadata } = state;
  return { metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
