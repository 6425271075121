import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as metadataActions from "../actions/metadata";
import * as actions from "../actions/auth";
import ZeroStateEmail from "../components/ZeroStateEmail";
import ZeroStatePassword from "../components/ZeroStatePassword";
import ZeroStateSettings from "../components/ZeroStateSettings";
import ZeroStateVerify from "../components/ZeroStateVerify";
import HeaderLogoOnly from "../components/HeaderLogoOnly";
import { message } from "antd";
import * as nav from "../routes/nav";
import {
  STATE_JUBILI_EMAIL,
  STATE_JOIN_EMAIL,
  STATE_SIGNIN_EMAIL,
  STATE_SIGNIN_PASSWORD,
  STATE_JUBILI_PASSWORD,
  STATE_USER_SETTINGS,
  STATE_VERIFICATION_LINK,
  STATE_SIGNIN_LINK,
  // STATE_JUBILI_PHOTO,
  OC_TYPE_CAT,
  OC_TYPE_JUBILI_VERIFIED,
  OC_TYPE_JOIN_VERIFIED,
  OC_TYPE_MAIN,
} from "../shared/constants";
import * as errc from "../oc/error_codes";
import "./styles/AuthFlowContainer.less";
import OC from "../oc";

const RECENT_LOGIN_LIMIT = 240000; // 4 minutes;

class AuthFlowContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowInitiator: "",
      authState: "",
      existingUser: null,
      resendEmailAddress: "",
      resendLinkType: "",
      emailError: "",
      password: "",
      userSettings: {},
      photo: null,
      companyLogo: null,
      logoFetched: false,
      passwordBusy: false,
    };
    this.onEmailSet = this.onEmailSet.bind(this);
    this.onPasswordSet = this.onPasswordSet.bind(this);
    this.onSettingsSet = this.onSettingsSet.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.onReroute = this.onReroute.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps?.location?.pathname !== this.props.location?.pathname) {
      this.onReroute();
    }
  }
  onReroute() {
    this.initComponent();
  }
  redirectingForLoggedIn() {
    const { user } = this.props.metadata;
    const { currentUser } = this.props.auth;
    if (user && currentUser) {
      setTimeout(() => nav.goto(nav.URL_HOMEPAGE));
      return true;
    }
    return false;
  }
  initComponent() {
    const { query } = this.props.location;
    this._isMounted = true;
    this.loadImages();
    if (nav.isAuthFlowJubiliURL()) {
      this.setState({
        authState: STATE_JUBILI_EMAIL,
        flowInitiator: STATE_JUBILI_EMAIL,
      });
    } else if (nav.isAuthFlowJoinURL()) {
      this.setState({
        authState: STATE_JOIN_EMAIL,
        flowInitiator: STATE_JOIN_EMAIL,
      });
    } else if (nav.isAuthFlowSigninURL()) {
      if (query?.timeout !== "true" && this.redirectingForLoggedIn()) {
        return;
      }
      this.setState({
        authState: STATE_SIGNIN_EMAIL,
        flowInitiator: STATE_SIGNIN_EMAIL,
      });
    } else if (nav.isAuthFlowJubiliVerifiedURL()) {
      this.setState({
        authState: STATE_USER_SETTINGS,
        flowInitiator: STATE_JUBILI_EMAIL,
      });
    } else if (nav.isAuthFlowJoinVerifiedURL()) {
      this.setState({
        authState: STATE_USER_SETTINGS,
        flowInitiator: STATE_JOIN_EMAIL,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    if (this.securityTimeout) {
      clearTimeout(this.securityTimeout);
      this.securityTimeout = null;
    }
  }
  componentDidMount() {
    this.initComponent();
    const hasPass = this.props.metadata?.user?.hasPass;
    if (nav.isAuthFlowJoinVerifiedURL() && !hasPass) {
      this.securityTimeout && clearTimeout(this.securityTimeout);
      this.securityTimeout = setTimeout(() => {
        if (nav.isAuthFlowJoinVerifiedURL()) {
          nav.goto(`${nav.URL_AUTH_FLOW_SIGNIN}?timeout=true`);
          OC.getInstance().logout();
        }
      }, RECENT_LOGIN_LIMIT);
    }
  }
  loadImages() {
    const { company } = this.props.metadata;
    if (company && company.logo) {
      OC.getInstance()
        .getImageURL(company.logo)
        .then((url) => {
          if (url) {
            this._isMounted &&
              this.setState({ companyLogo: url, logoFetched: true });
            return;
          }
          this._isMounted && this.setState({ logoFetched: true });
        });
      return;
    }
    this.setState({ logoFetched: true });
  }
  saveLocation(newLocation) {
    this.props.metadataActions.saveLocation(newLocation);
  }
  runPostAuthActions() {
    switch (this.state.flowInitiator) {
      case STATE_JUBILI_EMAIL:
        setTimeout(() => nav.goto(nav.URL_HOMEPAGE_JUBILI));
        break;
      default:
        setTimeout(() => nav.goto(nav.URL_HOMEPAGE));
        return <div />;
    }
  }
  finishUpdateUserFlow() {
    const { name, location, password, dob, anniversary } =
      this.state.userSettings;
    const { user } = this.props.metadata;
    if (!user) {
      console.log("auth failed"); //warn
      return;
    }
    const u = user.clone();
    u.name = name || u.name;
    u.locationRef = location && location.ref ? location.ref : u.locationRef;
    u.avatar = this.state.photo || user.avatar || null;
    u.dob = dob || null;
    u.anniversary = anniversary || null;
    this.props.actions.updateProfile(u, password, (err) => {
      if (err) {
        if (err.code === errc.AUTH_REQUIRES_LOGIN) {
          nav.goto(`${nav.URL_AUTH_FLOW_SIGNIN}?timeout=true`);
          OC.getInstance().logout();
          return;
        }
        message.error(
          "Failed to update profile. Please check network connection."
        );
        return;
      }
      setTimeout(() => this.runPostAuthActions());
    });
  }
  // onPhotoSet(photo, callback) {
  //   this.setState({ photo: photo });
  //   setTimeout(() => this.finishUpdateUserFlow(callback));
  // }
  // Photo Upload removed from Authflow. Delete this later
  onSettingsSet(name, location, password, dob, anniversary) {
    const settings = {
      name: name,
      location: location,
      password: password,
      dob: dob,
      anniversary: anniversary,
    };
    // if (this.props.metadata.user?.avatar) {
    this.setState({ userSettings: settings });
    setTimeout(() => this.finishUpdateUserFlow());
    //   return;
    // }
    // this.setState({ userSettings: settings, authState: STATE_JUBILI_PHOTO });
    // Photo Upload removed from Authflow. Delete this later
  }
  onPasswordSet(password) {
    //mz-todo: Perform required checks with password. Pass password error if necessary.
    if (!password) {
      return;
    }
    this.setState({ passwordBusy: true });
    const { existingUser } = this.state;
    switch (this.state.authState) {
      case STATE_SIGNIN_PASSWORD:
        if (!existingUser) {
          this.setState({ passwordBusy: false });
          return;
        }
        this.props.actions.login(existingUser.email, password, (err) => {
          if (err) {
            this.setState({ passwordBusy: false });
            message.error("Invalid password");
            return;
          }
          const redirectVal = this.props.location.query[nav.QUERY_REDIRECT];
          if (redirectVal === nav.QUERY_REMINDERS) {
            setTimeout(() => nav.goto(nav.URL_REMINDERS));
          } else {
            setTimeout(() => nav.goto(nav.URL_HOMEPAGE));
          }
        });
        break;
      case STATE_JUBILI_PASSWORD:
        if (!existingUser) {
          this.setState({ passwordBusy: false });
          return;
        }
        this.props.actions.login(existingUser.email, password, (err) => {
          if (err) {
            this.setState({ passwordBusy: false });
            message.error("Invalid password");
            return;
          }
          setTimeout(() => nav.goto(nav.URL_HOMEPAGE_JUBILI));
        });
        break;
      default:
        break;
    }

    this.setState({ password: password });
  }
  sendSigninMagicLink(user) {
    var ocType = "";
    switch (this.state.flowInitiator) {
      case STATE_JUBILI_EMAIL:
        ocType = OC_TYPE_CAT;
        break;
      case STATE_SIGNIN_EMAIL:
        ocType = OC_TYPE_MAIN;
        break;
      default:
        break;
    }
    this.props.actions.sendMagicLink(user.email, ocType, (err) => {
      if (err) {
        message.error("Invalid email address");
        return;
      }
      this.setState({
        authState: STATE_SIGNIN_LINK,
        resendEmailAddress: user.email,
        resendLinkType: ocType,
      });
    });
  }
  sendVerificationMagicLink(email) {
    var ocType = "";
    switch (this.state.flowInitiator) {
      case STATE_JUBILI_EMAIL:
        ocType = OC_TYPE_JUBILI_VERIFIED;
        break;
      case STATE_JOIN_EMAIL:
        ocType = OC_TYPE_JOIN_VERIFIED;
        break;
      case STATE_SIGNIN_EMAIL:
        ocType = OC_TYPE_MAIN;
        break;
      default:
        break;
    }
    this.props.actions.sendMagicLink(email, ocType, (err) => {
      if (err) {
        message.error("Invalid email address");
        return;
      }
      this.setState({
        authState: STATE_VERIFICATION_LINK,
        resendEmailAddress: email,
        resendLinkType: ocType,
      });
    });
  }
  onEmailSet(user, userEmail) {
    //mz-todo: Perform required checks on email in every case below.
    //         Pass email error if necessary.
    if (this.state.flowInitiator === STATE_JUBILI_EMAIL) {
      if (user && user.email) {
        this.setState({ existingUser: user });
        user.hasPass
          ? this.setState({ authState: STATE_JUBILI_PASSWORD })
          : this.sendSigninMagicLink(user);
      } else {
        this.sendVerificationMagicLink(userEmail);
      }
    } else if (this.state.flowInitiator === STATE_SIGNIN_EMAIL) {
      if (user && user.email) {
        this.setState({ existingUser: user });
        user.hasPass
          ? this.setState({ authState: STATE_SIGNIN_PASSWORD })
          : this.sendSigninMagicLink(user);
      } // No Else case required. SignIn component should not callback with null.
    } else if (this.state.flowInitiator === STATE_JOIN_EMAIL) {
      this.sendVerificationMagicLink(userEmail);
    }
  }
  render() {
    const { passwordBusy } = this.state;
    const {
      authState,
      emailError,
      existingUser,
      flowInitiator,
      logoFetched,
      companyLogo,
      resendEmailAddress,
      resendLinkType,
    } = this.state;
    const { loginError } = this.props.auth;
    const isEmailState =
      authState === STATE_JUBILI_EMAIL ||
      authState === STATE_JOIN_EMAIL ||
      authState === STATE_SIGNIN_EMAIL;
    if (!authState || !logoFetched) {
      return null;
    }
    return (
      <div className="AuthFlowContainer">
        <HeaderLogoOnly showLogo={!!this.state.companyLogo} />
        {isEmailState && (
          <ZeroStateEmail
            userTimeout={this.props.location?.query?.timeout === "true"}
            onReroute={this.onReroute}
            companyLogo={companyLogo}
            authFlow={authState}
            onNext={this.onEmailSet}
            error={emailError}
          />
        )}

        {(authState === STATE_SIGNIN_PASSWORD ||
          authState === STATE_JUBILI_PASSWORD) && (
          <ZeroStatePassword
            companyLogo={companyLogo}
            email={existingUser ? existingUser.email : ""}
            authFlow={flowInitiator}
            onNext={this.onPasswordSet}
            error={loginError}
            sendForgotLink={this.props.actions.sendMagicLink}
            busy={passwordBusy}
          />
        )}

        {authState === STATE_USER_SETTINGS && (
          <ZeroStateSettings
            companyLogo={companyLogo}
            user={this.props.metadata.user}
            onAddNewLocation={this.saveLocation}
            locationList={this.props.metadata.locations}
            onNext={this.onSettingsSet}
          />
        )}

        {(authState === STATE_SIGNIN_LINK ||
          authState === STATE_VERIFICATION_LINK) && (
          <ZeroStateVerify
            companyLogo={companyLogo}
            resendLink={this.props.actions.sendMagicLink}
            resendType={resendLinkType}
            email={resendEmailAddress}
            authFlow={authState}
          />
        )}

        {/* {authState === STATE_JUBILI_PHOTO && (
          <ZeroStatePhoto companyLogo={companyLogo} onNext={this.onPhotoSet} />
        )} */}
        {/* Photo Upload removed from Authflow. Delete this later */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth, metadata } = state;
  return { auth, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    metadataActions: bindActionCreators(metadataActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthFlowContainer);
