import Model from "./model";
import { DEFAULT_USER_LEVEL_PLAN } from "../../shared/constants";

export default class User extends Model {
  ref = null;
  email = null;
  name = null;
  dob = { day: null, month: null };
  anniversary = { day: null, month: null };
  location = { id: null, name: null };
  locationRef = null;
  isAdmin = false;
  hasPass = false;
  verification = null;
  avatar = null;
  remindersDisabled = false;
  avatarDownloadURL = null;
  defaultPlan = null;
  _lastUpdated = null;
  getKey() {
    return this.email ? this.email.toLowerCase() : null;
  }
  async parse(data, ref) {
    this.ref = ref;
    this.email = data.email ? data.email.toLowerCase() : null;
    this.name = data.name || null;
    this.dob = data.dob || { day: null, month: null };
    this.anniversary = data.anniversary || { day: null, month: null };
    this.isAdmin = data.isAdmin || false;
    this.hasPass = data.hasPass || false;
    this.verification = data.verification;
    this.avatar = data.avatar || null;
    this.remindersDisabled = data.remindersDisabled || false;
    this.defaultPlan = data.defaultPlan || null;
    this._lastUpdated = data._lastUpdated || null;
    // if (data.locationRef) {
    //   const res = await data.locationRef.get();
    //   this.location = res.data();
    //   this.location.id = res.id;
    //   this.locationRef = data.locationRef;
    // }
    return this;
  }
  async refreshRefs() {
    if (this.locationRef) {
      const res = await this.locationRef.get();
      this.location = res.data();
      this.location.id = res.id;
    }
  }
  getDoc() {
    return {
      email: this.email ? this.email.toLowerCase() : null,
      name: this.name || null,
      dob: this.dob || { day: null, month: null },
      anniversary: this.anniversary || { day: null, month: null },
      // locationRef: this.locationRef || null,
      hasPass: this.hasPass || false,
      avatar: this.avatar || null,
      _lastUpdated: this._lastUpdated || null,
    };
  }
  clone() {
    const _d = this.dob;
    const _a = this.anniversary;
    const u = new User();
    u.ref = this.ref;
    u.email = this.email;
    u.name = this.name;
    u.dob = { day: _d ? _d.day : null, month: _d ? _d.month : null };
    u.anniversary = {
      day: _a ? _a.day : null,
      month: _a ? _a.month : null,
    };
    u.location = this.location;
    u.locationRef = null;
    u.isAdmin = this.isAdmin;
    u.hasPass = this.hasPass;
    u.verification = this.verification;
    u.avatar = this.avatar;
    u.avatarDownloadURL = this.avatarDownloadURL;
    u.defaultPlan = this.defaultPlan;
    u._lastUpdated = this._lastUpdated || null;
    return u;
  }
  hasAnniversary() {
    return (
      this.anniversary &&
      this.anniversary.day &&
      this.anniversary.month !== null &&
      this.anniversary.month !== undefined
    );    
  }
  hasDOB() {
    return (
      this.dob &&
      this.dob.day &&
      this.dob.month !== null &&
      this.dob.month !== undefined
    );
  }
  getRemainingFreeCards() {
    try {
      if (!this.defaultPlan) {
        return DEFAULT_USER_LEVEL_PLAN.remainingCards;
      }
      const res = parseInt(this.defaultPlan.remainingCards, 10);
      return res >= 0 ? res : 0;
    } catch {
      return 0;
    }
  }
}
