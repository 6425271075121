import React from "react";
import "../containers/styles/CardDraft.less";
import { Row, Col, Modal, Tooltip, Button, message, Input } from "antd";
import ImageUploader from "./custom/ImageUploader";
import OC from "../oc";
import * as errc from "../oc/error_codes";
import moment from "moment";

const { TextArea } = Input;

class CardIntro extends React.Component {
  constructor(props) {
    super(props);
    const { draftCard } = this.props;
    this.state = {
      title: draftCard.title,
      message: draftCard.message,
      defaultPreview: null,
      logo: null,
      logoFetchCompleted: false,
      fontsLoaded: false,
    };
    this.onKeyDownCheck = this.onKeyDownCheck.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onTitleBlur = this.onTitleBlur.bind(this);
    this.onMessageBlur = this.onMessageBlur.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
    this.titleRef = React.createRef();
  }
  loadImages() {
    const { draftCard, company } = this.props;
    if (draftCard && draftCard.id) {
      const filename = `card_cover_${draftCard.id}`;
      OC.getInstance()
        .getImageURLForFile(filename)
        .then((url) => {
          if (!url) {
            return;
          }
          this._mounted && this.setState({ defaultPreview: url });
        });
    }
    if (company && company.logo) {
      OC.getInstance()
        .getImageURL(company.logo)
        .then((url) => {
          if (url) {
            this._mounted && this.setState({ logo: url });
          }
          this._mounted && this.setState({ logoFetchCompleted: true });
        });
    } else {
      this.setState({ logoFetchCompleted: true });
    }
  }
  removeCoverPhoto() {
    const { draftCard } = this.props;
    if (!draftCard || !draftCard.id) {
      return;
    }
    const filename = `card_cover_${draftCard.id}`;
    OC.getInstance()
      .deleteWithFilename(filename)
      .then((err) => {
        if (err && err.code !== errc.STORAGE_OBJ_NOT_FOUND) {
          message.error(
            "Failed to remove cover photo. Please check network connection."
          );
          return;
        }
        message.info("Cover photo removed!");
      });
  }
  onFileChanged(file) {
    if (!file) {
      this.removeCoverPhoto();
      return;
    }
    const { draftCard } = this.props;
    if (!draftCard || !draftCard.id) {
      return;
    }
    const filename = `card_cover_${draftCard.id}`;
    const reader = new FileReader();
    reader.onload = (e) => {
      OC.getInstance()
        .updateImageFile(e.target.result, filename)
        .then((fullpath) => {
          if (!fullpath) {
            message.error(
              "Failed to update cover photo. Please check network connection."
            );
            return;
          }
          message.info("Cover photo updated!");
        });
    };
    reader.readAsArrayBuffer(file);
  }
  onTitleBlur() {
    const { draftCard, onCardUpdate } = this.props;
    if (draftCard.title === this.state.title) {
      return;
    }
    const updatedCard = draftCard.cloneNew();
    updatedCard.title = this.state.title;
    onCardUpdate && onCardUpdate(updatedCard);
  }
  onMessageBlur() {
    const { draftCard, onCardUpdate } = this.props;
    if (draftCard.message === this.state.message) {
      return;
    }
    const updatedCard = draftCard.cloneNew();
    updatedCard.message = this.state.message;
    onCardUpdate && onCardUpdate(updatedCard);
  }
  onKeyDownCheck(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.target.blur();
    }
  }
  onTitleChange(e) {
    this.setState({ title: e.target.value });
  }
  onMessageChange(e) {
    this.setState({ message: e.target.value });
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  componentDidUpdate(prevProps) {
    const { draftCard } = this.props;
    if (prevProps && prevProps.draftCard !== draftCard) {
      this.setState({
        title: draftCard?.title || "",
        message: draftCard?.message || "",
      });
    }
  }
  fontLoadAndFailSafeRoutine() {
    setTimeout(() => {
      !this.state.fontsLoaded && this.setState({ fontsLoaded: true });
    }, 2500);
    try {
      document.fonts.ready.then(() => {
        this.setState({ fontsLoaded: true });
        this.autoFocusTitle();
      });
    } catch {
      this.setState({ fontsLoaded: true });
      this.autoFocusTitle();
    }
  }
  autoFocusTitle() {
    try {
      if (this.titleRef && this.titleRef.current) {
        const { draftCard } = this.props;
        const now = moment.utc();
        const created = moment(draftCard.createdAt);
        if (now.diff(created, "seconds") < 30) {
          const el = this.titleRef.current;
          setTimeout(() => {
            el.focus && el.focus();
            this.setState({ title: " " });
            setTimeout(() => {
              this.setState({ title: draftCard?.title || undefined });
            });
          });
        }
      }
    } catch {}
  }
  componentDidMount() {
    this._mounted = true;
    this.fontLoadAndFailSafeRoutine();
    this.loadImages();
  }
  render() {
    const { draftCard, company, onCompanyEdit } = this.props;
    const { brandColor } = company;
    const { title, message, fontsLoaded } = this.state;
    if (!draftCard) {
      return null;
    }
    const { logoFetchCompleted, logo } = this.state;

    return (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={16} lg={16} className="cover">
          <div className="brand-logo">
            {logoFetchCompleted && !logo ? (
              <Button type="link" onClick={onCompanyEdit}>
                <i className="fal fa-plus-circle"></i>&nbsp;Logo &amp; Brand
                Color
              </Button>
            ) : null}

            <Tooltip title="Edit Logo &amp; Brand Color" mouseEnterDelay={0.0}>
              {logoFetchCompleted && logo && (
                <img
                  src={logo}
                  alt="Edit Logo &amp; Brand Color"
                  onClick={onCompanyEdit}
                />
              )}
            </Tooltip>
          </div>

          {fontsLoaded ? (
            <div className="heading">
              <Tooltip trigger="hover" title="Edit Title">
                <TextArea
                  autoSize={true}
                  className="single-line title-placeholder"
                  style={{ color: !brandColor ? "#049fd9" : brandColor }}
                  onChange={this.onTitleChange}
                  onKeyDown={this.onKeyDownCheck}
                  onBlur={this.onTitleBlur}
                  placeholder="Edit Title"
                  value={title}
                  ref={this.titleRef}
                />
              </Tooltip>
              <Tooltip trigger="hover" title="Edit Subtitle">
                <TextArea
                  autoSize={true}
                  style={{ color: !brandColor ? "#049fd9" : brandColor }}
                  className="single-line subtitle-placeholder"
                  onKeyDown={this.onKeyDownCheck}
                  onChange={this.onMessageChange}
                  onBlur={this.onMessageBlur}
                  placeholder="Edit Subtitle"
                  value={message}
                />
              </Tooltip>
            </div>
          ) : null}

          <div className="image-uploader">
            <div className="clearfix">
              <ImageUploader
                defaultPreview={this.state.defaultPreview}
                uploadSize={5}
                onFileChanged={this.onFileChanged}
              >
                <p className="ant-upload-text">
                  <span className="desktop">
                    <i className="fal fa-plus-circle"></i> Cover Photo
                  </span>
                  <span className="mobile">
                    {" "}
                    <i className="fal fa-image-polaroid"></i> Tap to upload a
                    cover photo
                  </span>
                </p>
              </ImageUploader>

              <Modal
                visible={this.props.previewVisible}
                title={this.props.previewTitle}
                footer={null}
                onCancel={this.handleCancel}
                centered
                closeIcon={<i className="fal fa-times"></i>}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={this.props.previewImage}
                />
              </Modal>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default CardIntro;
