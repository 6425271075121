import * as types from "../constants";

const initialState = {
  paymentModalVisible: false,
  products: null,
  activePlan: null,
  activeSubscriptions: null,
  encodedData: null,
  customerExists: null,
};

export function payment(state = initialState, action) {
  switch (action.type) {
    case types.ON_TOGGLE_PAYMENT_MODAL:
      return {
        ...state,
        paymentModalVisible: action.visible,
        encodedData: action.visible ? action.encodedData : null,
      };
    case types.ON_PRODUCTS_FETCHED:
      return { ...state, products: action.products };
    case types.ON_ACTIVE_PLAN_LOADED:
      return { ...state, activePlan: action.plan };
    case types.ON_ACTIVE_SUBSCRIPTIONS_LOADED:
      return { ...state, activeSubscriptions: action.subscriptions || [] };
    case types.ON_STRIPE_CUSTOMER_STATUS_FETCHED:
      return { ...state, customerExists: action.customerExists };
    default:
      return state;
  }
}
