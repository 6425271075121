import * as types from "../constants";
import OC from '../oc';

export function togglePaymentModal(visible, encodedData) {
  return (dispatch) => {
    return dispatch({
      type: types.ON_TOGGLE_PAYMENT_MODAL,
      visible: visible,
      encodedData: encodedData,
    });
  };
}

export function fetchProducts(callback) {
  return dispatch => {
    dispatch(fetchProductsDispatch(null));
    OC.getInstance().fetchProducts()
      .then((res) => {
        callback && callback(res);
        return dispatch(fetchProductsDispatch(res));
      });
  }
}

export function loadActiveSubscription(callback) {
  return dispatch => {
    // dispatch(loadActiveSubscriptionsDispatch(null));
    OC.getInstance().fetchActiveSubscriptions()
      .then((res) => {
        callback && callback(res);
        return dispatch(loadActiveSubscriptionsDispatch(res));
      });
  }
}

export function loadActivePlan(activeSubscriptions) {
  return dispatch => {
    // dispatch(loadActivePlanDispatch(null));
    OC.getInstance().getActivePlan(activeSubscriptions)
      .then((res) => {
        return dispatch(loadActivePlanDispatch(res));
      });
  }
}

export function authCustomerExists() {
  return dispatch => {
    dispatch(authCustomerExistsDispatch(null));
    OC.getInstance().fetchAuthStripeCustomer()
      .then((res) => {
        return dispatch(authCustomerExistsDispatch(res ? true : false));
      });
  }
}

function fetchProductsDispatch(res) {
  return {
    type: types.ON_PRODUCTS_FETCHED,
    products: res,
  }
}
function loadActiveSubscriptionsDispatch(res) {
  return {
    type: types.ON_ACTIVE_SUBSCRIPTIONS_LOADED,
    subscriptions: res,
  }  
}
function loadActivePlanDispatch(res) {
  return {
    type: types.ON_ACTIVE_PLAN_LOADED,
    plan: res,
  }  
}

function authCustomerExistsDispatch(res) {
  return {
    type: types.ON_STRIPE_CUSTOMER_STATUS_FETCHED,
    customerExists: res,
  }  
}