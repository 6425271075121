import React from "react";
import "./styles/Automations.less";
import { Row, Col, Button, Modal, message } from "antd";
import workspace from "./img/artwork-google-workspace.png";
import automateBirthday from "./img/artwork-automate-birthday-cards.png";
import automateBirthdate from "./img/artwork-automate-birthdate-collection.png";
import automateReminders from "./img/artwork-reminder-emails.png";
import ModalAutomations from "./ModalAutomations";
import OC from "../oc";
import { WORKSPACE_GWS, WS_RES_NON_ADMIN_USER } from "../shared/constants";

class Automations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      connectWorkSpace: false,
      birthdayCollection: false,
      birthdayCards: false,
      wsConnectBusy: false,
      wsDisableBusy: false,
    };
    this.onStartWizardClick = this.onStartWizardClick.bind(this);
    this.enableFeature = this.enableFeature.bind(this);
    this.disableFeature = this.disableFeature.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onConnectWorkSpaceClick = this.onConnectWorkSpaceClick.bind(this);
    this.onEnableCollectionClick = this.onEnableCollectionClick.bind(this);
    this.onEnableCardsClick = this.onEnableCardsClick.bind(this);
    this.onEnableRemindersClick = this.onEnableRemindersClick.bind(this);
    this.onDisableWorkSpaceClick = this.onDisableWorkSpaceClick.bind(this);
    this.onDisableCollectionClick = this.onDisableCollectionClick.bind(this);
    this.onDisableCardsClick = this.onDisableCardsClick.bind(this);
    this.onDisableRemindersClick = this.onDisableRemindersClick.bind(this);
  }
  componentDidMount() {
    const { company } = this.props;
    if (company?.workspaceConnected === WORKSPACE_GWS) {
      this.setState({ connectWorkSpace: true });
    }
  }
  async onDisableWorkSpaceClick() {
    setTimeout(() => this.setState({ wsDisableBusy: true }));
    const res = await OC.getInstance().disableGWIntegration();
    if (!res.err) {
      this.setState({ connectWorkSpace: false, wsDisableBusy: false });
      return;
    } else if (res.err_code === WS_RES_NON_ADMIN_USER) {
      message.error("This operation requires admin rights.");
    } else {
      message.error(
        "Could not complete the operation at this time. We are investigating the issue. Please try again later.",
        8
      );
    }
    this.setState({ wsDisableBusy: false });
  }
  onDisableCollectionClick() {
    this.disableFeature("birthdayCollection");
  }
  onDisableCardsClick() {
    this.disableFeature("birthdayCards");
  }
  
  onEnableRemindersClick() {
    const { enableReminders } = this.props;
    enableReminders && enableReminders(true);
  }
  onDisableRemindersClick() {
    const { enableReminders } = this.props;
    enableReminders && enableReminders(false);
  }

  async onConnectWorkSpaceClick() {
    setTimeout(() => this.setState({ wsConnectBusy: true }));
    const url = await OC.getInstance().getGCPAuthURL();
    if (url) {
      window.location.assign(url);
    }
  }
  onEnableCollectionClick() {
    this.enableFeature("birthdayCollection");
  }
  onEnableCardsClick() {
    this.enableFeature("birthdayCards");
  }

  enableFeature(feature) {
    switch (feature) {
      case "birthdayCollection":
        this.setState({ birthdayCollection: true });
        break;
      case "birthdayCards":
        this.setState({ birthdayCards: true });
        break;
      default:
        return;
    }
  }
  disableFeature(feature) {
    switch (feature) {
      case "birthdayCollection":
        this.setState({ birthdayCollection: false });
        break;
      case "birthdayCards":
        this.setState({ birthdayCards: false });
        break;
      default:
        return;
    }
  }
  onStartWizardClick() {
    this.setState({ showModal: true });
  }
  handleCancel() {
    this.setState({ showModal: false });
  }
  render() {
    const {
      connectWorkSpace,
      birthdayCollection,
      birthdayCards,
    } = this.state;
    const { user = {} } = this.props;
    return (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={20} lg={18} xl={18}>
          <div className="Automations">
            <Row>
              <Col span={24}>
                <div className="intro">
                  <h3>Greeted Automations</h3>
                  <p>
                    Enabling Automation feature allows company administrators to
                    setup only once as per their requirement and let Greeted do
                    the rest.
                  </p>
                  {/* Enable Later */}
                  {/* <p>
                    <Button type="link" onClick={this.onStartWizardClick}>
                      Start Wizard
                    </Button>{" "}
                    to learn more about each of the available automation feature
                    and enable them.
                  </p> */}
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="card">
                  <div className="img">
                    <img src={workspace}></img>
                  </div>
                  <div className="info">
                    <h4>
                      Google Workplace Integration{" "}
                      {connectWorkSpace && (
                        <span className={`custom-tag green`}>
                          <i className="far fa-check"></i> Enabled
                        </span>
                      )}
                    </h4>

                    <p>
                      Allows Greeted to access and sync email addresses of
                      anyone in the company
                    </p>
                    {connectWorkSpace ? (
                      <Button
                        disabled={this.state.wsDisableBusy}
                        loading={this.state.wsDisableBusy}
                        onClick={this.onDisableWorkSpaceClick}
                        type="link"
                      >
                        Disable
                      </Button>
                    ) : (
                      <Button
                        disabled={this.state.wsConnectBusy}
                        loading={this.state.wsConnectBusy}
                        onClick={this.onConnectWorkSpaceClick}
                        type="primary"
                      >
                        Connect to Workspace
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              {/* Enable Later */}
              {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="card">
                  <div className="img">
                    <img src={automateBirthdate}></img>
                  </div>
                  <div className="info">
                    <h4>
                      Automate Birth Date Collection{" "}
                      {birthdayCollection && (
                        <span className={`custom-tag green`}>
                          <i className="far fa-check"></i> Enabled
                        </span>
                      )}
                    </h4>

                    <p>
                      Automatically collect birth dates from everyone in the
                      company
                    </p>
                    {birthdayCollection ? (
                      <Button
                        onClick={this.onDisableCollectionClick}
                        type="link"
                      >
                        Disable
                      </Button>
                    ) : (
                      <Button
                        onClick={this.onEnableCollectionClick}
                        type="primary"
                      >
                        Enable Automation
                      </Button>
                    )}
                  </div>
                </div>
              </Col> */}
              {/* Enable Later */}
              {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="card">
                  <div className="img">
                    <img src={automateBirthday}></img>
                  </div>
                  <div className="info">
                    <h4>
                      Automate Birthday Cards{" "}
                      {birthdayCards && (
                        <span className={`custom-tag green`}>
                          <i className="far fa-check"></i> Enabled
                        </span>
                      )}
                    </h4>

                    <p>
                      Automatically create cards and send invitations to
                      everyone in the company
                    </p>
                    {birthdayCards ? (
                      <Button onClick={this.onDisableCardsClick} type="link">
                        Disable
                      </Button>
                    ) : (
                      <Button onClick={this.onEnableCardsClick} type="primary">
                        Enable Automation
                      </Button>
                    )}
                  </div>
                </div>
              </Col> */}
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="card">
                  <div className="img">
                    <img src={automateReminders}></img>
                  </div>
                  <div className="info">
                    <h4>
                      Birthday Reminders{" "}
                      {!user.remindersDisabled && (
                        <span className={`custom-tag green`}>
                          <i className="far fa-check"></i> Enabled
                        </span>
                      )}
                    </h4>

                    <p>
                      Remind company administrators about upcoming birthdays
                    </p>
                    {!user.remindersDisabled ? (
                      <Button
                        onClick={this.onDisableRemindersClick}
                        type="link"
                      >
                        Disable
                      </Button>
                    ) : (
                      <Button
                        onClick={this.onEnableRemindersClick}
                        type="primary"
                      >
                        Enable Automation
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Modal
              closable={false}
              visible={this.state.showModal}
              footer={null}
              onCancel={this.handleCancel}
              centered
              closeIcon={<i className="fal fa-times"></i>}
            >
              <ModalAutomations
                enableFeature={this.enableFeature}
                handleCancel={this.handleCancel}
                connectWorkSpace={this.state.connectWorkSpace}
                birthdayCollection={this.state.birthdayCollection}
                birthdayCards={this.state.birthdayCards}
                birthdayReminders={!user.remindersDisabled}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Automations;
