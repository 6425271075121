import moment from "moment";

export default class DOB {
  day = null;
  month = null;
  constructor(day, month) {
    this.day = day;
    this.month = month;
  }
  toString() {
    if (!this.day || this.month === null || this.month === undefined) {
      return "";
    }
    if (this._isLeapYearDOB()) {
      return "Feb 29th";
    } else {
      const dt = this._getDOBMoment();
      return dt ? dt.format("MMM Do") : "";
    }
  }
  toMoment() {
    if (!this.day || this.month === null || this.month === undefined) {
      return null;
    }
    return this._getDOBMoment();
  }
  _getDOBMoment() {
    if (!this.day || this.month === null || this.month === undefined) {
      return null;
    }    
    const dtNow = moment();
    const dtDOB = moment();
    dtDOB.month(this.month);
    dtDOB.date(this.day);
    dtDOB.hour(9);
    dtDOB.minute(0);
    dtDOB.second(0);
    if (dtDOB.isBefore(dtNow)) {
      dtDOB.year(dtDOB.year() + 1);
    }
    if (this._isLeapYearDOB() && dtDOB.isLeapYear()) {
      dtDOB.month(1);
      dtDOB.date(29);
    } else if (this._isLeapYearDOB() && !dtDOB.isLeapYear()) {
      dtDOB.month(2);
      dtDOB.date(1);
    }
    return dtDOB;
  }
  _isLeapYearDOB() {
    return this.day === 29 && this.month === 1;
  }
}
