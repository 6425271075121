import React from "react";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import { Button, Form, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles/Modal.less";
import ImageUploader from "./custom/ImageUploader";
import OC from "../oc";
import { OC_CARD_PLURAL } from "../shared/constants";
import Company from "../oc/models/company";

//mz-todo: Include this._mounted.
class Branding extends React.Component {
  constructor(props) {
    super(props);
    const { company } = this.props.metadata;
    this.state = {
      logoFile: null,
      name: company && company.name,
      brandColor: company && company.brandColor,
      defaultPreview: null,
      componentBusy: false,
      busyCompanyEdit: false,
    };
    this.triggerAutoUpdate = this.triggerAutoUpdate.bind(this);
    this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this); 
    this.removeCompanyLogo = this.removeCompanyLogo.bind(this);
    this.saveCompanyData = this.saveCompanyData.bind(this);
    this.onKeyDownCheck = this.onKeyDownCheck.bind(this);
  }
  onKeyDownCheck(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.target.blur();
    }
  }
  removeCompanyLogo() {
    const { company } = this.props.metadata;
    if (!company) {
      return;
    }
    setTimeout(() => this.setState({ busyCompanyEdit: true }));
    this.updateCompanyItem(company.name, company.brandColor, null);
  }
  updateCompanyItem(name, brandColor, logoURL) {
    const { company } = this.props.metadata;
    const c = company ? company : new Company();
    c.brandColor = brandColor;
    c.logo = logoURL;
    c.name = name;
    this.props.metadataActions.updateCompany(c, (_) => {
      this.setState({ busyCompanyEdit: false });
    });
  }
  uploadCompanyLogo(file, currentURL, callback) {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      OC.getInstance()
        .uploadImage(e.target.result, currentURL || null)
        .then((fullpath) => {
          if (!fullpath) {
            callback && callback(null);
            return;
          }
          callback && callback(fullpath);
        });
    };
    reader.readAsArrayBuffer(file);
  }
  saveCompanyData(companySettingsData) {
    if (this.state.busyCompanyEdit) {
      return;
    }
    setTimeout(() => {
      this.setState({ busyCompanyEdit: true });
    });
    const { brandColor, name, logoFile } = companySettingsData;
    const { company } = this.props.metadata;
    if (logoFile) {
      this.uploadCompanyLogo(logoFile, company.logo || null, (fullpath) => {
        if (!fullpath) {
          message.info("Could not update organization logo.");
        }
        this.updateCompanyItem(
          name,
          brandColor,
          fullpath || company.logo
        );
      });
    } else {
      this.updateCompanyItem(name, brandColor, company.logo);
    }
  }
  loadCurrentLogo() {
    const { company } = this.props.metadata;
    const currentLogo = company && company.logo;
    if (currentLogo) {
      OC.getInstance()
        .getImageURL(currentLogo)
        .then((url) => {
          if (!url) {
            return;
          }
          this.setState({ defaultPreview: url });
        });
    }
  }
  removeLogo() {
    const { company } = this.props.metadata;
    const currentLogo = company && company.logo;
    if (currentLogo) {
      setTimeout(() => this.setState({ componentBusy: true }));
      OC.getInstance()
        .deleteImage(currentLogo)
        .then((err) => {
          setTimeout(() => this.setState({ componentBusy: false }));
          if (err) {
            message.error(
              "Could not remove organization logo. Please check network connection."
            );
            return;
          }
          this.removeCompanyLogo();
          this.setState({ logoFile: null, defaultPreview: null });
        });
    }
  }
  onFileChanged(file) {
    if (!file) {
      this.removeLogo();
      return;
    }
    this.setState({ logoFile: file });
    this.triggerAutoUpdate();
  }
  onCompanyNameChange(e) {
    this.setState({ name: e.target.value });
  }
  onColorChange(color) {
    this.setState({ brandColor: color.color });
  }
  triggerAutoUpdate() {
    if (this.state.componentBusy) {
      return;
    }
    setTimeout(()=>{
      this.saveCompanyData(this.state);
    })
  }
  componentDidMount() {
    this.loadCurrentLogo();
  }
  render() {
    const { componentBusy, busyCompanyEdit} = this.state;
    return (
      <div className="ModalCompanySettings" style={{ position: "relative" }}>
        {componentBusy || busyCompanyEdit ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              opacity: 0.5,
              width: "100%",
              background: "#fafafa",
              zIndex: 9999,
            }}
          >
            <h3 className="loader">
              <LoadingOutlined />
            </h3>
          </div>
        ) : null}

        <div className="title">
          <p>
            The following organization settings are used on all {OC_CARD_PLURAL}.
          </p>
        </div>

        <Form name="basic">
          <Form.Item>
            <h4>Your Organization’s Name</h4>
            <Input
              onKeyDown={this.onKeyDownCheck}
              onBlur={this.triggerAutoUpdate}
              onChange={this.onCompanyNameChange}
              placeholder="Dunder Mifflin"
              prefix={<SearchOutlined />}
              value={this.state.name}
            />
          </Form.Item>
          <Form.Item>
            <h4>Your Organization’s Logo (optional)</h4>
            <ImageUploader
              allowGif={true}
              defaultPreview={this.state.defaultPreview}
              onFileChanged={this.onFileChanged}
            >
              <p className="ant-upload-text">
                Drag &amp; Drop Organization Logo or <Button>Browse</Button>
              </p>
            </ImageUploader>
          </Form.Item>
          <div className="brand-color">
            <div>
              <h4>Brand Color</h4>
              <p>Used for all celebration titles and cover art.</p>
            </div>

            <div className="company-color">
              <i
                className="far fa-paint-brush-alt"
                style={{ pointerEvents: "none" }}
              ></i>
              <ColorPicker
                onClose={this.triggerAutoUpdate}
                className="color-picker"
                onChange={this.onColorChange}
                defaultColor="#049fd9"
                color={this.state.brandColor}
              />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default Branding;
