import React from "react";
import { Row, Col, Button, Space } from "antd";
import logo from "./img/logo-greeted.svg";
import "./styles/MainFooter.less";

class MainFooter extends React.Component {
  render() {
    return (
      <Row justify="center" className="main-footer">
        <Col>
          <div className="logo">
            <img src={logo} alt="Greeted" />
          </div>
          <Space direction="vertical" size={4} align="center">
            <Space size={8}>
              <Button type="link" href="/privacy">
                Privacy Policy
              </Button>{" "}
              <Button type="link" href="/terms">
                {" "}
                Terms &amp; Conditions
              </Button>{" "}
              <Button href="mailto:hello@greeted.io" type="link">
                Contact
              </Button>
            </Space>
            <small>&copy; 2021 All rights reserved.</small>
          </Space>
        </Col>
      </Row>
    );
  }
}

export default MainFooter;
