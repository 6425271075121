import React from "react";
import { Button, Form, Input, Checkbox } from "antd";
import {
  categoryData,
  PLAN_TYPE_FREE_COMPANY,
  OC_CARD_PLURAL,
  OC_CARD_SINGULAR,
  CAT_OTHER
} from "../shared/constants";
import "./styles/Modal.less";
import EmojiHandler from "./EmojiHandler";
import {
  startSingleCardSubscription,
  availableCardsCount,
  isRunningLow,
  getBuyButtonText,
} from "../shared/card_utils";
import Logger, { PRIORITY_CRITICAL } from "../shared/logger";
import { LoadingOutlined } from "@ant-design/icons";

class WooCandidateSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      busyAddingUser: false,
      wooUsername: "",
      singleCardProduct: null,
      planRequestDispatched: false,
      buyButtonBusy: false,
    };
    this.onClickNext = this.onClickNext.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onWithCompanyNameToggle = this.onWithCompanyNameToggle.bind(this);
    this.onClickBuy = this.onClickBuy.bind(this);
    this.onClickUpgrade = this.onClickUpgrade.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadSubscriptionData();
    const { wooUsername } = this.props;
    if (!wooUsername) {
      return;
    }
    this.setState({ wooUsername: wooUsername });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onNameChange(e) {
    this.setState({ wooUsername: e.target.value });
  }
  // loadSingleCardProduct... Also exists in ModalCardSettings.js
  loadSingleCardProduct(products) {
    if (!products) {
      this.state.singleCardProduct && this.setState({singleCardProduct: null});
      return;
    }
    const prd = products.find(item => item.getTotalCards() === 1);
    if (prd) {
      this.setState({singleCardProduct: prd});
    } else {
      this.state.singleCardProduct && this.setState({singleCardProduct: null});
    }
  }
  // loadSubscriptionData... Also exists in ModalCardSettings.js
  loadSubscriptionData() {
    const { products, activePlan, activeSubscriptions } = this.props.payment;
    const { paymentActions } = this.props;
    if (!products || !products.length) {
      paymentActions.fetchProducts((products) => {
        this._isMounted && this.loadSingleCardProduct(products);
      });
    } else {
      this._isMounted && this.loadSingleCardProduct(products);
    }
    if (!activePlan || !activeSubscriptions || !activeSubscriptions.length) {
      paymentActions.loadActiveSubscription((res) => {
        paymentActions.loadActivePlan(res);
        this._isMounted && this.setState({ planRequestDispatched: true });
      });
    } else {
      this._isMounted && this.setState({ planRequestDispatched: true });
    }
  }
  async onClickUpgrade() {
    const { paymentActions, closeModal } = this.props;
    const encodedData = await this.getEncodedData();
    paymentActions && paymentActions.togglePaymentModal(true, encodedData);
    closeModal && closeModal();
  }
  async getEncodedData() {
    const { selectedCategory } = this.props;
    const { wooUsername } = this.state;
    try {
      const data = {
        selectedCategory: selectedCategory,
        wooUsername: wooUsername,
      };
      const jsonString = JSON.stringify(data);
      const encodedData = Buffer.from(jsonString).toString("base64");
      return encodedData;
    } catch (err) {
      this.setState({ buyButtonBusy: false });
      await Logger.logStripe(
        PRIORITY_CRITICAL,
        err && err.message ? err.message : "function failed",
        "WooCandidateSettings:getEncodedData",
        {
          selectedCategory: selectedCategory,
          wooUsername: wooUsername,
        }
      );
      return null;
    }
  }
  // onClickBuy ... Also exists in ModalCardSettings.js
  async onClickBuy() {
    this.setState({ buyButtonBusy: true });
    const { singleCardProduct } = this.state;
    if (!singleCardProduct) {
      this.onClickUpgrade();
      this.setState({ buyButtonBusy: false });
      return;
    }
    const encodedData = await this.getEncodedData();
    startSingleCardSubscription(singleCardProduct, encodedData);
  }
  onClickNext() {
    if (!this.state.wooUsername || this.props.busyInitCard) {
      return;
    }
    const { onSubmit } = this.props;
    onSubmit && onSubmit(this.state.wooUsername);
  }
  onWithCompanyNameToggle(e) {
    const { onWithCompanyNameToggle } = this.props;
    onWithCompanyNameToggle && onWithCompanyNameToggle(e.target.checked);
  }
  renderLoader() {
    return (
      <h3 className="loader" style={{marginTop: 0}}>
        <LoadingOutlined />
      </h3>
    );
  }  
  // renderCTAs ... Also exists in ModalCardSettings.js
  renderCTAs() {
    if (this.props.isEditMode) {
      return (
        <Form.Item>
          <Button
            loading={this.props.busyInitCard}
            disabled={this.state.busyAddingUser}
            type="primary"
            size="large"
            block
            htmlType="submit"
            onClick={this.onClickNext}
          >
            Update
          </Button>
        </Form.Item>
      );
    }
    const { products, activePlan, activeSubscriptions } = this.props.payment;
    const { planRequestDispatched, singleCardProduct, buyButtonBusy } = this.state;
    const avCardsCount = availableCardsCount(activePlan);
    if (
      !products ||
      !products.length ||
      !activePlan ||
      !activeSubscriptions ||
      !planRequestDispatched
    ) {
      return this.renderLoader();
    }
    return (
      <>
        <Form.Item>
          {activePlan.type === PLAN_TYPE_FREE_COMPANY || avCardsCount ? (
            <Button
              loading={this.props.busyInitCard}
              disabled={this.state.busyAddingUser}
              type="primary"
              size="large"
              block
              htmlType="submit"
              onClick={this.onClickNext}
            >
              Next
            </Button>
          ) : (
            <Button
              type="primary"
              loading={buyButtonBusy}
              disabled={buyButtonBusy}
              size="large"
              block
              onClick={this.onClickBuy}
            >
              {getBuyButtonText(singleCardProduct)}
            </Button>
          )}
        </Form.Item>
        {isRunningLow(activePlan) ? (
          <Button type="link" size="large" block onClick={this.onClickUpgrade}>
            Upgrade for discounts ({avCardsCount}{" "}
            {avCardsCount === 1
              ? OC_CARD_SINGULAR.toLowerCase()
              : OC_CARD_PLURAL.toLowerCase()}{" "}
            remaining)
          </Button>
        ) : null}
      </>
    );
  }
  render() {
    const { isWithCompanyName, selectedCategory, metadata } = this.props;
    const { categories, company } = metadata;
    const category = categories.find(
      (element) => element.key === selectedCategory
    );
    return (
      <div className="ModalCardSettings">
        <h2>
          <EmojiHandler>
            <span role="img" aria-labelledby="OC">
              {categoryData[selectedCategory].icon}
            </span>
          </EmojiHandler>
          {category && category.name}{" "}
          <Button onClick={this.props.onEditCategoryClick} type="link">
            <i className="fal fa-pen"></i>
          </Button>
        </h2>
        {selectedCategory === CAT_OTHER && (
          <Checkbox
            checked={isWithCompanyName}
            onChange={this.onWithCompanyNameToggle}
          >
            Recipient is with {company?.name || "the organization"}
          </Checkbox>
        )}
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <Form.Item
            rules={[
              { required: true, message: "Please enter Recipient's name" },
            ]}
          >
            <h4>Recipient’s Full Name</h4>
            <Input
              value={this.state.wooUsername}
              prefix={<i className="fal fa-user"></i>}
              placeholder="Michael Scott"
              onChange={this.onNameChange}
            />
          </Form.Item>
          {this.renderCTAs()}
        </Form>
      </div>
    );
  }
}

export default WooCandidateSettings;
