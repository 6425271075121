import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/card";
import { Button, Checkbox, Space, message, Modal } from "antd";
import "./styles/Modal.less";
import UserAvatar from "../components/custom/UserAvatar";
import { categories } from "../shared/constants";
import ModalAddTeammates from "../components/ModalAddTeammates";
import { copyLink } from "../shared/util";

class ModalInvites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: [],
      checkedList: [],
      busy: false,
      addTeammatesVisible: false,
    };
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onInviteClicked = this.onInviteClicked.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onModalAfterClose = this.onModalAfterClose.bind(this);
    this.onCopyLinkClicked = this.onCopyLinkClicked.bind(this);
    this.onTeammateModalInviteSent = this.onTeammateModalInviteSent.bind(this);
  }
  getInviteesList() {
    const inviteesList = [];
    const { usersList } = this.props.metadata;
    const card = this.props.card.draftCard;
    const isEmailLessCard =
      card && card.isEmailLessCard && card.isEmailLessCard();
    const currentUserEmail = this.props.metadata.user
      ? this.props.metadata.user.email
      : "";
    if (card && (card.recipient || isEmailLessCard) && usersList) {
      const { recipient } = card;
      for (let user of usersList) {
        if (
          (isEmailLessCard || user.email !== recipient?.email) &&
          user.email !== card.owner.id &&
          user.email !== currentUserEmail
        ) {
          inviteesList.push(user);
        }
      }
      const { invited } = card;
      const outsiders = [];
      if (invited && invited.length) {
        for (let email of invited) {
          let alreadyExists = false;
          for (let item of inviteesList) {
            if (email === item.email) {
              alreadyExists = true;
              break;
            }
          }
          if (!alreadyExists) {
            outsiders.push({ email: email });
          }
        }
        if (outsiders.length) {
          inviteesList.push(...outsiders);
        }
      }
    }
    return inviteesList || [];
  }
  getAlreadyInvitedCount() {
    const { draftCard } = this.props.card;
    if (draftCard && draftCard.invited) {
      return draftCard.invited.length || 0;
    }
    return 0;
  }
  onTeammateModalInviteSent() {
    this.setState({ addTeammatesVisible: false });
    this.selectDeselectAll(true);
  }
  onModalAfterClose() {
    this.setState({ addTeammatesVisible: false });
  }
  onModalClose() {
    const { toggleInviteModal } = this.props.actions;
    toggleInviteModal && toggleInviteModal(false);
  }
  onInviteClicked() {
    const { saveCard, toggleInviteModal } = this.props.actions;
    const card = this.props.card.draftCard;
    const { checkedList } = this.state;
    const currentUserEmail = this.props.metadata.user
      ? this.props.metadata.user.email
      : "";

    if (!saveCard || !card || !currentUserEmail) {
      return;
    }
    const inviteCount = (checkedList && checkedList.length) || "";
    const newCard = card.cloneNew();
    if (!newCard.invited) {
      newCard.invited = [];
    }
    for (let user of checkedList) {
      newCard.invited.push(user.email);
    }
    this.setState({ busy: true });
    newCard.inviterLastEmail = currentUserEmail;
    saveCard(newCard, (res) => {
      this.setState({ checkedList: [] });
      if (!res) {
        message.error(
          "Could not invite teammates. Please check network connection and try again."
        );
      } else {
        this.setState({ busy: false });
        toggleInviteModal && toggleInviteModal(false);
        message.success(
          `${inviteCount ? inviteCount : ""} ${
            inviteCount > 1 ? "Invites" : "Invite"
          } Sent`
        );
      }
    });
  }
  onSelectAll(e) {
    this.selectDeselectAll(e.target.checked);
  }
  selectDeselectAll(selectAll) {
    var { checkedList, filteredList } = this.state;
    if (selectAll) {
      checkedList = [];
      const card = this.props.card.draftCard;
      if (card && card.invited && card.invited.length) {
        for (let user of filteredList) {
          if (card.invited.indexOf(user.email) === -1) {
            checkedList.push(user);
          }
        }
      } else {
        checkedList.push(...filteredList);
      }
    } else {
      checkedList = [];
    }
    this.setState({ checkedList: checkedList });
  }
  shouldDisable(user) {
    const card = this.props.card.draftCard;
    if (user && user.email && card.invited && card.invited.length) {
      for (let email of card.invited) {
        if (email === user.email) {
          return true;
        }
      }
    }
    return false;
  }
  onCheckChanged(user, e) {
    const { checkedList } = this.state;
    if (e.target.checked) {
      const index = checkedList.indexOf(user);
      if (index === -1) {
        checkedList.push(user);
      }
    } else {
      const index = checkedList.indexOf(user);
      if (index > -1) {
        checkedList.splice(index, 1);
      }
    }
    this.setState({ checkedList: checkedList });
  }
  isChecked(user) {
    const { checkedList } = this.state;
    const index = checkedList.indexOf(user);
    if (index > -1) {
      return true;
    }
    return false;
  }
  getCategoryNameBy(key) {
    const cat = categories.find((element) => element.key === key);
    if (cat === null || cat === undefined) {
      return "";
    }
    return cat.name;
  }
  renderInvitesModal() {
    const card = this.props.card.draftCard;
    if (!card) {
      return null;
    }
    // mz-todo: Check for woo candidate card.
    const { filteredList, checkedList, busy } = this.state;
    const alreadyInvitedCount = this.getAlreadyInvitedCount();
    const selectAllCount = filteredList.length - alreadyInvitedCount || "";
    const selectAllChecked =
      filteredList.length - alreadyInvitedCount === checkedList.length;
    return (
      <div className="ModalInvites">
        <h2>Invite Others to Post</h2>
        {/* <div className="recipient-details">
          <EmojiHandler>
            <span className="emoji">
              {card.category &&
                categoryData[card.category] &&
                categoryData[card.category].icon}{" "}
            </span>
          </EmojiHandler>
          <span className="category">
            {this.getCategoryNameBy(card && card.category)}
          </span>
          <UserAvatar
            key={`homelist_avatar_${
              card.recipient ? card.recipient.email : null
            }`}
            fetchPath={card.getAvatarFetchPath && card.getAvatarFetchPath()}
            name={card.getRecipientName && card.getRecipientName()}
          />
          <span className="recipient-name">{recipient && recipient.name}</span>
        </div> */}
        <p className="subtext">
          <Button type="link" onClick={this.onCopyLinkClicked}>
            <i className="fal fa-link"></i> Copy Invitation link
          </Button>
          &nbsp; or email invitations below.
        </p>
        {/* <div className="user-search">
          <Search
            placeholder="Search"
            prefix={<i className="fal fa-search"></i>}
          />
        </div> */}
        <div className="select-all">
          <div>
            <Checkbox
              onChange={this.onSelectAll}
              disabled={!selectAllCount}
              checked={selectAllChecked}
            >
              {`${selectAllChecked ? "Select" : "Select"} All ${
                selectAllCount > 1 ? selectAllCount : ""
              }`}
            </Checkbox>
          </div>
          <div>
            <Button
              type="link"
              onClick={() => this.setState({ addTeammatesVisible: true })}
            >
              <i className="fal fa-plus"></i>&nbsp;Add Teammates
            </Button>
          </div>
        </div>

        <ul className="user-listing">
          {filteredList.map((item, _) => {
            const disabled = this.shouldDisable(item);
            const checkedNew = this.isChecked(item);
            return (
              <li
                key={item.email}
                className={`${
                  !disabled && checkedNew ? "invitee-selected" : ""
                } ${disabled ? "already-invited" : ""}`}
              >
                <Checkbox
                  disabled={disabled}
                  onChange={this.onCheckChanged.bind(this, item)}
                  checked={disabled || checkedNew}
                >
                  <Space size={0}>
                    <UserAvatar
                      key={`homelist_avatar_${item ? item.email : null}`}
                      fetchPath={item.avatar}
                      name={item.name ? item.name : item.email}
                    />
                    <span className="name">{item.name}</span>
                    <span className="email">{item.email}</span>
                  </Space>
                </Checkbox>
              </li>
            );
          })}
        </ul>

        <Button
          disabled={busy || !(checkedList && checkedList.length)}
          loading={busy}
          onClick={this.onInviteClicked}
          type="primary"
          size="large"
          block
          htmlType="submit"
          className="send-invites"
        >
          <i className="fal fa-paper-plane"></i>
          {`Invite ${(checkedList && checkedList.length) || ""}`}
        </Button>
      </div>
    );
  }
  renderTeammatesModal() {
    const { draftCard } = this.props.card;
    const currentUserEmail = this.props.metadata.user
      ? this.props.metadata.user.email
      : "";
    return (
      <ModalAddTeammates
        company={this.props.metadata.company}
        card={draftCard}
        usersList={this.props.metadata.usersList}
        updateCard={this.props.actions.saveCard}
        onInviteSent={this.onTeammateModalInviteSent}
        recipientEmail={
          draftCard && draftCard.recipient ? draftCard.recipient.email : ""
        }
        ownerEmail={draftCard && draftCard.owner ? draftCard.owner.id : ""}
        selfEmail={currentUserEmail}
      />
    );
  }
  onCopyLinkClicked() {
    copyLink("ModalInvites:onCopyLinkClicked");
  }
  componentDidUpdate(prevProps, prevState) {
    try {
      const isGettingVisible =
        prevProps.card.inviteModalVisible === false &&
        this.props.card.inviteModalVisible === true;
      const isReturningBack =
        prevState.addTeammatesVisible === true &&
        this.state.addTeammatesVisible === false;
      if (isGettingVisible || isReturningBack) {
        this.setState({ filteredList: this.getInviteesList() });
        setTimeout(() => this.selectDeselectAll(true));
      }
    } catch {}
  }
  render() {
    return (
      <Modal
        afterClose={this.onModalAfterClose}
        maskClosable={true}
        visible={this.props.card.inviteModalVisible}
        onCancel={this.onModalClose}
        footer={null}
        centered
        closeIcon={<i className="fal fa-times"></i>}
      >
        {this.state.addTeammatesVisible
          ? this.renderTeammatesModal()
          : this.renderInvitesModal()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { card, metadata } = state;
  return { card, metadata };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalInvites);
