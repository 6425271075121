import Model from './model';

export default class Company extends Model {
  brandColor = null;
  domain = null;
  logo = null;
  name = null;
  permanentUrl = null;
  defaultPlan = null;
  workspaceConnected = null;
  getKey() {
    return this.domain;
  }
  parse(data) {
    this.brandColor = data.brandColor || null;
    this.domain = data.domain || null;
    this.logo = data.logo || null;
    this.name = data.name || null;
    this.permanentUrl = data.permanentUrl || null;
    this.defaultPlan = data.defaultPlan || null;
    this.workspaceConnected = data.workspaceConnected || null;
    return this;
  }
  getDoc() {
    return {
      brandColor: this.brandColor || null,
      logo: this.logo || null,
      name: this.name || null,
      permanentUrl: this.permanentUrl || null,
    }
  }
}

