import 'firebase/auth';
import { logError, PRIORITY_HIGH, PRIORITY_NORMAL } from './error_handler';
import moment from "moment";
// import OC from "./"; // Removing firebase analytics

const urlFieldPlaceholder = '&#URL_FIELDS#';
const magicLinkRedirectURL = '%ORIGIN%/auth_action?oc_type=%OC_TYPE%&email=%USER_EMAIL%';
const postAuthRedirectURL = `%ORIGIN%/auth_action?oc_type=%OC_TYPE%&email=%USER_EMAIL%${urlFieldPlaceholder}`;

export default class Auth {
  auth = null;
  onLoginStateChanged = null;
  authCallbackPausedAt = null;
  constructor(firebase) {
    if (!firebase) {
      throw new Error("null parameter");
    }
    this.auth = firebase.auth();
    const _that = this;
    this.auth.onAuthStateChanged(function (user) {
      if (_that.authCallbackPausedAt) {
        const now = moment();
        if (now.diff(_that.authCallbackPausedAt, "seconds") <= 20) {
          return;
        }
        _that.authCallbackPausedAt = null;
      }
      if (user) {
        // Removing firebase analytics
        // /**
        //  * https://firebase.google.com/docs/analytics/userid
        //  * Note: You are responsible for ensuring that your use of the user ID is in accordance 
        //  * with the Google Analytics Terms of Service. This includes avoiding the use of impermissible 
        //  * personally identifiable information, and providing appropriate notice of your use of 
        //  * identifiers in your Privacy Policy. Your user ID must not contain information that a 
        //  * third party could use to determine the identity of an individual user. 
        //  * For example, you cannot use a user's email address or social security number as a user ID.
        //  */
        // var company = null;
        // try {
        //   company = user.email.split("@").pop();
        // } catch {}
        // OC.getInstance().analytics.setUserProperties({ user_id: user.uid, company: company }); // Removing firebase analytics
        _that.onLoginStateChanged && _that.onLoginStateChanged(user);
      } else {
        // OC.getInstance().analytics.setUserProperties({ user_id: null, company: null }); // Removing firebase analytics
        _that.onLoginStateChanged && _that.onLoginStateChanged(null);
      }
    });
  }
  pauseAuthStateCallback() {
    this.authCallbackPausedAt = moment();
  }
  resumeAuthStateCallback() {
    this.authCallbackPausedAt = null;
  }
  // Not used in v1
  async verifyUserEmail(code) {
    try {
      await this.auth.applyActionCode(code);
      return true;
    } catch (err) {
      //warn: Remove this error log. It's not required in production.
      //      The exception occurs for invalid or expired links only.
      logError(err, "verifyUserEmail");
      return false;
    }
  }
  // Not used in v1
  async sendVerificationEmail() {
    const verificationLinkRedirectURL = 'http://localhost:3000/auth_action?oc_type=verify&verified_email=%USER_EMAIL%';
    if (this.auth.currentUser) {
      const actionCodeSettings = {
        url: verificationLinkRedirectURL.replace("%USER_EMAIL%", this.auth.currentUser.email),
      };
      try {
        await this.auth.currentUser.sendEmailVerification(actionCodeSettings);
        return true;
      } catch (err) {
        logError(err, "sendVerificationEmail; " + this.auth.currentUser.email);
        return false;
      }
    }
    return false;
  }
  // loginWith ... Returns err or null.
  async loginWith(email, password) {
    try {
      await this.auth.signInWithEmailAndPassword(email, password);
      return null;
    } catch (err) {
      return err;
    }
  }
  // sendMagicLink ... Returns err or null.
  async sendMagicLink(email, ocType) {
    const actionCodeSettings = {
      url: magicLinkRedirectURL
        .replace("%ORIGIN%", window.location.origin)
        .replace("%OC_TYPE%", ocType)
        .replace("%USER_EMAIL%", email),
      handleCodeInApp: true,
    };
    try {
      await this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
      return null;
    } catch (err) {
      logError(err, "sendMagicLink", PRIORITY_HIGH);
      return err;
    }
  }
  // sendPostAuthLink ... Returns err or null.
  async sendPostAuthLink(email, ocType, urlFieldMap) {
    var url = postAuthRedirectURL
      .replace("%ORIGIN%", window.location.origin)
      .replace("%OC_TYPE%", ocType)
      .replace("%USER_EMAIL%", email);
    if (urlFieldMap) {
      for (const [key, value] of urlFieldMap.entries()) {
        if (key && value) {
          url = url.replace(urlFieldPlaceholder, `&${key}=${value}${urlFieldPlaceholder}`);
        }
      }
    }
    url = url.replace(urlFieldPlaceholder, "");
    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };
    try {
      await this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
      return null;
    } catch (err) {
      logError(err, "sendPostAuthLink", PRIORITY_HIGH);
      return err;
    }
  }
  // loginWithMagicLink ... Returns err or null.
  async loginWithMagicLink(email, link) {
    if (!email) {
      return new Error("invalid email");
    }
    if (this.auth.isSignInWithEmailLink(link)) {
      try {
        await this.auth.signInWithEmailLink(email, link);
        return null;
      } catch (err) {
        logError(err, "loginWithMagicLink", PRIORITY_NORMAL, link);
        return err;
      }
    } else {
      return new Error("invalid link");
    }
  }
  // Not used in v1
  async signupWithEmailPassword(email, password) {
    try {
      await this.auth.createUserWithEmailAndPassword(email, password);
      return true;
    } catch (err) {
      logError(err, "signupWithEmailPassword");
      return false;
    }
  }
  // signout ... Returns err or null.
  async signout() {
    try {
      await this.auth.signOut();
      return null;
    } catch (err) {
      return err;
    }
  }
  // updatePassword ... Returns err or null.
  async updatePassword(password) {
    if (this.auth.currentUser) {
      try {
        await this.auth.currentUser.updatePassword(password);
        return null
      } catch (err) {
        logError(err, "updatePassword", PRIORITY_HIGH);
        return err;
      }
    }
    return null;
  }
  addAuthStateListener(callback) {
    this.onLoginStateChanged = callback;
  }
  removeAuthStateListener() {
    this.onLoginStateChanged = null;
  }
  // __createJubiliUser ... Temp. func to create users.
  // async __createJubiliUser(email) {
  //   const actionCodeSettings = {
  //     url: magicLinkRedirectURL
  //       .replace("%ORIGIN%", "https://jubili.co")
  //       .replace("%OC_TYPE%", "join_ver")
  //       .replace("%USER_EMAIL%", email),
  //     handleCodeInApp: true,
  //   };
  //   try {
  //     await this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  //     return null;
  //   } catch (err) {
  //     logError(err, "sendMagicLink", PRIORITY_HIGH);
  //     return err;
  //   }
  // }
}