import React from "react";
import "../../containers/styles/CardDraft.less";
import { Row, Col } from "antd";
import OC from "../../oc";
import "../styles/cssanimation.min.css";
import { LoadingOutlined } from "@ant-design/icons";
import Confetti from "react-dom-confetti";
import { isMobile } from "react-device-detect";
import ColorThief from "colorthief";
//import LazyLoad from "react-lazyload";
import { OC_PROD_TITLE } from "../../shared/constants";

const configConfetti = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  width: "10px",
  height: "10px",
  perspective: "1000px",
};

const mobileConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 25,
  elementCount: 50,
  dragFriction: 0.23,
  width: "6px",
  height: "6px",
  perspective: "1000px",
};

function animateSequence() {
  var a = document.getElementsByClassName("sequence");
  for (var i = 0; i < a.length; i++) {
    var $this = a[i];
    const animatorWidthThreshold = $this.clientWidth - 5;
    var letter = $this.innerHTML;
    letter = letter.trim();
    var doc = new DOMParser().parseFromString(letter, "text/html");
    letter = doc.documentElement.textContent;
    var str = "";
    var delay = 100;
    for (const ch of letter) {
      if (ch !== " ") {
        str +=
          '<span style="animation-delay:' +
          delay +
          "ms; -moz-animation-delay:" +
          delay +
          "ms; -webkit-animation-delay:" +
          delay +
          'ms; ">' +
          ch +
          "</span>";
        delay += 150;
      } else str += '<span class="space-or-newline">&nbsp;</span>';
    }
    $this.innerHTML = str;
    try {
      const spans = $this.querySelectorAll("span");
      var totalWidth = 0;
      var lastWordWidth = 0;
      var lastSpace = null;
      for (const sp of spans) {
        if (sp.classList.contains("space-or-newline")) {
          lastSpace = sp;
          lastWordWidth = 0;
        }
        lastWordWidth += sp.clientWidth;
        totalWidth += sp.clientWidth;
        if (animatorWidthThreshold && totalWidth > animatorWidthThreshold) {
          if (lastSpace) {
            lastSpace.innerHTML = "<br>";
            lastSpace.style.display = "inline";
          }
          totalWidth = lastWordWidth;
        }
      }
    } catch {}
  }
}

class CardUserView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverPhotoNotFound: false,
      coverPhoto: null,
      logo: null,
      runConfetti1: false,
      runConfetti2: false,
      runConfetti3: false,
      runConfetti4: false,
      confettiInvoked: false,
    };
  }
  loadImages() {
    const { draftCard, company } = this.props;
    if (draftCard && draftCard.id && company && company.domain) {
      const filename = `card_cover_${draftCard.id}`;
      OC.getInstance()
        .getImageURLForFileAndCompany(filename, company.domain)
        .then((url) => {
          if (!url) {
            this._isMounted && this.setState({ coverPhotoNotFound: true });
            return;
          }
          this._isMounted && this.setState({ coverPhoto: url });
        });
    }
    if (company && company.logo) {
      OC.getInstance()
        .getImageURL(company.logo)
        .then((url) => {
          if (!url) {
            this.fireConfetti();
            return;
          }
          this._isMounted && this.setState({ logo: url });
          setTimeout(
            () => this._isMounted && this.extractColorsAndExecuteConfetti()
          );
        });
    } else {
      this.fireConfetti();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    try {
      document.fonts.ready.then(function () {
        animateSequence();
      });
    } catch {
      animateSequence();
    }
    this._isMounted = true;
    this.loadImages();
  }
  fireConfetti() {
    if (this.state.confettiInvoked) {
      return;
    }
    this.setState({ confettiInvoked: true });
    setTimeout(
      () => this._isMounted && this.setState({ runConfetti1: true }),
      1000
    );
    setTimeout(
      () => this._isMounted && this.setState({ runConfetti2: true }),
      2000
    );
    setTimeout(
      () => this._isMounted && this.setState({ runConfetti3: true }),
      3000
    );
    setTimeout(
      () => this._isMounted && this.setState({ runConfetti4: true }),
      4000
    );
  }
  extractColorsAndExecuteConfetti() {
    if (this.state.confettiInvoked) {
      return;
    }
    try {
      const _that = this;
      if (this.state.logo) {
        fetch(this.state.logo)
          .then(function (response) {
            return response.blob();
          })
          .then(function (blob) {
            var myImage = document.createElement("img");
            var objectURL = URL.createObjectURL(blob);
            myImage.onload = () => {
              const colorThief = new ColorThief();
              const result = colorThief.getPalette(myImage, 5);
              configConfetti.colors = [];
              mobileConfig.colors = [];
              result.forEach((element) => {
                const cval =
                  "rgb(" +
                  element[0] +
                  "," +
                  element[1] +
                  "," +
                  element[2] +
                  ")";
                configConfetti.colors.push(cval);
                mobileConfig.colors.push(cval);
              });
              _that.fireConfetti();
            };
            myImage.src = objectURL;
          });
      }
    } catch {}
  }
  render() {
    const { draftCard, company } = this.props;
    const brandColor = company && company.brandColor;
    if (!draftCard || !company) {
      return null;
    }
    return (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={16} lg={16} className="cover">
          <Confetti
            active={this.state.runConfetti1}
            config={isMobile ? mobileConfig : configConfetti}
            className="confetti1"
          />
          <div className="brand-logo cursor-auto">
            {this.state.logo && (
              <img src={this.state.logo} alt={OC_PROD_TITLE} />
            )}
          </div>
          <Confetti
            active={this.state.runConfetti2}
            config={isMobile ? mobileConfig : configConfetti}
            className="confetti2"
          />
          <div className="heading">
            <h1>
              <div
                style={{ color: !brandColor ? "#049fd9" : brandColor }}
                className="cssanimation lePeek sequence"
              >
                {draftCard.title ? draftCard.title.trim() : ""}
              </div>
            </h1>
            <Confetti
              active={this.state.runConfetti3}
              config={isMobile ? mobileConfig : configConfetti}
              className="confetti3"
            />
            <h4>
              <div style={{ color: !brandColor ? "#049fd9" : brandColor }}>
                {draftCard.message ? draftCard.message.trim() : ""}
              </div>
            </h4>
            <Confetti
              active={this.state.runConfetti4}
              config={isMobile ? mobileConfig : configConfetti}
              className="confetti4"
            />
          </div>

          <div className="clearfix cover-photo-wrapper">
            {this.state.coverPhoto && (
              <img
                className="cover-photo"
                style={{
                  marginTop: 72,
                  width: "auto",
                  maxHeight: "400px",
                  borderRadius: "12px",
                }}
                alt="Cover"
                src={this.state.coverPhoto}
              />
            )}

            {!this.state.coverPhotoNotFound && !this.state.coverPhoto ? (
              <h3 className="loader">
                <LoadingOutlined
                  style={{ color: "#1890ff", fontSize: "24px" }}
                />
              </h3>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  }
}

export default CardUserView;
