import Model from './model';

export default class Location extends Model {
  id = null;
  ref = null;
  name = null;
  _lastUpdated = null;
  getKey() {
    return this.id;
  }
  parse(id, data, obj) {
    this.id = id;
    this.ref = obj.ref;
    this.name = data.name;
    this._lastUpdated = data._lastUpdated || null;
    return this;
  }
  getDoc() {
    return {
      name: this.name || null,
      _lastUpdated: this._lastUpdated || null,
    }
  }
}