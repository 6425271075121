import React from "react";
import "./styles/ZeroState.less";
import { Form, Input, Button } from "antd";
import {
  STATE_JUBILI_EMAIL,
  STATE_JOIN_EMAIL,
  STATE_SIGNIN_EMAIL,
  OC_PROD_TITLE,
  OC_CARD_SINGULAR,
} from "../shared/constants";
import OC from "../oc";
import signin from "./img/artwork-signin.png";
import signup from "./img/artwork-signup.png";
import logo from "./img/logo-greeted.svg";
import * as nav from "../routes/nav";
import { isProhibitedDomain } from "../shared/auth_utils";

class ZeroStateEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      busy: false,
      emailError: null,
    };
    this.onEmailNextClick = this.onEmailNextClick.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onToggleAuthView = this.onToggleAuthView.bind(this);
    this.onEmailFocus = this.onEmailFocus.bind(this);
  }
  onToggleAuthView() {
    this.setState({ emailError: null });
    const { onReroute } = this.props;
    if (this.props.authFlow === STATE_SIGNIN_EMAIL) {
      nav.goto(nav.URL_AUTH_FLOW_JOIN);
    } else {
      nav.goto(nav.URL_AUTH_FLOW_SIGNIN);
    }
    onReroute && setTimeout(() => onReroute());
  }
  onEmailFocus() {
    this.setState({ emailError: null });
  }
  onEmailChanged(e) {
    this.setState({ email: e.target.value, emailError: null });
  }
  onEmailNextClick() {
    if (!this.state.email) {
      return;
    }
    if (!this.isValidEmail(this.state.email)) {
      const error = "Please provide a valid email address";
      this.setState({ emailError: error });
      return;
    }
    if (
      this.props.authFlow === STATE_JOIN_EMAIL &&
      isProhibitedDomain(this.state.email)
    ) {
      const error = `Please enter a work email address. For the time being, ${OC_PROD_TITLE} is only available for organizations & teams.`;
      this.setState({ emailError: error });
      return;
    }
    //mz-todo: prolly validate email format.
    this.setState({ busy: true });
    const { onNext } = this.props;
    if (!onNext) {
      return;
    }
    // mz-todo: warn: This could cause some unexpected error. The function findUserBy() will
    // return null if there was an exception, even if the user actually existed. This would
    // be interpreted as if the user did not exist and might cause other bugs.
    // Handle this case, probably by introducing and exists() function that actually returns
    // err object if an exception is thrown.
    OC.getInstance()
      .findUserBy(this.state.email)
      .then((user) => {
        switch (this.props.authFlow) {
          case STATE_JUBILI_EMAIL:
            onNext(user, this.state.email);
            break;
          case STATE_SIGNIN_EMAIL:
            if (user) {
              onNext(user);
            } else {
              const error = (
                <>
                  You don't seem to have an account. Please{" "}
                  <Button
                    onClick={this.onToggleAuthView}
                    type="link"
                    style={{ padding: 0 }}
                  >
                    Sign Up
                  </Button>
                  .
                </>
              );
              this.setState({ busy: false, emailError: error });
            }
            break;
          case STATE_JOIN_EMAIL:
            if (user) {
              const error = (
                <>
                  You seem to already have an account. Please{" "}
                  <Button
                    onClick={this.onToggleAuthView}
                    type="link"
                    style={{ padding: 0 }}
                  >
                    Sign In
                  </Button>
                  .
                </>
              );
              this.setState({ busy: false, emailError: error });
            } else {
              onNext(null, this.state.email);
            }
            break;
          default:
            break;
        }
      });
  }
  renderLogo() {
    const { companyLogo } = this.props;
    if (companyLogo) {
      return (
        <div className="brand-logo">
          <img src={companyLogo} alt={OC_PROD_TITLE} />
        </div>
      );
    } else {
      return (
        <div className="brand-logo">
          <span className="product">
            <img src={logo} alt={OC_PROD_TITLE} />
          </span>
        </div>
      );
    }
  }
  isValidEmail(email) {
    if (!email || email.indexOf("@") === -1) {
      return false;
    }
    return true;
  }
  render() {
    var title = "";
    const { authFlow } = this.props;
    const { emailError } = this.state;
    switch (authFlow) {
      case STATE_JUBILI_EMAIL:
        title = `New ${OC_CARD_SINGULAR}`;
        break;
      case STATE_JOIN_EMAIL:
        title = "Sign Up";
        break;
      case STATE_SIGNIN_EMAIL:
        title = "Sign In";
        break;
      default:
        break;
    }
    return (
      <div>
        <div className="ZeroState">
          <div className="title">
            {this.renderLogo()}
            <div className="artwork">
              {authFlow === STATE_SIGNIN_EMAIL ? (
                <img src={signin} alt={OC_PROD_TITLE} />
              ) : (
                <img src={signup} alt={OC_PROD_TITLE} />
              )}
            </div>
            <h2>{title}</h2>
            {this.props.userTimeout ? (
              <p>
                You were signed out due to inactivity. Please enter your email
                to sign in again.
              </p>
            ) : null}
          </div>

          <Form name="basic">
            <Form.Item
              rules={[{ required: true, message: "Please select recipient" }]}
            >
              <h4>Your Work Email Address</h4>
              <Input
                autoFocus
                style={emailError && { borderColor: "#FAAD14", borderWidth: 1 }}
                onFocus={this.onEmailFocus}
                onChange={this.onEmailChanged}
                placeholder="Pam.Beesly@DunderMifflin.com"
                prefix={<i className="fal fa-envelope"></i>}
              />
              {emailError && (
                <span className="warning-state">{emailError}</span>
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                loading={this.state.busy}
                size="large"
                block
                htmlType="submit"
                onClick={this.onEmailNextClick}
              >
                Next
              </Button>

              <Button
                style={{ display: "block" }}
                disabled={this.state.busy}
                type="link"
                onClick={this.onToggleAuthView}
                className="sign-in-link secondary"
              >
                {authFlow === STATE_SIGNIN_EMAIL
                  ? "Don't have an account? Sign Up"
                  : "Already have an account? Sign In"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default ZeroStateEmail;
