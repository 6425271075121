import React from "react";
import "./styles/HeaderOne.less";
import { Layout, Button } from "antd";
import logo from "./img/logo-greeted.svg";
import * as nav from "../routes/nav";
import { OC_PROD_TITLE } from "../shared/constants";

const { Header } = Layout;

class HeaderLogoOnly extends React.Component {
  constructor(props) {
    super(props);
    this.onLogoClick = this.onLogoClick.bind(this);
  }
  onLogoClick() {
    nav.gotoMain();
  }
  render() {
    return (
      <div className="HeaderLogin">
        <Layout>
          <Header theme="light">
            <div className="logo-parent">
              <div className="logo">
                {this.props.showLogo ? (
                  <Button type="link" onClick={this.onLogoClick}>
                    <img src={logo} alt={OC_PROD_TITLE} />
                  </Button>
                ) : null}
              </div>
              <div className="logo" />
            </div>
          </Header>
        </Layout>
      </div>
    );
  }
}

export default HeaderLogoOnly;
