import React from "react";
import { Button, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles/Modal.less";
import OC from "../oc";
import { OC_PROD_TITLE } from "../shared/constants";
import DayMonthPicker from "./DayMonthPicker";
import { isNonNegativeInteger } from "../shared/util";

class ModalBirthdayCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      dobDay: null,
      dobMonth: null,
      isValidDOB: false,
      logoFetched: false,
      companyLogo: null,
    };
    this.onDoneClicked = this.onDoneClicked.bind(this);
    this.onDobChanged = this.onDobChanged.bind(this);
    this.afterModalClose = this.afterModalClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.dobPickerRef = React.createRef();
  }
  onDobChanged(val) {
    this.setState({
      dobDay: val.day,
      dobMonth: val.month,
      isValidDOB: val.isValid,
    });
  }
  handleCancel() {
    const { toggleBirthdayModal } = this.props.metadataActions;
    toggleBirthdayModal && toggleBirthdayModal(false);
  }
  afterModalClose() {
    this.setState({
      busy: false,
      dobDay: null,
      dobMonth: null,
    });
  }
  async onDoneClicked() {
    const { user } = this.props.metadata;
    const { isValidDOB } = this.state;
    const { toggleBirthdayModal } = this.props.metadataActions;
    if (this.state.busy) {
      return;
    }
    if (!isValidDOB) {
      const { current } = this.dobPickerRef;
      current && current.validate();
      return;
    }
    if (!user) {
      this.setState({ busy: false });
      toggleBirthdayModal && toggleBirthdayModal(false);
      return;
    }
    if (this.state.dobDay && isNonNegativeInteger(this.state.dobMonth)) {
      user.dob.day = this.state.dobDay;
      user.dob.month = this.state.dobMonth;
      this.setState({ busy: true });
      this.addUpdateUser(user);
    } else {
      this.setState({ busy: false });
      toggleBirthdayModal && toggleBirthdayModal(false);
    }
  }
  addUpdateUser(user) {
    const { toggleBirthdayModal } = this.props.metadataActions;
    const { updateProfile } = this.props.authActions;
    updateProfile &&
      updateProfile(user, null, (err) => {
        if (err) {
          message.error(
            "Could not update user info. Please check network connection."
          );
        }
        this.setState({ busy: false });
        toggleBirthdayModal && toggleBirthdayModal(false);
      });
  }
  shouldShow() {
    const { user } = this.props.metadata;
    const { inviteModalVisible } = this.props.card;
    if (user && user.hasDOB) {
      if (!user.hasDOB() && !inviteModalVisible) {
        return true;
      }
    }
    return false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadImages();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  loadImages() {
    const { company } = this.props.metadata;
    if (company && company.permanentUrl) {
      this.setState({ companyLogo: company.permanentUrl, logoFetched: true });
      return;
    }
    if (company && company.logo) {
      OC.getInstance()
        .getImageURL(company.logo)
        .then((url) => {
          if (url) {
            this._isMounted &&
              this.setState({ companyLogo: url, logoFetched: true });
            return;
          }
          this._isMounted && this.setState({ logoFetched: true });
        })
        .catch(() => {
          this._isMounted && this.setState({ logoFetched: true });
        });
      return;
    }
    this.setState({ logoFetched: true });
  }
  renderLogo() {
    const { companyLogo } = this.state;
    if (companyLogo) {
      return (
        <div className="brand-logo">
          <span className="product">
            <img src={companyLogo} alt={OC_PROD_TITLE} />
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
  render() {
    const { brithdayModalVisible, user } = this.props.metadata;
    return (
      <Modal
        width={520}
        maskClosable={true}
        visible={brithdayModalVisible && this.shouldShow()}
        afterClose={this.afterModalClose}
        footer={null}
        className="birthday-modal"
        style={{ zIndex: 9999 }}
        onCancel={this.handleCancel}
        centered
      >
        {!this.state.logoFetched ? (
          <h3 className="loader">
            <LoadingOutlined />
          </h3>
        ) : (
          <div className="ModalBirthdayCollection">
            {this.renderLogo()}
            <h2>Looks like we don’t have your birthday</h2>

            <h4>Your Birthday</h4>
            <DayMonthPicker
              onValueChange={this.onDobChanged}
              day={user?.dob?.day}
              month={user?.dob?.month}
              isNullable={false}
              ref={this.dobPickerRef}
            />
            <Button
              disabled={this.state.busy}
              loading={this.state.busy}
              onClick={this.onDoneClicked}
              type="primary"
              size="large"
              block
              htmlType="submit"
              className="add-invites"
            >
              Done
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalBirthdayCollection;
