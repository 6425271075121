import * as types from '../constants';
import OC from '../oc';

export function toggleSettingsView(visible) {
  return (dispatch) => {
    return dispatch({
      type: types.ON_TOGGLE_SETTINGS_VIEW,
      visible: visible,
    });
  };
}
export function login(username, password, statusCallback) {
  return dispatch => {
    OC.getInstance().login(username, password)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          return dispatch(loginFailedDispatch(err));
        } else {
          statusCallback && statusCallback();
          return dispatch(loginSuccessDispatch());
        }
      });
  }
}
export function logout() {
  return dispatch => {
    OC.getInstance().logout()
      .then(() => {
        return dispatch(logoutDispatch());
      });
  }
}
export function sendMagicLink(email, ocType, statusCallback) {
  return dispatch => {
    OC.getInstance().sendMagicLink(email, ocType)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          return dispatch(sendMagicLinkFailedDispatch(err));
        } else {
          statusCallback && statusCallback();
          return dispatch(sendMagicLinkSuccessDispatch());
        }
      });
  }
}
export function signupCompletion(data) {
  return dispatch => {
    OC.getInstance().signupCompletion(
      data.locationID,
      data.departmentID,
      data.birthDate,
      data.birthMonth,
      data.password,
    ).then((err) => {
      if (err) {
        return dispatch(signCompletionFailedDispatch(err));
      } else {
        return dispatch(signCompletionSuccessDispatch());
      }
    });
  }
}
export function resetErrors() {
  return dispatch => {
    return dispatch(resetErrorDispatch());
  }
}
export function changeAuthUserTo(user) {
  return dispatch => {
    return dispatch(authStateDispatch(user))
  }
}
export function fetchUserInfo(callback) {
  return dispatch => {
    const email = OC.getInstance().getCurrentAuthUserEmail();
    if (!email) {
      callback && callback(null);
      return dispatch({ type: "NONE" });
    }
    dispatch(fetchingUserInfoDispatch());
    OC.getInstance().findCompanyUser(email)
      .then((res) => {
        callback && callback(res);
        return dispatch(onfetchedUserInfoDispatch(res));
      });
  }
}
export function updateProfile(user, password, statusCallback) {
  return dispatch => {
    OC.getInstance().updateProfile(user, password)
      .then((err) => {
        if (err) {
          statusCallback && statusCallback(err);
          return dispatch({ type: "" });
        } else {
          if (!user.avatar && user.avatarDownloadURL) {
            user.avatarDownloadURL = null;
          }
          statusCallback && statusCallback();
          return dispatch(onUpdateUserInfoDispatch(user));
        }
      });
  }
}
export function fetchAvatarURL(path) {
  return dispatch => {
    OC.getInstance().getImageURL(path)
      .then((res) => {
        return dispatch(fetchAvatarURLDispatch(res || ""));
      });
  }
}

function authStateDispatch(user) {
  return {
    type: types.ON_AUTH_CHANGED,
    user: user,
  }
}
function loginSuccessDispatch() {
  return {
    type: types.ON_LOGIN_SUCCESS,
  }
}
function loginFailedDispatch(err) {
  //mz-todo: check err object to post proper error message
  return {
    type: types.ON_LOGIN_FAILED,
    msg: "Invalid username or password",
  }
}
function logoutDispatch() {
  return {
    type: types.ON_LOGOUT,
  }
}
function sendMagicLinkSuccessDispatch() {
  return {
    type: types.ON_MAGIC_SIGNUP_SUCCESS,
  }
}
function sendMagicLinkFailedDispatch(err) {
  //mz-todo: check err object to post proper error message
  return {
    type: types.ON_MAGIC_SIGNUP_FAILED,
    msg: "Failed to signup",
  }
}
function resetErrorDispatch() {
  return {
    type: types.RESET_AUTH_ERRORS,
  }
}
function signCompletionFailedDispatch(err) {
  //mz-todo: check err object to post proper error message
  return {
    type: types.ON_SIGNUP_COMPLETION_FAILED,
    msg: "Signup completion failed",
  }
}
function signCompletionSuccessDispatch() {
  return {
    type: types.ON_SIGNUP_COMPLETION_SUCCESS,
  }
}
function fetchingUserInfoDispatch() {
  return {
    type: types.FETCHING_USER_INFO,
  }
}
function onfetchedUserInfoDispatch(res) {
  return {
    type: types.ON_FETCHED_USER_INFO,
    userInfo: res,
  }
}
function onUpdateUserInfoDispatch(res) {
  return {
    type: types.ON_UPDATE_USER_INFO,
    userInfo: res,
  }
}
function fetchAvatarURLDispatch(res) {
  return {
    type: types.ON_FETCH_AVATAR,
    downloadURL: res,
  }
}