import { combineReducers } from 'redux';
import { auth } from './auth';
import { card } from './card';
import { metadata } from './metadata';
import { payment } from './payment';
import { cardTemplates } from './cardTemplates'; //mz-todo: Remove later.

const rootReducer = combineReducers({
	auth, card, cardTemplates, metadata, payment,
});

export default rootReducer;