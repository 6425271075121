import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/payment";
import * as nav from "../routes/nav";
import { Button, Row, Card, Col, Modal, message } from "antd";
import "./styles/Modal.less";
import { LoadingOutlined } from "@ant-design/icons";
import OC from "../oc";
import Logger, { PRIORITY_CRITICAL } from "../shared/logger";
import EmojiHandler from "../components/EmojiHandler";

class ModalPayment extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.state = {
      busyCTA: false,
      loadingCTA: {},
    };
  }
  async onSubClicked(product) {
    const { encodedData } = this.props.payment;
    const priceId = product.getPriceId();
    if (!priceId) {
      return;
    }
    const { loadingCTA = {} } = this.state;
    const { protocol, host } = window.location;
    var successUrl = `${protocol}//${host}`;
    if (successUrl.endsWith("/")) {
      successUrl = successUrl.slice(0,-1);
    }
    successUrl = !encodedData ? successUrl : `${successUrl}${nav.URL_HOMEPAGE}?pi=${encodedData}`
    loadingCTA[product.id] = true;
    this.setState({ busyCTA: true, loadingCTA: loadingCTA });
    await OC.getInstance().startSubscription(
      priceId,
      true,
      successUrl,
      (url, err) => {
        if (err || !url) {
          loadingCTA[product.id] = false;
          this.setState({ busyCTA: false, loadingCTA: loadingCTA });
          message.error(
            "Could not continue due to an error. We are investigating the issue. Please try again later.",
            8
          );
          Logger.logStripe(
            PRIORITY_CRITICAL,
            err ? err.message : "invalid url",
            "ModalPayment:onReloadClicked",
            { productId: product.id }
          );
          return;
        }
        window.location.assign(url);
      }
    );
  }
  onCloseModal() {
    this.props.actions.togglePaymentModal(false);
  }
  getDisplayProducts(products) {
    return products.filter((element) => element.getTotalCards() !== 1);
  }
  isAlreadySubscribed(product) {
    const { activeSubscriptions } = this.props.payment;
    if (!activeSubscriptions) {
      return false;
    }
    const item = activeSubscriptions.find(
      (item) => item.getProductId() === product.id
    );
    return item ? true : false;
  }
  renderProducts() {
    const { products, activePlan, activeSubscriptions } = this.props.payment;
    const { busyCTA, loadingCTA = {} } = this.state;
    if (!products || !products.length || !activePlan || !activeSubscriptions) {
      return (
        <h3
          className="loader"
          style={{
            marginTop: "0px",
          }}
        >
          <LoadingOutlined />
        </h3>
      );
    }
    const displayProducts = this.getDisplayProducts(products);
    return displayProducts.map((product) => {
      return (
        <Col key={`product_${product.id}`} xs={24} sm={12} md={6} lg={6} xl={6}>
          <Card hoverable title={product.name} bordered>
            <div className="quantity">
              <span>{product.getTotalCards()} Cards</span>
              <span>anually</span>
            </div>

            <div className="price">
              <span>{product.getSingleCardPriceTag()}</span>
              <span>per card</span>
            </div>

            {this.isAlreadySubscribed(product) ? (
              <Button
                disabled={busyCTA}
                loading={loadingCTA[product.id]}
                onClick={this.onSubClicked.bind(this, product)}
              >
                Reload
              </Button>
            ) : (
              <Button
                disabled={busyCTA}
                loading={loadingCTA[product.id]}
                onClick={this.onSubClicked.bind(this, product)}
              >
                Upgrade
              </Button>
            )}
          </Card>
        </Col>
      );
    });
  }
  loadSubscriptionData() {
    const { products, activePlan, activeSubscriptions } = this.props.payment;
    if (!products || !products.length) {
      this.props.actions.fetchProducts();
    }
    if (!activePlan || !activeSubscriptions || !activeSubscriptions.length) {
      this.props.actions.loadActiveSubscription((res) => {
        this.props.actions.loadActivePlan(res);
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.payment.paymentModalVisible === false &&
      this.props.payment.paymentModalVisible === true
    ) {
      this.loadSubscriptionData();
    }
  }
  render() {
    const { paymentModalVisible } = this.props.payment;
    const freeCards = this.props.user?.getRemainingFreeCards 
      ? this.props.user.getRemainingFreeCards() 
      : 0;
    return (
      <Modal
        width={"100%"}
        maskClosable={true}
        visible={paymentModalVisible}
        onCancel={this.onCloseModal}
        footer={null}
        className="payment-modal"
        style={{ zIndex: 9999 }}
        destroyOnClose={true}
        centered
        closeIcon={<i className="fal fa-times"></i>}
      >
        <div className="ModalPayment">
          <h2>Pick a Plan</h2>
          {freeCards > 0 ? <h4 className="sub-text">
            Your {freeCards} Available {freeCards === 1 ? "Card" : "Cards"} will be rolled over to the new subscription{" "}
            <EmojiHandler>😊</EmojiHandler>
          </h4> : null}
          <Row gutter={24}>{this.renderProducts()}</Row>
          <h4 className="footer-text">
            <EmojiHandler>💡</EmojiHandler> Reload, Upgrade, or Cancel
            Subscription at any time.
          </h4>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { payment } = state;
  return { payment };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPayment);
