import { GiphyFetch } from "@giphy/js-fetch-api";
import { categories } from "./constants";
import Logger, { PRIORITY_CRITICAL } from "./logger";
import { message } from "antd";
import OC from "../oc"; // WARN: Remove later. Just for automation view on prod.

function isWindows() {
  return navigator.platform.indexOf("Win") > -1;
}

// function isApple() {
//   return (
//     navigator.userAgent.indexOf("Mac OS X") !== -1 ||
//     navigator.userAgent.indexOf("iPhone") !== -1
//   );
// }

export function showTwemoji() {
  if (
    window.location.search &&
    window.location.search.indexOf("twemoji") > -1
  ) {
    return true;
  }
  return isWindows();
}

export const gifyAPIKey = "hneTiHTRsFh3FhY9j3L3CmddMCYXEwvC";
export const gf = new GiphyFetch(gifyAPIKey);

export function groupCardsExist() {
  return !!categories.find(
    (element) => element.isGroupType && !element.isHidden
  );
}

export function getLink(errTag) {
  try {
    var url = window.location.href?.split("?")[0];
    url = url?.split("#")[0];
    if (!url) {
      throw new Error("oc: error while splitting href");
    }
    return url;
  } catch (err) {
    try {
      Logger.logAnalysis(
        PRIORITY_CRITICAL,
        "href or href split failed",
        errTag ? errTag : "util:getLink",
        {
          href: window.location?.href,
          err: err?.message,
        }
      );
    } catch (logErr) {
      console.log("oc_fs_href", errTag, logErr); //mz-info: For fs log.
    }
    return "https://greeted.io";
  }
}

export function copyLink(errTag) {
  try {
    navigator &&
      navigator.clipboard &&
      navigator.clipboard.writeText &&
      navigator.clipboard.writeText(getLink(errTag)) &&
      message.info("Link Copied!");
  } catch (err) {
    try {
      Logger.logAnalysis(
        PRIORITY_CRITICAL,
        "copy link: navigator object failed",
        errTag ? errTag : "util:copyLink",
        {
          navigator: !!navigator,
          clipboard: !!(navigator && navigator.clipboard),
          writeText: !!(
            navigator &&
            navigator.clipboard &&
            navigator.clipboard.writeText
          ),
          err: err?.message,
        }
      );
    } catch (logErr) {
      console.log("oc_fs_href_clip", errTag, logErr); //mz-info: For fs log.
    }
  }
}

export function isNonNegativeInteger(val) {
  return (
    val !== null && val !== undefined && val >= 0 && typeof val === "number"
  );
}

// WARN: Remove later. Just for automation view on prod.
export function isInternalUser() {
  try {
    const internalDomains = [
      "gmail.com",
      "dundermifflininc.net",
      "multiply.io",
      "outlook.com",
    ];
    const email = OC.getInstance().getCurrentAuthUserEmail();
    if (email) {
      const domain = email.split("@").pop();
      return internalDomains.indexOf(domain) >= 0;
    }
  } catch {
    return false;
  }
}
