import Model from "./model";
import OC from "../";
import {
  CAT_WOO_CANDIDATE,
  CAT_THANKSGIVING,
  CAT_AN_OCCASION,
  CAT_HAPPY_HOLIDAYS,
  CAT_HAPPY_NEW_YEAR,
  CAT_OTHER,
  categories,
} from "../../shared/constants";

export default class Card extends Model {
  id = null;
  category = null;
  title = null;
  message = null;
  postsCount = 0;
  postUsers = [];
  // photo = null; //Deprecated
  // art = null; //Deprecated
  delivery = {
    ownerZoneName: null,
    ownerZoneNameIANA: null,
    ownerOffset: null,
    utc: null,
    triggerAt: null,
  };
  owner = null;
  recipientRef = null;
  recipient = null;
  postsDisabled = false;
  categoryData = {};
  invited = [];
  createdAt = null;
  getKey() {
    return this.id;
  }
  async parse(id, data) {
    this.id = id;
    this.category = data.category;
    this.title = data.title;
    this.message = data.message;
    this.postsCount = data.postsCount || 0;
    this.postUsers = data.postUsers || [];
    // this.photo = data.photo; //Deprecated
    // this.art = data.art; //Deprecated
    this.delivery = data.delivery;
    this.owner = data.owner;
    this.postsDisabled = data.postsDisabled || false;
    this.categoryData = data.categoryData || {};
    this.invited = data.invited || [];
    this.createdAt = data.createdAt;
    if (data.recipient) {
      this.recipientRef = data.recipient;
      const res = await data.recipient.get();
      this.recipient = res.data();
    }
    return this;
  }
  getDoc() {
    if (this.isAdmin()) {
      return this._getDocForCardOwner();
    } else {
      return this._getDocForNonOwner();
    }
  }
  _getDocForCardOwner() {
    const res = {
      category: this.category || "",
      title: this.title || "",
      message: this.message || "",
      // mz-info: These fields are only updated explicitly from store.
      // postsCount: this.postsCount || 0,
      // postUsers: this.postUsers || [],

      // photo: this.photo || null, // Deprecated
      // art: this.art || null, // Deprecated
      delivery: this.delivery || null,
      owner: this.owner,
      recipient: this.recipientRef,
      postsDisabled: this.postsDisabled || false,
      categoryData: this.categoryData || null,
      invited: this.invited || [],
      createdAt: this.createdAt || null,
    };
    if (this.inviterLastEmail) {
      res.inviterLastEmail = this.inviterLastEmail;
    }
    if (this.requestManualDispatch) {
      res.requestManualDispatch = true;
    }
    return res;
  }
  _getDocForNonOwner() {
    const res = {
      invited: this.invited || [],
    };
    if (this.inviterLastEmail) {
      res.inviterLastEmail = this.inviterLastEmail;
    }
    return res;
  }  
  cloneNew() {
    const c = new Card();
    c.id = this.id;
    c.category = this.category;
    c.title = this.title;
    c.message = this.message;
    c.postsCount = this.postsCount;
    c.postUsers = this.postUsers;
    // c.photo = this.photo; //Deprecated
    // c.art = this.art; //Deprecated
    c.delivery = this.delivery;
    c.owner = this.owner;
    c.recipientRef = this.recipientRef;
    c.recipient = this.recipient;
    c.postsDisabled = this.postsDisabled;
    c.categoryData = this.categoryData;
    c.invited = this.invited;
    c.createdAt = this.createdAt;
    return c;
  }
  getSerializable(currentUserEmail) {
    const data = {
      id: this.id,
      category: this.category,
      title: this.title,
      message: this.message,
      postsCount: this.postsCount || 0,
      postUsers: this.postUsers || [],
      // photo: this.photo, //Deprecated
      // art: this.art, //Deprecated
      delivery: this.delivery,
      recipient: this.recipient,
      postsDisabled: this.postsDisabled,
      categoryData: this.categoryData,
      invited: this.invited || [],
      createdAt: this.createdAt,
      __isAdmin:
        (this.owner && this.owner.id) === currentUserEmail ? true : false,
      __cached: true,
    };
    if (data.recipient) {
      data.recipient.locationRef = null;
    }
    return data;
  }
  deserialize(data) {
    this.id = data.id;
    this.category = data.category;
    this.title = data.title;
    this.message = data.message;
    this.postsCount = data.postsCount;
    this.postUsers = data.postUsers;
    // this.photo = data.photo; //Deprecated
    // this.art = data.art; //Deprecated
    this.delivery = data.delivery;
    this.recipient = data.recipient;
    this.postsDisabled = data.postsDisabled;
    this.categoryData = data.categoryData;
    this.invited = data.invited;
    this.createdAt = data.createdAt;
    this.__isAdmin = data.__isAdmin;
    this.__cached = true;
    return this;
  }
  getCategoryName() {
    const data = categories.find(item => item.key === this.category);
    return data?.name || "";
  }
  getRecipientEmail() {
    if (this.isEmailLessCard()) {
      return null;
    }
    const { recipient } = this;
    if (recipient && recipient.email) {
      return recipient.email;
    }
    return null;
  }
  getRecipientName() {
    if (this.isGroupType()) {
      return "";
    }
    switch (this.category) {
      case CAT_WOO_CANDIDATE:
        return this.categoryData && this.categoryData.name
          ? this.categoryData.name
          : "";
      case CAT_OTHER:
        if (this.recipient === null) {
          return this.categoryData && this.categoryData.name
            ? this.categoryData.name
            : "";
        } else {
          return this.recipient && this.recipient.name ? this.recipient.name : "";
        }
      default:
        return this.recipient && this.recipient.name ? this.recipient.name : "";
    }
  }
  passesNameRequirements() {
    if (this.isGroupType()) {
      return true;
    }
    return !!this.getRecipientName();
  }
  passesEmailRequirements() {
    if (this.isGroupType()) {
      return true;
    }
    switch (this.category) {
      case CAT_WOO_CANDIDATE:
        return true;
      case CAT_OTHER:
        if (this.recipient === null) {
          return true;
        } else {
          return !!(this.recipient && this.recipient.email);
        }

      default:
        return !!(this.recipient && this.recipient.email);
    }
  }
  isEmailLessCard() {
    if (this.isGroupType()) {
      return true;
    }
    if (this.category === CAT_OTHER && this.recipient === null) {
      return true;
    }
    return this.category === CAT_WOO_CANDIDATE;
  }
  isGroupType() {
    return (
      this.category === CAT_THANKSGIVING ||
      this.category === CAT_AN_OCCASION ||
      this.category === CAT_HAPPY_HOLIDAYS ||
      this.category === CAT_HAPPY_NEW_YEAR
    );
  }
  getAvatarFetchPath() {
    if (this.isGroupType()) {
      return null;
    }
    switch (this.category) {
      case CAT_WOO_CANDIDATE:
        return null;
        case CAT_OTHER:
        if (this.recipient === null) {
          return null;
        } else {
          return this.recipient && this.recipient.avatar;
        }
      default:
        return this.recipient && this.recipient.avatar;
    }
  }
  getPostsCount() {
    if (this.postsCount && this.postsCount !== undefined) {
      return this.postsCount;
    }
    return 0;
  }
  getPostUsers() {
    if (this.postUsers && this.postUsers !== undefined) {
      return this.postUsers;
    }
    return [];
  }
  // Misleading function name; isAdmin() should be isOwner()
  isAdmin() {
    const authEmail = OC.getInstance().getCurrentAuthUserEmail();
    if (!authEmail) {
      return false;
    }
    return this.isCached && this.isCached()
      ? this.__isAdmin
      : authEmail === (this.owner && this.owner.id);
  }
  isRecipient() {
    const authEmail = OC.getInstance().getCurrentAuthUserEmail();
    if (!authEmail) {
      return false;
    }
    return (this.recipient && this.recipient.email) === authEmail;
  }
}
