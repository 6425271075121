import React from "react";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import Fade from "react-reveal/Fade";

const { Dragger } = Upload;
const DEFAULT_UPLOAD_SIZE = 2;

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      previewImage: null,
      edited: false,
    };
    this.beforeUpload = this.beforeUpload.bind(this);
    this.onImageRemoved = this.onImageRemoved.bind(this);
  }
  onImageRemoved() {
    this.setState({ file: null, previewImage: null, edited: true });
    this.props.onFileChanged && this.props.onFileChanged(null);
  }
  loadPreview(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ previewImage: e.target.result });
    };
    reader.readAsDataURL(file);
  }
  beforeUpload(file) {
    var isAllowedFormat =
      file.type === "image/jpeg" || file.type === "image/png";
    isAllowedFormat =
      isAllowedFormat || (this.props.allowGif && file.type === "image/gif");
    if (!isAllowedFormat) {
      message.error(
        `Only JPG/PNG${this.props.allowGif ? "/GIF" : ""} files are allowed`
      );
      return false;
    }
    const uploadSize = this.props.uploadSize || DEFAULT_UPLOAD_SIZE;
    const isLt1M = file.size / 1024 / 1024 < uploadSize;
    if (!isLt1M) {
      message.error(`Image must smaller than ${uploadSize}MB`);
      return false;
    }
    this.setState({ file: file, edited: true });
    this.loadPreview(file);
    this.props.onFileChanged && this.props.onFileChanged(file);
    return false;
  }
  getEmptyImage() {
    const byteString =
      "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";
    const mimeString = "image/png";
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    const f = new File([blob], `image`, { type: mimeString });
    f.uid = "default-" + Math.random().toString();
    return f;
  }
  loadDefaultPreview() {
    if (!this.state.edited && this.props.defaultPreview) {
      setTimeout(() => {
        this.setState({
          file: this.getEmptyImage(),
          previewImage: this.props.defaultPreview,
          edited: true,
        });
      });
    }
  }
  getDragger() {
    const fileItem = [];
    if (this.state.file) {
      fileItem.push(this.state.file);
    }
    return (
      <Dragger
        style={this.props.busy ? { pointerEvents: "none" } : null}
        className={`${this.state.previewImage ? "has-image" : ""}`}
        fileList={fileItem}
        onRemove={this.onImageRemoved}
        multiple={false}
        beforeUpload={this.beforeUpload}
      >
        {this.state.previewImage ? (
          <Fade>
            <div>
              <img src={this.state.previewImage} alt="Upload" />
            </div>
          </Fade>
        ) : (
          this.props.children
        )}
      </Dragger>
    );
  }
  render() {
    this.loadDefaultPreview();
    return this.props.showCropTool ? (
      <ImgCrop
        shape={this.props.shape ? this.props.shape : "rect"}
        rotate={true}
        grid={true}
        aspect={this.props.aspect ? this.props.aspect : 1 / 1}
      >
        {this.getDragger()}
      </ImgCrop>
    ) : (
      this.getDragger()
    );
  }
}
export default ImageUploader;
