import React from "react";
import "./styles/StartState.less";
import start from "./img/artwork-start.png";
import CardInitButton from "./CardInitButton";
import { OC_PROD_TITLE, OC_CARD_SINGULAR } from "../shared/constants";

class StartState extends React.Component {
  render() {
    return (
      <div className="StartState">
        <div className="title">
          <div className="artwork">
            <img src={start} alt={OC_PROD_TITLE} />
          </div>
          <h2>Celebrate a teammate</h2>
          <p>Bring the team together and make occasions truly special.</p>
        </div>
        <CardInitButton
          buttonProps={{
            type: "primary",
            size: "large",
            block: true,
            className: "new-greeting",
          }}
        >
          <i className="fal fa-plus"></i>&nbsp;New {OC_CARD_SINGULAR}
        </CardInitButton>
      </div>
    );
  }
}

export default StartState;
