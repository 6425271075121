import React from "react";
import "./styles/VerifyEmail.less";
import { Button, Space } from "antd";
import pending from "./img/artwork-pendingemail.png";
import gmail from "./img/icon-gmail.svg";
import outlook from "./img/icon-outlook.svg";
import yahoo from "./img/icon-yahoo.svg";
import { STATE_VERIFICATION_LINK, OC_PROD_TITLE } from "../shared/constants";

class PendingEmail extends React.Component {
  //mz-todo: warning... inline styles for the message element. Fix later.
  render() {
    return (
      <div className="VerifyEmail Pending">
        <div className="title">
          <div className="brand-logo"></div>
          <div className="artwork">
            <img src={pending} alt={OC_PROD_TITLE} />
          </div>
          <h2>Your post is pending email verification</h2>
          {this.props.authFlow === STATE_VERIFICATION_LINK ? (
            <p style={{ textAlign: "center" }}>
              Please verify your email address by signing in with that link.
            </p>
          ) : (
            <p style={{ display: "inline-block", textAlign: "center" }}>
              We’ve emailed you a sign-in link. Simply click it to finish
              posting. You can always edit your post after it has been posted.
            </p>
          )}
        </div>

        <div className="services">
          <Space size="large">
            <Button size="large" href="http://gmail.com" target="blank">
              <img src={gmail} alt={OC_PROD_TITLE} /> Open Gmail
            </Button>
            <Button size="large" href="http://outlook.com" target="blank">
              <img src={outlook} alt={OC_PROD_TITLE} /> Open Outlook
            </Button>
            <Button size="large" href="http://login.yahoo.com" target="blank">
              <img src={yahoo} alt={OC_PROD_TITLE} /> Open Yahoo
            </Button>
          </Space>
        </div>
        <p className="text-center resend-email">
          Didn’t receive email?{" "}
          <Button
            type="link"
            disabled={this.props.busy}
            onClick={this.props.onResend}
          >
            {this.props.busy ? "Resending ..." : "Resend Email"}
          </Button>
        </p>
      </div>
    );
  }
}

export default PendingEmail;
